import React, { Fragment, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import InputAdornment from '@mui/material/InputAdornment';
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import VolumeUp from "@mui/icons-material/VolumeUp";
import"../../assets/scss/pages/eligibility.css"
import axios from "axios";
const Input = styled(MuiInput)`
  width: 62px;
`;
export default function LoanEligibilty() {
  const [value, setValue] = useState(0);
  const [income, setIncome] = useState(0);
  const[foir,setFoir]=useState(0);
  const[tenure,setTenure]=useState(0);
  const[intrestRate,setIntrestRate]=useState(0)
  const [currentEMI, setCurrentEMI] = useState(0);
  const[finalResult,setFinalResult]=useState(0);
  const[reset,setReset]=useState(false)
  const [loanEligibility, setLoanEligibility] = useState(0);
 const resetEligibility=()=>{
  setReset(true);
  setFinalResult(0);
  setValue(0);
  setIncome(0);
  setFoir(0);
  setIntrestRate(0);
  setTenure(0);
  setCurrentEMI(0);
  
  setdata({
    bankVO: {
      bankId: "",
    },
  })
 }
  const handleIncomeChange = (e, newValue) => {
    setIncome(newValue);
    // setIncome({
    //   ...income,
    //   [e.target.name]:e.target.value
    // })
  };
  const handleCurrentEMIChange = (event, newValue) => {
    setCurrentEMI(newValue);
  };
  const handleFoirChange = (event, newValue) => {
    setFoir(newValue);
  };
  const handleTenureChange = (event, newValue) => {
    setTenure(newValue);
  };
  const handleIntrestRateChange = (event, newValue) => {
    setIntrestRate(newValue);
  };
  const [data, setdata] = useState({
    monthlyIncome: "10000",
    otherEMI: "",
    foir: "",
    intrestRate: "",
    tenure: "",
    bankVO: {
      bankId: "",
    },
  });

  const bankId=data.bankVO?.bankId;

  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getFoirBybankIdAndIncome?bankId=${bankId}&actualIncome=${income}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);

        setFoir(response.data.foir);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [bankId,income]);
  console.log("Foir===============",foir)
  const handleChangeForBank = (e) => {
    setdata({
      ...data,
      bankVO: {
        bankId: e.target.value,
      },
    });
  };

  const handleChange = (e) => {
    setIncome({
      ...income,
      [e.target.name]: e.target.value,
    });
  };
  
  const calculate = () => {
    const r = 100000;
    const fo = foir;
    var newArray = [fo];
    console.log("newArray", newArray[0]);
    const roi = intrestRate / 12 / 100;
    const t = tenure;
    console.log("r", r);
    console.log("roi", roi);

    const EMI = (r * roi * Math.pow(1 + roi, t)) / (Math.pow(1 + roi, t) - 1);
    console.log("EMI", EMI);
    const lba = income * fo - currentEMI / EMI;
    const lba1 = (income * fo) / 100 - currentEMI;
    const lba2 = lba1 / EMI;
    const lbashort = lba2.toString().slice(0, -12);
    const replacedValue = lbashort.replace(".", ",");
    console.log("replacedValue", lbashort);
    setFinalResult(lbashort);
    //  return lbashort;
  };


  // const r = 100000;
  // const roi = intrestRate / 12 / 100;
  // console.log("r", r);
  // console.log("roi", roi);
  // const fo = foir;
  // var newArray = [fo];
  // console.log("newArray", newArray[0]);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("DATA", data);
    calculate();
  };

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? 0 : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 200) {
      setValue(200);
    }
  };
  const [banklist, setbankList] = useState([]);
  const loadBank = async () => {
    const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllBank`);
    setbankList(result.data);
  };

  useEffect(() => {
    loadBank();
  }, []);
console.log("final Result",finalResult);
  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
             <form onSubmit={handleSubmit}>
             <div className="row mt-5">
                <div className="col-md-3 mt-2">
                  <span className="d-flex justify-content-end ">Bank</span>
                </div>
                <div className="col-md-6 ">
                  <select
                    class="form-select form-select-md"
                    aria-label=".form-select-md example"
                    name="bankVO.bankId"
                    onChange={handleChangeForBank}
                     value={data.bankVO?.bankId}
                  >
                    <option value="">Please Select</option>
                    {banklist?.length > 0 ? (
                      <Fragment>
                        {banklist.map((bank, index) => {
                          return (
                            <option key={index} value={bank?.bankId}>
                              {bank?.bankName}
                            </option>
                          );
                        })}
                      </Fragment>
                    ) : null}
                  </select>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3 eligibiltyLable">
                  <span className="d-flex justify-content-end">
                    Monthly Income
                  </span>
                </div>
                <div className="col-md-6 eligibilitySlider">
                  <Slider
                    value={income}
                    min={1000}
                    max={1000000}
                    step={1000}
                    onChange={handleIncomeChange}
                    aria-labelledby="income-slider"
                  />
                </div>
                <div className="col-md-2">
                  <Input
                  type="number"
                    name="income"
                    className="form-control sm form-control-plaintext eligibilityInput"
                    startAdornment={<InputAdornment position="start">₹</InputAdornment>}
            value={income}
            size="small"
            onChange={(e)=>setIncome(e.target.value)}
                    style={{width:"100px"}}
                    //onBlur={handleBlur}
                  />
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-3 eligibiltyLable">
                  <span className="d-flex justify-content-end">
                    Current EMI
                  </span>
                </div>
                <div className="col-md-6 eligibilitySlider">
                  <Slider
                    value={currentEMI}
                    min={5000}
                    max={1000000}
                    step={1000}
                    onChange={handleCurrentEMIChange}
                    aria-labelledby="income-slider"
                  />
                </div>
                <div className="col-md-2">
                  <Input
                    className="form-control sm form-control-plaintext eligibilityInput"
                    name="monthlyIncome"
                    value={currentEMI}
                    size="small"
                    startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                    style={{width:"100px"}}
                    onChange={(e)=>setCurrentEMI(e.target.value)}
                    //onBlur={handleBlur}
                  />
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-3 eligibiltyLable">
                  <span className="d-flex justify-content-end">
                    Fixed Obligations Income Ratio
                  </span>
                </div>
                <div className="col-md-6 eligibilitySlider">
                  <Slider
                    value={foir}
                    min={5}
                    disabled
                    max={100}
                    step={5}
                    onChange={handleFoirChange}
                    aria-labelledby="income-slider"
                  />
                </div>
                <div className="col-md-2 eligibilityInput">
                  <Input
                    className="form-control sm form-control-plaintext"
                    name="monthlyIncome"
                    value={foir}
                    disabled
                    size="small"
                    style={{width:"100px"}}
                    onChange={(e)=>setFoir(e.target.value)}
                    endAdornment={<InputAdornment position="end"> % </InputAdornment>}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">%</InputAdornment>
                    //   )
                    // }}
                  />
                </div>
                <div className="com-md-1"></div>
                <div className="col-md-3 eligibiltyLable">
                  <span className="d-flex justify-content-end">
                    Tenure (In Months)
                  </span>
                </div>
                <div className="col-md-6 eligibilitySlider">
                  <Slider
                    value={tenure}
                    min={0}
                    max={360}
                    step={12}
                    onChange={handleTenureChange}
                    aria-labelledby="income-slider"
                  />
                </div>
                <div className="col-md-2 eligibilityInput">
                  <Input
                    className="form-control sm form-control-plaintext"
                    name="monthlyIncome"
                    value={tenure}
                    size="small"
                    style={{width:"100px"}}
                    onChange={(e)=>setTenure(e.target.value)}
                   
                  />
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-3 eligibiltyLable">
                  <span className="d-flex justify-content-end">
                   Intrest Rate
                  </span>
                </div>
                <div className="col-md-6 eligibilitySlider">
                  <Slider
                    value={intrestRate}
                    min={1}
                    max={20}
                    step={0.1}
                    onChange={handleIntrestRateChange}
                    aria-labelledby="income-slider"
                  />
                </div>
                <div className="col-md-2 eligibilityInput">
                  <Input
                    className="form-control sm form-control-plaintext"
                    value={intrestRate}
                    size="small"
                    style={{width:"100px"}}
                    onChange={(e)=>setIntrestRate(e.target.value)}
                    endAdornment={<InputAdornment position="end"> % </InputAdornment>}
                  />
                </div>
                <div className="row mt-4">
                          <div className="col-md-12 d-flex justify-content-center">
                          <button
                          className="btn btn-outline-primary  previousButton"
                        onClick={resetEligibility}
                        >
                          Reset
                        </button>
                        <button type="submit"
                        className="btn btn-primary nextButton"
                        >
                          Check Eligibility
                        </button>
                          </div>
                        </div>
                        <div className="row mt-5 mb-4">
                          <div className="col-md-2"></div>
                          <div className="col-md-4 d-flex justify-content-end mt-2">
                            <span style={{fontWeight:"bold",fontSize:"20px",color:"#1976d2"}}>Affordable Loan Amount -</span>
                          </div>
                          <div className="col-md-4 d-flex justify-content-Start mt-2">
                            {finalResult !== 0 && reset !== true ?<span style={{fontWeight:"bold",fontSize:"20px",color:"#1976d2"}}> {finalResult}   Lakhs</span>:<span className="mt-1" style={{color:"#fd0505",fontSize:"15px"}}>Please Enter Valid details</span>}
                          </div>
                        </div>
              </div>
             </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

import React,{Fragment} from 'react';
import {useRef} from "react"; 
import axios from "axios";
import moment from 'moment'
import {
  Button, Modal, ModalFooter,
  ModalHeader, ModalBody
} from "reactstrap";
import leftChevron from "../../../assets/images/back-button.png";
import { Formik,useFormik } from 'formik';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Tooltip from "rc-tooltip";
import { useState,useEffect } from 'react';
import TableContainer from '../../../Components/Common/TableContainer';
import { Col, Container, Row, Card, CardHeader, CardBody, Input,} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
const initialValues={
  logNotes:'',
}
export default function ViewTaskDetails() {
  const [characterLimit] = useState(300);
  const navigate = useNavigate();
  const [isSubmitted, setSubmitted]=useState(false);
  const formRef = useRef(null)
  const {taskId}=useParams();
  // const {values,handleBlur,handleChange,handleSubmit,touched,errors}=useFormik({
  //   initialValues:initialValues,
  //   onSubmit:(values)=>{
  //     axios.post(``).then((response)=>{
  //       console.log(response);
        
  //       setSubmitted(true);
  //     }).catch((err)=>{
  //       console.error(err);
  //     });
  //   setSubmitted(true);
  //   toast.success('Notes Added successfully !', {
  //     position: toast.POSITION.TOP_RIGHT
  // });
  //   },
    
  //  });

  // const char=values?.logNotes.length;
      // console.log("char count function calling...",300-char);
  const[task,settask]=useState({
    taskStatus:{
      taskStatusId:'',
    },
    taskNotes:'',
    caseOwner:{
      ownerID:'',
    }
  });
  useEffect(()=>{
    const d6 =`https://pradhanportal.com/loanApplication/master/getLoanCaseTaskById?taskId=${taskId}`;
    axios.get(d6).then((response)=>{
      console.log("response :",response.data)
      settask(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);
console.log("task========",task);

  const[status,setStatus]=useState([]);
  useEffect(()=>{
    const d6 =`https://pradhanportal.com/loanApplication/customer/getAllTaskStatus`;
    axios.get(d6).then((response)=>{
      console.log("response :",response.data)
      setStatus(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);

  const[leadOwners,setLeadOwners]=useState([]);
  useEffect(()=>{
    const d1 =`https://pradhanportal.com/loanApplication/master/getAllOwner`;
    axios.get(d1).then((response)=>{
      console.log("response :",response.data)
  
      setLeadOwners(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);


  const handleChangeFortaskstatus=(e)=>{
    settask({ 
      ...task,
      taskStatus:{
        taskStatusId: e.target.value } 
  }); 
  }

  const handleChangeForCaseOwner=(e)=>{
    settask({ 
      ...task,
      caseOwner:{
        ownerID: e.target.value } 
  }); 
  }
  
  const handleChange = (e) => {  
    settask({ 
        ...task,
      [e.target.name]: e.target.value       
    }); 
  };

  const SubmitForm=(e)=>{
    e.preventDefault();
axios.put('https://pradhanportal.com/loanApplication/master/editLoanCaseTask',task).then((response)=>{
          console.log(response);
          setSubmitted(true);
          toggle();
          toast.success('Case Owner  Updated successfully!', {
            position: toast.POSITION.TOP_RIGHT
            })
        }).catch((err)=>{
          console.error(err);
        });
  }
const handleSubmit=(e)=>{
// e.priventDefault();
e.preventDefault();
axios.put('https://pradhanportal.com/loanApplication/master/editLoanCaseTask',task).then((response)=>{
          console.log(response);
          setSubmitted(true);
        }).catch((err)=>{
          console.error(err);
        });
}

const LoanCaseId=task?.loanCaseID;
  const navigateToViewCase = () => {
    navigate(`/viewLoanCase/${LoanCaseId}`);
  };
  var renderTooltip21 = <span>Add New SubTask</span>;

  const navigateToAddSubTask=()=>{
    navigate(`/addSubTask/${taskId}`);
  }
   const notetime=moment().format('lll');
  console.log("notetime:",notetime);

  console.log("LeadID:",taskId);
   // Modal open state
   const [modal, setModal] = React.useState(false);
  
   // Toggle for Modal
   const toggle = () => setModal(!modal);
   const startD = task.startDate;
  const startdate = moment(startD).format("DD MMM YYYY");

  const planDate = task.plannedEndDate;
  const plannedDate = moment(planDate).format("DD MMM YYYY");
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 bg-info p-2">
            <span><img src={leftChevron} width = "25" height = "25" alt='' className='mx-2'  onClick={navigateToViewCase}/></span><span className='text-light'id='heading2'>Task ID {task?.taskId}   <span className='mx-4'>{task?.taskName}</span> </span> <span className='mx-2' id='subtaskheading'>
            Add Subtask
            <Tooltip  placement="right" overlay={renderTooltip21} >
                  <i  className="bi bi-plus-square-fill btn btn-sm" onClick={navigateToAddSubTask}></i> 
                  </Tooltip>
            </span>


            
          </div>
          <form onSubmit={handleSubmit}>
          <div className="card">
              <span className='mt-4' id='taskheading'>Task Information</span>
              <div className="card card12 mt-2">
                <div className="card-body">
              <div className="row">
              <div className="col-md-12">
              
                 <div className="row">
                 <div className="col-md-4">
                <div className="row">
                <div className="col-sm-4 mt-2">
                   <span className="d-flex justify-content-start">Stage</span>
                </div>
                <div className="col-sm-8 mt-2">
                   <span className="d-flex justify-content-start">{task?.stage?.loanStage}</span>
                </div>
                <div className="col-sm-4 mt-3">
                   <span className="d-flex justify-content-start">Task Status</span>
                </div>
                <div className="col-sm-8 mt-2">
                <select
                          class="form-select form-select-sm"
                          aria-label=".form-select-sm example"
                          name='taskStatus.taskStatusId'
                          onChange={handleChangeFortaskstatus}
                          value={task?.taskStatus?.taskStatusId}
                        >
                           <option value="">Please Select</option>
                         {
                            status?.length > 0 ? <Fragment>
                              {
                                status.map((taskStatus,index)=>{
                                  return(
                                    <option value={taskStatus?.taskStatusId}key={index}>{taskStatus.taskStatus}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                </div>
                <div className="col-md-4 mt-2">
                   <span className="d-flex justify-content-start">Task Owner</span>
                </div>
                <div className="col-md-8 mt-2">
                   <span className="d-flex justify-content-start">{task?.caseOwner?.ownerName}
                   <span ><Link to="" alt=""onClick={toggle} className="notDisabled mx-4"id='reassing'>Reassign</Link></span>
                   </span>
                   {/* <Modal isOpen={modal} id="assignmodal">
                                     <ModalHeader className="bg-primary d-flex justify-content-center pb-1 pt-1"
                                      ><span id="assignowner1">Reassign Task</span></ModalHeader>
                                     <ModalBody>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12">
                        <div className="row">
                        <div className="col-md-4 mt-2">
                        <span className="d-flex justify-content-end  ">Task Owner</span>
                          </div>
                          <div className="col-md-8">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name="caseOwner.ownerID"
                          onChange={handleChangeForCaseOwner}
                          value={task?.caseOwner?.ownerID}
                        > 
                          <option value="">Please select Owner</option>
                          {
                            leadOwners?.length > 0 ? <Fragment>
                              {
                                leadOwners.map((owner,index)=>{
                                  return(
                                    <option key={index} value={owner.ownerID}>{owner.ownerName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          </div>
                        </div>
                      </div>
                     
                     
                    </div>
                </ModalBody>
                <ModalFooter>
               <div className="container">
               <div className="row ">
                        <div className="col-md-12">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  id="btn-cancel" onClick={toggle}>Cancel</Button>
                        <Button color="primary" type="submit" onClick={toggle} id="btn-cancel">Reassign Task </Button>
                        </div>
                        </div>
                      </div>
               </div>
                </ModalFooter>
            </Modal>   */}
            {/* {
               isSubmitted ? toast() &
               toast.success('Case Owner  Updated successfully!', {
                position: toast.POSITION.TOP_RIGHT
                })
                :  */}
            
            <Modal isOpen={modal} id="assignmodal">
                                     <ModalHeader className="bg-primary d-flex justify-content-center pb-1 pt-1"
                                      ><span id="assignowner1">Reassign Task</span></ModalHeader>
                  <ModalBody>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12">
                          <form onSubmit={SubmitForm}>
                            <div className="row">
                              <div className="col-md-4 mt-3">
                                <span className="d-flex justify-content-end  ">
                                Task Owner
                                </span>
                              </div>
                              <div className="col-md-8 mt-2">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-md example"
                                  name="caseOwner.ownerID"
                                  onChange={handleChangeForCaseOwner}
                                  value={task?.caseOwner?.ownerID}
                                >
                                  <option selected>Please Select</option>
                                  {leadOwners?.length > 0 ? (
                                    <Fragment>
                                      {leadOwners.map((role, index) => {
                                        return (
                                          <option
                                            value={role.ownerID}
                                            key={index}
                                          >
                                            {role.ownerName}
                                          </option>
                                        );
                                      })}
                                    </Fragment>
                                  ) : null}
                                </select>
                              </div>
                              <div className="col-md-12 mt-4">
                                <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                                  <Button
                                    color="secondary"
                                    id="btn-cancel"
                                    onClick={toggle}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    id="btn-cancel"
                                  >
                                    Reassign Task
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal> 
                {/* } */}
                </div>
                <div className="col-sm-4 mt-2">
                   <span className="d-flex justify-content-start">Created By</span>
                </div>
                <div className="col-sm-8 mt-2">
                   <span className="d-flex justify-content-start">Vinay Verma</span>
                </div>
                </div>
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                  <div className="row">
                  <div className="col-sm-4 mt-2">
                   <span className="d-flex justify-content-start">Start Date</span>
                </div>
                <div className="col-sm-8 mt-2">
                   <span className="d-flex justify-content-start">{startdate}</span>
                </div>
                <div className="col-sm-4 mt-2">
                   <span className="d-flex justify-content-start">Planned End Date</span>
                </div>
                <div className="col-sm-8 mt-2">
                   <span className="d-flex justify-content-start">{plannedDate}</span>
                </div>
                <div className="col-sm-4 mt-2">
                   <span className="d-flex justify-content-start">Duration</span>
                </div>
                <div className="col-sm-8 mt-2">
                   <span className="d-flex justify-content-start">{task?.duration} Day</span>
                </div>
                <div className="col-sm-4 mt-2">
                   <span className="d-flex justify-content-start">Actual End Date</span>
                </div>
                <div className="col-sm-8 mt-2">
                   <span className="d-flex justify-content-start"></span>
                </div>
                  </div>
                  </div>
                 </div>
                </div>
              </div>
              </div>
              </div>
              {
                isSubmitted ?
                <div className="row mx-2">
                <div className="col-md-8">
                  {
                    task?.taskNotes !== null ? <Fragment>{task?.caseOwner?.ownerName} Added a note {notetime}</Fragment>: null
                  }
                  <div>{task?.taskNotes}</div>
                </div>
              </div>
              :null
              }
              <div className="row">
                <span id='taskheading1'>Add Notes</span>
              </div>
             <div className="col-md-6 mt-2">
             <div className="form-group">
                              <textarea
                              name="taskNotes"
                              className="form-control"
                              maxLength="300"
                              rows={5}
                              value={task.taskNotes}
                              onChange={handleChange}
                             />
                            </div>
                            <div className=" d-md-flex justify-content-md-end"> Remaining Characters: /{characterLimit} </div>
              </div>
              <div className="row mt-3 mb-3">
                      <div className="col-md-10">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                          <button
                          onClick={navigateToViewCase}
                            class="btn btn-secondary me-md-2"
                            id="Cancelbtn1"
                            type="button"
                          >
                            Cancel
                          </button>
                          <button class="btn btn-primary" type="submit" id="Cancelbtn2">
                           Add Notes
                          </button>
                        </div>
                      </div>
                    </div>
             </div>
             </form>
        </div>
      </div>
    </Fragment>
  )
}

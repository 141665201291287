import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../../assets/images/editIcon.png";
import Tooltip from "rc-tooltip";

export default function ViewSubTaskDetails() {
    var renderTooltip = <span>Edit Loan Sub Task</span>;
  const { subTaskId } = useParams();
  const [viewtask, setViewTask] = useState([]);
  const navigate = useNavigate();
  const naviagteToLoanSubTasks = () => {
    navigate("/loanSubTask");
  };
  const navigateToEditLoanTask = () => {
    navigate(`/editLoanSubTask/${subTaskId}`);
  };

  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getSubTaskBySubTaskId?subTaskId=${subTaskId}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setViewTask(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
   <Fragment>
     <div className="container-fluid ">
        <div className="card">
          <div className="row mt-5">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="card" id="card1">
                <div className="row">
                  <div className="col-md-9">
                    <div id="heading2" className="">
                      {viewtask.subTaskName}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mt-3" style={{ marginRight: "17px" }}>
                      <Tooltip placement="left" overlay={renderTooltip}>
                        <h6 id="viewLeadEditIcon">
                          <img
                            src={editIcon}
                            width="24"
                            height="19"
                            alt=""
                            className=""
                            onClick={navigateToEditLoanTask}
                          />
                        </h6>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <span className="d-flex justify-content-end">
                         Loan Task ID
                      </span>
                    </div>
                    <div className="col-md-9">
                      <span className=" text-white px-1" id="leadidbg">
                        {viewtask.generatedId}
                      </span>
                    </div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                         Loan Stage Name
                      </span>
                    </div>
                    <div className="col-md-9 mt-2">{viewtask.loanStageVO?.loanStage}</div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                         Loan Task Name
                      </span>
                    </div>
                    <div className="col-md-9 mt-2">{viewtask.loanTaskVO?.loanTask}</div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                         Loan Sub Task Name
                      </span>
                    </div>
                    <div className="col-md-9 mt-2">{viewtask.subTaskName}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="row mt-4 mb-4">
              <div className="col-md-12">
                <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                  <button
                    onClick={naviagteToLoanSubTasks}
                    className="btn btn-secondary previousButton"
                    type="button"
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   </Fragment>
  )
}

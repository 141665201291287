import React, { Fragment, useState, useEffect } from "react";
import { Button } from "reactstrap";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
export default function AddKYCDetails() {
  const [isSubmitted, setSubmitted] = useState(false);
  const { LoanCaseId } = useParams();
  const navigate = useNavigate();

  // const fullName = 'Muhammad Ali'
  // const formattedName = fullName.split(" ")[0];
  // console.log("formattedName",formattedName);

  const [getApplicantId, setApplicantId] = useState({
    type: "",
    applicantId: "",
    applicantName: "",
    primaryNumber: "",
    secondaryNumber: "",
    email: "",
  });
  const [applicantData, setApplicantData] = useState({
    applicantId: "",
    kycType: "",
    aadharNumber: "",
    panNumber: "",
  });
  const [applicant, setApplicant] = useState({});
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/customer/getAllApplicantByLoanCaseID?loanCaseID=${LoanCaseId}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response :", response.data);
        setApplicant(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [LoanCaseId]);
  console.log("applicant", applicant);

  const id = getApplicantId?.applicantId;

  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/customer/getApplicantById?applicantId=${id}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response :", response.data);
        setApplicantData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  console.log("applicantID", id);
  console.log("applicantData", applicantData);

  const handleChangeForApplicant = (e) => {
    setApplicantId({
      ...getApplicantId,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e) => {
    setApplicantData({
      ...applicantData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("https://pradhanportal.com/loanApplication/customer/editApplicant", applicantData)
      .then((response) => {
        console.log(response);
        setSubmitted(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const navigateTOViewLoanCase = () => {
    navigate(`/viewLoanCase/${LoanCaseId}`);
  };
  return (
    <Fragment>
      {isSubmitted ? (
        navigate(`/viewLoanCase/${LoanCaseId}`) &
        toast.success("Applicant KYC Details Added successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        })
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-9 mt-3">
                      <span className="customerDetails">KYC Details</span>
                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            {" "}
                            Applicant Name
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="applicantId"
                            onChange={handleChangeForApplicant}
                            value={getApplicantId.applicantId}
                          >
                            <option value="">Please Select</option>
                            {applicant?.length > 0 ? (
                              <Fragment>
                                {applicant.map((apl, index) => {
                                  return (
                                    <option
                                      value={apl?.applicantId}
                                      key={index}
                                    >
                                      {apl.applicantName}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                        <div className="co-md-3"></div>

                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            KYC Type
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="kycType"
                            onChange={handleChange}
                            value={applicantData?.kycType}
                          >
                            <option value="">Please Select</option>
                            <option value="AADHAR">AADHAR</option>
                            <option value="PAN">PAN</option>
                          </select>
                        </div>
                        <div className="col-md-3"></div>
                        {applicantData?.kycType === "AADHAR" ? (
                          <Fragment>
                            <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">
                                AADHAR Number
                              </span>
                            </div>
                            <div className="col-md-6 mt-2">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter AADHAR Number"
                                aria-label="Username"
                                name="aadharNumber"
                                maxLength={12}
                                aria-describedby="basic-addon1"
                                onChange={handleChange}
                                value={applicantData?.aadharNumber}
                              />
                            </div>
                            <div className="col-md-3"></div>
                          </Fragment>
                        ) : null}
                        {applicantData.kycType === "PAN" ? (
                          <Fragment>
                            <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">
                                PAN Number
                              </span>
                            </div>
                            <div className="col-md-6 mt-2">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter PAN Number"
                                aria-label="Username"
                                name="panNumber"
                                max={12}
                                aria-describedby="basic-addon1"
                                onChange={handleChange}
                                value={applicantData?.panNumber}
                              />
                            </div>
                            <div className="col-md-3"></div>
                          </Fragment>
                        ) : null}
                        <div className="row mt-3 mb-3">
                          <div className="col-md-10">
                            <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                              <button
                                onClick={navigateTOViewLoanCase}
                                class="btn btn-secondary me-md-2"
                                id="Cancelbtn1"
                                type="button"
                              >
                                Cancel
                              </button>
                              <button
                                class="btn btn-primary"
                                type="submit"
                                id="Cancelbtn2"
                              >
                                Submit KYC
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

import React, { Fragment, useState,useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import editIcon from "../../../assets/images/editIcon.png";
import Tooltip from "rc-tooltip";
export default function ViewChannelPartner() {
    var renderTooltip = <span>Edit CP</span>;
    const {partnershipId}=useParams();
    const[viewCP,setViewCP]=useState([]);
    const navigate = useNavigate();
    const naviagteToChannelPartners =()=>{
        navigate('/channelPartner');
    }
    useEffect(()=>{
        const d6 =`https://pradhanportal.com/loanApplication/master/getPartnershipBypartnershipId?partnershipId=${partnershipId}`;
        axios.get(d6).then((response)=>{
          console.log("response :",response.data)
          setViewCP(response.data);
        }).catch((err)=>{
           console.log(err);
        });
      },[]);
  return (
    <Fragment>
         <div className="container-fluid ">
        <div className="card">
          
          <div className="row mt-5">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="card" id="card1">
              <div className="row">
                  <div className="col-md-9">
                    <div id="heading2" className="">
                      {viewCP.partnershipName}
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div className="mt-3" style={{ marginRight: "17px" }}>
                      <Tooltip placement="left" overlay={renderTooltip}>
                        <h6 id="viewLeadEditIcon">
                          <img
                            src={editIcon}
                            width="24"
                            height="19"
                            alt=""
                            className=""
                            onClick={""}
                          />
                        </h6>
                      </Tooltip>
                    </div>
                  </div> */}
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <span className="d-flex justify-content-end">
                        Channel Partner ID
                      </span>
                    </div>
                    <div className="col-md-9">
                      <span className=" text-white px-1" style={{backgroundColor:"#FDB714"}}>
                       {viewCP.generatedId}
                      </span>
                    </div>
                    {/* <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                      Channel Partner Name
                      </span>
                    </div>
                    <div className="col-md-9 mt-2">
                    {viewCP.partnershipName}
                    </div> */}
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                      Channel Partner Mo.No.
                        </span>
                    </div>
                    <div className="col-md-9 mt-2">
                     {viewCP.mobileNumber}
                    </div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                      Channel Partner Email ID
                      </span>
                    </div>
                    <div className="col-md-9 mt-2">
                      {viewCP.emailId}
                    </div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                        Bank
                      </span>
                    </div>
                    <div className="col-md-9 mt-2">
                      {viewCP.bankVO?.bankName}
                    </div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                        Branch
                      </span>
                    </div>
                    <div className="col-md-9 mt-2">
                      {viewCP.bankBranchVO?.bankBranchName}
                    </div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                        Account Holder Name
                      </span>
                    </div>
                    <div className="col-md-9 mt-2">
                     {viewCP.accountHolderName}
                    </div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                        Account Number
                      </span>
                    </div>
                    <div className="col-md-9 mt-2">
                      <span className=" text-white px-1" style={{backgroundColor:"#FDB714"}}>
                       {viewCP.accountNumber}
                      </span>
                    </div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                        IFSC Code
                      </span>
                    </div>
                    <div className="col-md-9 mt-2">
                      <span className=" text-white px-1" style={{backgroundColor:"#FDB714"}}>
                       {viewCP.ifscCode}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
         
          <div>
            <div className="row mt-4 mb-4">
              <div className="col-md-12">
                <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                  <button
                    onClick={naviagteToChannelPartners}
                    className="btn btn-secondary previousButton"
                    type="button"
                  >
                    Back
                  </button>

                 
                </div>
              </div>
            </div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                 <span style={{paddingRight:"10px"}}>Note:-Channel Partner can only be edited through Application Settings</span>
                </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

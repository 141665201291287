import { Formik, useFormik } from "formik";
import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "../../../assets/scss/pages/customer.css";
import axios from "axios";
import Avatar from "react-avatar";
import uploadimg from "../../../assets/images/upload.png";
import moment from "moment";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import Tooltip from "rc-tooltip";
import Multiselect from "multiselect-react-dropdown";
import { useParams } from "react-router-dom";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import csc from "country-state-city";
import Select from "react-select";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import ReactDOM from "react-dom";

export default function CreateCustomer() {
  const { customerId } = useParams();
  console.log("customerId", customerId);
  const [cAddress, setCAddress] = useState(true);
  const [inputText, setInputText] = useState("");
  const [characterLimit] = useState(300);
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const fileref = useRef(null);
  const navigate = useNavigate();
  const year = new Date().getFullYear();
  const years = Array.from(new Array(60), (val, index) => year - index);
  const [drivinglicense, setDrivingLicense] = useState("");
  const [modal, setModal] = React.useState(false);
  const [officeAddress, setOfficeAddress] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  // Toggle for Modal
  const toggle = () => setModal(!modal);
  const [field, setField] = useState([]);
  const onChangePicture = (event) => {
    if (event.target.files[0]) {
      console.log("picture: ", event.target.files);
      setPicture(event.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  console.log("imgData", imgData);

  const [customerData, setCustomerData] = useState({
    customerName: "",
    gender: "",
    dob: "",
    customerType: "",
    employmentType: {
      empTypeID: "",
    },
    residentType: {
      residentTypeID: "",
    },
    religion: {
      religionID: "",
    },
    caste: {
      casteID: "",
    },
    primaryNumber: "",
    secondaryNumber: "",
    emailID: "",
    pan: "",
    aadhar: "",
    drivingLicense: "",
    electricityBillNumber: "",
    qualification: {
      qualificationID: "",
    },
    qualifyingYear: "",
    maritalStatus: {
      maritalID: "",
    },
    noOfDependents: "",
    noOfChildrens: "",
    spouseName: "",
    spouseDOB: "",
    prmntCountry: "",
    prmntCountryId: "",
    prmntCountryCode: "",
    prmntState: "",
    prmntStateId: "",
    prmntCityId: "",
    prmntCity: "",
    pAddressLine1: "",
    pAddressLine2: "",
    pLandmark: "",
    pPIN: "",
    addressStatus: "",
    sameaspermnantlocation: "",
    cAddressLine1: "",
    cAddressLine2: "",
    cLandmark: "",
    correspondsCountry: "",
    correspondCountryId: "",
    correspondCountryCode: "",
    correspondState: "",
    correspondStateId: "",
    correspondCityId: "",
    correspondCity: "",
    cPIN: "",
    oAddressLine1: "",
    oAddressLine2: "",
    oLandmark: "",
    officeCountry: "",
    officeCountryId: "",
    officeState: "",
    officeStateId: "",
    officeCityId: "",
    officeCity: "",
    oPIN: "",
    logNotes: "",
    file: "",
    kycType: "",
  });
  const [data, setData] = useState({});
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/customer/getCustomerById?customerId=${customerId}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setData(response.data);
        setCustomerData(response.data);
        setSelectedVal({
          label: response?.data.prmntCountry,
          id: response?.data.prmntCountryId,
        });
        setSelectedValstate({
          label: response?.data.prmntState,
          id: response?.data.prmntStateId,
        });
        setSelectedValcity({
          label: response?.data.prmntCity,
          id: response?.data.prmntCityId,
        });
        setSelectedCorrespondsVal({
          label: response?.data.correspondsCountry,
          id: response?.data.correspondCountryId,
        });
        setSelectedValCorrespondsstate({
          label: response?.data.correspondState,
          id: response?.data.correspondStateId,
        });
        setSelectedValCorrespondscity({
          label: response?.data.correspondCity,
          id: response?.data.correspondCityId,
        });
        setSelectedOfficeVal({
          label: response?.data.officeCountry,
          id: response?.data.officeCountryId,
        });
        setSelectedValOfficestate({
          label: response?.data.officeState,
          id: response?.data.officeStateId,
        });
        setSelectedValOfficecity({
          label: response?.data.officeCity,
          id: response?.data.officeCityId,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [customerId]);

  console.log(customerData);
  const navigateToViewCustomer = () => {
    navigate(`/ViewCustomer/${customerId}`);
  };

  const handleChange = (e) => {
    setCustomerData({
      ...customerData,
      [e.target.name]: e.target.value,
    });
  };

  const [residentType, setResidentType] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/customer/getAllResidentType`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setResidentType(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [empmentType, setempmentType] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/customer/getAllEmploymentType`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setempmentType(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [religion, setReligion] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/customer/getAllReligion`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setReligion(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [caste, setCaste] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/customer/getAllCaste`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setCaste(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [maritalStatus, setMaritalStatus] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/customer/getAllMaritalStatus`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setMaritalStatus(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [country, setCountry] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/customer/getAllCountry`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setCountry(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [state, setState] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/customer/getAllState`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setState(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [city, setCity] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/customer/getAllCity`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setCity(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [qualification, setQualification] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/customer/getAllQualification`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setQualification(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChangeMultiSelect = (value) => {
    console.log("Event  :", value);
    setKycTypes(...kycTypes, [value]);
  };
  const [kycTypes, setKycTypes] = useState([]);
  const [options, setOtions] = useState([
    "Driving License",
    "Electricity Bill",
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("leadData", customerData);
    // axios.put('/editLead',leadData).then((response)=>{
    //     console.log(response);

    //     setSubmitted(true);
    //   }).catch((err)=>{
    //     console.error(err);
    //   });
    // setSubmitted(true);
  };
  const countries = csc.getAllCountries();
  console.log(csc.getAllCountries());

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));

  const updatedCorrespondsCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));

  const updatedOfficeCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));
  const updatedStates = (prmntCountryId) =>
    csc
      .getStatesOfCountry(prmntCountryId)
      .map((state) => ({ label: state.name, value: state.id, ...state }));

  const updatedCorrespondsStates = (correspondCountryId) =>
    csc
      .getStatesOfCountry(correspondCountryId)
      .map((state) => ({ label: state.name, value: state.id, ...state }));

  const updatedOfficeStates = (officeCountryId) =>
    csc
      .getStatesOfCountry(officeCountryId)
      .map((state) => ({ label: state.name, value: state.id, ...state }));
  const updatedCities = (prmntStateId) =>
    csc
      .getCitiesOfState(prmntStateId)
      .map((city) => ({ label: city.name, value: city.id, ...city }));

  const updatedCorrespondsCities = (prmntStateId) =>
    csc
      .getCitiesOfState(prmntStateId)
      .map((city) => ({ label: city.name, value: city.id, ...city }));

  const updatedOfficeCities = (prmntStateId) =>
    csc
      .getCitiesOfState(prmntStateId)
      .map((city) => ({ label: city.name, value: city.id, ...city }));
  const ddlChange = (e) => {
    setCustomerData({
      ...customerData,
      prmntCountry: e.name,
      prmntCountryId: e.value,
    });
    setSelectedVal({ label: e.name, id: e.value });
  };
  const ddlChangeCorrespondsCountry = (e) => {
    setCustomerData({
      ...customerData,
      correspondsCountry: e.name,
      correspondCountryId: e.value,
    });
    setSelectedCorrespondsVal({ label: e.name, id: e.value });
  };

  const ddlChangeOfficeCountry = (e) => {
    setCustomerData({
      ...customerData,
      officeCountry: e.name,
      officeCountryId: e.value,
    });
    setSelectedOfficeVal({ label: e.name, id: e.value });
  };
  const ddlChangeState = (e) => {
    setCustomerData({
      ...customerData,
      prmntState: e.name,
      prmntStateId: e.value,
    });
    setSelectedValstate({ label: e.name, id: e.value });
  };

  const ddlChangeCorrespondsState = (e) => {
    setCustomerData({
      ...customerData,
      correspondState: e.name,
      correspondStateId: e.value,
    });
    setSelectedValCorrespondsstate({ label: e.name, id: e.value });
  };

  const ddlChangeOfficeState = (e) => {
    setCustomerData({
      ...customerData,
      officeState: e.name,
      officeStateId: e.value,
    });
    setSelectedValOfficestate({ label: e.name, id: e.value });
  };
  const ddlChangeCity = (e) => {
    setCustomerData({
      ...customerData,
      prmntCity: e.name,
      prmntCityId: e.value,
    });
    setSelectedValcity({ label: e.name, id: e.value });
  };

  const ddlChangeCorrespondsCity = (e) => {
    setCustomerData({
      ...customerData,
      correspondCity: e.name,
      correspondCityId: e.value,
    });
    setSelectedValCorrespondscity({ label: e.name, id: e.value });
  };

  const ddlChangeOfficeCity = (e) => {
    setCustomerData({
      ...customerData,
      officeCity: e.name,
      officeCityId: e.value,
    });
    setSelectedValOfficecity({ label: e.name, id: e.value });
  };

  const [selectedVal, setSelectedVal] = useState({
    label: customerData.prmntCountry,
    id: customerData.prmntCountryId,
  });
  const [selectedValstate, setSelectedValstate] = useState({
    label: customerData.prmntState,
    id: customerData.prmntStateId,
  });
  const [selectedValcity, setSelectedValcity] = useState({
    label: customerData.prmntCity,
    id: customerData.prmntCityId,
  });

  const [selectedCorrespondsVal, setSelectedCorrespondsVal] = useState({
    label: customerData.correspondsCountry,
    id: customerData.correspondCountryId,
  });
  const [selectedValCorrespondsstate, setSelectedValCorrespondsstate] =
    useState({
      label: customerData.correspondState,
      id: customerData.correspondStateId,
    });
  const [selectedValCorrespondscity, setSelectedValCorrespondscity] = useState({
    label: customerData.prmntCity,
    id: customerData.prmntCityId,
  });

  const [selectedOfficeVal, setSelectedOfficeVal] = useState({
    label: customerData.prmntCountry,
    id: customerData.prmntCountryId,
  });
  const [selectedValOfficestate, setSelectedValOfficestate] = useState({
    label: customerData.prmntState,
    id: customerData.prmntStateId,
  });
  const [selectedValOfficecity, setSelectedValOfficecity] = useState({
    label: customerData.prmntCity,
    id: customerData.prmntCityId,
  });
  const handleChangeEmp = (e) => {
    setCustomerData({
      ...customerData,
      employmentType: {
        empTypeID: e.target.value,
      },
    });
  };
  const handleChangeReligon = (e) => {
    setCustomerData({
      ...customerData,
      religion: {
        religionID: e.target.value,
      },
    });
    // setRelg({
    //   ...relg,
    //   religion:{
    //     religonID:e.target.value
    //   }
    // })
  };
  // const[relg,setRelg]=useState('');
  // const religionId=relg.religion.religonID;
  // console.log("religionID:",religionId);
  const handleChangeCast = (e) => {
    setCustomerData({
      ...customerData,
      caste: {
        casteID: e.target.value,
      },
    });
  };
  const handleChangeQualification = (e) => {
    setCustomerData({
      ...customerData,
      qualification: {
        qualificationID: e.target.value,
      },
    });
  };
  const handleChangeMaritalStatus = (e) => {
    setCustomerData({
      ...customerData,
      maritalStatus: {
        maritalID: e.target.value,
      },
    });
  };
  const handleChangePermnentCountry = (e) => {
    setCustomerData({
      ...customerData,
      pCountry: {
        countryID: e.target.value,
      },
    });
  };
  const handleChangeCorrespondenceCountry = (e) => {
    setCustomerData({
      ...customerData,
      cCountry: {
        countryID: e.target.value,
      },
    });
  };

  const handleChangeForOfficeState = (e) => {
    setCustomerData({
      ...customerData,
      officeAddressState: {
        stateID: e.target.value,
      },
    });
  };

  const handleChangeForOfficeCity = (e) => {
    setCustomerData({
      ...customerData,
      officeAddressCity: {
        cityID: e.target.value,
      },
    });
  };

  const handleChangeForOfficeCountry = (e) => {
    setCustomerData({
      ...customerData,
      officeAddressCountry: {
        countryID: e.target.value,
      },
    });
  };
  const handleChangeCorrespondenceState = (e) => {
    setCustomerData({
      ...customerData,
      cState: {
        stateID: e.target.value,
      },
    });
  };
  const handleChangePermenentState = (e) => {
    setCustomerData({
      ...customerData,
      pState: {
        stateID: e.target.value,
      },
    });
  };
  const handleChangePermenentCity = (e) => {
    setCustomerData({
      ...customerData,
      pCity: {
        cityID: e.target.value,
      },
    });
  };
  const handleChangeCorrespondenceCity = (e) => {
    setCustomerData({
      ...customerData,
      cCity: {
        cityID: e.target.value,
      },
    });
  };

  const handleChangeForResidentType = (e) => {
    setCustomerData({
      ...customerData,
      residentType: {
        residentTypeID: e.target.value,
      },
    });
  };
  const Form = ({ dob }) => {
    const [date, setDate] = useState(
      moment(customerData.dob).format("YYYY-MM-DD")
    );

    return <input type="time" value={date} />;
  };

  const navigateToCustmer = () => {
    navigate(`/customers`);
    toast.success("Customer deleted successfully !", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const [error, setError] = useState({});
  const SubmitForm = (e) => {
    e.preventDefault();
    if (validator.isEmpty(customerData.customerName)) {
      setError(true);
    } else if (validator.isEmpty(customerData.gender)) {
      setError(true);
    }
    // else if(validator.isEmpty(customerData.dob))
    // {
    //   setError(true);
    // }
    // else if(validator.isEmpty(customerData.customerType))
    // {
    //   setError(true);
    // }
    // else if(validator.isEmpty(customerData.primaryNumber))
    // {
    //   setError(true);
    // }
    // else if(validator.isEmpty(customerData.emailID))
    // {
    //   setError(true);
    // }
    else {
      axios
        .put("https://pradhanportal.com/loanApplication/customer/editCustomer", customerData)
        .then((response) => {
          console.log(response);

          setSubmitted(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  var renderTooltip = <span>Delete Customer</span>;
  const char = customerData?.logNotes?.length;
  console.log("char count function calling...", 300 - char);

  return (
    <Fragment>
      {/* <pre>{JSON.stringify(customerData)}</pre> */}
      {isSubmitted ? (
        navigate(`/ViewCustomer/${customerId}`) &
        toast.success("Customer Updated successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        })
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <Tooltip placement="right" overlay={renderTooltip}>
                  <h3 id="trashbtn">
                    <i
                      class="bi bi-trash  btn-lg"
                      onClick={navigateToCustmer}
                    ></i>
                  </h3>
                </Tooltip>
                <form onSubmit={SubmitForm}>
                  <div className="row">
                    <div className="col-md-9 mt-3">
                      <span className="customerDetails">Customer Details</span>
                      <div className="row">
                        <div className="col-md-3 mt-1">
                          <span
                            className="d-flex justify-content-end"
                            id="mandatory"
                          >
                            Customer Name
                          </span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            name="customerName"
                            onChange={handleChange}
                            value={customerData.customerName}
                            className="form-control sm"
                            placeholder="FirstName , MiddleName ,LastName"
                          />
                          {error && customerData.customerName == "" ? (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              Please Enter Customer Name
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span
                            className="d-flex justify-content-end"
                            id="mandatory"
                          >
                            Gender
                          </span>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio7"
                              onChange={handleChange}
                              checked={customerData.gender === "Male"}
                              value="Male"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio7"
                            >
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio8"
                              onChange={handleChange}
                              value="Female"
                              checked={customerData.gender === "Female"}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio8"
                            >
                              Female
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio9"
                              onChange={handleChange}
                              checked={customerData.gender === "Other"}
                              value="Other"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio9"
                            >
                              Other
                            </label>
                          </div>
                          {error && customerData.gender == "" ? (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              Please Select Gender
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span
                            className="d-flex justify-content-end"
                            id="mandatory"
                          >
                            DOB
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="date"
                            name="dob"
                            className="form-control sm"
                            placeholder="dd-mm-yy"
                            onChange={handleChange}
                            // value={moment(customerData.dob).format("DD-MM-YY")}
                            value={
                              customerData?.dob !== ""
                                ? moment(customerData?.dob).format("YYYY-MM-DD")
                                : ""
                            }
                          />
                          {error && customerData.dob == "" ? (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              Please Select Gender
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span
                            className="d-flex justify-content-end"
                            id="mandatory"
                          >
                            Customer Type
                          </span>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="customerType"
                              id="inlineRadio3"
                              onChange={handleChange}
                              checked={customerData.customerType === "Non HNI"}
                              value="Non HNI"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio3"
                            >
                              Non HNI
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="customerType"
                              id="inlineRadio4"
                              onChange={handleChange}
                              checked={customerData.customerType === "HNI"}
                              value="HNI"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio4"
                            >
                              HNI
                            </label>
                          </div>
                          {error && customerData.customerType == "" ? (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              Please Select Gender
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Employment Type
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="employmentType.empTypeID"
                            onChange={handleChangeEmp}
                            value={customerData?.employmentType?.empTypeID}
                          >
                            <option selected>Please Select</option>
                            {empmentType?.length > 0 ? (
                              <Fragment>
                                {empmentType.map((emp, index) => {
                                  return (
                                    <option value={emp?.empTypeID} key={index}>
                                      {emp.empType}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Resident Type
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="residentType.residentTypeID"
                            onChange={handleChangeForResidentType}
                            value={customerData?.residentType?.residentTypeID}
                          >
                            <option value="">Please Select</option>
                            {residentType?.length > 0 ? (
                              <Fragment>
                                {residentType.map((resident, index) => {
                                  return (
                                    <option
                                      value={resident?.residentTypeID}
                                      key={index}
                                    >
                                      {resident.residentType}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Religion
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="religion.religionID"
                            onChange={handleChangeReligon}
                            value={customerData?.religion?.religionID}
                          >
                            <option value="">Please Select</option>
                            {religion?.length > 0 ? (
                              <Fragment>
                                {religion.map((type, index) => {
                                  return (
                                    <option
                                      value={type?.religionID}
                                      key={index}
                                    >
                                      {type.religionName}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Caste
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="caste.casteID"
                            onChange={handleChangeCast}
                            value={customerData?.caste?.casteID}
                          >
                            <option value="">Please Select</option>
                            {caste?.length > 0 ? (
                              <Fragment>
                                {caste.map((type, index) => {
                                  return (
                                    <option value={type?.casteID} key={index}>
                                      {type.casteName}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                      <div className="row">
                        <p className="contactDetails">Contact Details</p>
                        <div className="col-md-3 mt-2">
                          <span
                            className="d-flex justify-content-end "
                            id="mandatory"
                          >
                            Primary Mobile Number
                          </span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="number"
                            name="primaryNumber"
                            className="form-control"
                            placeholder="Enter  Mobile Number"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleChange}
                            value={customerData.primaryNumber}
                          />
                          {error && customerData.primaryNumber == "" ? (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              Please Select Gender
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-1">
                          <span className=" whatsapp">
                            <i class="bi bi-whatsapp "></i>
                          </span>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Secondary Mobile Number
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter  Mobile Number"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            name="secondaryNumber"
                            onChange={handleChange}
                            value={customerData.secondaryNumber}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <span
                            className="d-flex justify-content-end  "
                            id="mandatory"
                          >
                            Email ID
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="emailID"
                            className="form-control"
                            placeholder="Enter Email ID"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            name="emailID"
                            onChange={handleChange}
                            value={customerData.emailID}
                          />
                          {error && customerData.emailID == "" ? (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              Please Select Gender
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <p className="kycDetails">KYC Details</p>
                        </div>
                        <div className="col-md-6 mt-4">
                          <a
                            href="#react"
                            aria-hidden="true"
                            className=" button mx-3 addKYC"
                            onClick={toggle}
                          >
                            Add Other KYC Details
                          </a>
                        </div>
                        <div className="col-md-3"></div>
                        <Modal isOpen={modal} id="assignmodal">
                          <div className="container-fluid">
                            <ModalHeader className="bg-primary d-flex justify-content-center pb-1 pt-1">
                              <span id="assignowner1">Add KYC</span>
                            </ModalHeader>
                            <ModalBody>
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-md-4 mt-2">
                                        <span className="d-flex justify-content-end  ">
                                          KYC Type
                                        </span>
                                      </div>
                                      <div className="col-md-8">
                                        <Multiselect
                                          isObject={false}
                                          onRemove={(event) => {
                                            console.log(event);
                                          }}
                                          onSelect={(value) => {
                                            handleChangeMultiSelect(value);
                                            console.log("event", value);
                                          }}
                                          options={options}
                                          displayValue="name"
                                          name="kycType"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ModalBody>
                            <ModalFooter>
                              <div className="container">
                                <div className="row ">
                                  <div className="col-md-12">
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                                      <Button
                                        color="secondary"
                                        id="btn-cancel"
                                        onClick={toggle}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        color="primary"
                                        type="submit"
                                        onClick={toggle}
                                        id="btn-cancel"
                                      >
                                        Add KYC
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ModalFooter>
                          </div>
                        </Modal>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            PAN
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="text"
                            name="pan"
                            className="form-control sm"
                            placeholder="Enter Pan Number"
                            onChange={handleChange}
                            value={customerData.pan}
                          />
                        </div>
                        <div className="col-md-3">
                          <label>
                            <input type="file" hidden />
                            <img
                              src={uploadimg}
                              width="30"
                              height="30"
                              className="mt-2"
                            />
                          </label>
                          <span className="mx-2">Upload PAN</span>
                        </div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            AADHAR
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="text"
                            name="aadhar"
                            className="form-control sm"
                            placeholder="Enter Aadhar Number"
                            onChange={handleChange}
                            value={customerData.aadhar}
                          />
                        </div>
                        <div className="col-md-3">
                          <label>
                            <input type="file" hidden />
                            <img
                              src={uploadimg}
                              width="30"
                              height="30"
                              className="mt-2"
                            />
                          </label>
                          <span className="mx-2">Upload AADHAR</span>
                        </div>
                        {kycTypes.map((field, idx) => {
                          return (
                            <Fragment key={`${idx}`}>
                              <div className="col-md-3 mt-3">
                                <span className="d-flex justify-content-end">
                                  {field}
                                </span>
                              </div>
                              <div className="col-md-6 mt-2">
                                <input
                                  type="text"
                                  name={field}
                                  onChange={handleChange}
                                  value={customerData.field}
                                  className="form-control sm"
                                  placeholder={field}
                                />
                              </div>
                              <div className="col-md-3">
                                <label>
                                  <input type="file" hidden />
                                  <img
                                    src={uploadimg}
                                    width="30"
                                    height="30"
                                    className="mt-2"
                                  />
                                </label>
                                <span className="mx-2">Upload {field}</span>
                                {/* <a href='#' type='button' className='btn-sm text-danger' onClick={()=>{setDrivingLicense(false)}}>Remove</a> */}
                              </div>
                            </Fragment>
                          );
                        })}
                      </div>
                      <div className="row">
                        <p className="educationDetails">Education Details</p>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Highest Qualification
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="qualification.qualificationID"
                            onChange={handleChangeQualification}
                            value={customerData.qualification?.qualificationID}
                          >
                            <option value="">Please Select</option>
                            {qualification?.length > 0 ? (
                              <Fragment>
                                {qualification.map((type, index) => {
                                  return (
                                    <option
                                      value={type?.qualificationID}
                                      key={index}
                                    >
                                      {type.qualification}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Qualifying Year
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="qualifyingYear"
                            onChange={handleChange}
                            value={customerData.qualifyingYear}
                          >
                            <option value="">Please Select</option>

                            {years.map((year, index) => {
                              return (
                                <option key={`year${index}`} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                      <div className="row">
                        <p className="familyDetails mt-3">Family Details</p>
                        <div className="col-md-3 mt-1">
                          <span className="d-flex justify-content-end  ">
                            Marital Status
                          </span>
                        </div>
                        <div className="col-md-6">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="maritalStatus.maritalID"
                            onChange={handleChangeMaritalStatus}
                            value={customerData.maritalStatus?.maritalID}
                          >
                            <option value="">Please Select</option>
                            {maritalStatus?.length > 0 ? (
                              <Fragment>
                                {maritalStatus.map((status, index) => {
                                  return (
                                    <option
                                      value={status?.maritalID}
                                      key={index}
                                    >
                                      {status.maritalStatus}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            No of Dependents
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="text"
                            name="noOfDependents"
                            className="form-control sm"
                            placeholder="Enter No. of Dependents"
                            onChange={handleChange}
                            value={customerData.noOfDependents}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        {customerData?.maritalStatus?.maritalStatus ===
                        "Married" ? (
                          <Fragment>
                            <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">
                                No of Children
                              </span>
                            </div>
                            <div className="col-md-6 mt-2">
                              <input
                                type="text"
                                name="noOfChildrens"
                                className="form-control sm"
                                placeholder="Enter No.of childrens"
                                onChange={handleChange}
                                value={customerData.noOfChildrens}
                              />
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">
                                Spouse Name
                              </span>
                            </div>
                            <div className="col-md-6 mt-2">
                              <input
                                type="text"
                                name="spouseName"
                                className="form-control sm"
                                placeholder="Enter Spouse Name"
                                onChange={handleChange}
                                value={customerData.spouseName}
                              />
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">
                                Spouse DOB
                              </span>
                            </div>
                            <div className="col-md-6 mt-2">
                              <input
                                type="date"
                                name="spouseDOB"
                                className="form-control sm"
                                placeholder="dd-mm-yy"
                                onChange={handleChange}
                                value={customerData.spouseDOB}
                              />
                            </div>
                            <div className="col-md-3"></div>
                          </Fragment>
                        ) : null}
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-3 mt-3">
                          <p className="addressDetails">Address Details</p>
                        </div>
                        <div className="col-md-6 mt-3">
                          <a
                            className="addOfficeAddress btn"
                            onClick={() => {
                              setOfficeAddress(true);
                            }}
                          >
                            Add Office Address Details
                          </a>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-1">
                          <span className="d-flex justify-content-end address">
                            Permanent Address
                          </span>
                        </div>
                        <div className="col-md-6 mt-2"></div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-4">
                          <span className="d-flex justify-content-end">
                            Address Line 1
                          </span>
                        </div>
                        <div className="col-md-6 mt-3">
                          <input
                            type="text"
                            name="pAddressLine1"
                            className="form-control sm"
                            placeholder="Enter Address Line 1"
                            onChange={handleChange}
                            value={customerData.pAddressLine1}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            Address Line 2
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="text"
                            name="permanentAddressLine2"
                            className="form-control sm"
                            placeholder="Enter Address Line 2"
                            onChange={handleChange}
                            value={customerData.permanentAddressLine2}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            Landmark
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="text"
                            name="pLandmark"
                            className="form-control sm"
                            placeholder="Enter Landmark"
                            onChange={handleChange}
                            value={customerData.pLandmark}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Country
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <Select
                            id="prmntCountry"
                            name="prmntCountry"
                            className="countrydrp"
                            onChange={ddlChange}
                            value={selectedVal}
                            options={updatedCountries}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            State
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <Select
                            name="prmntState"
                            id="prmntState"
                            className="countrydrp"
                            value={selectedValstate}
                            options={updatedStates(
                              customerData.prmntCountryId
                                ? customerData.prmntCountryId
                                : null
                            )}
                            onChange={ddlChangeState}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            City
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <Select
                            id="prmntCity"
                            className="countrydrp"
                            name="prmntCity"
                            options={updatedCities(
                              customerData.prmntStateId
                                ? customerData.prmntStateId
                                : null
                            )}
                            value={selectedValcity}
                            onChange={ddlChangeCity}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            PIN
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="number"
                            name="pPIN"
                            className="form-control sm"
                            placeholder="Enter PIN"
                            onChange={handleChange}
                            value={customerData?.pPIN}
                          />
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end address">
                            Correspondence Address
                          </span>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="addressStatus"
                              id="inlineRadio22"
                              onChange={handleChange}
                              value={customerData?.addressStatus ? false : true}
                              checked={customerData.addressStatus === true}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio22"
                            >
                              Same as Above
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                      {customerData.address != "Same as Above" ? (
                        <div className="row">
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              Address Line 1
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                              type="text"
                              name="cAddressLine1"
                              className="form-control sm"
                              placeholder="Enter Address Line 1"
                              onChange={handleChange}
                              value={customerData?.cAddressLine1}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              Address Line 2
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                              type="text"
                              name="cAddressLine2"
                              className="form-control sm"
                              placeholder="Enter Address Line 2"
                              onChange={handleChange}
                              value={customerData?.cAddressLine2}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              Landmark
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                              type="text"
                              name="cLandmark"
                              className="form-control sm"
                              placeholder="Enter Landmark"
                              onChange={handleChange}
                              value={customerData?.cLandmark}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end ">
                              Country
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <Select
                              id="correspondsCountry"
                              name="correspondsCountry"
                              className="countrydrp"
                              onChange={ddlChangeCorrespondsCountry}
                              value={selectedCorrespondsVal}
                              options={updatedCorrespondsCountries}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end  ">
                              State
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <Select
                              name="correspondState"
                              id="correspondState"
                              className="countrydrp"
                              value={selectedValCorrespondsstate}
                              options={updatedCorrespondsStates(
                                customerData.correspondCountryId
                                  ? customerData.correspondCountryId
                                  : null
                              )}
                              onChange={ddlChangeCorrespondsState}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end  ">
                              City
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <Select
                              id="correspondCity"
                              className="countrydrp"
                              name="correspondCity"
                              options={updatedCorrespondsCities(
                                customerData.correspondStateId
                                  ? customerData.correspondStateId
                                  : null
                              )}
                              value={selectedValCorrespondscity}
                              onChange={ddlChangeCorrespondsCity}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              PIN
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                              type="number"
                              name="cPIN"
                              className="form-control sm"
                              placeholder="Enter PIN"
                              onChange={handleChange}
                              value={customerData?.cPIN}
                            />
                          </div>
                          <div className="col-md-3"></div>
                        </div>
                      ) : null}
                      {officeAddress || customerData.oAddressLine1 != null ? (
                        <Fragment>
                          <div className="row mt-2">
                            <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end address">
                                Office Address
                              </span>
                            </div>
                            <div className="col-md-6 mt-3">
                              <a
                                type="button"
                                className="btn-sm text-danger"
                                onClick={() => {
                                  setOfficeAddress(false);
                                }}
                              >
                                Remove
                              </a>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3 mt-4">
                              <span className="d-flex justify-content-end">
                                Address Line 1
                              </span>
                            </div>
                            <div className="col-md-6 mt-3">
                              <input
                                type="text"
                                name="oAddressLine1"
                                className="form-control sm"
                                placeholder="Enter Address Line 1"
                                onChange={handleChange}
                                value={customerData?.oAddressLine1}
                              />
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">
                                Address Line 2
                              </span>
                            </div>
                            <div className="col-md-6 mt-2">
                              <input
                                type="text"
                                name="oAddressLine2"
                                className="form-control sm"
                                placeholder="Enter Address Line 2"
                                onChange={handleChange}
                                value={customerData.oAddressLine2}
                              />
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">
                                Landmark
                              </span>
                            </div>
                            <div className="col-md-6 mt-2">
                              <input
                                type="text"
                                name="oLandmark"
                                className="form-control sm"
                                placeholder="Enter Landmark"
                                onChange={handleChange}
                                value={customerData?.oLandmark}
                              />
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end ">
                                Country
                              </span>
                            </div>
                            <div className="col-md-6 mt-2">
                              <Select
                                id="officeCountry"
                                name="officeCountry"
                                className="countrydrp"
                                onChange={ddlChangeOfficeCountry}
                                value={selectedOfficeVal}
                                options={updatedOfficeCountries}
                              />
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end  ">
                                State
                              </span>
                            </div>
                            <div className="col-md-6 mt-2">
                              <Select
                                name="officeState"
                                id="officeState"
                                className="countrydrp"
                                value={selectedValOfficestate}
                                options={updatedOfficeStates(
                                  customerData.officeCountryId
                                    ? customerData.officeCountryId
                                    : null
                                )}
                                onChange={ddlChangeOfficeState}
                              />
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end  ">
                                City
                              </span>
                            </div>
                            <div className="col-md-6 mt-2">
                              <Select
                                id="officeCity"
                                className="countrydrp"
                                name="officeCity"
                                options={updatedOfficeCities(
                                  customerData.officeStateId
                                    ? customerData.officeStateId
                                    : null
                                )}
                                value={selectedValOfficecity}
                                onChange={ddlChangeOfficeCity}
                              />
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">
                                PIN
                              </span>
                            </div>
                            <div className="col-md-6 mt-2">
                              <input
                                type="number"
                                name="oPIN"
                                className="form-control sm"
                                placeholder="Enter PIN"
                                onChange={handleChange}
                                value={customerData?.oPIN}
                              />
                            </div>
                            <div className="col-md-3"></div>
                          </div>
                        </Fragment>
                      ) : null}
                      <div className="row mt-3">
                        <p className="logNotes">Log Notes</p>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            Lead Notes
                          </span>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <textarea
                              name="logNotes"
                              className="form-control"
                              maxLength="300"
                              rows={3}
                              value={customerData.logNotes}
                              onChange={handleChange}
                            />
                          </div>
                          <div className=" d-md-flex justify-content-md-end">
                            {" "}
                            Remaining Characters: {300 - char}/{characterLimit}{" "}
                          </div>
                        </div>{" "}
                        <div className="col-md-3"></div>
                      </div>
                    </div>
                    <div className="col-md-3 mt-4">
                      <div className="previewProfilePic" id="image">
                        {imgData != null ? (
                          <img
                            id="image"
                            className="playerProfilePic_home_tile"
                            src={imgData}
                          />
                        ) : (
                          <img
                            id="image"
                            className="playerProfilePic_home_tile"
                            src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                          />
                        )}
                      </div>
                      <div className="register_profile_image">
                        <input
                          className="profilePic"
                          ref={fileref}
                          type="file"
                          hidden
                          name="file"
                          value={customerData.file}
                          onChange={onChangePicture}
                        />
                        {imgData == null ? (
                          <a
                            id="img"
                            to=""
                            onClick={() => {
                              fileref.current.click();
                            }}
                          >
                            <span className="profile">Upload Profile Pic</span>
                          </a>
                        ) : (
                          <a
                            to=""
                            id="img"
                            onClick={() => {
                              fileref.current.click();
                            }}
                          >
                            <span className="profile  bg-light mx-3 px-1">
                              Edit Profile Pic
                            </span>
                          </a>
                        )}
                      </div>
                    </div>
                    <div className="row mt-3 mb-3">
                      <div className="col-md-10">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                          <button
                            onClick={navigateToViewCustomer}
                            class="btn btn-secondary me-md-2"
                            id="Cancelbtn1"
                            type="button"
                          >
                            Cancel
                          </button>
                          <button
                            class="btn btn-primary"
                            type="submit"
                            id="Cancelbtn2"
                          >
                            Update Customer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Tooltip from "rc-tooltip";
import { Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap"
import {  toast } from 'react-toastify';
import moment from 'moment';
export default function UpdateChannelPartner() {
    const { partnershipId } = useParams();
    const [isSubmitted, setSubmitted] = useState(false);
    const [channelPartner, setChannelPartner] = useState({
      partnerVO:{
        partnerId:''
      },
        bankVO:{
            bankId:'',
          },
          bankBranchVO:{
            bankBranchId:'',
          },
          partnershipName:"",
          mobileNumber:"",
          emailId:"",
          accountHolderName:"",
          accountNumber:"",
          ifscCode:"",
          dateOfJoining:"",

    });
    const navigate = useNavigate();
    const navigateToViewCP = () => {
        navigate(`/viewChannelpartner/${partnershipId}`);
      };
    useEffect(() => {
        const d6 = `https://pradhanportal.com/loanApplication/master/getPartnershipBypartnershipId?partnershipId=${partnershipId}`;
        axios
          .get(d6)
          .then((response) => {
            console.log("response :", response.data);
            setChannelPartner(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);
      const [banks,setBanks] = useState({});
      useEffect(() => {
        const d6 = 'https://pradhanportal.com/loanApplication/master/getAllBank';
        axios
          .get(d6)
          .then((response) => {
            console.log("Bank List response :", response.data);
    
            setBanks(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);

      const [partnerData,setPartnerData] = useState([]);
      useEffect(() => {
        const d6 = 'https://pradhanportal.com/loanApplication/master/getAllPartner';
        axios
          .get(d6)
          .then((response) => {
            console.log("Bank List response :", response.data);
    
            setPartnerData(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);


      const bnkId= channelPartner.bankVO.bankId;
      console.log("BankID",bnkId);
      const [bankBranch, setBankBranch] = useState({});
      useEffect(() => {
        const d6 = `https://pradhanportal.com/loanApplication/master/getAllBranchByBankId?bankId=${bnkId}`;
        axios
          .get(d6)
          .then((response) => {
            console.log("response :", response.data);
    
            setBankBranch(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }, [bnkId]);
      console.log("bankBranch",bankBranch);
    
      const handleChangeForBank =(e)=>{
        setChannelPartner({
            ...channelPartner,
            bankVO: {
              bankId:e.target.value
              },
        })
      }
      const handleChangeForBankBranch =(e)=>{
        setChannelPartner({
            ...channelPartner,
            bankBranchVO: {
              bankBranchId:e.target.value
              },
        })
      }

      const handleChangeForPartner=(e)=>{
        setChannelPartner({
            ...channelPartner,
            partnerVO: {
              partnerId:e.target.value
              },
        })
      }
      const handleChange=(e)=>{
        setChannelPartner({
            ...channelPartner,
            [e.target.name]:e.target.value
        })
      }
      const handleSubmit=(e)=>{
        e.preventDefault();
        axios.put('https://pradhanportal.com/loanApplication/master/editChannelPartner',channelPartner).then((response)=>{
            console.log(response);
            setSubmitted(true);
          }).catch((err)=>{
            console.error(err);
          });
      }

      const authUser = JSON.parse(localStorage.getItem("user"));

      var renderTooltip = <span>Delete Channel Partner</span>;
    
      const [modal1, setModal1] = React.useState(false);
      const toggle1 = () => setModal1(!modal1);
    
      const DeleteLead = () => {
        const dataUrl =`https://pradhanportal.com/loanApplication/master/deleteChannelPartner?partnershipId=${partnershipId}`;
            axios.post(dataUrl).then((response)=>{
            
              console.log("response :",response.data);
              
              setChannelPartner(response.data);
            }).catch((err)=>{
               console.log(err);
            });
      navigate(`/addPartnershipManagement`);
      toast.success('Loan Sub Task Deleted Successfully !', {
        position: toast.POSITION.TOP_RIGHT
    });
    };
  return (
   <Fragment>
    {
        isSubmitted ? navigate(`/viewChannelpartner/${partnershipId}`) &
        toast.success('Channel Partner Edited Successfully!', {
         position: toast.POSITION.TOP_RIGHT
         })
        :
       <div className="container">
       <div className="row">
               <div className="col-md-2"></div>
               <div className="col-md-8">
                  <form onSubmit={handleSubmit}>
                  <div className="card">
                       <div className="card-header bg-primary d-flex justify-content-center  cardHeader">
                       <span id="assignowner1">Edit Channel Partner</span>
                       </div>
                       {
            authUser.role?.role === "Local Admin" ? <Fragment>
            <Tooltip  placement="right" overlay={renderTooltip} >
                <h3 id="trashbtn">
                <i class="bi bi-trash  btn-lg" 
                
                onClick={toggle1}
                ></i>
            </h3>
            </Tooltip>
            </Fragment>:null
           }
            <Modal fade={true} isOpen={modal1} toggle={toggle1} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#f7b84b,secondary:#f06548"
            style={{ width: "100px", height: "100px" }}
          ></lord-icon>
          <span className='warningmodal'>Warning !</span>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-4 mb-0">
              Are you sure you want to remove this record ?
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={toggle1}
          >
            Close
          </button>
          <button
            type="button"
            className="btn w-sm btn-danger "
            id="delete-record"
            onClick={()=>{setChannelPartner(); DeleteLead()}}
          >
            Yes, Delete It!
          </button>
        </div>
      </ModalBody>
    </Modal>
                       <div className="card-body">
                         <div className="row mt-2">
                         <div className="col-md-4 mt-3">
                           <span
                             className="d-flex justify-content-end  "
                           >
                             Partner
                           </span>
                         </div>
                         <div className="col-md-6 mt-2">
                           <select
                             class="form-select form-select-md"
                             aria-label=".form-select-md example"
                             name="partnerVO.partnerId"
                             onChange={handleChangeForPartner}
                             value={channelPartner.partnerVO?.partnerId ==="" ? channelPartner.partnerVO.partnerId = partnerData.partnerId : channelPartner.partnerVO?.partnerId}
                           >
                             <option value="">Please Select</option>
                             {partnerData?.length > 0 ? (
                               <Fragment>
                                 {partnerData.map((partner, index) => {
                                   return (
                                     <option
                                       key={index}
                                       value={partner?.partnerId}
                                     >
                                       {partner?.partnerName}
                                     </option>
                                   );
                                 })}
                               </Fragment>
                             ) : null}
                           </select>
                         </div>
                           <div className="col-md-4 mt-3">
                       <span className="d-flex justify-content-end">Channel Partner Name</span>
                     </div>
                     <div className="col-md-6 mt-2">
                       <input
                        type="text"
                        name="partnershipName"
                         value={channelPartner.partnershipName}
                        onChange={handleChange}
                         className="form-control sm"
                         placeholder="Enter Channel Partner Name"
                       /> 
                     </div>
                     <div className="col-md-2 mt-2"></div>

                     <div className="col-md-4 mt-3">
                       <span className="d-flex justify-content-end">Channel Partner Mo.No.</span>
                     </div>
                     <div className="col-md-6 mt-2">
                       <input
                        type="number"
                        name="mobileNumber"
                         value={channelPartner.mobileNumber}
                        onChange={handleChange}
                         className="form-control sm"
                         placeholder="Enter Channel Partner Number"
                       /> 
                     </div>
                     <div className="col-md-2 mt-2"></div>
                     <div className="col-md-4 mt-3">
                       <span className="d-flex justify-content-end">Channel Partner Email ID</span>
                     </div>
                     <div className="col-md-6 mt-2">
                       <input
                        type="email"
                        name="emailId"
                         value={channelPartner.emailId}
                        onChange={handleChange}
                         className="form-control sm"
                         placeholder="Enter Channel Partner Email ID"
                       /> 
                     </div>
                     <div className="col-md-2 mt-2"></div>
                     <div className="col-md-4 mt-3">
                           <span
                             className="d-flex justify-content-end  "
                           >
                             Bank
                           </span>
                         </div>
                         <div className="col-md-6 mt-2">
                           <select
                             class="form-select form-select-md"
                             aria-label=".form-select-md example"
                             name="bankVO.bankId"
                             onChange={handleChangeForBank}
                             value={channelPartner.bankVO?.bankId ==="" ? channelPartner.bankVO.bankId = banks.bankId : channelPartner.bankVO?.bankId}
                           >
                             <option value="">Please Select</option>
                             {banks?.length > 0 ? (
                               <Fragment>
                                 {banks.map((bank, index) => {
                                   return (
                                     <option
                                       key={index}
                                       value={bank?.bankId}
                                     >
                                       {bank?.bankName}
                                     </option>
                                   );
                                 })}
                               </Fragment>
                             ) : null}
                           </select>
                         </div>
                         <div className="col-md-4 mt-3">
                           <span
                             className="d-flex justify-content-end  "
                           >
                             Branch
                           </span>
                         </div>
                         <div className="col-md-6 mt-2">
                           <select
                             class="form-select form-select-md"
                             aria-label=".form-select-md example"
                             name="bankBranchVO.bankBranchId"
                             onChange={handleChangeForBankBranch}
                             value={channelPartner.bankBranchVO?.bankBranchId ==="" ? channelPartner.bankBranchVO.bankBranchId = bankBranch.bankBranchId : channelPartner.bankBranchVO?.bankBranchId}
                           >
                             <option value="">Please Select</option>
                             {bankBranch?.length > 0 ? (
                               <Fragment>
                                 {bankBranch.map((branch, index) => {
                                   return (
                                     <option
                                       key={index}
                                       value={branch?.bankBranchId}
                                     >
                                       {branch?.bankBranchName}
                                     </option>
                                   );
                                 })}
                               </Fragment>
                             ) : null}
                           </select>
                         </div>
                         <div className="col-md-2 mt-2"></div>
                     <div className="col-md-4 mt-3">
                       <span className="d-flex justify-content-end">Account Holder Name</span>
                     </div>
                     <div className="col-md-6 mt-2">
                       <input
                        type="text"
                        name="accountHolderName"
                         value={channelPartner.accountHolderName}
                        onChange={handleChange}
                         className="form-control sm"
                         placeholder="Enter Channel Partner Holder Name"
                       /> 
                     </div>
                     <div className="col-md-2 mt-2"></div>
                     <div className="col-md-4 mt-3">
                       <span className="d-flex justify-content-end">Account Number</span>
                     </div>
                     <div className="col-md-6 mt-2">
                       <input
                        type="text"
                        name="accountNumber"
                         value={channelPartner.accountNumber}
                        onChange={handleChange}
                         className="form-control sm"
                         placeholder="Enter Channel Partner Account Number"
                       /> 
                     </div>
                     <div className="col-md-2 mt-2"></div>
                     <div className="col-md-4 mt-3">
                       <span className="d-flex justify-content-end">IFSC Code</span>
                     </div>
                     <div className="col-md-6 mt-2">
                       <input
                        type="text"
                        name="ifscCode"
                         value={channelPartner.ifscCode}
                        onChange={handleChange}
                         className="form-control sm"
                         placeholder="Enter Channel Partner IFSC Code"
                       /> 
                     </div>
                     <div className="col-md-2 mt-2"></div>
                     <div className="col-md-4 mt-3">
                       <span className="d-flex justify-content-end">Date of Joining</span>
                     </div>
                     <div className="col-md-6 mt-2">
                       <input
                        type="date"
                        name="dateOfJoining"
                         value={channelPartner?.dateOfJoining !=="" ? moment(channelPartner?.dateOfJoining).format('YYYY-MM-DD') : ""}
                        onChange={handleChange}
                         className="form-control sm"
                         placeholder="Enter Channel Partner IFSC Code"
                       /> 
                     </div>
                     <div className="col-md-2 mt-2"></div>
                         </div>
                       </div>
                           <div className="row mt-3 mb-3">
                       <div className="col-md-12">
                       <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                       <Button color="secondary"  className="previousButton" onClick={navigateToViewCP}>Cancel</Button>
                       <Button color="primary" type="submit" className="nextButton">Update</Button>
                       </div>
                       </div>
                           </div>
                   </div>
                  </form>
               </div>
               <div className="col-md-2"></div>
           </div>
   </div>
    }
   </Fragment>
  )
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';

//import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Lead from './Lead';
import lead from "../../assets/scss/pages/lead.css";


const DashboardAnalytics = () => {
document.title="Pradhan Portal";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Dashboard" pageTitle="Lead" />
                    <Row>
                        <Col lg={12}>
                            <Lead/>
                            {/* <UpgradeAccountNotise />
                            <Widget /> */}
                        </Col>
                        {/* <LiveUsers /> */}
                    </Row>
                    
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DashboardAnalytics;
import React,{Fragment, useMemo}from 'react'
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Input,} from "reactstrap";
import "rc-tooltip/assets/bootstrap.css";
import axios from "axios";
import Tooltip from "rc-tooltip";
import TableContainer from '../../../../Components/Common/TableContainer';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Cell } from 'gridjs';
import moment from 'moment';
import "../../../../assets/scss/pages/_userManagement.css"
export default function RolePrivileges() {


    var renderTooltip = <span>Create New User</span>;

    const navigate = useNavigate();
  const navigateToCreateLead = () => {
    // 👇️ navigate to /CreateLead
    navigate('/addNewUser');
  };
  
  const completeJD = () => {
    toast.error('Error! Complete the General Discussion First', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toast-message'
    });
};
    const[filterLeads,setFilterLeads]=useState([]);
    const [search,setSearch]=useState("");
    const [data, setData] = useState([]);
   
     
    const loadLead = async () => {
      const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllRolePrivilege`);
      setData(result.data);
      
      setFilterLeads(result.data);
    };

   console.log("user",data)
    useEffect(() => {
      loadLead();
    }, []);
    useEffect(() => {
      const result=data.filter(feature=>{
        return feature.privilegesFeature.featureName.toLowerCase().match(search.toLowerCase())
      });
      setFilterLeads(result);
    }, [search]);
    const columns = useMemo(
      () => [
        {
            Header: "Sr No",
            Cell: (data) => {
                return ( 
                    <>{data.row.index + 1}</>
                )},
                filterable: false,
        },
        {
            Header: "Feature",
            accessor:"privilegesFeature.featureName",
            filterable: false,
            
          },
        {
          Header: "Privilege",
          accessor:"privilegeName",
          filterable: false,
          
        },
        
        {
          Header: "Executive",
          Cell: (data) => {
            return ( 
                <Fragment>
                    <input className="form-check-input mx-3" type="checkbox" id="inlineCheckbox1" value="option1" checked></input>
                </Fragment>
            )},
          filterable: false,
          
        },
        {
          Header: "Team Leader",
          Cell: (data) => {
            return ( 
                <Fragment>
                    <input className="form-check-input mx-3" type="checkbox" id="inlineCheckbox1" value="option1" checked></input>
                </Fragment>
            )},
          filterable: false,
        },
        {
          Header: "Assistant Manager",
          Cell: (data) => {
            return ( 
                <Fragment>
                    <input className="form-check-input mx-3" type="checkbox" id="inlineCheckbox1" value="option1" checked></input>
                </Fragment>
            )},
          filterable: false,
          
        },
        {
          Header: "Deputy Manager",
          Cell: (data) => {
            return ( 
                <Fragment>
                    <input className="form-check-input mx-3" type="checkbox" id="inlineCheckbox1" value="option1" checked></input>
                </Fragment>
            )},
          filterable: false,
        },
        {
          Header: "Manager",
          Cell: (data) => {
            return ( 
                <Fragment>
                    <input className="form-check-input mx-3" type="checkbox" id="inlineCheckbox1" value="option1" checked></input>
                </Fragment>
            )},
          filterable: false,
        },
        {
            Header: "Operation Manager",
            Cell: (data) => {
                return ( 
                    <Fragment>
                        <input className="form-check-input mx-3" type="checkbox" id="inlineCheckbox1" value="option1" checked></input>
                    </Fragment>
                )},
            filterable: false,
          },
          {
            Header: "Sales Manager",
            Cell: (data) => {
                return ( 
                    <Fragment>
                        <input className="form-check-input mx-3" type="checkbox" id="inlineCheckbox1" value="option1" checked></input>
                    </Fragment>
                )},
            filterable: false,
          },
          {
            Header: "Sales Executive",
            Cell: (data) => {
                return ( 
                    <Fragment>
                        <input className="form-check-input mx-3" type="checkbox" id="inlineCheckbox1" value="option1"></input>
                    </Fragment>
                )},
            filterable: false,
          },
          {
            Header: "CEO",
            Cell: (data) => {
                return ( 
                    <Fragment>
                        <input className="form-check-input mx-3" type="checkbox" id="inlineCheckbox1" value="option1" ></input>
                    </Fragment>
                )},
            filterable: false,
          },
          {
            Header: "Local Admin",
            Cell: (data) => {
                return ( 
                    <Fragment>
                        <input className="form-check-input mx-3" type="checkbox" id="inlineCheckbox1" value="option1" checked></input>
                    </Fragment>
                )},
            filterable: false,
          },
      ],
      []
    );

  return (
    <Fragment>
        <Container fluid>
            <Row>
                <Col lg={12}>
                <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={3}>
                   
              </Col><Col sm={6}></Col>
              <Col sm={3}>
                      <div className="search-box">
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder="Search for..."
                          value={search}
                          onChange={(e)=>setSearch(e.target.value)}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                <div className="main">
                {
                  data?.deleteFlag!==null?(
                    <TableContainer
                    columns={columns}
                    data={filterLeads}
                    isGlobalFilter={false}
                    isAddUserList={false}
                    customPageSize={5}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-0"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-secondary"
                    isLeadsFilter={false}
                    SearchPlaceholder='Search for'
                    highlightOnHover
                   
                  />
                  ):null
                }
                  </div>
                  </CardBody>
                </Card>
                </Col>
            </Row>
        </Container>
    </Fragment>
  )
}



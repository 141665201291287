import React,{Fragment,useMemo} from 'react'
import Tooltip from "rc-tooltip";
import TableContainer from '../../../Components/Common/TableContainer';
import { Col, Container, Row, Card, CardHeader, CardBody, Input,} from "reactstrap";
import { useState } from 'react';
import axios from "axios";
import setting from "../../../assets/images/setting.png";
import {Button, Modal, ModalFooter, ModalHeader, ModalBody} from "reactstrap" ;
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link } from 'feather-icons-react/build/IconComponents';
import { useParams } from 'react-router-dom';
export default function ViewTemplates() {
    const [visibleTask, setVisibleTask] = useState(false);
    const navigate =useNavigate();
    const {templateId}=useParams();
   console.log(templateId);
    const [viewTemplate, SetViewTemplate]=useState({});
    useEffect (()=>{
    const dataUrl =`https://pradhanportal.com/loanApplication/master/getTemplateById?templateId=${templateId}`;
        axios.get(dataUrl).then((response)=>{
          console.log("response :",response.data)
          SetViewTemplate(response.data);
        }).catch((err)=>{
           console.log(err);
        }); 
  },[templateId]);

console.log("viewTemplate",viewTemplate);

const [stage, setStage]=useState([]);
    useEffect (()=>{
    const dataUrl =`https://pradhanportal.com/loanApplication/master/getAllTaskByTemplateId?templateId=${templateId}`;
        axios.get(dataUrl).then((response)=>{
          console.log("response :",response.data)
          setStage(response.data);
        }).catch((err)=>{
           console.log(err);
        }); 
  },[templateId]);

console.log("Stage",stage);
    
    var renderTooltip = <span>Add New Task</span>;
  return (
    <Fragment>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                <div className="card">
                <form >
                    <div className='bg-primary d-flex justify-content-center pb-1 pt-1 templateheader'>New Templates</div>
                    <div className="card-body">
                    <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form">
                          <div className="row">
                          <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Template Name</span>
                      </div>
                      <div className="col-md-8 mt-2">
                        <input
                          type="text"
                          name="templateName"
                          className="form-control sm"
                          placeholder="Enter Template Name"
                          value={viewTemplate.templateName}
                          
                        /> 
                      </div>
                      <div className="col-md-3 mt-4">
                      <span className="d-flex justify-content-end  ">Case Type</span>
                          </div>
                          <div className="col-md-8 mt-3">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='caseType'
                          value={viewTemplate.caseType}
                        >
                          <option selected>Please Select</option>
                          <option value="Loan">Loan</option>
                          <option value="Insurance">Insurance</option>
                          </select>
                          </div>
                          <div className="col-md-3 mt-4">
                      <span className="d-flex justify-content-end  ">Category</span>
                          </div>
                          <div className="col-md-8 mt-3">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='category'
                          value={viewTemplate.category}
                        >
                          <option selected>Please Select</option>
                          <option value="Home Loan">Loan</option>
                          <option value="Car Loan"> Car Loan</option>
                          </select>
                          </div>
                          <div className="col-md-3 mt-4">
                      <span className="d-flex justify-content-end  ">Sub Category</span>
                          </div>
                          <div className="col-md-8 mt-3">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='subCategory'
                          value={viewTemplate.subCategory}
                        >
                          <option selected>Please Select</option>
                          <option value="New Home Loan">New Home Loan</option>
                          <option value="Top Up & Take Over">Top Up & Take Over</option>
                          </select>
                          </div>
                          <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Duration</span>
                      </div>
                      <div className="col-md-8 mt-2">
                        <input
                          type="number"
                          className="form-control sm"
                          placeholder="Duration"
                          name='duration'
                          value={viewTemplate.duration}
                        /> 
                      </div>
                      <div className="col-md-1 mt-3">
                        Days
                      </div>
                        <Container fluid>
            <Row>
                <Col lg={12}>
                <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={3}>
                    <div className='d-flex align-items-center '>
              <div className='d-inline-block'>
                <div className='d-flex justify-content-Start tasklist'>Task List</div>
              </div>
              <div className='d-inline-block mx-1'>
              <Tooltip 
                   placement="right"
                   overlay={renderTooltip}
              >
              <h2><i onClick={''} className="bi bi-plus-square-fill btn btn-lg"></i> </h2>  
            </Tooltip>
              </div>
            </div>
              </Col><Col sm={6}></Col>
              <Col sm={3}>
                    </Col>
                  </Row>
                </CardHeader>
                <table className="table table-hover table-striped text-center">
        <thead className="bg-primary text-white">
          <tr>
          <th >Sr No </th>
           <th >Stage </th> 
            <th>Task Name</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {
          stage?.map((stages, projectIndex) => {
            return (
              <Fragment key={projectIndex}>
                {/* {stages?.loanTask?.map((employee, employeeIndex) => (
                  <tr key={employeeIndex}> */}
                    {/* <td onClick={()=>{setVisibleTask(true)}}>{employeeIndex === 0 ? stages.loanStage?.loanStage : null }</td> */}
                   <tr>
                    <td>{projectIndex+1}</td>
                    <td>{stages.loanStage?.loanStage}</td>
                   
                      <td>{stages.loanTask}</td>
                    
                    <td>{stages.discription}</td>
                    </tr>
                  {/* </tr>
                ))} */}
              </Fragment>
            );
          })}
        </tbody>
      </table>
                </Card>
                </Col>
            </Row>
        </Container>  
        <div className="col-md-4"></div>
        <div className="col-md-5 mt-4">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  id="btn-cancel" onClick={''}>Cancel</Button>
                        <Button color="primary" type="submit" id="btn-cancel">Create Template</Button>
                        </div>
                        </div>
                        </div></div>
                      </div>
                    </div>
                   </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

import React from 'react';
import { Fragment } from 'react';
import axios from "axios";
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import "../../assets/scss/pages/lead.css";
import { useNavigate } from 'react-router-dom';
import Tooltip from "rc-tooltip";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loan from "../../assets/images/loan.png";
import insurance from "../../assets/images/insurance.png";
import realEstate from "../../assets/images/real-estate.png";
import editIcon from "../../assets/images/editIcon.png";
import {Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap" 
export default function ViewDeletedLeads() {
  const toggle = () => setModal(!modal);
  const [modal, setModal] = React.useState(false); 
  const navigate = useNavigate();
  const navigateToCreateLead = () => {
    navigate('/deletedLeads');
  };

  const[leadData,setLeadData]=useState({
    ownerVO: {
        ownerID: '',
        converLead:false,
    }
  });
 
    const {LeadID}=useParams();
   console.log(LeadID);
    const [viewLead, SetViewLead]=useState({});
    useEffect (()=>{
    const dataUrl =`https://pradhanportal.com/loanApplication/master/getLeadById?leadID=${LeadID}`;
        axios.get(dataUrl).then((response)=>{
        
          console.log("response :",response.data)
          setLeadData(response.data);
          SetViewLead(response.data);
        }).catch((err)=>{
           console.log(err);
        });
    
  },[LeadID]);

console.log("viewlead",viewLead);

const[leadOwners,setLeadOwners]=useState([]);
useEffect(()=>{
  const d1 =`https://pradhanportal.com/loanApplication/master/getAllOwner`;
  axios.get(d1).then((response)=>{
    console.log("response :",response.data)

    setLeadOwners(response.data);
  }).catch((err)=>{
     console.log(err);
  });
},[]);

  const navigateToUpdateLead=()=>{
    navigate(`/UpdateLead/${LeadID}`);
  }

  const SubmitForm=(e)=>{
    
    e.preventDefault();
    console.log("leadData",leadData);
    axios.put('https://pradhanportal.com/loanApplication/master/editLead',leadData).then((response)=>{
        console.log(response);
     
      }).catch((err)=>{
        console.error(err);
      });
      DeleteLead();
  };

  const DeleteLead = () => {
    const dataUrl =`https://pradhanportal.com/loanApplication/master/convertLead?leadID=${LeadID}`;
        axios.put(dataUrl).then((response)=>{
        
          console.log("response :",response.data);
          
          setLeadData(response.data);
        }).catch((err)=>{
           console.log(err);
        });
  navigate(`/LeadCreation-Lead`);
  toast.success('Lead Converted successfully !', {
    position: toast.POSITION.TOP_RIGHT
});
};

  const handleChange = (e) => {  
    setLeadData({ 
        ...leadData,
        ownerVO:{
          ownerID: e.target.value }    
    }); 
  };

  const RestoreLead =()=>{
    const dataUrl =`https://pradhanportal.com/loanApplication/master/restoreDeletedLead?leadID=${LeadID}`;
    axios.put(dataUrl).then((response)=>{
    
      console.log("response :",response.data);
      
      setLeadData(response.data);
    }).catch((err)=>{
       console.log(err);
    });
navigate(`/LeadCreation-Lead`);
toast.success('Lead Restored successfully !', {
position: toast.POSITION.TOP_RIGHT
});
  }

  var renderTooltip = <span>Edit Lead</span>;
  return (
    <Fragment>
          <div className="container-fluid ">
           <div className="card">
           <div className="card-header">
           <div className="row">
            <div className="col-md-6">
            <span id='heading'> {viewLead.customerName}</span> <span className='mx-3 '>{viewLead.customerTypeVO?.customerType}</span>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-2">
            {/* <Tooltip 
                   placement="right"
                   overlay={renderTooltip}
              >
                <h6 id="viewLeadEditIcon">
                <img src={editIcon} width = "24" height = "19" alt='' className='' onClick={navigateToUpdateLead}/>
            </h6>
            </Tooltip> */}
            </div>
           </div>
           </div>
            <div className="row">
              <div className="col-md-6">
                <div className="card" id='card1'>
                  <div className="card-header"id='heading2'>
                    Lead Details {
                      viewLead.type==="Loan"?<span> <img src={loan} width = "40" height = "30" alt='' className=''/></span>:null
                    }
                    {
                      viewLead.type==="Insurance"?<span> <img src={insurance} width = "40" height = "30" alt='' className=''/></span>:null
                    }
                    {
                      viewLead.type==="Real Estate"?<span> <img src={realEstate} width = "40" height = "30" alt='' className=''/></span>:null
                    }
                  </div>
                  <div className="card-body">
                    <div className="row">
                  <div className="col-md-3">
                   <span className="d-flex justify-content-end">Lead ID</span>
                </div>
                <div className="col-md-9">
                <span className=' text-white px-1'id='leadidbg'> {viewLead.id} </span>
                </div>
                <div className="col-md-3 mt-2">
                   <span className="d-flex justify-content-end">Created On</span>
                </div>
                <div className="col-md-7 mt-2">
                {viewLead.createdDate}
                </div>
                <div className="col-md-3 mt-2">
                   <span className="d-flex justify-content-end">Branch</span>
                </div>
                <div className="col-md-7 mt-2">
                  {viewLead?.initiatorVO?.branchVO.branch}
                </div>
                <div className="col-md-3 mt-2">
                   <span className="d-flex justify-content-end">Lead Type</span>
                </div>
                <div className="col-md-7 mt-2">
                  {viewLead.typesVO?.leadTypeVO?.leadType}  -  {viewLead?.typesVO?.type}
                </div>
                {/* <div className="col-md-3 mt-2">
                   <span className="d-flex justify-content-end">Loan Type</span>
                </div>
                <div className="col-md-7 mt-2">
                {viewLead.loanType}
                </div> */}
                <div className="col-md-3 mt-2">
                   <span className="d-flex justify-content-end">Loan Amount</span>
                </div>
                <div className="col-md-7 mt-2">
               <span className=' text-white px-1'id='leadidbg'> ₹ {viewLead.amount}</span>
                </div>
                <div className="col-md-3 mt-2">
                   <span className="d-flex justify-content-end">Initiated By</span>
                </div>
                <div className="col-md-7 mt-2">
                {viewLead?.initiatorVO?.initiatorName}
                </div>
                <div className="col-md-3 mt-2">
                   <span className="d-flex justify-content-end">Lead Owner</span>
                </div>
                <div className="col-md-7 mt-2">
                {viewLead?.ownerVO?.ownerName}
                </div>
                <div className="col-md-3 mt-2">
                   <span className="d-flex justify-content-end">Lead Source</span>
                </div>
                <div className="col-md-7 mt-2">
                <span className=' text-white px-1'id='leadidbg'> {viewLead?.leadSourceVO?.leadSource}</span>
                </div>
                  </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-1"></div> */}
              <div className="col-md-6">
               <div className="card"id='card2'>
                <div className="card-header"id='heading2'>
                  Contact Details
                </div>
                <div className="card-body">
                <div className="row">
                <div className="col-md-5 mt-2">
                   <span className="d-flex justify-content-end">Primary Mobile Number</span>
                </div>
                <div className="col-md-7">
                {viewLead.primaryNumber}
                <span className='mx-2' id='viewLeadWhatsapp'>
                          <i class="bi bi-whatsapp"></i>
                        </span>
                </div>
                <div className="col-md-5 mt-2">
                   <span className="d-flex justify-content-end">Secondary Mobile Number</span>
                </div>
                <div className="col-md-7 mt-2">
               {viewLead.secondaryNumber} 
               
                </div>
                <div className="col-md-5 mt-2">
                   <span className="d-flex justify-content-end">Email ID</span>
                </div>
                <div className="col-md-7 mt-2">
                  {viewLead.emailID}
                </div>
                </div>
                {
                  viewLead.customerType ==="Non Individual"?
                  <Fragment>
                   <div className="row">
                   <div className="col-md-5 mt-2">
                   <span className="d-flex justify-content-end">Contact Person</span>
                </div>
                <div className="col-md-7 mt-2">
                  {viewLead.customerName}
                </div>
                   </div>
                  </Fragment>:null
                }
                {
                 viewLead.customerContactDetail==="Add Seprately"?
                   <Fragment>
                        <div className="row">
                   <div className="col-md-5 mt-2">
                   <span className="d-flex justify-content-end">Customer Mobile No</span>
                </div>
                <div className="col-md-7 mt-1">
                  {viewLead.customerMobileNo}
                  <span className='mx-2' id='whatsapp'>
                    <i class="bi bi-whatsapp"></i>
                  </span>
                </div>
                <div className="col-md-5 mt-2">
                   <span className="d-flex justify-content-end">Customer 2nd Mobile No</span>
                </div>
                <div className="col-md-7 mt-2">
                  {viewLead.customer2ndMoNo}
                  
                </div>
                <div className="col-md-5 mt-2">
                   <span className="d-flex justify-content-end">Customer Email ID</span>
                </div>
                <div className="col-md-7 mt-2">
                  {viewLead.contactEmail}
                </div>
                   </div>
                   </Fragment>:null
                   
                }
                </div>
               </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-4">
                <div className="card" id='card3'>
                  <div className="card-header"id='heading2'>
                    Log Notes
                  </div>
                  <div className="card-body">
                      <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                        {viewLead.leadNotes}
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-1"></div> */}
              <div className="col-md-6 mt-4">
               <div className="card"id='card4'>
                <div className="card-header"id='heading2'>
                  General Discussion {
                    viewLead.gdStatusVO
                    ?.gdStatus==="Pending" ?<Fragment><span className='mx-3 text-success'>{viewLead.gdStatusVO?.gdStatus
                    }</span></Fragment>:
                    <Fragment>
                      <span className='mx-3 text-danger'>{viewLead.gdStatusVO?.gdStatus}</span>
                      </Fragment>
                  }
                </div>
                <div className="card-body">
                <div className="row">
                  <div className="col-md-1"></div>
                        <div className="col-md-10">GD Link</div>
                       
                        <div className="col-md-10 mx-5 mt-2">
                        <a href={viewLead.gdLink}>{viewLead.gdLink}</a>
                        </div>
                      </div>
                </div>
               </div>
              </div>
            </div>
            <div className="card-footer">
            <div className="row mt-3 mb-2">
                      <div className="col-md-12">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                          <button
                          onClick={navigateToCreateLead}
                            class="btn btn-secondary"
                            id="editbtn"
                            type="button"
                          >
                            Back
                          </button>
                          <button
                          
                          onClick={toggle}
                          class="btn btn-primary" type="button" id='editbtn'>
                           Restore Lead
                          </button>
                        </div>
                      </div>
                    </div>
            </div>
            <Modal fade={true} isOpen={modal} toggle={toggle} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#f7b84b,secondary:#f06548"
            style={{ width: "100px", height: "100px" }}
          ></lord-icon>
          {/* <span className='warningmodal'>Warning !</span> */}
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-4 mb-0">
              Are you sure you want to restore  this Lead ?
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-secondary"
            data-bs-dismiss="modal"
            onClick={toggle}
          >
            Close
          </button>
          <button
            type="button"
            className="btn w-sm btn-primary "
            id="delete-record"
            onClick= {RestoreLead}
          >
            Restore Lead
          </button>
        </div>
      </ModalBody>
    </Modal>
            {/* <Modal isOpen={modal} id="assignmodal">
                 <ModalHeader className="bg-primary d-flex justify-content-center pb-1 pt-1">
                    <span id="assignowner1">Convert Lead</span>
                </ModalHeader>
                <ModalBody>
                   <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <form onSubmit={SubmitForm}>
                          <div className="row">
                          <div className="col-md-4 mt-2">
                        <span className="d-flex justify-content-end">customer</span>
                      </div>
                      <div className="col-md-8 mt-1">
                      {viewLead.customerName}
                      </div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Lead Type</span>
                      </div>
                      <div className="col-md-8 mt-2">
                      {viewLead.typesVO?.leadTypeVO?.leadType}  -  {viewLead?.typesVO?.type}
                      </div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Lead Owner</span>
                      </div>
                      <div className="col-md-8 mt-2">
                      {viewLead?.ownerVO?.ownerName}  
                      </div>
                      <div className="col-md-4 mt-3">
                      <span className="d-flex justify-content-end  ">Select Task Template

</span>
                          </div>
                          <div className="col-md-8 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name="ownerVO.ownerID"
                          // onChange= {handleChange}
                          // value={leadData.ownerVO?.ownerID}
                        >
                          <option selected>Please Select </option>
                          <option value="Template 1">Template 1</option>
                          <option value="Template 2">Template 2</option>
                          </select>
                          </div>
                        <div className="col-md-4 mt-3">
                      <span className="d-flex justify-content-end  ">Executive Name</span>
                          </div>
                          <div className="col-md-8 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name="ownerVO.ownerID"
                          onChange= {handleChange}
                          value={leadData.ownerVO?.ownerID}
                        >
                          <option selected>Please Select Owner</option>
                          {
                            leadOwners?.length > 0 ? <Fragment>
                              {
                                leadOwners.map((owner,index)=>{
                                  return(
                                    <option key={index} value={owner.ownerID}>{owner.ownerName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                          </select>
                          </div>
                          <div className="col-md-12 mt-4">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  id="btn-cancel" onClick={toggle}>Cancel</Button>
                        <Button color="primary" type="submit" id="btn-cancel">Restore Lead</Button>
                        </div>
                        </div>
                        </div></form>
                      </div>
                    </div>
                   </div>
                </ModalBody>
            </Modal>  */}
           </div>
          </div>
    </Fragment>
  )
}

import types from "./actionTypes";



export const logInStart = (credentials,navigate) =>  {
  console.log("navigate 0 : ",navigate)
  console.log("credentials 0 : ",credentials)
  return ({
  type: types.LOG_IN_START,
  payload: {credentials,navigate},
})};

export const logInSuccess = (user) => ({
  type: types.LOG_IN_SUCCESS,
  payload: user,
});

export const logInFailure = (error) => ({
  type: types.LOG_IN_FAILURE,
  payload: error,
});

export const registerStart = (credentials) => ({
  type: types.REGISTER_START,
  payload: credentials,
});

export const registerSuccess = (user) => ({
  type: types.REGISTER_SUCCESS,
  payload: user,
});

export const registerFailure = (error) => ({
  type: types.REGISTER_FAILURE,
  payload: error,
});

export const logOut = (navigate) => ({
  type: types.LOG_OUT,
  payload:{navigate},
});
import React,{Fragment} from 'react';
import { useEffect } from 'react';
import {Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap";
import axios from "axios";
import { useState } from 'react';
export default function LeadOwnerAssingModal() {
    const toggle = () => setModal(!modal);
    const [modal, setModal] = React.useState(false); 

    const[owner,setOwner]=useState([]);
    useEffect(()=>{
      const d6 =`https://pradhanportal.com/loanApplication/master/getAllOwner`;
      axios.get(d6).then((response)=>{
        console.log("response :",response.data)
    
        setOwner(response.data);
      }).catch((err)=>{
         console.log(err);
      });
    },[])
  return (
    <Fragment>
        Helllllllloooo
         <Modal  id="assignmodal">
                 <ModalHeader className="bg-primary d-flex justify-content-center pb-1 pt-1">
                    <span id="assignowner1">Assign Lead</span>
                </ModalHeader>
                <ModalBody>
                   <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form">
                          <div className="row">
                          <div className="col-md-4 mt-2">
                        <span className="d-flex justify-content-end">Customer Name</span>
                      </div>
                      <div className="col-md-8 mt-1">
                        
                      </div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Lead Type</span>
                      </div>
                      <div className="col-md-8 mt-2">
                      </div>
                        <div className="col-md-4 mt-3">
                      <span className="d-flex justify-content-end  ">Executive Name</span>
                          </div>
                          <div className="col-md-8 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='employmentType.empTypeID'
                          // onChange={handleChange}
                          // value={values.employmentType?.empTypeID}
                          // onBlur={handleBlur}
                        >
                          <option selected>Please Select</option>
                          {
                            owner?.length > 0 ? <Fragment>
                              {
                                owner.map((role,index)=>{
                                  return(
                                    <option value={role.ownerID}key={index}>{role.ownerName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                          </select>
                          </div>
                          <div className="col-md-12 mt-4">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  id="btn-cancel" onClick={toggle}>Cancel</Button>
                        <Button color="primary" type="submit" id="btn-cancel">Assign Lead</Button>
                        </div>
                        </div>
                        </div></div>
                      </div>
                    </div>
                   </div>
                </ModalBody>
            </Modal> 
    </Fragment>
  )
}

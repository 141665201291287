import React,{Fragment, useMemo}from 'react'
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Input,} from "reactstrap";
import "rc-tooltip/assets/bootstrap.css";
import axios from "axios";
import Tooltip from "rc-tooltip";
import TableContainer from '../../../../Components/Common/TableContainer';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Cell } from 'gridjs';
import moment from 'moment';
import "../../../../assets/scss/pages/_userManagement.css"
export default function UserMgmtPool() {


    var renderTooltip = <span>Create New User</span>;

    const navigate = useNavigate();
  const navigateToCreateLead = () => {
    // 👇️ navigate to /CreateLead
    navigate('/addNewUser');
  };
  
  const completeJD = () => {
    toast.error('Error! Complete the General Discussion First', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toast-message'
    });
};
    const[filterLeads,setFilterLeads]=useState([]);
    const [search,setSearch]=useState("");
    const [data, setData] = useState([]);
   
     
    const loadLead = async () => {
      const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllUsers`);
      setData(result.data);
      
      setFilterLeads(result.data);
    };

   console.log("user",data)
    useEffect(() => {
      loadLead();
    }, []);
    // useEffect(() => {
    //   const result=data.filter(leads=>{
    //     return leads.customerName.toLowerCase().match(search.toLowerCase())
    //   });
    //   setFilterLeads(result);
    // }, [search]);
    const columns = useMemo(
      () => [
        {
            Header: "Sr No",
            Cell: (data) => {
                return ( 
                    <>{data.row.index + 1}</>
                )},
                filterable: false,
        },
        {
          Header: "User ID",
          Cell: (data) => {
            return (
              <Link id='leadid1' to={`/viewUser/${data?.row.original.userId}`} >{data?.row.original.generatedUserId}</Link>
            )}
          
        },
        {
          Header: "DOB",
          accessor: (d) => {
            return moment(d.dob)
              .format("DD MMM YYYY")
          },
          filterable: false,
          
        },
        
        {
          Header: "User Name",
          accessor: "userName",
          filterable: false,
          
        },
        {
          Header: "User Role",
          accessor: "role.role",
          filterable: false,
        },
        {
          Header: "Branch",
          accessor: "branch.branch",
          filterable: false,
          
        },
        {
          Header: "City",
          accessor: "city.cityName",
          filterable: false,
        },
        {
          Header: "Mobile Number",
          accessor: "primaryNumber",
          filterable: false,
        },
        {
            Header: "Status",
            // accessor: "status",
            Cell: (data) => (
             <Fragment>
              {
                data.row.original.status !== "Active" ? <Fragment>
                      
                       <div className='DisableUser'>
                     {data.row.original.status}
                     </div>
                </Fragment>:
                <Fragment>
                     <div className='ActiveUser'>
                       {data.row.original.status}
                       </div>
                </Fragment>
              }
             </Fragment>
            ),
            filterable: false,
          },
        // {
          
      ],
      []
    );

  return (
    <Fragment>
        <Container fluid>
            <Row>
                <Col lg={12}>
                <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={3}>
                    <div className='d-flex align-items-center '>
              <div className='d-inline-block'>
                <h3 style={{width:"100px"}}> User List</h3>
              </div>
              <div className='d-inline-block'>
              <Tooltip 
                   placement="right"
                   overlay={renderTooltip}
              >
              <h2><i onClick={navigateToCreateLead} className="bi bi-plus-square-fill btn btn-lg"></i> </h2>  
            </Tooltip>
              </div>
              <div className='d-inline-block mx-5'>
              <Link to="/addRolePrivileges" className='btn btn-primary'style={{width:"200px"}}>Role Privileges</Link>
              {/* <Tooltip 
                   placement="right"
                   overlay={renderTooltip}
              >
              <h2><i onClick={navigateToCreateLead} className="bi bi-plus-square-fill btn btn-lg"></i> </h2>  
            </Tooltip> */}
              </div>
            </div>
              </Col><Col sm={6}></Col>
              <Col sm={3}>
                      <div className="search-box">
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder="Search for..."
                          value={search}
                          onChange={(e)=>setSearch(e.target.value)}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                <div className="main">
                {
                  data?.deleteFlag!==null?(
                    <TableContainer
                    columns={columns}
                    data={filterLeads}
                    isGlobalFilter={false}
                    isAddUserList={false}
                    customPageSize={5}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-0"
                    tableClass="align-middle table-nowrap"
                    theadClass="tableheader"
                    isLeadsFilter={false}
                    SearchPlaceholder='Search for'
                    highlightOnHover
                   
                  />
                  ):null
                }
                  </div>
                  </CardBody>
                </Card>
                </Col>
            </Row>
        </Container>
    </Fragment>
  )
}



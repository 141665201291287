import React from 'react';
//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


function App() {
 
  return (
    
      <React.Fragment>
      <Route />
      <ToastContainer autoClose={2500}  />
    </React.Fragment>
    
  );
}

export default App;

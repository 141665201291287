import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import {logger} from "redux-logger";
import thunk from "redux-thunk";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware,logger,thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore(initialState) {

  const store = createStore(
    
    rootReducer,
      initialState,
      composeEnhancers(
          applyMiddleware(...middlewares)
      ),
  );
  sagaMiddleware.run(rootSaga);
  return store;
}
// export{store};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import leadslogo from "../assets/images/leads.png"
import AddNewCustomer from "../pages/CustomerManagement/CreateCustomer";
import axios from "axios";
const Navdata = () => {
    const navigate = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false);
    
    const [isBaseUi, setIsBaseUi] = useState(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState(false);
    const [isForms, setIsForms] = useState(false);
    const [isTables, setIsTables] = useState(false);
    const [isCharts, setIsCharts] = useState(false);
    const [isIcons, setIsIcons] = useState(false);
    const [isMaps, setIsMaps] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);
    //Lead
    const [isLeadCreation , setIsLeadCreation]=useState(false);
    const[isLeadPool,setIsLeadPool]=useState(false);
    const [isDeletedLead,setIsDeletedLead]=useState(false);
    const [isArchivedLead,setIsArchivedLead]=useState(false);
    //Customer Management
    const[isCustomerManagement,setIsCustomerManagement]=useState(false);
    const [isSettingApplication,setIsSettingApplication]=useState(false);

    //Loan Case
    const[isLoanCase,setIsLoanCase]=useState(false);

    //Channel PArtner
    const[isChannelPartner,setIsChannelPartner]=useState(false);

    //Bank Branch Master
    const[isBankBranch,setIsBankBranch]=useState(false);
// Loan Emi Calculator
const[isEmiCalculator,setIsEmiCalculaotor]= useState(false);
    //User Management
    const[isUserManagement,setIsUserManagement]=useState(false);
    // Apps
    const [isEmail, setEmail] = useState(false);
    const [isSubEmail, setSubEmail] = useState(false);
    const [isEcommerce, setIsEcommerce] = useState(false);
    const [isProjects, setIsProjects] = useState(false);
    const [isTasks, setIsTasks] = useState(false);
    const [isCRM, setIsCRM] = useState(false);
    const [isCrypto, setIsCrypto] = useState(false);
    const [isInvoices, setIsInvoices] = useState(false);
    const [isSupportTickets, setIsSupportTickets] = useState(false);
    const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
    const [isJobs, setIsJobs] = useState(false);
    const [isJobList, setIsJobList] = useState(false);
    const [isCandidateList, setIsCandidateList] = useState(false);


    // Authentication
    const [isSignIn, setIsSignIn] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [isPasswordCreate, setIsPasswordCreate] = useState(false);
    const [isLockScreen, setIsLockScreen] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [isVerification, setIsVerification] = useState(false);
    const [isError, setIsError] = useState(false);

    // Pages
    const [isProfile, setIsProfile] = useState(false);
    const [isLanding, setIsLanding] = useState(false);


    // Charts
    const [isApex, setIsApex] = useState(false);

    // Multi Level
    const [isLevel1, setIsLevel1] = useState(false);
    const [isLevel2, setIsLevel2] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if(iscurrentState !=="LeadCreation"){
            setIsLeadCreation(false);
        }
        if(iscurrentState !== 'DeletedLeads'){
            setIsDeletedLead(false);
        }
        if(iscurrentState !== 'ArchivedLeads'){
            setIsArchivedLead(false);
        }
        if(iscurrentState !=="Customer Management"){
            setIsCustomerManagement(false);
        }
        if(iscurrentState !=="bankBranchManagement"){
            setIsBankBranch(false);
        }
        if(iscurrentState !=="LoanCases"){
            setIsLoanCase(false);
        }
        if(iscurrentState !=="channelPartner"){
            setIsChannelPartner(false);
        }
        if(iscurrentState !=='LeadFreePool'){
            setIsLeadCreation(false);
        }
        if(iscurrentState !=="settingApplication"){
            setIsSettingApplication(false);
        }
        if(iscurrentState !== "userManagement"){
            setIsUserManagement(false);
        }
        if(iscurrentState !=='emiCalculator'){
            setIsEmiCalculaotor(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (iscurrentState === 'Widgets') {
            navigate("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState !== 'Landing') {
            setIsLanding(false);
        }
    }, [
        navigate,
        iscurrentState,
        isDashboard,
        isLeadCreation,
        isDeletedLead,
        isArchivedLead,
        isLeadPool,
        isCustomerManagement,
        isSettingApplication,
        isLoanCase,
        isBankBranch,
        isApps,
        isChannelPartner,
        isEmiCalculator,
        isUserManagement,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel
    ]);
    const authUser = JSON.parse(localStorage.getItem("user"));
    const obj=authUser?.role?.role;
    console.log("role....",obj);

//     const [data, setData] = useState([]);

//     useEffect(() => {
//       const d6 = `https://pradhanportal.com/loanApplication/master/getAllLoanCases`;
//       axios
//         .get(d6)
//         .then((response) => {
//           console.log("response :", response.data);
//           setData(response.data);
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }, []);
//   console.log("data",data);


    const menuItems = [
        {
            label: "",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Dashboard",
            icon: "ri-dashboard-2-line",
            link: "/dashboard",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
         },
         authUser?.role?.role ==="Executive" || authUser?.role?.role === "Assistant Manager" ||  authUser?.role?.role ==="Team Leader" || authUser?.role?.role ==="Local Admin"?
           
         {
             id: "emiCalculator",
             label: "Loan Eligibility",
             icon: "ri-pages-line",
             link: "/emiCalculator",
             stateVariables: isEmiCalculator,
             click: function (e) {
                 e.preventDefault();
                 isEmiCalculator(!isEmiCalculator);
                 setIscurrentState('emiCalculator');
                 updateIconSidebar(e);
             }
     }:{}, 
     
       // authUser?.role?.role ==="Sales Executive" ||  authUser?.role?.role ==="Team Leader" || authUser?.role?.role === "Assistant Manager" || authUser?.role?.role ==="Local Admin" ?
          {
            
                id: "apps",
                label: "Leads",
                icon: "ri-apps-2-line",
                link: "/#",
                stateVariables: isApps,
                click: function (e) {
                    e.preventDefault();
                    setIsApps(!isApps);
                    setIscurrentState('Apps');
                    updateIconSidebar(e);
                },
                stateVariables: isApps,
                subItems: [
                    {
                        id: "LeadCreation",
                        label: "Free Pool",
                        link: "/LeadCreation-Lead",
                        parentId: "apps",
                    },
                    {
                        id: "ArchivedLeads",
                        label: "Archived Leads",
                        link: "/archivedLeads",
                        parentId: "apps",
                    },
                    {
                        id: "DeletedLeads",
                        label: "Deleted Leads",
                        link: "/deletedLeads",
                        parentId: "apps",
                    },
                    
                ],
            
        //  }:{
            
        },
                authUser?.role?.role ==="Executive" ||  authUser?.role?.role ==="Team Leader" || authUser?.role?.role === "Assistant Manager"|| authUser?.role?.role ==="Local Admin" ?
            {
                id: "Customer Management",
                label: "Customer Management",
                icon: " ri-group-2-fill",
                link: "/Customers",
                stateVariables: isCustomerManagement,
                click: function (e) {
                    e.preventDefault();
                    setIsLeadPool(!isCustomerManagement);
                    setIscurrentState('Customer Management');
                    updateIconSidebar(e);
                }
            }:{},
            authUser?.role?.role ==="Executive" || authUser?.role?.role === "Assistant Manager" ||  authUser?.role?.role ==="Team Leader" || authUser?.role?.role ==="Local Admin"?
            {
                id: "LoanCases",
                label: "Loan Cases",
                icon: "ri-pages-line",
                link: "/loanCases",
                stateVariables: isLoanCase,
                click: function (e) {
                    e.preventDefault();
                    isLoanCase(!isLoanCase);
                    setIscurrentState('LoanCases');
                    updateIconSidebar(e);
                },
            }:{},   
            
            authUser?.role?.role ==="Executive" || authUser?.role?.role === "Assistant Manager" ||  authUser?.role?.role ==="Team Leader" || authUser?.role?.role ==="Local Admin"?
           
            {
                id: "channelPartner",
                label: "Channel Partner",
                icon: "ri-pages-line",
                link: "/channelPartner",
                stateVariables: isChannelPartner,
                click: function (e) {
                    e.preventDefault();
                    isChannelPartner(!isChannelPartner);
                    setIscurrentState('channelPartner'); 
                    updateIconSidebar(e);
                }
        }:{}, 
        authUser?.role?.role ==="Executive" || authUser?.role?.role === "Assistant Manager" ||  authUser?.role?.role ==="Team Leader" || authUser?.role?.role ==="Local Admin"?
           
            {
                id: "bankBranchManagement",
                label: "Bank Branch",
                icon: "ri-pages-line",
                link: "/bankBranchMaster",
                stateVariables: isBankBranch,
                click: function (e) {
                    e.preventDefault();
                    isBankBranch(!isBankBranch);
                    setIsBankBranch('bankBranchManagement'); 
                    updateIconSidebar(e);
                }
        }:{}, 
             authUser?.role?.role ==="Local Admin" ?
            {
                id: "settingApplication",
                label: "Settings",
                icon: " ri-settings-3-line",
                link: "/applicationSetting",
                stateVariables: isSettingApplication,
                click: function (e) {
                    e.preventDefault();
                    setIsSettingApplication(!isSettingApplication);
                    setIscurrentState('settingApplication');
                    updateIconSidebar(e);
                }
            }:{},    
            authUser?.role?.role ==="Local Admin" ?    
                {
                id: "userManagement",
                label: "User Management",
                icon: " ri-group-2-fill",
                link: "/userMgmtPool",
                stateVariables: isUserManagement,
                click: function (e) {
                    e.preventDefault();
                    setIsUserManagement(!isUserManagement);
                    setIscurrentState('userManagement');
                    updateIconSidebar(e);
                }
            }:{}
     


   
    

    ];

    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
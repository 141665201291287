import React,{Fragment, useState} from 'react'
import pfslogo from "../../assets/images/pradhanFinservLogo_full_color.png";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
export default function ForgotPassWord() {

const navigate = useNavigate();
const [isSubmitted, setSubmitted]=useState(false);
const navigateEnterCode = () => {
     navigate('/vefifyCode');
}

const [data,setData]=useState({
  emailID:'',
})

const handelChange=(e)=>{
  setData({ 
    ...data,
  [e.target.name]: e.target.value       
}); 
}
const email=data.emailID;
console.log("email+++++++",email);
const SubmitForm=(e)=>{
      
  e.preventDefault();
  console.log("data",data);
  const email=data.emailID
  axios.get(`/login/sendResetPasswordEmail?emailID=${email}`).then((response)=>{
      console.log(response);
      localStorage.setItem("userEmail",JSON.stringify(email));
      setSubmitted(true);
    }).catch((err)=>{
      console.error(err);
    });
};
  return (
    <Fragment>
       {
       isSubmitted ? navigate(`/vefifyCode/${email}`) 
      //  toast.success('Lead Updated successfully!', {
      //   position: toast.POSITION.TOP_RIGHT
      //   })
       :
     
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-12">
                    <div className="row mt-5">
                        <div className="col-md-1"></div>
                        <div className="col-md-11">
                        <img src={pfslogo} alt="" height="73" width="195" className='logo' />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <form onSubmit={SubmitForm}>
                            <div className="card passforgotcard">
                                <div className="card-header bg-primary">
                                   <span className='passforgotcardheader'> Forgot Password?</span>
                                </div>
                                <div className="card-body">
                                  <div>
                                    <span>
                                    Don't worry, we'll send you a message to help you reset your password.
                                    </span>
                                  </div>
                                  <div className='mt-4'>
                                  Registered Email ID
                                  </div>
                                  <div className='mt-3'>
                                  <input type="text" 
                                  className="form-control"
                                  required
                                  name='emailID'
                                  onChange={handelChange}
                                   placeholder="Enter your register mail id"
                                   aria-label="Username" aria-describedby="basic-addon1"
                                  />
                                  </div>
                                  <div className="row mt-1">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-5">
                                    <div className='mt-4'>
                                  <button className="btn btn-primary btn-md" type="submit" id="password-addon"
                                   > Continue </button>
                                  </div>
                                    </div>
                                    <div className="col-md-3"></div>
                                  </div>
                                </div>
                            </div>
                            </form>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>
            </div>
        </div>
}
    </Fragment>
  )
}

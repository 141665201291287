import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LeadDetails from "./LeadDetails";
import CustomerDetails from "./CustomerDetails";
import { useMultipleForm } from "usetheform";
import StepLabel from "@mui/material/StepLabel";
import ContactDetails from "./ContactDetails";
import AddressDetails from "./AddressDetails";
import CaseDetails from "./CaseDetails";
import { useState } from "react";
const steps = [
  "Lead Details",
  "Customer Details",
  "Conatct Details",
  "Case Details",
  "Address Details",
];

export default function AddLeads() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [getWizardState, wizard] = useMultipleForm();
  const[leadData,setLeadData]=React.useState({
    customerName: "",
    customerId: "",
    lookingFor:"",
    customerType:'',
    residentTypeVO: {
      residentTypeID: "",
    },
    customerTypeVO: {
      customerTypeID: "",
    },
    leadTypeVO: {
      leadTypeID: "",
    },
    typesVO: {
      typeId: "",
    },
    subTypesVO: {
      subTypeID: "",
    },
    initiatorVO: {
      initiatorID: "",
    },
    branchVO: {
      branchID: "",
    },
    bankVO:{
      bankId:'',
    },
    bankBranchVO:{
      bankBranchId:'',
    },
    ownerVO: {
      ownerID: "",
    },
    userVO:{
      userId:'',
    },
    partnerVO:{
      partnerId:'',
    },
    partnershipVO:{
      partnershipId:'',
    },
    projectName:'',
    gdStatusVO: {
      gdStatusID: "",
    },
    leadSourceVO: {
      leadSourceID: "",
    },
    country:'',
      countryId:'',
      state:'',
      stateId:'',
      cityId:'',
      city:'',
    addressLine1:"",
    addressLine2:"",
    landMark:'',
    coverAmount:'',
    pinCode:'',
    previousPolicy:"",
    addOn:"",
    gender: "",
    dob:'',
    paymentMode:"",
    rcNumber:"",
    previousPolicyCover:"",
    previousPolicyCompany:"",
    previousDiseaseOfCustomer1:"",
    propertyAge:"",
    propertyCost:"",
    previousLoanAmount:"",
    addOnEstimatedCost:"",
    nomineeName:"",
    nomineedob:"",
    nomineeRelation:"",
  familyVO:[
    {
      familyMemberCount:"",
      familyMemberName:"",
      familyMemberdob:"",
      familyMembergender:"",
      familyMemberPreviousDisease:""  
    }
  ],
    directorName:"",
    primaryNumber: "",
    secondaryNumber: "",
    emailID: "",
    amount: "",
    gdLink: "",
    directorPrimaryNumber: "",
    directorSecondaryNumber: "",
    directorEmailID: "",
    customerContactDetails: "",
    contactPerson1stNumber: "",
    contactPerson2ndNumber: "",
    contactPersonEmail: "",
    contactPerson: "",
    contactPersonRelation: "",
    leadNotes: "",
    text: "",
    item: "",
  })
  const[familyMember,setFamilyMember]=useState({
    
    familyMemberCount:"",
    familyMemberName:"",
    familyMemberdob:"",
    familyMembergender:"",
    familyMemberPreviousDisease:""
 
})
  const [selectedVal, setSelectedVal] = useState({
    label:"",
    id:""
});
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const getStepContent = (value) => {
  
    if (activeStep === 0) {
     return <LeadDetails handleNext={handleNext} addLeadDetails={addLeadDetails} activeStep={activeStep} handleBack={handleBack} leadData={leadData} setLeadData={setLeadData}/>;
   } else if (activeStep === 1) {
     return <CustomerDetails handleNext={handleNext} addLeadDetails={addLeadDetails} handleBack={handleBack} leadData={leadData} setLeadData={setLeadData}/>;
   } else if (activeStep === 2) {
    return <ContactDetails handleNext={handleNext} addLeadDetails={addLeadDetails} handleBack={handleBack} leadData={leadData} setLeadData={setLeadData}/>;
  }  else if (activeStep === 3) {
    return <CaseDetails handleNext={handleNext} addLeadDetails={addLeadDetails} handleBack={handleBack} leadData={leadData} setLeadData={setLeadData} familyMember={familyMember} setFamilyMember={setFamilyMember}/>;
  }
   else if (activeStep === 4) {
    return <AddressDetails handleNext={handleNext} addLeadDetails={addLeadDetails} handleBack={handleBack} leadData={leadData} setLeadData={setLeadData} selectedVal={selectedVal} setSelectedVal={setSelectedVal}/>;
  }  
 };
 const addLeadDetails =()=>{
  console.log("Lead Details Added",leadData)
 }
  return (
    <Box sx={{ width: "100%" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
                <Stepper  activeStep={activeStep}>
                  {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]} >
                      <StepLabel><span id="headerLabel">{label}
                        </span></StepLabel>
                    </Step>
                  ))}
                </Stepper>
                
               <div className="card-body">
                 <div>
                  {/* {allStepsCompleted() ? (
                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                      </Typography>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleReset}>Reset</Button>
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment> */}
                      {/* <Typography >
                        <div className="">{getStepContent(activeStep)}</div>
                      </Typography> */}
                      {/* <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      > */}
                        {/* <div className="row">
                          <div className="col-md-4"></div>
                          <div className="col-md-6">
                          <button
                          className="btn btn-secondary me-md-2"
                          // id="Cancelbtn1"
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Back
                        </button>
                        <button onClick={handleNext}
                        className="btn btn-primary"
                        // id="Cancelbtn2"
                        >
                          Next
                        </button>
                          </div>
                        </div> */}
                        {/* {activeStep !== steps.length &&
                          (completed[activeStep] ? (
                            <Typography
                              variant="caption"
                              sx={{ display: "inline-block" }}
                            >
                              Step {activeStep + 1} already completed
                            </Typography>
                          ) : (
                            <Button onClick={handleComplete}>
                              {completedSteps() === totalSteps() - 1
                                ? "Finish"
                                : "Complete Step"}
                            </Button>
                          ))} */}
                      {/* </Box> */}
                    {/* </React.Fragment>
                  )} */}
                  {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
             {/* {joinpoupopen(true)}         */}
             </Typography>
      ) : (
        <>
        <div className="row marglrftrightminusrow">
          <div className={activeStep==0?"col-11":"col-12"}>
          <div className="">{getStepContent(activeStep)}</div> 
         
         
          </div>
          <br/>
        </div>
        </>
      )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}

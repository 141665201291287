import React,{Fragment, useState} from 'react'
import pfslogo from "../../assets/images/pradhanFinservLogo_full_color.png";
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
export default function VerificationCode() {

const navigate = useNavigate();
const user = JSON.parse(localStorage.getItem('userEmail'));
console.log("user===",user)
const[data,setData]=useState({
  otp:'',
})
const handelChange=(e)=>{
  setData({ 
    ...data,
  [e.target.name]: e.target.value       
}); 
}

const SubmitForm=(e)=>{   
  e.preventDefault();
  console.log("data",data);
  const otp=data.otp
  axios.get(`/login/validateOTP?emailID=${user}&otp=${otp}`).then((response)=>{
      console.log(response);
      if(response.code==200){
        toast.success("otp Verified")
        navigate("/resetPassword");
      }
    }).catch((err)=>{
      console.error(err);
    });
};
  return (
    <Fragment>
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-12">
                    <div className="row mt-5">
                        <div className="col-md-1"></div>
                        <div className="col-md-11">
                        <img src={pfslogo} alt="" height="73" width="195" className='logo' />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                          <form onSubmit={SubmitForm}>
                            <div className="card passforgotcard">
                                <div className="card-header bg-primary">
                                   <span className='passforgotcardheader'>Enter Code</span>
                                </div>
                                <div className="card-body">
                                  <div>
                                    <span>
                                    Last step! To secure your account, enter the code we just sent to 
                                    </span>
                                  </div>
                                  <div>
                                    <span>
                                    {user}
                                    </span>
                                  </div>
                                  <div className='mt-4'>
                                  Code
                                  </div>
                                  <div className='mt-2'>
                                  <input type="number" 
                                  required
                                  className="form-control"
                                  name='otp'
                                  onChange={handelChange}
                                   placeholder="Enter code"
                                  />
                                  </div>
                                  <div className="row mt-1">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-5">
                                    <div className='mt-3'>
                                  <button className="btn btn-primary btn-md" type="submit" id="password-addon"
                                   > Submit </button>
                                  </div>
                                    </div>
                                    <div className="col-md-3"></div>
                                  </div>
                                </div>
                            </div>
                            </form>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

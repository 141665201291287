import React,{Fragment, useMemo}from 'react'
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Input,} from "reactstrap";
import "rc-tooltip/assets/bootstrap.css";
import axios from "axios";
import Tooltip from "rc-tooltip";
import { time } from 'echarts';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Cell } from 'gridjs';
import moment from 'moment';
import { Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap" ;
import { useFormik } from "formik";
const initialValues= { 
    loanStageVO:{
        loanStageID:'',
    },
    loanTaskVO:{
        loanTaskId:'',
    },
  subTaskName:"",
 
}
export default function AddSubTask() {
  const [isSubmitted, setSubmitted]=useState(false);
  const {values,errors,handleBlur,handleChange,handleSubmit,touched}=useFormik({
    initialValues:initialValues,
    onSubmit:(values)=>{
      axios.post('https://pradhanportal.com/loanApplication/master/addSubTask',values).then((response)=>{
        console.log(response);
        setSubmitted(true);
        toast.success('Loan Stage created successfully !', {
            position: toast.POSITION.TOP_RIGHT
        });
      }).catch((err)=>{
        console.error(err);
      });
    setSubmitted(true);
  
    },
   });

  var renderTooltip = <span>Add New Task</span>;

    const navigate = useNavigate();
  const navigateToCreateLead = () => {
    navigate('/AddNewCustomer');
  };
  
const NavigateToAddLoanTask =()=>{
  navigate(`/addLoanTask`)
}

  const completeJD = () => {
    toast.error('Error! Complete the General Discussion First', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toast-message'
    });
};
    const[filterLeads,setFilterLeads]=useState([]);
    const [search,setSearch]=useState("");
    const [loanTasks,setloanTasks]=useState([]);
    const [data, setData] = useState([]);
    const toggle = () => setModal(!modal);
    const [modal, setModal] = React.useState(false); 

    
   
   
    const[stages,setStages]=useState([]);
  useEffect(()=>{
    const d1 =`https://pradhanportal.com/loanApplication/master/getAllLoanStage`;
    axios.get(d1).then((response)=>{
      console.log("response :",response.data)
  
      setStages(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[])

console.log("stages",stages);
  const[roles,setRoles]=useState([]);
  useEffect(()=>{
    const d1 =`https://pradhanportal.com/loanApplication/master/getAllRole`;
    axios.get(d1).then((response)=>{
      console.log("response :",response.data)
  
      setRoles(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[])


  const stageId = values?.loanStageVO?.loanStageID

  useEffect(()=>{
    const d1 =`https://pradhanportal.com/loanApplication/master/getAllLoanTaskByStageId?loanStageID=${stageId}`;
    axios.get(d1).then((response)=>{
      console.log("response :",response.data)
  
      setloanTasks(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[stageId])
   console.log("LoanTask",loanTasks)
    // useEffect(() => {
    //   loadLead();
    // }, []);
    // useEffect(() => {
    //   const result=data.filter(leads=>{
    //     return leads.loanTask?.toLowerCase().match(search.toLowerCase())
    //   });
    //   setloanTasks(result);
    // }, [search]);
  return (
    <Fragment>
        {/* <pre>{JSON.stringify(values)}</pre> */}
        {
       isSubmitted ? navigate(`/loanSubTask`) &
       toast.success('Sub Task Added successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
        <div className="container">
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="card">
                       <form onSubmit={handleSubmit}>
                       <div className="card-header bg-primary d-flex justify-content-center cardHeader">
                        <span id="assignowner1">Add New Loan Sub Task</span>
                        </div>
                        <div className="card-body">
                          <div className="row">
                          <div className="col-md-3 mt-4">
                      <span className="d-flex justify-content-end  ">Loan Stage</span>
                          </div>
                          <div className="col-md-8 mt-3">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='loanStageVO.loanStageID'
                          onChange={handleChange}
                          value={values.loanStageVO?.loanStageID}
                          onBlur={handleBlur}
                        >
                          <option selected>Please Select</option>
                          {
                            stages?.length > 0 ? <Fragment>
                              {
                                stages.map((Stage,index)=>{
                                  return(
                                    <option value={Stage?.loanStageID}key={index}>{Stage.loanStage}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                          </select>
                          </div>
                          <div className="col-md-3 mt-4">
                      <span className="d-flex justify-content-end  ">Loan Task</span>
                          </div>
                          <div className="col-md-8 mt-3">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='loanTaskVO.loanTaskId'
                          onChange={handleChange}
                          value={values.loanTaskVO?.loanTaskId}
                          onBlur={handleBlur}
                        >
                          <option selected>Please Select</option>
                          {
                            loanTasks?.length > 0 ? <Fragment>
                              {
                                loanTasks.map((Stage,index)=>{
                                  return(
                                    <option value={Stage.loanTaskId}key={index}>{Stage.loanTask}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                          </select>
                          </div>
                          <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Sub Task Name</span>
                      </div>
                      <div className="col-md-8 mt-2">
                        <input
                          type="text"
                        //   name="loanTask"
                        //   value={search}
                        //  onChange={(e)=>setSearch(e.target.value)}
                          className="form-control sm"
                          placeholder="Enter New Sub Task Name"
                          name='subTaskName'
                          onChange={handleChange}
                          value={values.subTaskName}
                          onBlur={handleBlur}
                        />
                          {/* {
                           search ? <Fragment>
                             {
                               loanTasks?.map((stage,index)=>{
                                 return (
                                 <ul key={index}>{stage.loanTask}</ul>
                                     )
 
                               })
                             }
                           </Fragment>:null
                         }  */}
                      </div>
                      {/* <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">User Role</span>
                          </div>
                          <div className="col-md-8 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='employmentType.empTypeID'
                          // onChange={handleChange}
                          // value={values.employmentType?.empTypeID}
                          // onBlur={handleBlur}
                        >
                          <option selected>Please Select</option>
                          {
                            roles?.length > 0 ? <Fragment>
                              {
                                roles.map((role,index)=>{
                                  return(
                                    <option value={role?.roleId}key={index}>{role.role}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                          </select>
                          </div> */}
                     
                          </div>
                        </div>
                            <div className="row mt-3 mb-3">
                        <div className="col-md-12">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  className="cancelButton" onClick={''}>Cancel</Button>
                        <Button color="primary" type="submit" className="submitButton">Add Sub Task</Button>
                        </div>
                        </div>
                            </div>
                       </form>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
}
    </Fragment>
  )
}



import React ,{Fragment}from 'react';
import { useFormik } from "formik";
import { useState } from 'react';
import { useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';
const initialValues= {     
  userId:'',
        userName:'',
        email:'',
        gender:'',
        dob:'',
        city:{
          cityID:''
        },
        branch:{
          branchID:''
        },
        primaryNumber:'',
        secondaryNumber:'',
        role:{
          roleId:''
        },
        status:'',  
}
export default function AddNewUser() {
const navigate = useNavigate();
  const [isSubmitted, setSubmitted]=useState(false);
  const {values,errors,handleBlur,handleChange,handleSubmit,touched}=useFormik({
    initialValues:initialValues,
    onSubmit:(values)=>{
      axios.post('https://pradhanportal.com/loanApplication/master/addUser',values).then((response)=>{
        console.log(response);
        setSubmitted(true);
      }).catch((err)=>{
        console.error(err);
      });
    },
   });

 
   const NavigateToUserMgmt = () =>{
    navigate('/userMgmtPool');
   }

   const[city,setCity]=useState([]);
  useEffect(()=>{
    const d1 =`https://pradhanportal.com/loanApplication/master/getAllCities`;
    axios.get(d1).then((response)=>{
      console.log("response :",response.data)
  
      setCity(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);

  const cityId = values?.city?.cityID;
console.log("cityId",cityId);

const[branch,setBranch]=useState([]);
useEffect(()=>{
  const d1 =`https://pradhanportal.com/loanApplication/master/getAllBranchesByCityId?cityID=${cityId}`;
  axios.get(d1).then((response)=>{
    console.log("response :",response.data)
    setBranch(response.data);
  }).catch((err)=>{
     console.log(err);
  });
},[cityId]);


const[role,setRole]=useState([]);
useEffect(()=>{
  const d1 =`https://pradhanportal.com/loanApplication/master/getAllRole`;
  axios.get(d1).then((response)=>{
    console.log("response :",response.data)
    setRole(response.data);
  }).catch((err)=>{
     console.log(err);
  });
},[]);
  return (
    <Fragment>
      {/* <pre>{JSON.stringify(values)}</pre> */}
      {
       isSubmitted ? navigate('/userMgmtPool') &
       toast.success('User Added successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <form onSubmit={handleSubmit} >
                            <div className="row mt-3">
                                <div className="col-md-6">
                                {/* <span id="heading1">New User</span> */}
                                </div>
                            </div>
                            <div className="row mt-4">
                            <div className="col-md-2 mt-1">
                        <span className="d-flex justify-content-end">User Name</span>
                      </div>
                      <div className="col-md-5">
                        <input
                          type="text"
                          name="userName"
                          onChange={handleChange}
                          value={values.userName}
                          onBlur={handleBlur}
                          className="form-control sm"
                          placeholder="Enter Customer Name"
                        />
                      </div>
                      <div className="col-md-5"></div>
                      <div className="col-md-2 mt-3">
                        <span className="d-flex justify-content-end">Gender</span>
                        </div>
                        <div className="col-md-5 mt-3">
                        <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio7"
                              onChange={handleChange}
                              value="Male"
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio7"
                            >
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio8"
                              onChange={handleChange}
                              value="Female"
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio8"
                            >
                              Female
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio9"
                              onChange={handleChange}
                              value="Other"
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio9"
                            >
                              Other
                            </label>
                          </div>
                        </div>
                        <div className="col-md-5"></div>
                        <div className="col-md-2 mt-3">
                        <span className="d-flex justify-content-end"> DOB</span>
                      </div>
                      <div className="col-md-5 mt-2">
                        <input
                          type="date"
                          name="dob"
                          className="form-control sm"
                          placeholder="dd-mm-yy"
                          onChange={handleChange}
                          value={values.dob}
                          onBlur={handleBlur}
                        />
                      </div><div className='col-md-5'></div>
                      <div className="col-md-2 mt-3">
                        <span className="d-flex justify-content-end">Email ID</span>
                      </div>
                      <div className="col-md-5 mt-2">
                        <input
                          type="email"
                          name="email"
                          className="form-control sm"
                          placeholder="Enter Email ID"
                          onChange={handleChange}
                          value={values.email}
                          onBlur={handleBlur}
                        />
                      </div><div className='col-md-5'></div>
                      <div className="col-md-2 mt-3">
                        <span className="d-flex justify-content-end">Primary Number</span>
                      </div>
                      <div className="col-md-5 mt-2">
                        <input
                          type="number"
                          name="primaryNumber"
                          className="form-control sm"
                          placeholder="Enter Primary Mobile Number"
                          onChange={handleChange}
                          value={values.primaryNumber}
                          onBlur={handleBlur}
                        />
                      </div><div className='col-md-5'></div>
                      <div className="col-md-2 mt-3">
                        <span className="d-flex justify-content-end">Secondary Number</span>
                      </div>
                      <div className="col-md-5 mt-2">
                        <input
                          type="number"
                          name="secondaryNumber"
                          className="form-control sm"
                          placeholder="Enter Secondary Mobile Number"
                          onChange={handleChange}
                          value={values.secondaryNumber}
                          onBlur={handleBlur}
                        />
                      </div><div className='col-md-5'></div>
                      <div className="col-md-2 mt-3">
                      <span className="d-flex justify-content-end  ">City</span>
                          </div>
                          <div className="col-md-5 mt-2">
                          <select
                          name='city.cityID'
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          onChange={handleChange}
                          value={values.city?.cityID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                     city?.length > 0 ? <Fragment>
                       {
                         city.map((cities,index)=>{
                           return(
                             <option value={cities?.cityID}key={index}>{cities.cityName}</option>
                             
                           );
                         })
                       }
                     </Fragment>:null
                   }
                        </select>
                          </div><div className='col-md-5'></div>
                          <div className="col-md-2 mt-3">
                      <span className="d-flex justify-content-end  ">Branch</span>
                          </div>
                          <div className="col-md-5 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='branch.branchID'
                          onChange={handleChange}
                          value={values.branch?.branchID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                     branch?.length > 0 ? <Fragment>
                       {
                         branch.map((branches,index)=>{
                           return(
                             <option value={branches?.branchID}key={index}>{branches.branch}</option>
                             
                           );
                         })
                       }
                     </Fragment>:null
                   }
                        </select>
                          </div><div className='col-md-5'></div>
                          <div className="col-md-2 mt-3">
                      <span className="d-flex justify-content-end  ">User Role</span>
                          </div>
                          <div className="col-md-5 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='role.roleId'
                          onChange={handleChange}
                          value={values.role?.roleId}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                     role?.length > 0 ? <Fragment>
                       {
                         role.map((roles,index)=>{
                           return(
                             <option value={roles?.roleId}key={index}>{roles.role}</option>
                             
                           );
                         })
                       }
                     </Fragment>:null
                   }
                        </select>
                          </div><div className='col-md-5'></div>
                            </div>
                            <div className="row">
                              <div className="col-md-7">
                              <div class="d-grid gap-2 d-md-flex justify-content-md-center my-4">
                          <button
                            class="btn btn-secondary me-md-2"
                            id="Cancelbtn1"
                            type="button"
                            onClick={NavigateToUserMgmt}
                          >
                            Cancel
                          </button>
                          <button class="btn btn-primary" type="submit" id="Cancelbtn2">
                            Add New User
                          </button>
                        </div>
                              </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
}
    </Fragment>
  )
}

import React,{Fragment, useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import {Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap" ;
import { useNavigate } from 'react-router-dom';
export default function ViewUser() {
    const {userId}=useParams();
    const navigate = useNavigate();
    const NavigateToMgmtPool =()=>{
      navigate('/userMgmtPool');
    }
    const [userData,setUserData]=useState({});
    useEffect (()=>{
        const dataUrl =`https://pradhanportal.com/loanApplication/master/getUserById?userId=${userId}`;
            axios.get(dataUrl).then((response)=>{
            
              console.log("response :",response.data)
              setUserData(response.data);
            }).catch((err)=>{
               console.log(err);
            });
        
      },[userId]);

      const dob= userData.dob
      const DOB=moment(dob).format("DD MMM YYYY");

      const NavigateToEditUser=()=>{
        navigate(`/editUser/${userId}`);
      }
  return (
    <Fragment>
        <div className="container">
            <div className="row">
                <div className="col-md-10">
                <div className="card" id='card1'>
                  <div className="card-header"id='heading2'>
                   
                  </div>
                  <div className="card-body">
                    <div className="row">
                  <div className="col-md-3">
                   <span className="d-flex justify-content-end">User Name</span>
                </div>
                <div className="col-md-9">
                <span className=' '> {userData.userName} </span>
                </div>
                <div className="col-md-3 mt-3">
                   <span className="d-flex justify-content-end">Gender</span>
                </div>
                <div className="col-md-7 mt-3">
                {userData.gender}
                </div>
                <div className="col-md-3 mt-3">
                   <span className="d-flex justify-content-end">DOB</span>
                </div>
                <div className="col-md-7 mt-3">
                    {DOB}
                </div>
                <div className="col-md-3 mt-3">
                   <span className="d-flex justify-content-end">Email ID</span>
                </div>
                <div className="col-md-7 mt-3">
                {userData.email}
                </div>
                {/* <div className="col-md-3 mt-2">
                   <span className="d-flex justify-content-end">Loan Type</span>
                </div>
                <div className="col-md-7 mt-2">
                {viewLead.loanType}
                </div> */}
                <div className="col-md-3 mt-3">
                   <span className="d-flex justify-content-end">Primary Mobile Number</span>
                </div>
                <div className="col-md-7 mt-3">
               <span className=''>{userData.primaryNumber}</span>
                </div>
                <div className="col-md-3 mt-3">
                   <span className="d-flex justify-content-end">City</span>
                </div>
                <div className="col-md-7 mt-3">
                {userData.city?.cityName}
                </div>
                <div className="col-md-3 mt-3">
                   <span className="d-flex justify-content-end">Branch</span>
                </div>
                <div className="col-md-7 mt-3">
                {userData.branch?.branch}
                </div>
                <div className="col-md-3 mt-3">
                   <span className="d-flex justify-content-end">User Role</span>
                </div>
                <div className="col-md-7 mt-3">
                <span className=''>
                {userData.role?.role}
                </span>
                </div>

                <div className="row mt-5">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                    <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  id="btn-cancel" onClick={NavigateToMgmtPool}>Cancel</Button>
                        <Button color="primary" type="submit" id="btn-cancel"onClick={NavigateToEditUser}>Edit</Button>
                        </div>
                    </div>
                </div>
                  </div>
                  </div>

                </div>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

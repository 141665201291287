import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Tooltip from "rc-tooltip";
import { Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap"
import {  toast } from 'react-toastify';
export default function EditType() {
    const { typeId } = useParams();
    const [isSubmitted, setSubmitted] = useState(false);
    const [typeData, setTypeData] = useState({
        leadTypeVO:{
            leadTypeID:'',
          },
          type:"",

    });
    const navigate = useNavigate();
    const navigateToViewSubType = () => {
        navigate(`/viewType/${typeId}`);
      };
    useEffect(() => {
        const d6 = `https://pradhanportal.com/loanApplication/master/getTypeById?typeID=${typeId}`;
        axios
          .get(d6)
          .then((response) => {
            console.log("response :", response.data);
            setTypeData(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);
      const [leadTypes, setLeadTypes] = useState([]);
      useEffect(() => {
        const d5 = `https://pradhanportal.com/loanApplication/master/getAllLeadType`;
        axios
          .get(d5)
          .then((response) => {
            console.log("response :", response.data);
    
            setLeadTypes(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);


    //   const ltID = typeData.leadTypeVO?.leadTypeID;
    // console.log("leadtype", ltID);
    // const [type, setType] = useState({});
    // useEffect(() => {
    //   const d6 = `https://pradhanportal.com/loanApplication/master/getTypesByLeadID?leadTypeID=${ltID}`;
    //   axios
    //     .get(d6)
    //     .then((response) => {
    //       console.log("response :", response.data);
  
    //       setType(response.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }, [ltID]);
    
    const handleChangeForLeadType=(e)=>{
        setTypeData({
            ...typeData,
            leadTypeVO: {
                leadTypeID:e.target.value
              },
        })
      }
      const handleChangeForType =(e)=>{
        setTypeData({
            ...typeData,
            typeVO: {
                typeId:e.target.value
              },
        })
      }
      const handleChange=(e)=>{
        setTypeData({
            ...typeData,
            [e.target.name]:e.target.value
        })
      }
      const handleSubmit=(e)=>{
        e.preventDefault();
        axios.post('https://pradhanportal.com/loanApplication/master/editType',typeData).then((response)=>{
            console.log(response);
            setSubmitted(true);
          }).catch((err)=>{
            console.error(err);
          });
      }
      const authUser = JSON.parse(localStorage.getItem("user"));

      var renderTooltip = <span>Delete Lead Type</span>;
    
      const [modal1, setModal1] = React.useState(false);
      const toggle1 = () => setModal1(!modal1);
    
      const DeleteLead = () => {
        const dataUrl =`https://pradhanportal.com/loanApplication/master/deleteType?typeID=${typeId}`;
            axios.post(dataUrl).then((response)=>{
            
              console.log("response :",response.data);
              
              setTypeData(response.data);
            }).catch((err)=>{
               console.log(err);
            });
            navigate(`typemgmt`);
      toast.success('Lead Type Deleted successfully !', {
        position: toast.POSITION.TOP_RIGHT
    });
    };
  return (
    <Fragment>
        {
            isSubmitted ? navigate(`/viewType/${typeId}`) &
            toast.success('Lead Type Edited successfully!', {
             position: toast.POSITION.TOP_RIGHT
             })
            :
            <div className="container">
            <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                       <form onSubmit={handleSubmit}>
                       <div className="card">
                            <div className="card-header bg-primary d-flex justify-content-center  cardHeader">
                            <span id="assignowner1">Edit Loan Type</span>
                            </div>
                            {
            authUser.role?.role === "Local Admin" ? <Fragment>
            <Tooltip  placement="right" overlay={renderTooltip} >
                <h3 id="trashbtn">
                <i class="bi bi-trash  btn-lg" 
                
                onClick={toggle1}
                ></i>
            </h3>
            </Tooltip>
            </Fragment>:null
           }
            <Modal fade={true} isOpen={modal1} toggle={toggle1} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#f7b84b,secondary:#f06548"
            style={{ width: "100px", height: "100px" }}
          ></lord-icon>
          <span className='warningmodal'>Warning !</span>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-4 mb-0">
              Are you sure you want to remove this record ?
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={toggle1}
          >
            Close
          </button>
          <button
            type="button"
            className="btn w-sm btn-danger "
            id="delete-record"
            onClick={()=>{setTypeData(); DeleteLead()}}
          >
            Yes, Delete It!
          </button>
        </div>
      </ModalBody>
    </Modal>
                            <div className="card-body">
                              <div className="row mt-2">
                               
                          <div className="col-md-4 mt-3">
                                <span
                                  className="d-flex justify-content-end  "
                                >
                                  Lead Type
                                </span>
                              </div>
                              <div className="col-md-6 mt-2">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-md example"
                                  name="leadTypeVO.leadTypeID"
                                  onChange={handleChangeForLeadType}
                                  value={typeData.leadTypeVO?.leadTypeID ==="" ? typeData.leadTypeVO.leadTypeID = leadTypes.leadTypeID : typeData.leadTypeVO?.leadTypeID}
                                >
                                  <option value="">Please Select</option>
                                  {leadTypes?.length > 0 ? (
                                    <Fragment>
                                      {leadTypes.map((leadType, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={leadType?.leadTypeID}
                                          >
                                            {leadType?.leadType}
                                          </option>
                                        );
                                      })}
                                    </Fragment>
                                  ) : null}
                                </select>
                              </div>
                              
                          <div className="col-md-4 mt-3">
                            <span className="d-flex justify-content-end"> Type </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                             type="text"
                             name="type"
                              value={typeData.type}
                             onChange={handleChange}
                              className="form-control sm"
                              placeholder="Enter Loan Type Name"
                            /> 
                          </div>
                          <div className="col-md-2 mt-2"></div>
                          
                              </div>
                            </div>
                                <div className="row mt-3 mb-3">
                            <div className="col-md-12">
                            <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                            <Button color="secondary"  className="previousButton" onClick={navigateToViewSubType}>Cancel</Button>
                            <Button color="primary" type="submit" className="nextButton">Update</Button>
                            </div>
                            </div>
                                </div>
                        </div>
                       </form>
                    </div>
                    <div className="col-md-2"></div>
                </div>
        </div>
        }
    </Fragment>
  )
}

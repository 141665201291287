import React, { Fragment } from "react";
import csc from "country-state-city";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { event } from "jquery";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Checkbox from '@mui/material/Checkbox';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
export default function AddressDetails({
  handleNext,
  addLeadDetails,
  handleBack,
  leadData,
  setLeadData,
  selectedVal,
  setSelectedVal,
}) {
  const [isSubmitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const submitFormData = (e) => {
    e.preventDefault();
    console.log("LeadData in CustomerDetails:::", leadData);
    //  handleNext();
    // axios
    //   .post("https://pradhanportal.com/loanApplication/customer/addLeadWithCustomer", leadData)
    //   .then((response) => {
    //     console.log(response);
    //     console.log("Submit Data", leadData);
    //     setSubmitted(true);
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
    if (leadData?.customerId !== undefined) {
      axios
        .post("https://pradhanportal.com/loanApplication/master/addLead", leadData)
        .then((response) => {
          console.log(response);
          setSubmitted(true);
          console.log("Submit Data", leadData);
        })
        .catch((err) => {
          console.error(err);
        });
      // setSubmitted(true);
      // toast.success("Lead created successfully !", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } else {
      axios
        .post("https://pradhanportal.com/loanApplication/customer/addLeadWithCustomer", leadData)
        .then((response) => {
          console.log(response);
          setSubmitted(true);
          console.log("Submit Data", leadData);
        })
        .catch((err) => {
          console.error(err);
        });
      // setSubmitted(true);
      // toast.success("Lead created successfully !", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
   
  };
  const countries = csc.getAllCountries();
  console.log(csc.getAllCountries());

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));
  const ddlChange = (e) => {
    setLeadData({
      ...leadData,
      reqCountry: e.name,
      countryId: e.id,
      reqCountryId: e.id,
    });

    //setFormData({ ...formData, country: e.label, countryId: e.value,cval:e.value })
    setSelectedVal({ label: e.label, id: e.value });
    console.log("sel val = ", selectedVal);
  };
  const handleChangeForState = (e) => {
    setLeadData({
      ...leadData,
      reqState: e.name,
      stateId: e.id,
      reqStateId: e.id,
    });

    //setFormData({ ...formData, country: e.label, countryId: e.value,cval:e.value })
    setSelectedVal({ label: e.label, id: e.value });
    console.log("sel val = ", selectedVal);
  };

  const handleChangeInput = (e) => {
    setLeadData({
      ...leadData,
      [e.target.name]: e.target.value,
    });
  };

  const updatedStates = (countryId) =>
    csc
      .getStatesOfCountry(countryId)
      .map((state) => ({ label: state.name, value: state.name, ...state }));

  const updatedCities = (stateId) =>
    csc
      .getCitiesOfState(stateId)
      .map((city) => ({ label: city.name, value: city.name, ...city }));
  const handleChange = (e) => {
    const { name, value, type } = e.currentTarget;
    setLeadData((preState) => ({
      ...preState,
      [name]: value,
    }));
  };
  return (
    <Fragment>
      {isSubmitted ? (
        navigate("/LeadCreation-Lead") &
          toast.success("Lead created successfully !", {
            position: toast.POSITION.TOP_RIGHT,
          })
        
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end ">
                Address Line 1
              </span>
            </div>
            <div className="col-md-5 mt-2">
              <input
                type="text"
                name="addressLine1"
                className="form-control"
                placeholder="Enter Address"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleChange}
                value={leadData.addressLine1}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end ">
                Address Line 2
              </span>
            </div>
            <div className="col-md-5 mt-2">
              <input
                type="text"
                name="addressLine2"
                className="form-control"
                placeholder="Enter Address"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleChange}
                value={leadData.addressLine2}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end ">Landmark</span>
            </div>
            <div className="col-md-5 mt-2">
              <input
                type="text"
                name="landMark"
                className="form-control"
                placeholder="Enter LandMark"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleChange}
                value={leadData.landMark}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end  ">Country</span>
            </div>
            <div className="col-md-5 mt-2">
              <Select
                id="country"
                name="country"
                label="country"
                className="countrydrp"
                rules={{ required: true }}
                options={updatedCountries}
                onChange={(event) =>
                  setLeadData({
                    ...leadData,
                    country: event.name,
                    countryId: event.id,
                    countryCode: event.id,
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end  ">State</span>
            </div>
            <div className="col-md-5 mt-2">
              <Select
                name="state"
                id="state"
                className="countrydrp"
                options={updatedStates(
                  leadData.countryId ? leadData.countryId : null
                )}
                onChange={(event) =>
                  setLeadData({
                    ...leadData,
                    state: event.name,
                    stateId: event.id,
                    stateCode: event.id,
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end  ">City</span>
            </div>
            <div className="col-md-5 mt-2">
              <Select
                //required
                id="city"
                className="countrydrp"
                name="city"
                options={updatedCities(
                  leadData.stateId ? leadData.stateId : null
                )}
                onChange={(event) =>
                  setLeadData({
                    ...leadData,
                    city: event.name,
                    cityId: event.id,
                    cityCode: event.id,
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end ">PIN</span>
            </div>
            <div className="col-md-5 mt-2">
              <input
                type="number"
                name="pinCode"
                className="form-control"
                placeholder="Enter PIN Code"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleChange}
                value={leadData.pinCode}
              />
            </div>
          </div>
         <div className="row mt-3">
          <div className="col-md-7">
         {
          leadData.country === "" || leadData.state === "" || leadData.city ==="" ? <Fragment>
 <div class="form-check form-check-inline d-flex justify-content-end">
  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
  <label class="form-check-label mx-3" for="inlineCheckbox1">Skip this step. I will do it later</label>
</div>
          </Fragment>:null
         }
          </div>
         </div>
          <div className="row mt-4">
            <div className="col-md-12 d-flex justify-content-center">
              <button
                className="btn btn-secondary  previousButton"
                onClick={handleBack}
              >
                Previous
              </button>
              <button
                onClick={submitFormData}
                className="btn btn-primary nextButton"
              >
                Create Lead
              </button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

import * as yup from "yup";

export const customerForm = yup.object({
    
    customerName: yup.string().min(3).max(35).required("Please Enter Customer Name"),
    gender : yup.string().required("Please Select Gender"),
    dob : yup.string().required("please Select DOB"),
    customerType : yup.string().required("Please Select CustomerType"),
    primaryNumber : yup.string().matches(new RegExp('[0-9]{7}')).min(10).max(10).required("please Enter Primary Mobile Number"),
    secondaryNumber : yup.string().matches(new RegExp('[0-9]{7}')).min(10).max(10),
    pan : yup.string(),
    aadhar : yup.number(),
    emailID: yup.string().email().required("Please Enter Your Email ID"),
});

export const leadForm = yup.object({
    
    customerName: yup.string().min(3).max(35).required("Please Enter Customer Name"),
    gender : yup.string().required("please Select Gender"),
   leadSourceVO : yup.object().shape({ leadSourceID: yup.number().required("Please Select Lead Source") }),
   customerTypeVO : yup.object().shape({ customerTypeID: yup.number().required("Please Select Customer Type") }),
   leadTypeVO : yup.object().shape({ leadTypeID: yup.number().required("Please Select Lead Type") }),
   initiatorVO : yup.object().shape({ initiatorID: yup.number().required("Please Select Initiator") }),
   gdStatusVO : yup.object().shape({ gdStatusID: yup.number().required("Please Select GD Status ") }),
    primaryNumber : yup.string().matches(new RegExp('[0-9]{7}')).required("Please Enter Primary Mobile Number"),
    contactPerson1stNumber : yup.string().matches(new RegExp('[0-9]{7}')).required("Please Enter Primary Mobile Number"),
    contactPerson: yup.string().min(3).max(35).required("Please Enter Contact Person Name"),
    typesVO : yup.object().shape({ typeId: yup.number().required("Please Select Type") }),
    amount:yup.number().required("Please Enter Amount")
});
import React from 'react';
import { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Link from 'feather-icons-react/build/IconComponents/Link';
import "../../assets/scss/pages/lead.css";
//import Components

import UpdateLeads from './UpdateLeads';
import { Breadcrumb,BreadcrumbItem} from 'reactstrap';






const UpdateLead = () => {
    return (
        <React.Fragment>
            <div className="page-content">
               <Container fluid>
               <Row>
                <Col xs={12}>
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Edit Lead</h4>
                   <Breadcrumb listTag="div"  className="page-title-right">
                  <BreadcrumbItem
                         href="#"
                         tag="a"
                         
                  >
                   Leads
                </BreadcrumbItem>
                 <BreadcrumbItem
                         href="#"
                         tag="a"
                         
                 >
                   Free Pool
                 </BreadcrumbItem>
                 <BreadcrumbItem
                        href="#"
                         tag="a"
                  >
                   View Lead
                       </BreadcrumbItem>
                    <BreadcrumbItem
                         active
                         tag="span"
                    >
                      Edit Lead
                 </BreadcrumbItem>
           </Breadcrumb>
       </div>
                </Col>
               </Row>
                    <Row>
                        <Col lg={12}>
                           <UpdateLeads/>
                            {/* <UpgradeAccountNotise />
                            <Widget /> */}
                        </Col>
                        {/* <LiveUsers /> */}
                    </Row>
                    </Container>
                    </div> 
           
        </React.Fragment>
    );
};

export default UpdateLead;
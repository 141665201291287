import React,{Fragment, useMemo}from 'react'
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Input,} from "reactstrap";
import "rc-tooltip/assets/bootstrap.css";
// import "../../assets/scss/pages/lead.css";
import axios from "axios";
import Tooltip from "rc-tooltip";
import TableContainer from '../../../../Components/Common/TableContainer';
import { time } from 'echarts';
import { useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';
import { Cell } from 'gridjs';
import moment from 'moment';
import {
  Button, Modal, ModalFooter,
  ModalHeader, ModalBody
} from "reactstrap" ;
import { useFormik } from "formik";

const initialValues= {     
    partnershipName:"",
}

export default function Productmgmt() {
  var renderTooltip = <span>Add New Product</span>;

  const navigateToSettings = () => {
    navigate(`/applicationSetting`);
  };
  const navigateToAddStage=()=>{
    navigate(`/addProduct`)
  }

  const [isSubmitted, setSubmitted]=useState(false);
  const {values,errors,handleBlur,handleChange,handleSubmit,touched}=useFormik({
    initialValues:initialValues,
    onSubmit:(values)=>{
      axios.post('https://pradhanportal.com/loanApplication/master/addPartnership',values).then((response)=>{
        console.log(response);
        setSubmitted(true);
      }).catch((err)=>{
        console.error(err);
      });
    setSubmitted(true);
    toast.success('Loan Stage created successfully !', {
      position: toast.POSITION.TOP_RIGHT
  });
    },
   });

    const navigate = useNavigate();
  
    const[filterLeads,setFilterLeads]=useState([]);
    const [search,setSearch]=useState("");
    const [data, setData] = useState([]);
    const toggle = () => setModal(!modal);
    const [modal, setModal] = React.useState(false); 
    const [loanStages,setLoanStages]=useState([]);
    const [selected, setSelected] = useState([]);
    const [autofetch, setAutofetch] = useState([]);
const [formData,setFormData]= useState({
  loanStage:"",raisedFor:""
})

    useEffect(() => {
      PopulatUser();
    }, []);
  
    const PopulatUser = async () => {
      const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllProject`);
      setLoanStages(result.data);
      console.log("autofetchuser",result.data)
    };
    

    const loadLead = async () => {
      const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllProject`);
      setData(result.data);
      setLoanStages(result.data);
      setFilterLeads(result.data);
    };

   console.log("loanStages:",loanStages );
    useEffect(() => {
      loadLead();
    }, []);
    useEffect(() => {
      const result=data.filter(leads=>{
        return leads.loanStage?.toLowerCase().match(search?.toLowerCase())
      });
      setLoanStages(result);
      // setSearch(result);
    }, [search]);

  const columns = useMemo(
    () => [
      
      {
        Header: "Project ID",
        accessor: "projectId",
      },
      {
        Header: "Project Name",
        accessor: "projectName",
       
      },
      
    ],
    []
  );
  return (
    <fragment>
      <Container fluid>
            <Row>
                <Col lg={12}>
                <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={3}>
                    <div className='d-flex align-items-center '>
                    {/* <span className='float-start'><img src={leftChevron} width = "30" height = "25" alt='' className='mx-4'  onClick={navigateToSettings}/></span> */}
              <div className='d-inline-block'>
                <h3> Partnership</h3>
              </div>
              <div className='d-inline-block'>
              <Tooltip 
                   placement="right"
                   overlay={renderTooltip}
              >
              <h2><i onClick={navigateToAddStage} className="bi bi-plus-square-fill btn btn-lg"></i> </h2>  
            </Tooltip>
              </div>
            </div>
              </Col><Col sm={6}></Col>
              <Col sm={3}>
                      <div className="search-box">
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder="Search for..."
                          // value={search}
                          // onChange={(e)=>setSearch(e.target.value)}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                <div className="main">
                {
                    <TableContainer
                    columns={columns}
                    data={filterLeads}
                    isGlobalFilter={false}
                    isAddUserList={false}
                    customPageSize={5}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-0"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-secondary"
                    isLeadsFilter={false}
                    // SearchPlaceholder='Search for'
                    highlightOnHover
                   
                  />
                  // ):null
                }
                  </div>
                  </CardBody>
                </Card>
                </Col>
            </Row>
        </Container>
    </fragment>
  )
}



import React,{Fragment,useEffect,useState} from 'react';
import { Button} from "reactstrap" ;
import axios from "axios";
import { useParams,useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify';
export default function EditCaseDetails() {
  const {LoanCaseId}=useParams();
  console.log("loanCaseId",LoanCaseId);
  const navigate = useNavigate();
  const [isSubmitted, setSubmitted]=useState(false);

const [CaseDetails,setCaseDetails]=useState({
  caseId:'',
  loanCaseId:LoanCaseId,
leadID:'',
leadTypeVO: {
  leadTypeID: '',
},
  typesVO:{
    typeId:'',
  },
  subType:{
    subTypeId:'',
  },
  gdLink:'',
  projectName:'',
  partnerVO:{
    partnerId:'',
  },
  cpName:'',
  caseOwner:{
    ownerId:'',
  },
  initiatorVO:{
    initiatorID:'',
  },
  partnershipVO:{
    partnershipId:'',
  },
  bankVO:{
    bankId:'',
  },
  bankBranchVO:{
    bankBranchId:'',
  },
  amount:'',
  sanctionAmount:"",
  sanctionDate:"",
  disbursementAmount:"",
  disbursementDate:"",
});



useEffect (()=>{
  const dataUrl =`https://pradhanportal.com/loanApplication/master/getLoanCaseById?loanCaseId=${LoanCaseId}`;
      axios.get(dataUrl).then((response)=>{
      
        console.log("response :",response.data)
        setCaseDetails(response.data);
      }).catch((err)=>{
         console.log(err);
      });
},[LoanCaseId]);
console.log("CaseDetails",CaseDetails);

const[branch,setBranch]=useState();
  useEffect(()=>{
    const d1 =`https://pradhanportal.com/loanApplication/master/getAllBranch`;
    axios.get(d1).then((response)=>{
      console.log("response :",response.data)
      setBranch(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);

  const[caseTypes,setCaseTypes]=useState([]);
  useEffect(()=>{
    const d5 =`https://pradhanportal.com/loanApplication/master/getAllLeadType`;
    axios.get(d5).then((response)=>{
      console.log("response :",response.data)
  
      setCaseTypes(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);

  const ltID=CaseDetails.leadTypeVO?.leadTypeID;
  console.log("leadtype",ltID);
  const [caseTyp, setcaseType]=useState({});
  useEffect(()=>{
    const d6 =`https://pradhanportal.com/loanApplication/master/getTypesByLeadID?leadTypeID=${ltID}`;
    axios.get(d6).then((response)=>{
      console.log("response :",response.data);
  
      setcaseType(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[ltID]);

  const[leadInitiator,setLeadInitiator]=useState([]);
  useEffect(()=>{
    const d2 =`https://pradhanportal.com/loanApplication/master/getAllInitiator`;
    axios.get(d2,).then((response)=>{
      console.log("response :",response.data)
  
      setLeadInitiator(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);

  const[partner,setPartner]=useState([]);
  useEffect(()=>{
    const d5 =`https://pradhanportal.com/loanApplication/master/getAllPartner`;
    axios.get(d5).then((response)=>{
      console.log("response :",response.data)
  
      setPartner(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);

  const partnerId=CaseDetails.partnerVO?.partnerId;
  const[partnership,setPartnership]=useState([]);
  useEffect(()=>{
    const d5 =`https://pradhanportal.com/loanApplication/master/getAllCPBypartnerId?partnerId=${partnerId}`;
    axios.get(d5).then((response)=>{
      console.log("response :",response.data)
  
      setPartnership(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[partnerId]);


  const [banks,setBanks] = useState({});
   useEffect(() => {
     const d6 = 'https://pradhanportal.com/loanApplication/master/getAllBank';
     axios
       .get(d6)
       .then((response) => {
         console.log("Bank List response :", response.data);
 
         setBanks(response.data);
       })
       .catch((err) => {
         console.log(err);
       });
   }, []);

   const bankId=CaseDetails.bankVO?.bankId;
   const [bankBranch,setBankBranch] = useState({});
   useEffect(() => {
     const d6 = `https://pradhanportal.com/loanApplication/master/getAllBranchByBankId?bankId=${bankId}`;
     axios
       .get(d6)
       .then((response) => {
         console.log("Bank List response :", response.data);
 
         setBankBranch(response.data);
       })
       .catch((err) => {
         console.log(err);
       });
   }, [bankId]);


  const ownerID =CaseDetails?.ownerVO?.ownerID;
  console.log("ownerID",ownerID);
  const[getOwner,setGetOwner]=useState('');
  useEffect(()=>{
    const d6 =`https://pradhanportal.com/loanApplication/master/getOwnerById?ownerID=${ownerID}`;
    axios.get(d6).then((response)=>{
      console.log("response :",response.data)
  
      setGetOwner(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[ownerID]);

  const[leadOwners,setLeadOwners]=useState([]);
  useEffect(()=>{
    const d1 =`https://pradhanportal.com/loanApplication/master/getAllOwner`;
    axios.get(d1).then((response)=>{
      console.log("response :",response.data)
  
      setLeadOwners(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);

const handleChange = (e) => {  
  setCaseDetails({ 
      ...CaseDetails,
    [e.target.name]: e.target.value       
  }); 
};

const handleChangeForResidentType=(e)=>{
  setCaseDetails({ 
    ...CaseDetails,
    leadSourceVO:{
      branchVO:{
      branch: e.target.value } 
      },
}); 
}

const handleChangeForPartner=(e)=>{
  setCaseDetails({ 
    ...CaseDetails,
   partnerVO:{
    partnerId:e.target.value
   }
}); 
}

const handleChangeForPartnership=(e)=>{
  setCaseDetails({ 
    ...CaseDetails,
   partnershipVO:{
    partnershipId:e.target.value
   }
}); 
}

const handleChangeForBank=(e)=>{
  setCaseDetails({ 
    ...CaseDetails,
   bankVO:{
    bankId:e.target.value
   }
}); 
}

const handleChangeForBankBranch=(e)=>{
  setCaseDetails({ 
    ...CaseDetails,
   bankBranchVO:{
    bankBranchId:e.target.value
   }
}); 
}
const handleChangeForCaseType=(e)=>{
  setCaseDetails({ 
    ...CaseDetails,
    leadTypeVO:{
      leadTypeID: e.target.value } 
}); 
}

const handleChangeForType=(e)=>{
  setCaseDetails({ 
    ...CaseDetails,
    typesVO:{
      typeId: e.target.value } 
}); 
}
const handleChangeForInitiator=(e)=>{
  setCaseDetails({ 
    ...CaseDetails,
    initiatorVO:{
      initiatorID: e.target.value } 
}); 
}


const handleChangeForOwner=(e)=>{
  setCaseDetails({ 
    ...CaseDetails,
    ownerVO:{
      ownerID: e.target.value } 
}); 
}

 const navigateTOViewLoanCase =()=>{
  navigate(`/viewLoanCase/${LoanCaseId}`);
 }
 const handleSubmit=(e)=>{
  e.preventDefault();
  axios.put('https://pradhanportal.com/loanApplication/master/editLoanCaseDetails',CaseDetails).then((response)=>{
      console.log(response);
      setSubmitted(true);
    }).catch((err)=>{
      console.error(err);
    });
 }
  return (
    <Fragment>
       {
       isSubmitted ? navigate(`/viewLoanCase/${LoanCaseId}`) &
       toast.success('Case Details Updated successfully!', {
        position: toast.POSITION.TOP_RIGHT
        }) :
        <div className="container">
            <div className="row">
            <div className="col-md-1"></div>
                <div className="col-md-10">
                    <div className="card">
                      <form onSubmit={handleSubmit}>
                        <div className="card-header bg-primary d-flex justify-content-Start loanCaseCardHeader">
                        <span id="assignowner1">{CaseDetails.customerName} - {CaseDetails.id}</span>
                        </div>
                        <div className="card-body">
                            <div className="row">
                           
                          <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Lead Ref</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="leadID"
                          className="form-control sm"
                          placeholder="Enter New Task Name"
                          onChange={handleChange}
                          disabled
                          value={CaseDetails.leadID}
                        />
                        </div>
                        <div className="col-md-4 mt-4">
                      <span className="d-flex justify-content-end  ">Type</span>
                          </div>
                          <div className="col-md-6 mt-3">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='type.typeId'
                          onChange={handleChangeForCaseType}
                          value={CaseDetails.leadTypeVO?.leadTypeID}
                        >
                          <option selected>Please Select</option>
                          {
                           caseTypes?.length > 0 ? <Fragment>
                             {
                               caseTypes.map((Types,index)=>{
                                 return(
                                   <option value={Types.leadTypeID} key={index}>{Types.leadType}</option>
                                   
                                 );
                               })
                             }
                           </Fragment>:null
                           }
                          </select>
                          </div>
                          <div className="col-md-4 mt-4">
                      <span className="d-flex justify-content-end  ">Sub Type</span>
                          </div>
                          <div className="col-md-6 mt-3">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='subType.subTypeId'
                          onChange={handleChangeForType}
                          value={CaseDetails.typesVO?.typeId}
                        >
                          <option selected>Please Select</option>
                          {
                           caseTyp?.length > 0 ? <Fragment>
                             {
                               caseTyp.map((Types,index)=>{
                                 return(
                                   <option value={Types.typeId} key={index}>{Types.type}</option>
                                   
                                 );
                               })
                             }
                           </Fragment>:null
                           }
                          </select>
                          </div>
                          <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">GD Link</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="gdLink"
                          className="form-control sm"
                          placeholder="Enter GD Link"
                          onChange={handleChange}
                          value={CaseDetails.gdLink}
                        />
                        </div>
                       
                          {/* <div className="col-md-3 mt-4">
                      <span className="d-flex justify-content-end  ">Sub Type</span>
                          </div>
                          <div className="col-md-8 mt-3">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='employmentType.empTypeID'
                        >
                          <option selected>Please Select</option>
                          </select>
                          </div> */}
                          <div className="col-md-4 mt-4">
                      <span className="d-flex justify-content-end  ">Partner</span>
                          </div>
                          <div className="col-md-6 mt-3">
                          <select
                          className="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='partnerVO.partnerId'
                          value={CaseDetails.partnerVO?.partnerId}
                          onChange={handleChangeForPartner}
                        >
                            <option selected>Please Select</option>
                          {partner?.length > 0 ? (
                                <Fragment>
                                  {partner.map((partner, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={partner?.partnerId}
                                      >
                                        {partner?.partnerName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                          </select>
                          </div>
                          <div className="col-md-4 mt-4">
                      <span className="d-flex justify-content-end  ">channel Partner</span>
                          </div>
                          <div className="col-md-6 mt-3">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='partnershipVO.partnershipId'
                          value={CaseDetails.partnershipVO?.partnershipId}
                          onChange={handleChangeForPartnership}
                        >
                           <option selected>Please Select</option>
                          {partnership?.length > 0 ? (
                                <Fragment>
                                  {partnership.map((partnership, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={partnership?.partnershipId}
                                      >
                                        {partnership?.partnershipName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                          </select>
                          </div>
                          <div className="col-md-4 mt-4">
                      <span className="d-flex justify-content-end  ">Project  Name</span>
                          </div>
                          <div className="col-md-6 mt-3">
                          <input
                          type="text"
                          name="projectName"
                          className="form-control sm"
                          placeholder="Enter Project Name"
                          onChange={handleChange}
                          value={CaseDetails.projectName}
                        />
                          </div>
                          <div className="col-md-4 mt-4">
                      <span className="d-flex justify-content-end  ">Case Owner</span>
                          </div>
                          <div className="col-md-6 mt-3">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name="ownerVO.ownerID"
                         onChange= {handleChangeForOwner}
                         value={CaseDetails.ownerVO?.ownerID}
                       > 
                         <option value="">Please select Owner</option>
                         {
                           leadOwners?.length > 0 ? <Fragment>
                             {
                               leadOwners.map((owner,index)=>{
                                 return(
                                   <option key={index} value={owner.ownerID}>{owner.ownerName}</option>
                                   
                                 );
                               })
                             }
                           </Fragment>:null
                         }
                          </select>
                          </div>
                          <div className="col-md-4 mt-4">
                      <span className="d-flex justify-content-end  ">Lead Initiator</span>
                          </div>
                          <div className="col-md-6 mt-3">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='initiatorVO?.initiatorID'
                          onChange= {handleChangeForInitiator}
                          value={CaseDetails.initiatorVO?.initiatorID}
                        >
                          <option selected>Please Select</option>
                          {
                           leadInitiator?.length > 0 ? <Fragment>
                             {
                               leadInitiator.map((leadinitiator,index)=>{
                                 return(
                                   <option key={index} value={leadinitiator?.initiatorID}>{leadinitiator?.initiatorName}</option>
                                   
                                 );
                               })
                             }
                           </Fragment>:null
                         }
                          </select>
                          </div>
                          <div className="col-md-4 mt-4">
                      <span className="d-flex justify-content-end">Bank</span>
                          </div>
                          <div className="col-md-6 mt-3">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='bankVO.bankId'
                          onChange={handleChangeForBank}
                          value={CaseDetails.bankVO?.bankId}
                        >
                         <option value="">Please Select</option>
                              {banks?.length > 0 ? (
                                <Fragment>
                                  {banks.map((bank, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={bank?.bankId}
                                      >
                                        {bank?.bankName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                         
                          </select>
                          </div>
                            <div className="col-md-4 mt-4">
                      <span className="d-flex justify-content-end">Branch</span>
                          </div>
                          <div className="col-md-6 mt-3">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='bankBranchVO.bankBranchId'
                          onChange={handleChangeForBankBranch}
                          value={CaseDetails.bankBranchVO?.bankBranchId}
                        >
                          <option value="">Please Select</option>
                              {bankBranch?.length > 0 ? (
                                <Fragment>
                                  {bankBranch.map((bankBranch, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={bankBranch?.bankBranchId}
                                      >
                                        {bankBranch?.bankBranchName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                          </select>
                          </div>
                          {
                            CaseDetails.partnerVO?.partnerId !== 1 ? <Fragment>
<div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Sanction Amount</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="sanctionAmount"
                          className="form-control sm"
                          placeholder="Enter Sanction Amount"
                          onChange={handleChange}
                          value={CaseDetails.sanctionAmount}
                        />
                        </div>
                        <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Sanction Date</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="date"
                          name="sanctionDate"
                          className="form-control sm"
                          placeholder="Enter Sanction Date"
                          onChange={handleChange}
                          value={CaseDetails.sanctionDate}
                        />
                        </div>
                        <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Disbursement Amount</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="disbursementAmount"
                          className="form-control sm"
                          placeholder="Enter Disbursement Amount"
                          onChange={handleChange}
                          value={CaseDetails.disbursementAmount}
                        />
                        </div>
                        <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Disbursement Date</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="date"
                          name="disbursementDate"
                          className="form-control sm"
                          placeholder="Enter Disbursement Date"
                          onChange={handleChange}
                          value={CaseDetails.disbursementDate}
                        />
                        </div>
                            </Fragment>:<Fragment>
                            <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Loan Amount</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="amount"
                          className="form-control sm"
                          placeholder="Enter Amount"
                          onChange={handleChange}
                          value={CaseDetails.amount}
                        />
                        </div>
                            </Fragment>
                          }
                          
                            </div>
                        </div>
                        <div className="row mt-1 mb-3">
                            <div className="col-md-3"></div>
                        <div className="col-md-6 my-1">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  className='previousButton' onClick={navigateTOViewLoanCase}>Cancel</Button>
                        <Button color="primary" type="submit" className='nextButton'>Update</Button>
                        </div>
                        </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        }
    </Fragment>
  )
}

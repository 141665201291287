
import React,{Fragment} from 'react';
import { useEffect,useState } from 'react';
import axios from 'axios';
import { useFormik } from "formik";
import { useParams } from 'react-router-dom';
const initialValues = {
  sequenceNo:'',
  loanCaseID:'',
  stage:{
    loanStageID:'',
  },
  taskName:'',
taskStatus:{
  taskStatusId:'',
},
discription:'',
  startDate:'',
  plannedEndDate:'',
  caseOwner: {
    ownerID: '',
},
}
export default function AddTaskBelow() {
  

  const {LoanCaseId}=useParams();
   const caseId=LoanCaseId.split(",")[1]
   const seqNo=LoanCaseId.split(",")[0]
  console.log("loanCaseId",caseId);
  console.log("sequenceNo",seqNo);
 
  const [isSubmitted, setSubmitted]=useState(false);
  const {values,errors,handleBlur,handleChange,handleSubmit,touched}=useFormik({
    initialValues:initialValues,
    
    onSubmit:(values)=>{
      axios.post('https://pradhanportal.com/loanApplication/master/createLoanCaseTaskBelowSequenceNo',values).then((response)=>{
        console.log(response);
        setSubmitted(true);
      }).catch((err)=>{
        console.error(err);
      });
    },
   });
   console.log(values);
  
   values.loanCaseID=caseId;
   values.sequenceNo=seqNo;

   const [viewCase, SetViewCase]=useState({});
   useEffect (()=>{
   const dataUrl =`https://pradhanportal.com/loanApplication/master/getLoanCaseById?loanCaseId=${caseId}`;
       axios.get(dataUrl).then((response)=>{
         console.log("response :",response.data)
         SetViewCase(response.data);
       }).catch((err)=>{
          console.log(err);
       });
   
 },[caseId]);


  const[stage,setStage]=useState([]);
  useEffect(() => {
      loadLead();
    }, []);
  const loadLead = async () => {
    values.loanCaseID = LoanCaseId;
    console.log("LoanCaseId=========",values.loanCaseID);
      const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllLoanStage`);
      setStage(result.data);
 
    };
    console.log("stage",stage);

    const[owner,setOwner]=useState([]);
  useEffect(() => {
    loadOwner();
    }, []);
  const loadOwner = async () => {
      const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllOwner`);
      setOwner(result.data);
 
    };
    console.log("owner",owner);
    
  return (
    <Fragment>
      {/* <pre>{JSON.stringify(values)}</pre> */}
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                    <form  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}>
                        <div className="row">
                            <div className="col-md-12">
                            <span id="taskheading3"className='mx-3 my-3'>{viewCase.customerName} / <span className=' text-white px-1'id='leadidbg'>{viewCase.typesVO?.type} </span>
                           </span>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 <span>Stage</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                            <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='stage.loanStageID'
                          onChange={handleChange}
                          value={values.stage?.loanStageID}
                          onBlur={handleBlur}
                        >
                          <option selected>Please Select</option>
                          {
                            stage?.length > 0 ? <Fragment>
                              {
                                stage.map((loanStage,index)=>{
                                  return(
                                    <option value={loanStage?.loanStageID}key={index}>{loanStage.loanStage}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 <span>Task Name</span>
                            </div>
                            
                        </div>
                        <div className="row ">
                        <div className="col-md-1"></div>
                        <div className="col-md-9">
                            <input
                          type="text"
                          name="taskName"
                          className="form-control sm"
                          placeholder="Enter Task Name"
                          onChange={handleChange}
                          value={values.taskName}
                          onBlur={handleBlur}
                        />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 <span>Add Description (Optional)</span>
                            </div>
                           
                        </div>
                        <div className="row">
                        <div className="col-md-1"></div>
                            <div className="col-md-9">
                             <div className="form-group">
                              <textarea
                              name="discription"
                              className="form-control"
                              maxLength="300"
                              rows={5}
                              onChange={handleChange}
                              value={values.discription}
                              onBlur={handleBlur}
                             />
                            </div>
                            <div className=" d-md-flex justify-content-md-end"> Remaining Characters: 300 /300 </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 {/* <span>Add Description</span> */}
                            </div>
                            
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 <span>Schedule</span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-1"></div>
                            <div className="col-md-1 mt-2">
                                 <span className='mt-2'>Start Date</span>
                            </div>
                            <div className="col-md-3">
                            <input
                          type="Date"
                          name="startDate"
                          className="form-control sm"
                          placeholder="Enter Task Name"
                          onChange={handleChange}
                          value={values.startDate}
                          onBlur={handleBlur}
                        />
                            </div>
                            <div className="col-md-1 mt-2">
                                 <span className='mt-2'>End Date</span>
                            </div>
                            <div className="col-md-3">
                            <input
                          type="Date"
                          name="plannedEndDate"
                          className="form-control sm"
                          placeholder="Enter Task Name"
                          onChange={handleChange}
                          value={values.plannedEndDate}
                          onBlur={handleBlur}
                        />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 <span>Owner</span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                            <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='caseOwner.ownerID'
                          onChange={handleChange}
                          value={values.caseOwner?.ownerID}
                          onBlur={handleBlur}
                        >
                          <option selected>Please Select</option>
                          {
                            owner?.length > 0 ? <Fragment>
                              {
                                owner.map((ownerList,index)=>{
                                  return(
                                    <option value={ownerList?.ownerID}key={index}>{ownerList.ownerName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                            </div>
                        </div>
                        <div className="row mt-3 mb-3">
                      <div className="col-md-9 mt-2">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                          <button
                          onClick={''}
                            class="btn btn-secondary me-md-2"
                            id="Cancelbtn1"
                            type="button"
                          >
                            Cancel
                          </button>
                          <button class="btn btn-primary" type="submit" id="Cancelbtn2">
                           Add Task
                          </button>
                          <button class="btn btn-primary" type="submit" id="Cancelbtn2">
                           Add More Task
                          </button>
                        </div>
                      </div>
                    </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
  )
}


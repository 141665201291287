
import React,{Fragment} from 'react'
import {useState,useEffect } from 'react'
import {useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import {Button} from "reactstrap";
const initialValues={
  type:'',
  applicantId:'',
  applicantName:'',
  primaryNumber:'',
  secondaryNumber:'',
  email:''
}
export default function AddContactDetails() {
    const[isApplicant,setIsApplicant]=useState(false);
    const[isCoApplicant,setISCoApplicant]=useState(false);
    const {LoanCaseId}=useParams();
    const navigate = useNavigate();
    const [isSubmitted, setSubmitted]=useState(false);
      console.log("loanCaseId",LoanCaseId);

      const [applicant, setApplicant]=useState({});
      useEffect (()=>{
      const dataUrl =`https://pradhanportal.com/loanApplication/customer/getAllApplicantByLoanCaseID?loanCaseID=${LoanCaseId}`;
          axios.get(dataUrl).then((response)=>{
          
            console.log("response :",response.data)
            setApplicant(response.data);
          }).catch((err)=>{
             console.log(err);
          });
      
    },[LoanCaseId]);
    console.log("applicant",applicant);
     
  

    
    const {values,handleBlur,handleChange,handleSubmit,touched,errors}=useFormik({
      initialValues:initialValues,
      onSubmit:(values)=>{
        axios.put('https://pradhanportal.com/loanApplication/customer/AddApplicantContactDetailsByID',values).then((response)=>{
          console.log(response);
          setSubmitted(true);
        }).catch((err)=>{
          console.error(err);
        });
      },
     });
     console.log(values);

     const abc = values.applicantId;
    console.log("ApplicantID",abc);

    const navigateTOViewLoanCase =()=>{
      navigate(`/viewLoanCase/${LoanCaseId}`);
     }
  return (
    <Fragment>
       {
       isSubmitted ? navigate(`/viewLoanCase/${LoanCaseId}`) &
       toast.success('Applicant Added successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                   <div className="card">
                   <form  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}>
                    <div className="row">
                  <div className="col-md-9 mt-3">
                  <span className='customerDetails'>Contact Details</span>
                   <div className="row">
                   <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end" id="mandatory">Type</span>
                        </div>
                        <div className="col-md-6 mt-3">
                        <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="type"
                              id="inlineRadio8"
                              onClick={()=>{setIsApplicant(true);setISCoApplicant(false)}}
                              onChange={handleChange}
                              checked={values.type === "Applicant"}
                              value="Applicant"
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio8"
                            >
                              Applicant
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio9"
                              onClick={()=>{setIsApplicant(false);setISCoApplicant(true)}}
                              onChange={handleChange}
                              checked={values.type === "Co-Applicant"}
                              value="Co-Applicant"
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio9"
                            >
                              Co-Applicant
                            </label>
                          </div>
                        </div>
                        <div className="co-md-3"></div>
                       {
                        isApplicant &&
                        <Fragment>
                             <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  "> Applicant Name</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='applicantId'
                          onChange={handleChange}
                          value={values.applicantId}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            applicant?.length > 0 ? <Fragment>
                              {
                                applicant.map((apl,index)=>{
                                  return(
                                    <option value={apl?.applicantId}key={index}>{apl.applicantName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="co-md-3"></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end " id="mandatory">Primary Mobile Number</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="number"
                          name="primaryNumber"
                          className="form-control"
                          placeholder="Enter  Mobile Number"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          onChange={handleChange}
                          value={values.primaryNumber}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="col-md-1 mt-1" >
                      
                        <span className='whatsapp'>
                          <i class="bi bi-whatsapp"></i>
                        </span>
                      </div>
                      <div className="col-md-2"></div>
                      <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Secondary Mobile Number</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="number"
                          name='secondaryNumber'
                          className="form-control"
                          placeholder="Enter  Mobile Number"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          onChange={handleChange}
                          value={values.secondaryNumber}
                          onBlur={handleBlur}
                          
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  "id="mandatory">Email ID</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email ID"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          name='email'
                          onChange={handleChange}
                          value={values.email}
                          onBlur={handleBlur}
                         />
                      </div>
                        </Fragment>
                       }
                       {
                        isCoApplicant &&
                        <Fragment>
                             <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  "> Co-Applicant Name</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='maritalStatus.maritalID'
                        >
                          <option value="">Please Select</option>
                        </select>
                          </div>
                          <div className="co-md-3"></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end " id="mandatory">Primary Mobile Number</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="number"
                          name="primaryNumber"
                          className="form-control"
                          placeholder="Enter  Mobile Number"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <div className="col-md-1" >
                      
                        <span className=' whatsapp'>
                          <i class="bi bi-whatsapp "></i>
                        </span>
                      </div>
                      <div className="col-md-2"></div>
                      <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Secondary Mobile Number</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter  Mobile Number"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  "id="mandatory">Email ID</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="emailID"
                          className="form-control"
                          placeholder="Enter Email ID"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                         />
                      </div>
                        </Fragment>
                       }
                         <div className="row mt-3 mb-3">
                      <div className="col-md-10">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                          <Button color="secondary"  className='previousButton' onClick={navigateTOViewLoanCase}>Cancel</Button>
                        <Button color="primary" type="submit" className='nextButton'>Add Contact</Button>
                        </div>
                      </div>
                    </div>
                   </div>
                   </div>
                   </div>
                   </form>
                   </div>
                   </div>
                   </div>
                   </div>
}
    </Fragment>
  )
}

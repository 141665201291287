import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumb,BreadcrumbItem} from 'reactstrap';
import AddSubTask from './AddSubTask';




const AddSubTasks = () => {
document.title="Pradhan Portal";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Row>
                <Col xs={12}>
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Lead Free Pool</h4>
                   <Breadcrumb listTag="div"  className="page-title-right ">
                  <BreadcrumbItem
                         href="#"
                         tag="a"
                  >
                   Leads
                </BreadcrumbItem>
                    <BreadcrumbItem
                         active
                         tag="span"
                    >
                     Free Pool
                 </BreadcrumbItem>
           </Breadcrumb>
       </div>
                </Col>
               </Row>
                    <Row>
                        <Col lg={12}>
                          <AddSubTask/>
                            {/* <UpgradeAccountNotise />
                            <Widget /> */
                            
                            }
                        </Col>
                        {/* <CreateLead/> */}
                    </Row>
                    
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddSubTasks;
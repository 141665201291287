const userActionTypes = {
    LOG_IN_START: "LOG_IN_START",
    LOG_IN_SUCCESS: "LOG_IN_SUCCESS",
    LOG_IN_FAILURE: "LOG_IN_FAILURE",
    REGISTER_START: "REGISTER_START",
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_FAILURE: "REGISTER_FAILURE",
    LOG_OUT: "LOG_OUT",
  };
  
  export default userActionTypes;

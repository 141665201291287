import { Formik, useFormik } from "formik";
import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "../../../assets/scss/pages/customer.css";
import axios from "axios";
import Avatar from "react-avatar";
import uploadimg from "../../../assets/images/upload.png";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { customerForm } from "../../../schema";
import Multiselect from "multiselect-react-dropdown";
import { MultiSelect } from "primereact/multiselect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import csc from "country-state-city";
import Select from "react-select";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import ReactDOM from "react-dom";

const initialValues = {
  customerName: "",
  gender: "",
  dob: "",
  customerType: "",
  employmentType: {
    empTypeID: "",
  },
  residentType: {
    residentTypeID: "",
  },
  religion: {
    religionID: "",
  },
  caste: {
    casteID: "",
  },
  primaryNumber: "",
  secondaryNumber: "",
  emailID: "",
  pan: "",
  aadhar: "",
  drivingLicense: "",
  electricityBillNumber: "",
  qualification: {
    qualificationID: "",
  },
  qualifyingYear: "",
  maritalStatus: {
    maritalID: "",
  },
  noOfDependents: "",
  noOfChildrens: "",
  spouseName: "",
  spouseDOB: "",
  prmntCountry: "",
  prmntCountryId: "",
  prmntCountryCode: "",
  prmntState: "",
  prmntStateId: "",
  prmntCityId: "",
  prmntCity: "",
  pAddressLine1: "",
  pAddressLine2: "",
  pLandmark: "",

  pPIN: "",
  addressStatus: "",
  sameaspermnantlocation: "",
  cAddressLine1: "",
  cAddressLine2: "",
  cLandMark: "",
  correspondsCountry: "",
  correspondCountryId: "",
  correspondCountryCode: "",
  correspondState: "",
  correspondStateId: "",
  correspondCityId: "",
  correspondCity: "",
  cPIN: "",
  oAddressLine1: "",
  oAddressLine2: "",
  oLandmark: "",
  officeCountry: "",
  officeCountryId: "",
  officeState: "",
  officeStateId: "",
  officeCityId: "",
  officeCity: "",
  oPIN: "",
  logNotes: "",
  file: "",
  kycType: "",
};

export default function CreateCustomer() {
  const [characterLimit] = useState(300);
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const fileref = useRef(null);
  const navigate = useNavigate();
  const [selectedCities, setSelectedCities] = useState(null);
  const [isSubmitted, setSubmitted] = useState(false);
  const year = new Date().getFullYear();
  const years = Array.from(new Array(60), (val, index) => year - index);
  const [modal, setModal] = React.useState(false);
  const [officeAddress, setOfficeAddress] = useState(false);
  // Toggle for Modal
  const toggle = () => setModal(!modal);
  const [field, setField] = useState([]);
  const [kycTypes, setKycTypes] = useState([]);
  const {
    values,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: customerForm,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("customerData", JSON.stringify(values));
      formData.append("profilePhoto", picture);
      formData.append("KYCTypes", kycTypes);
      const response = axios.post(
        "https://pradhanportal.com/loanApplication/customer/addCustomerWithProfile",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      response.then(() => {
        //toast.success("Data Added Successfully!");
        // newStepValue=1;
        setSubmitted(true);
      });
      response.catch((err) => {
        alert("Getting error in featching data");
        //  newStepValue=0;
      });

      //     axios.post('https://pradhanportal.com/loanApplication/customer/addCustomer',formData).then((response)=>{
      //       console.log(response);
      //       setSubmitted(true);
      //     }).catch((err)=>{
      //       console.error(err);
      //     });
      //   setSubmitted(true);
      //   toast.success('Customer created successfully !', {
      //     position: toast.POSITION.TOP_RIGHT
      // });
    },
  });
  console.log(values);

  const countries = csc.getAllCountries();
  console.log(csc.getAllCountries());

  const prmntupdatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));
  const correspondupdatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));
  const officeupdatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));
  const prmntupdatedStates = (prmntCountryId) =>
    csc
      .getStatesOfCountry(prmntCountryId)
      .map((state) => ({ label: state.name, value: state.name, ...state }));

  const correspondupdatedStates = (correspondCountryId) =>
    csc
      .getStatesOfCountry(correspondCountryId)
      .map((state) => ({ label: state.name, value: state.name, ...state }));
  const officeupdatedStates = (officeCountryId) =>
    csc
      .getStatesOfCountry(officeCountryId)
      .map((state) => ({ label: state.name, value: state.name, ...state }));

  const prmntupdatedCities = (prmntStateId) =>
    csc
      .getCitiesOfState(prmntStateId)
      .map((city) => ({ label: city.name, value: city.name, ...city }));

  const correspondupdatedCities = (correspondStateId) =>
    csc
      .getCitiesOfState(correspondStateId)
      .map((city) => ({ label: city.name, value: city.name, ...city }));

  const officeupdatedCities = (officeStateId) =>
    csc
      .getCitiesOfState(officeStateId)
      .map((city) => ({ label: city.name, value: city.name, ...city }));
  const [prmntCountryDefault, setprmntCountryDefault] = useState({
    label: values.prmntCountry,
    id: values.prmntCountryId,
  });
  const [officeCountryDefault, setofficeCountryDefault] = useState({
    label: values.officeCountry,
    id: values.officeCountryId,
  });
  const [officeStateDefault, setofficeStateDefault] = useState({
    label: values.officeState,
    id: values.officeStateId,
  });
  const [officeCityDefault, setofficeCityDefault] = useState({
    label: values.officeCity,
    id: values.officeCityId,
  });
  const [homeStateDefault, sethomeStateDefault] = useState({
    label: values.prmntState,
    id: values.prmntStateId,
  });
  const [cityDefault, setcityDefault] = useState({
    label: values.prmntCity,
    id: values.prmntCityId,
  });

  const countryHomeHandle = (event) => {
    setValues({
      ...values,
      prmntCountry: event.name,
      prmntCountryId: event.id,
      prmntCountryCode: event.id,
    });
    setprmntCountryDefault({
      ...prmntCountryDefault,
      label: event.name,
      id: event.id,
    });
  };
  const countryOfficeHandle = (event) => {
    setValues({
      ...values,
      officeCountry: event.name,
      officeCountryId: event.id,
    });
    setofficeCountryDefault({
      ...officeCountryDefault,
      label: event.name,
      id: event.id,
    });
  };

  const stateofficeHandle = (event) => {
    setValues({
      ...values,
      officeState: event.name,
      officeStateId: event.id,
    });
    setofficeStateDefault({
      ...officeStateDefault,
      label: event.name,
      id: event.id,
    });
  };

  const cityofficeHandle = (event) => {
    setValues({
      ...values,
      officeCity: event.name,
      officeCityId: event.id,
    });
    setofficeCityDefault({
      ...officeCityDefault,
      label: event.name,
      id: event.id,
    });
  };
  const stateprmntHandle = (event) => {
    setValues({
      ...values,
      prmntState: event.name,
      prmntStateId: event.id,
      // homeStateCode: event.id,
    });
    sethomeStateDefault({
      ...homeStateDefault,
      label: event.name,
      id: event.id,
    });
  };
  const onChangePicture = (event) => {
    if (event.target.files[0]) {
      console.log("picture: ", event.target.files);
      setPicture(event.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  console.log("picture==", picture);
  console.log("imgData", imgData);
  const [showCheckbox1, setshowCheckbox1] = useState(null);
  const [showCheckbox, setshowCheckbox] = useState(null);
  const [selectedVal, setSelectedVal] = useState({
    label: values.correspondsCountry,
    id: values.correspondCountryId,
  });
  const [selectedValstate, setSelectedValstate] = useState({
    label: values.correspondState,
    id: values.correspondStateId,
  });
  const [selectedValcity, setSelectedValcity] = useState({
    label: values.correspondCity,
    id: values.correspondCityId,
  });

  const handleChangeForSameLocation = (e) => {
    const { name, value, type, checked } = e.currentTarget;
    setValues((preState) => ({
      ...preState,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name == "sameaspermnantlocation") {
      if (checked == true) {
        setshowCheckbox1(true);
      } else {
        setshowCheckbox1(false);
      }
    }

    if (name == "sameaspermnantlocation" && checked) {
      setSelectedVal({
        label: values.prmntCountry,
        id: values.prmntCountryId,
      });
      setSelectedValstate({
        label: values.prmntState,
        id: values?.prmntStateId,
      });
      setSelectedValcity({
        label: values.prmntCity,
        id: values?.prmntCityId,
      });

      values.correspondsCountry = null;
      values.correspondCountryCode = null;
      values.correspondState = null;
      values.correspondCity = null;

      setValues((preState) => ({
        ...preState,
        // currentPinCode: additional.homePinCode,
        cAddressLine1: values.pAddressLine1,
        cAddressLine2: values.pAddressLine2,
        cLandMark: values.pLandmark,
        correspondsCountry: values.prmntCountry,
        correspondCountryId: values.prmntCountryId,
        correspondCountryCode: values.prmntCountryCode,
        correspondState: values.prmntState,
        correspondStateId: values.prmntStateId,
        correspondCity: values.prmntCity,
        correspondCityId: values.prmntCityId,
        cPIN: values.pPIN,
      }));
    }
    if (name == "sameaspermnantlocation" && !checked) {
      setSelectedVal({ label: "", id: "" });
      setSelectedValstate({ label: "", id: "" });
      setSelectedValcity({ label: "", id: "" });

      setValues((preState) => ({
        ...preState,
        // currentPinCode: "",
        cAddressLine1: "",
        cAddressLine2: "",
        cLandMark: "",
        cPIN: "",
        correspondsCountry: "",
        correspondCountryId: "",
        correspondCountryCode: "",
        correspondState: "",
        correspondStateId: "",
        correspondCity: "",
        correspondCityId: "",
      }));
    }
  };
  const navigateToCreateLeadCustomerMaster = () => {
    navigate("/customers");
    toast.success("Customer created successfully !", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const navigateToCreateLead = () => {
    // 👇️ navigate to /CreateLead
    navigate("/customers");
  };
  const stateCurrentHandle = (event) => {
    setValues({
      ...values,
      correspondState: event.name,
      correspondStateId: event.id,
      // currentStateCode: event.id,
    });
    setSelectedValstate({
      ...selectedValstate,
      label: event.name,
      id: event.id,
    });
  };

  const cityCurrentHandle = (event) => {
    setValues({
      ...values,
      correspondCity: event.name,
      correspondCityId: event.id,
      // currentCityCode: event.id,
    });
    setSelectedValcity({ ...selectedValcity, label: event.name, id: event.id });
  };
  const countrycorrespondtHandle = (event) => {
    setValues({
      ...values,
      correspondsCountry: event.name,
      correspondCountryId: event.id,
      correspondCountryCode: event.id,
    });
    setSelectedVal({ ...selectedVal, label: event.name, id: event.id });
  };
  const [residentType, setResidentType] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/customer/getAllResidentType`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setResidentType(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [empmentType, setempmentType] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/customer/getAllEmploymentType`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setempmentType(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [religion, setReligion] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/customer/getAllReligion`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setReligion(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [caste, setCaste] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/customer/getAllCaste`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setCaste(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [maritalStatus, setMaritalStatus] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/customer/getAllMaritalStatus`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setMaritalStatus(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [qualification, setQualification] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/customer/getAllQualification`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setQualification(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [inputFields, setInputFields] = useState([
    {
      fullName: "",
      emailAddress: "",
      salary: "",
    },
  ]);

  const handleChangeMultiSelect = (value) => {
    console.log("Event  :", value);
    setKycTypes(...kycTypes, [value]);
  };

  const [options, setOtions] = useState([
    "Driving_License",
    "Electricity_Bill",
  ]);
  console.log("kycTypes  :", kycTypes);
  //   const options = [
  //     { name: 'Driving License', code: 'DL' },
  //     { name: 'Electricity Bill', code: 'EBN' },
  // ];

  const char = values?.logNotes.length;
  console.log("char count function calling...", 300 - char);
  return (
    <Fragment>
      {isSubmitted ? (
        navigate("/Customers") &
        toast.success("Customer Added successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        })
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <div className="row">
                    <div className="col-md-9 mt-3">
                      <span className="customerDetails">Customer Details</span>
                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <span
                            className="d-flex justify-content-end"
                            id="mandatory"
                          >
                            Customer Name
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="text"
                            name="customerName"
                            onChange={handleChange}
                            value={values.customerName}
                            onBlur={handleBlur}
                            className="form-control sm"
                            placeholder="FirstName , MiddleName ,LastName"
                          />
                          {errors.customerName && touched.customerName ? (
                            <p className="form-error">{errors.customerName}</p>
                          ) : null}
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span
                            className="d-flex justify-content-end"
                            id="mandatory"
                          >
                            Gender
                          </span>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio7"
                              onChange={handleChange}
                              checked={values.gender === "Male"}
                              value="Male"
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio7"
                            >
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio8"
                              onChange={handleChange}
                              value="Female"
                              checked={values.gender === "Female"}
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio8"
                            >
                              Female
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio9"
                              onChange={handleChange}
                              checked={values.gender === "Other"}
                              value="Other"
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio9"
                            >
                              Other
                            </label>
                          </div>
                          {errors.gender && touched.gender ? (
                            <p className="form-error">{errors.gender}</p>
                          ) : null}
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span
                            className="d-flex justify-content-end"
                            id="mandatory"
                          >
                            DOB
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="date"
                            name="dob"
                            className="form-control sm"
                            placeholder="dd-mm-yy"
                            onChange={handleChange}
                            value={values.dob}
                            onBlur={handleBlur}
                          />
                          {errors.dob && touched.dob ? (
                            <p className="form-error">{errors.dob}</p>
                          ) : null}
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span
                            className="d-flex justify-content-end"
                            id="mandatory"
                          >
                            Customer Type
                          </span>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="customerType"
                              id="inlineRadio3"
                              onChange={handleChange}
                              value="Non HNI"
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio3"
                            >
                              Non HNI
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="customerType"
                              id="inlineRadio4"
                              onChange={handleChange}
                              value="HNI"
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio4"
                            >
                              HNI
                            </label>
                          </div>
                          {errors.customerType && touched.customerType ? (
                            <p className="form-error">{errors.customerType}</p>
                          ) : null}
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-4">
                          <span className="d-flex justify-content-end  ">
                            Employment Type
                          </span>
                        </div>
                        <div className="col-md-6 mt-3">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="employmentType.empTypeID"
                            onChange={handleChange}
                            value={values.employmentType?.empTypeID}
                            onBlur={handleBlur}
                          >
                            <option selected>Please Select</option>
                            {empmentType?.length > 0 ? (
                              <Fragment>
                                {empmentType.map((emp, index) => {
                                  return (
                                    <option value={emp?.empTypeID} key={index}>
                                      {emp.empType}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Resident Type
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="residentType.residentTypeID"
                            onChange={handleChange}
                            value={values.residentType?.residentTypeID}
                            onBlur={handleBlur}
                          >
                            <option value="">Please Select</option>
                            {residentType?.length > 0 ? (
                              <Fragment>
                                {residentType.map((resident, index) => {
                                  return (
                                    <option
                                      value={resident?.residentTypeID}
                                      key={index}
                                    >
                                      {resident.residentType}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Religion
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="religion.religionID"
                            onChange={handleChange}
                            value={values.religion?.religionID}
                            onBlur={handleBlur}
                          >
                            <option value="">Please Select</option>
                            {religion?.length > 0 ? (
                              <Fragment>
                                {religion.map((type, index) => {
                                  return (
                                    <option
                                      value={type?.religionID}
                                      key={index}
                                    >
                                      {type.religionName}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Caste
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="caste.casteID"
                            onChange={handleChange}
                            value={values.caste?.casteID}
                            onBlur={handleBlur}
                          >
                            <option value="">Please Select</option>
                            {caste?.length > 0 ? (
                              <Fragment>
                                {caste.map((type, index) => {
                                  return (
                                    <option value={type?.casteID} key={index}>
                                      {type.casteName}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <p className="contactDetails">Contact Details</p>
                        <div className="col-md-3 mt-2">
                          <span
                            className="d-flex justify-content-end "
                            id="mandatory"
                          >
                            Primary Mobile Number
                          </span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="number"
                            name="primaryNumber"
                            className="form-control"
                            placeholder="Enter  Mobile Number"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleChange}
                            value={values.primaryNumber}
                            onBlur={handleBlur}
                          />
                          {errors.primaryNumber && touched.primaryNumber ? (
                            <p className="form-error">{errors.primaryNumber}</p>
                          ) : null}
                        </div>
                        <div className="col-md-1">
                          <span className=" whatsapp">
                            <i class="bi bi-whatsapp "></i>
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Secondary Mobile Number
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter  Mobile Number"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            name="secondaryNumber"
                            onChange={handleChange}
                            value={values.secondaryNumber}
                            onBlur={handleBlur}
                          />
                          {errors.secondaryNumber && touched.secondaryNumber ? (
                            <p className="form-error">
                              {errors.secondaryNumber}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <span
                            className="d-flex justify-content-end  "
                            id="mandatory"
                          >
                            Email ID
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="emailID"
                            className="form-control"
                            placeholder="Enter Email ID"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            name="emailID"
                            onChange={handleChange}
                            value={values.emailID}
                            onBlur={handleBlur}
                          />
                          {errors.emailID && touched.emailID ? (
                            <p className="form-error">{errors.emailID}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <p className="kycDetails">KYC Details</p>
                        </div>
                        <div className="col-md-6 mt-4">
                          <a
                            aria-hidden="true"
                            className=" button mx-3 addKYC"
                            onClick={toggle}
                          >
                            Add Other KYC Details
                          </a>
                        </div>
                        <div className="col-md-3"></div>
                        <Modal isOpen={modal} id="assignmodal">
                          <div className="container-fluid">
                            <ModalHeader className="bg-primary d-flex justify-content-center pb-1 pt-1">
                              <span id="assignowner1">Add KYC</span>
                            </ModalHeader>
                            <ModalBody>
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-md-3 mt-2">
                                        <span className="d-flex justify-content-end  ">
                                          KYC Type
                                        </span>
                                      </div>
                                      <div className="col-md-6">
                                        <Multiselect
                                          isObject={false}
                                          onRemove={(event) => {
                                            console.log(event);
                                          }}
                                          onSelect={(value) => {
                                            handleChangeMultiSelect(value);
                                            console.log("event", value);
                                          }}
                                          options={options}
                                          displayValue="kycTypes"
                                          name="kycTypes"
                                        />
                                        {/* <MultiSelect value={selectedCities} 
                                        onChange={(e) => setSelectedCities(e.value)}
                                        options={options} optionLabel="kycType" display="chip" 
                                        placeholder="Select KYC Types" maxSelectedLabels={3}
                                        className="w-full md:w-20rem" /> */}
                                      </div>
                                      <div className="col-md-3"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ModalBody>
                            <ModalFooter>
                              <div className="container">
                                <div className="row ">
                                  <div className="col-md-12">
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                                      <Button
                                        color="secondary"
                                        id="btn-cancel"
                                        onClick={toggle}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        color="primary"
                                        type="submit"
                                        onClick={toggle}
                                        id="btn-cancel"
                                      >
                                        Add KYC
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ModalFooter>
                          </div>
                        </Modal>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            PAN
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="text"
                            name="pan"
                            className="form-control sm"
                            placeholder="Enter Pan Number"
                            onChange={handleChange}
                            value={values.pan}
                            onBlur={handleBlur}
                          />
                          {errors.pan && touched.pan ? (
                            <p className="form-error">{errors.pan}</p>
                          ) : null}
                        </div>
                        <div className="col-md-3">
                          <label>
                            <input type="file" hidden />
                            <img
                              src={uploadimg}
                              width="30"
                              height="30"
                              className="mt-2"
                            />
                          </label>
                          <span className="mx-1">Upload</span>
                        </div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            AADHAR
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="text"
                            name="aadhar"
                            className="form-control sm"
                            placeholder="Enter Aadhar Number"
                            onChange={handleChange}
                            value={values.aadhar}
                            onBlur={handleBlur}
                          />
                          {errors.aadhar && touched.aadhar ? (
                            <p className="form-error">{errors.aadhar}</p>
                          ) : null}
                        </div>
                        <div className="col-md-3">
                          <label>
                            <input type="file" hidden />
                            <img
                              src={uploadimg}
                              width="30"
                              height="30"
                              className="mt-2"
                            />
                          </label>
                          <span className="mx-2">Upload</span>
                        </div>
                        {kycTypes.map((field, idx) => {
                          return (
                            <Fragment key={`${idx}`}>
                              <div className="col-md-3 mt-3">
                                <span className="d-flex justify-content-end">
                                  {field}
                                </span>
                              </div>
                              <div className="col-md-6 mt-2">
                                <input
                                  type="text"
                                  name={field}
                                  onChange={handleChange}
                                  value={values.field}
                                  className="form-control sm"
                                  placeholder={field}
                                />
                              </div>
                              <div className="col-md-3">
                                <label>
                                  <input type="file" hidden />
                                  <img
                                    src={uploadimg}
                                    width="30"
                                    height="30"
                                    className="mt-2"
                                  />
                                </label>
                                <span className="mx-1">Upload {""}</span>
                                {/* <a href='#' type='button' className='btn-sm text-danger' onClick={()=>{setDrivingLicense(false)}}>Remove</a> */}
                              </div>
                            </Fragment>
                          );
                        })}
                      </div>
                      <div className="row">
                        <p className="educationDetails">Education Details</p>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Highest Qualification
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="qualification.qualificationID"
                            onChange={handleChange}
                            value={values.qualification?.qualificationID}
                            onBlur={handleBlur}
                          >
                            <option value="">Please Select</option>
                            {qualification?.length > 0 ? (
                              <Fragment>
                                {qualification.map((type, index) => {
                                  return (
                                    <option
                                      value={type?.qualificationID}
                                      key={index}
                                    >
                                      {type.qualification}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Qualifying Year
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="qualifyingYear"
                            onChange={handleChange}
                            value={values.qualifyingYear}
                            onBlur={handleBlur}
                          >
                            <option value="">Please Select</option>

                            {years.map((year, index) => {
                              return (
                                <option key={`year${index}`} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                      <div className="row">
                        <p className="familyDetails mt-3">Family Details</p>
                        <div className="col-md-3 mt-1">
                          <span className="d-flex justify-content-end  ">
                            Marital Status
                          </span>
                        </div>
                        <div className="col-md-6">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="maritalStatus.maritalID"
                            onChange={handleChange}
                            value={values.maritalStatus?.maritalID}
                            onBlur={handleBlur}
                          >
                            <option value="">Please Select</option>
                            {maritalStatus?.length > 0 ? (
                              <Fragment>
                                {maritalStatus.map((status, index) => {
                                  return (
                                    <option
                                      value={status?.maritalID}
                                      key={index}
                                    >
                                      {status.maritalStatus}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            No of Dependents
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="text"
                            name="noOfDependents"
                            className="form-control sm"
                            placeholder="Enter No. of Dependents"
                            onChange={handleChange}
                            value={values.noOfDependents}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        {values?.maritalStatus?.maritalID === "1" ? (
                          <Fragment>
                            <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">
                                No of Children
                              </span>
                            </div>
                            <div className="col-md-6 mt-2">
                              <input
                                type="text"
                                name="noOfChildrens"
                                className="form-control sm"
                                placeholder="Enter No. of Childrens"
                                onChange={handleChange}
                                value={values.noOfChildrens}
                                onBlur={handleBlur}
                              />
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">
                                Spouse Name
                              </span>
                            </div>
                            <div className="col-md-6 mt-2">
                              <input
                                type="text"
                                name="spouseName"
                                className="form-control sm"
                                placeholder="Enter Spouse Name"
                                onChange={handleChange}
                                value={values.spouseName}
                                onBlur={handleBlur}
                              />
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">
                                Spouse DOB
                              </span>
                            </div>
                            <div className="col-md-6 mt-2">
                              <input
                                type="date"
                                name="spouseDOB"
                                className="form-control sm"
                                placeholder="dd-mm-yy"
                                onChange={handleChange}
                                value={values.spouseDOB}
                                onBlur={handleBlur}
                              />
                            </div>
                            <div className="col-md-3"></div>
                          </Fragment>
                        ) : null}
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-3 mt-3">
                          <p className="addressDetails">Address Details</p>
                        </div>
                        <div className="col-md-6 mt-3">
                          <a
                            className="addOfficeAddress btn"
                            onClick={() => {
                              setOfficeAddress(true);
                            }}
                          >
                            Add Office Address Details
                          </a>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-4 mt-1">
                          <span className="d-flex justify-content-end address">
                            Permanent Address
                          </span>
                        </div>
                        <div className="col-md-8 mt-2"></div>
                        <div className="col-md-3 mt-4">
                          <span className="d-flex justify-content-end">
                            Address Line 1
                          </span>
                        </div>
                        <div className="col-md-6 mt-3">
                          <input
                            type="text"
                            name="pAddressLine1"
                            className="form-control sm"
                            placeholder="Enter Address Line 1"
                            onChange={handleChange}
                            value={values.pAddressLine1}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            Address Line 2
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="text"
                            name="pAddressLine2"
                            className="form-control sm"
                            placeholder="Enter Address Line 2"
                            onChange={handleChange}
                            value={values.pAddressLine2}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            Landmark
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="text"
                            name="pLandmark"
                            className="form-control sm"
                            placeholder="Enter Landmark"
                            onChange={handleChange}
                            value={values.pLandmark}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Country
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          {/* <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='pCountry.countryID'
                          onChange={handleChange}
                          value={values.pCountry?.countryID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            country?.length > 0 ? <Fragment>
                              {
                                country.map((type,index)=>{
                                  return(
                                    <option value={type?.countryID}key={index}>{type.countryName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select> */}
                          <Select
                            id="prmntCountry"
                            name="prmntCountry"
                            label="prmntCountry"
                            className="countrydrp"
                            rules={{ required: true }}
                            value={prmntCountryDefault}
                            options={prmntupdatedCountries}
                            onChange={countryHomeHandle}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            State
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          {/* <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='pState.stateID'
                          onChange={handleChange}
                          value={values.pState?.stateID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            state?.length > 0 ? <Fragment>
                              {
                                state.map((type,index)=>{
                                  return(
                                    <option value={type?.stateID}key={index}>{type.state}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select> */}
                          <Select
                            name="prmntState"
                            id="prmntState"
                            className="countrydrp"
                            options={prmntupdatedStates(
                              values.prmntCountryId
                                ? values.prmntCountryId
                                : null
                            )}
                            value={homeStateDefault}
                            onChange={stateprmntHandle}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end  ">
                            City
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          {/* <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='pCity.cityID'
                          onChange={handleChange}
                          value={values.pCity?.cityID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            city?.length > 0 ? <Fragment>
                              {
                                city.map((cities,index)=>{
                                  return(
                                    <option value={cities?.cityID}key={index}>{cities.cityName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select> */}
                          <Select
                            //required
                            id="prmntCity"
                            className="countrydrp"
                            name="prmntCity"
                            options={prmntupdatedCities(
                              values.prmntStateId ? values.prmntStateId : null
                            )}
                            onChange={(event) =>
                              setValues({
                                ...values,
                                prmntCity: event.name,
                                prmntCityId: event.id,
                              })
                            }
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            PIN
                          </span>
                        </div>
                        <div className="col-md-6 mt-2">
                          <input
                            type="number"
                            name="pPIN"
                            className="form-control sm"
                            placeholder="Enter PIN"
                            onChange={handleChange}
                            value={values?.pPIN}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <span className="d-flex justify-content-end address">
                            Correspondence Address
                          </span>
                        </div>
                        <div className="col-md-8 mt-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="sameaspermnantlocation"
                              id="inlineRadio22"
                              onChange={handleChangeForSameLocation}
                              defaultChecked={
                                values.sameashomelocation ? true : false
                              }
                              value={values.sameaspermnantlocation}
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio22"
                            >
                              Same as Above
                            </label>
                          </div>
                        </div>
                      </div>
                      {values.addressStatus != true ? (
                        <div className="row">
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              Address Line 1
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                              type="text"
                              name="cAddressLine1"
                              className="form-control sm"
                              placeholder="Enter Address Line 1"
                              onChange={handleChange}
                              value={values?.cAddressLine1}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              Address Line 2
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                              type="text"
                              name="cAddressLine2"
                              className="form-control sm"
                              placeholder="Enter Address Line 2"
                              onChange={handleChange}
                              value={values?.cAddressLine2}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              Landmark
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                              type="text"
                              name="cLandMark"
                              className="form-control sm"
                              placeholder="Enter Landmark"
                              onChange={handleChange}
                              value={values?.cLandMark}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end ">
                              Country
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            {/* <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='cCountry.countryID'
                          onChange={handleChange}
                          value={values.cCountry?.countryID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            country?.length > 0 ? <Fragment>
                              {
                                country.map((type,index)=>{
                                  return(
                                    <option value={type?.countryID}key={index}>{type.countryName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select> */}
                            <Select
                              id="correspondsCountry"
                              name="correspondsCountry"
                              label="correspondsCountry"
                              className="countrydrp"
                              rules={{ required: true }}
                              options={correspondupdatedCountries}
                              onChange={countrycorrespondtHandle}
                              value={selectedVal}
                              // onChange={(event) =>setValues({...values,correspondsCountry: event.name,correspondCountryId: event.id,
                              //   });setSelectedVal({ ...selectedVal, label: event.name, id: event.id });
                              // }
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end  ">
                              State
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            {/* <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='cState.stateID'
                          onChange={handleChange}
                          value={values.cState?.stateID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            state?.length > 0 ? <Fragment>
                              {
                                state.map((type,index)=>{
                                  return(
                                    <option value={type?.stateID}key={index}>{type.state}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select> */}
                            <Select
                              name="correspondState"
                              id="correspondState"
                              className="countrydrp"
                              options={correspondupdatedStates(
                                values.correspondCountryId
                                  ? values.correspondCountryId
                                  : null
                              )}
                              value={selectedValstate}
                              // onChange={(event) =>
                              //   setValues({
                              //     ...values,
                              //     correspondState: event.name,
                              //     correspondStateId: event.id,
                              //   })
                              // }
                              onChange={stateCurrentHandle}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end  ">
                              City
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <Select
                              //required
                              id="correspondCity"
                              className="countrydrp"
                              name="correspondCity"
                              options={correspondupdatedCities(
                                values.correspondStateId
                                  ? values.correspondStateId
                                  : null
                              )}
                              value={selectedValcity}
                              onChange={cityCurrentHandle}
                              // onChange={(event) =>
                              //   setValues({
                              //     ...values,
                              //     correspondCity: event.name,
                              //     correspondCityId: event.id,
                              //   })
                              // }
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              PIN
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                              type="number"
                              name="cPIN"
                              className="form-control sm"
                              placeholder="Enter PIN"
                              onChange={handleChange}
                              value={values?.cPIN}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="col-md-3"></div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              Address Line 1
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                              type="text"
                              name="cAddressLine1"
                              className="form-control sm"
                              placeholder="Enter Address Line 1"
                              onChange={handleChange}
                              value={
                                (values.cAddressLine1 = values?.pAddressLine1)
                              }
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              Address Line 2
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                              type="text"
                              name="cAddressLine2"
                              className="form-control sm"
                              placeholder="Enter Address Line 2"
                              onChange={handleChange}
                              value={
                                (values.cAddressLine2 = values?.pAddressLine2)
                              }
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              Landmark
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                              type="text"
                              name="cLandMark"
                              className="form-control sm"
                              placeholder="Enter Landmark"
                              onChange={handleChange}
                              value={(values.cLandMark = values?.pLandmark)}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end ">
                              Country
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            {/* <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='cCountry.countryID'
                          onChange={handleChange}
                          value={values.cCountry.countryID=values.pCountry?.countryID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            country?.length > 0 ? <Fragment>
                              {
                                country.map((type,index)=>{
                                  return(
                                    <option value={type?.countryID}key={index}>{type.countryName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select> */}
                            <Select
                              id="correspondsCountry"
                              name="correspondsCountry"
                              label="correspondsCountry"
                              className="countrydrp"
                              rules={{ required: true }}
                              value={
                                (values.correspondCountryId =
                                  values.prmntCountryId)
                              }
                              options={correspondupdatedCountries}
                              onChange={(event) =>
                                setValues({
                                  ...values,
                                  correspondsCountry: event.name,
                                  correspondCountryId: event.id,
                                })
                              }
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end  ">
                              State
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            {/* <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="cState.stateID"
                              onChange={handleChange}
                              //value={values.cState.stateID=values.pState?.stateID}
                              onBlur={handleBlur}
                            >
                              <option value="">Please Select</option>
                              {state?.length > 0 ? (
                                <Fragment>
                                  {state.map((type, index) => {
                                    return (
                                      <option value={type?.stateID} key={index}>
                                        {type.state}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                            </select> */}
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end  ">
                              City
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            {/* <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="cCity.cityID"
                              onChange={handleChange}
                              //   value={values.cCity.cityID=values.pCity?.cityID}
                              onBlur={handleBlur}
                            >
                              <option value="">Please Select</option>
                              {city?.length > 0 ? (
                                <Fragment>
                                  {city.map((cities, index) => {
                                    return (
                                      <option
                                        value={cities?.cityID}
                                        key={index}
                                      >
                                        {cities.cityName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                            </select> */}
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              PIN
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                              type="number"
                              name="cPIN"
                              className="form-control sm"
                              placeholder="Enter PIN"
                              onChange={handleChange}
                              value={(values.cPIN = values.pPIN)}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="col-md-3"></div>
                        </div>
                      )}
                      {officeAddress && (
                        <div className="row mt-2">
                          <div className="col-md-4 mt-3">
                            <span className="d-flex justify-content-end address">
                              Office Address
                            </span>
                          </div>
                          <div className="col-md-8 mt-3">
                            <a
                              type="button"
                              className="btn-sm text-danger"
                              onClick={() => {
                                setOfficeAddress(false);
                              }}
                            >
                              Remove
                            </a>
                          </div>
                          <div className="col-md-3 mt-4">
                            <span className="d-flex justify-content-end">
                              Address Line 1
                            </span>
                          </div>
                          <div className="col-md-6 mt-3">
                            <input
                              type="text"
                              name="oAddressLine1"
                              className="form-control sm"
                              placeholder="Enter Address Line 1"
                              onChange={handleChange}
                              value={values?.oAddressLine1}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              Address Line 2
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                              type="text"
                              name="oAddressLine2"
                              className="form-control sm"
                              placeholder="Enter Address Line 2"
                              onChange={handleChange}
                              value={values?.oAddressLine2}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              Landmark
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                              type="text"
                              name="oLandmark"
                              className="form-control sm"
                              placeholder="Enter Landmark"
                              onChange={handleChange}
                              value={values.oLandmark}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end ">
                              Country
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <Select
                              id="officeCountry"
                              name="officeCountry"
                              label="officeCountry"
                              className="countrydrp"
                              rules={{ required: true }}
                              value={officeCountryDefault}
                              options={officeupdatedCountries}
                              onChange={countryOfficeHandle}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end  ">
                              State
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <Select
                              name="officeState"
                              id="officeState"
                              className="countrydrp"
                              options={officeupdatedStates(
                                values.officeCountryId
                                  ? values.officeCountryId
                                  : null
                              )}
                              value={officeStateDefault}
                              onChange={stateofficeHandle}
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end  ">
                              City
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <Select
                              id="officeCity"
                              className="countrydrp"
                              name="officeCity  "
                              options={officeupdatedCities(
                                values.officeStateId
                                  ? values.officeStateId
                                  : null
                              )}
                              onChange={(event) =>
                                setValues({
                                  ...values,
                                  officeCity: event.name,
                                  officeCityId: event.id,
                                })
                              }
                            />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              PIN
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <input
                              type="number"
                              name="oPIN"
                              className="form-control sm"
                              placeholder="Enter PIN"
                              onChange={handleChange}
                              value={values.oPIN}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="col-md-3"></div>
                        </div>
                      )}
                      <div className="row mt-3">
                        <p className="logNotes">Log Notes</p>
                        <div className="col-md-3 mt-3">
                          <span className="d-flex justify-content-end">
                            Log Notes
                          </span>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <textarea
                              name="logNotes"
                              className="form-control"
                              maxLength="300"
                              rows={3}
                              value={values.logNotes}
                              onChange={handleChange}
                            />
                          </div>
                          <div className=" d-md-flex justify-content-md-end">
                            {" "}
                            Remaining Characters: {300 - char}/{characterLimit}{" "}
                          </div>
                        </div>{" "}
                        <div className="col-md-3"></div>
                      </div>
                    </div>
                    <div className="col-md-3 mt-4">
                      <div className="previewProfilePic" id="image">
                        {imgData != null ? (
                          <img
                            id="image"
                            className="playerProfilePic_home_tile"
                            src={imgData}
                          />
                        ) : (
                          <img
                            id="image"
                            className="playerProfilePic_home_tile"
                            src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                          />
                        )}
                      </div>
                      <div className="register_profile_image">
                        <input
                          className="profilePic"
                          ref={fileref}
                          type="file"
                          hidden
                          name="file"
                          value={values.file}
                          onChange={onChangePicture}
                        />
                        {imgData == null ? (
                          <a
                            id="img"
                            to=""
                            onClick={() => {
                              fileref.current.click();
                            }}
                          >
                            <span className="profile">Upload Profile Pic</span>
                          </a>
                        ) : (
                          <a
                            to=""
                            id="img"
                            onClick={() => {
                              fileref.current.click();
                            }}
                          >
                            <span className="profile bg-light mx-3 px-1">
                              Edit Profile Pic
                            </span>
                          </a>
                        )}
                      </div>
                    </div>
                    <div className="row mt-3 mb-3">
                      <div className="col-md-7">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                          <button
                            onClick={navigateToCreateLead}
                            class="btn btn-secondary me-md-2"
                            id="Cancelbtn1"
                            type="button"
                          >
                            Cancel
                          </button>
                          <button
                            class="btn btn-primary"
                            type="submit"
                            id="Cancelbtn2"
                          >
                            Create Customer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import csc from "country-state-city";
import Select from "react-select";
export default function EditCurrentAddress() {
  const [isSubmitted, setSubmitted] = useState(false);
  const { customerId } = useParams();
  const navigate = useNavigate();
  console.log("customerID", customerId);
  const [customerData, setCustomerData] = useState({
    cAddressLine1: "",
    cAddressLine2: "",
    cLandmark: "",
    correspondsCountry: "",
    correspondCountryId: "",
    correspondCountryCode: "",
    correspondState: "",
    correspondStateId: "",
    correspondCityId: "",
    correspondCity: "",
    cPIN: "",
  });
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/customer/getCustomerById?customerId=${customerId}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response :", response.data);
        setCustomerData(response.data);
        setSelectedCorrespondsVal({
          label: response?.data.correspondsCountry,
          id: response?.data.correspondCountryId,
        });
        setSelectedValCorrespondsstate({
          label: response?.data.correspondState,
          id: response?.data.correspondStateId,
        });
        setSelectedValCorrespondscity({
          label: response?.data.correspondCity,
          id: response?.data.correspondCityId,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [customerId]);

  const handleChange = (e) => {
    setCustomerData({
      ...customerData,
      [e.target.name]: e.target.value,
    });
  };

  const SubmitForm = (e) => {
    e.preventDefault();
    axios
      .put("https://pradhanportal.com/loanApplication/customer/editCustomer", customerData)
      .then((response) => {
        console.log(response);

        setSubmitted(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const countries = csc.getAllCountries();

  const updatedCorrespondsCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));

  const updatedCorrespondsStates = (correspondCountryId) =>
    csc
      .getStatesOfCountry(correspondCountryId)
      .map((state) => ({ label: state.name, value: state.id, ...state }));

  const updatedCorrespondsCities = (prmntStateId) =>
    csc
      .getCitiesOfState(prmntStateId)
      .map((city) => ({ label: city.name, value: city.id, ...city }));

  const ddlChangeCorrespondsCountry = (e) => {
    setCustomerData({
      ...customerData,
      correspondsCountry: e.name,
      correspondCountryId: e.value,
    });
    setSelectedCorrespondsVal({ label: e.name, id: e.value });
  };

  const ddlChangeCorrespondsState = (e) => {
    setCustomerData({
      ...customerData,
      correspondState: e.name,
      correspondStateId: e.value,
    });
    setSelectedValCorrespondsstate({ label: e.name, id: e.value });
  };

  const ddlChangeCorrespondsCity = (e) => {
    setCustomerData({
      ...customerData,
      correspondCity: e.name,
      correspondCityId: e.value,
    });
    setSelectedValCorrespondscity({ label: e.name, id: e.value });
  };

  const [selectedCorrespondsVal, setSelectedCorrespondsVal] = useState({
    label: customerData.correspondsCountry,
    id: customerData.correspondCountryId,
  });
  const [selectedValCorrespondsstate, setSelectedValCorrespondsstate] =
    useState({
      label: customerData.correspondState,
      id: customerData.correspondStateId,
    });
  const [selectedValCorrespondscity, setSelectedValCorrespondscity] = useState({
    label: customerData.prmntCity,
    id: customerData.prmntCityId,
  });
  const [viewCase, SetViewCase] = useState({});
  const LoanCaseId = JSON.parse(localStorage.getItem("LoanCaseId"));
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/master/getLoanCaseById?loanCaseId=${LoanCaseId}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response :", response.data);
        SetViewCase(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [LoanCaseId]);
  const navigateToPrevious = () => {
    navigate(`/viewLoanCase/${LoanCaseId}`);
  };
  return (
    <Fragment>
      {isSubmitted ? (
        navigate(`/viewLoanCase/${LoanCaseId}`) &
        localStorage.removeItem("LoanCaseId") &
        toast.success("Current Address Updated successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        })
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <div className="card">
                <form onSubmit={SubmitForm}>
                  <div className="card-header bg-primary d-flex justify-content-Start pb-1 pt-1 loanCaseCardHeader">
                    <span id="assignowner1">
                      {viewCase?.customerName} - {viewCase.id}
                    </span>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end address">
                          Current Address
                        </span>
                      </div>
                      <div className="col-md-8 mt-2"></div>
                      <div className="col-md-3 mt-4">
                        <span className="d-flex justify-content-end">
                          Address Line 1
                        </span>
                      </div>
                      <div className="col-md-6 mt-3">
                        <input
                          type="text"
                          name="cAddressLine1"
                          className="form-control sm"
                          placeholder="Enter Address Line 1"
                          value={customerData.cAddressLine1}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">
                          Address Line 2
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="cAddressLine2"
                          className="form-control sm"
                          placeholder="Enter Address Line 2"
                          value={customerData.cAddressLine2}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">
                          Landmark
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="cLandmark"
                          className="form-control sm"
                          placeholder="Enter Landmark"
                          value={customerData.cLandmark}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end  ">
                          Country
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <Select
                          id="correspondsCountry"
                          name="correspondsCountry"
                          className="countrydrp"
                          onChange={ddlChangeCorrespondsCountry}
                          value={selectedCorrespondsVal}
                          options={updatedCorrespondsCountries}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end  ">
                          State
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <Select
                          name="correspondState"
                          id="correspondState"
                          className="countrydrp"
                          value={selectedValCorrespondsstate}
                          options={updatedCorrespondsStates(
                            customerData.correspondCountryId
                              ? customerData.correspondCountryId
                              : null
                          )}
                          onChange={ddlChangeCorrespondsState}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end  ">
                          City
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <Select
                          id="correspondCity"
                          className="countrydrp"
                          name="correspondCity"
                          options={updatedCorrespondsCities(
                            customerData.correspondStateId
                              ? customerData.correspondStateId
                              : null
                          )}
                          value={selectedValCorrespondscity}
                          onChange={ddlChangeCorrespondsCity}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">PIN</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="number"
                          name="cPIN"
                          className="form-control sm"
                          placeholder="Enter PIN"
                          value={customerData.cPIN}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3"></div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="row">
                      <div className="col-md-3"></div>
                      <div className="col-md-6 my-1">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                          <Button
                            color="secondary"
                            className="previousButton"
                            onClick={navigateToPrevious}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            type="submit"
                            className="nextButton"
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

import React, { Fragment,useState,useEffect } from 'react'
import axios from 'axios';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import Lead from '../DashboardAnalytics/Lead';
import { use } from 'i18next';
export default function CaseDetails({handleNext,addLeadDetails,handleBack,leadData,setLeadData,familyMember,setFamilyMember}) {
    const Initiator = JSON.parse(localStorage.getItem('user'));
    const initID =Initiator.userID;
    const [characterLimit] = useState(300);

    const [leadOwners, setLeadOwners] = useState([]);
    useEffect(() => {
      const d1 = `https://pradhanportal.com/loanApplication/master/getAllOwner`;
      axios
        .get(d1)
        .then((response) => {
          console.log("response :", response.data);
  
          setLeadOwners(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);




    const [status, setStatus] = useState("");
    useEffect(() => {
      const d6 = `https://pradhanportal.com/loanApplication/master/getAllGdStatus`;
      axios
        .get(d6)
        .then((response) => {
          console.log("response :", response.data);
  
          setStatus(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);
    const [user, setUser] = useState({});
    useEffect(() => {
      const d6 = 'https://pradhanportal.com/loanApplication/master/getAllUsers';
      axios
        .get(d6)
        .then((response) => {
          console.log("response :", response.data);
  
          setUser(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);
    const initiID = leadData?.initiatorVO?.initiatorID;
    console.log("initiIDi", initiID);

    const ownerID = leadData?.ownerVO?.ownerID;
  console.log("ownerID", ownerID);
  const [getOwner, setGetOwner] = useState("");
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getOwnerById?ownerID=${ownerID}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);

        setGetOwner(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ownerID]);
  console.log(getOwner);
const usrId=leadData.userVO?.userId;
console.log("userIDDDD",usrId);
const [usr, setUsr] = useState({});
useEffect(() => {
  const d6 = `https://pradhanportal.com/loanApplication/master/getUserById?userId=${usrId}`;
  axios
    .get(d6)
    .then((response) => {
      console.log("response :", response.data);

      setUsr(response.data);
    })
    .catch((err) => {
      console.log(err);
    });
}, [usrId]);
   

    const [banks,setBanks] = useState({});
    useEffect(() => {
      const d6 = 'https://pradhanportal.com/loanApplication/master/getAllBank';
      axios
        .get(d6)
        .then((response) => {
          console.log("Bank List response :", response.data);
  
          setBanks(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);
    const bnkId= leadData.bankVO.bankId;
    console.log("BankID",bnkId);
    const [bankBranch, setBankBranch] = useState({});
    useEffect(() => {
      const d6 = `https://pradhanportal.com/loanApplication/master/getAllBranchByBankId?bankId=${bnkId}`;
      axios
        .get(d6)
        .then((response) => {
          console.log("response :", response.data);
  
          setBankBranch(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, [bnkId]);
    console.log("bankBranch",bankBranch);
    const handleInputChange =(e)=>{
        setLeadData({
         ...leadData,
         [e.target.name]:e.target.value
        });
     };
const[allFmData,setAllFmData]=useState([])

     const handleChangeForMember =(e)=>{
      setFamilyMemberData({
        ...familyMemberData,
        [e.target.name]:e.target.value,
       
      })
      setAllFmData(familyMemberData);
      console.log("allFmData",allFmData)
     }

     const handleChangeForMemberdob =(e)=>{
      setLeadData({
        ...leadData,
        familyMemberVO:{
          familyMemberdob:e.target.value
        }
      })
     }

     const handleChangeForMemberGender =(e)=>{
      setLeadData({
        ...leadData,
        familyMemberVO:{
          familyMembergender:e.target.value
        }
      })
     }


     const handleChangeForMemberPreviousDisease =(e)=>{
      setLeadData({
        ...leadData,
        familyMemberVO:{
          familyMemberPreviousDisease:e.target.value
        }
      })
     }

   


    const handleChangeForBank =(e)=>{
      setLeadData({
          ...leadData,
          bankVO: {
            bankId:e.target.value
            },
      })
    }
    const handleChangeForBankBranch =(e)=>{
      setLeadData({
          ...leadData,
          bankBranchVO: {
            bankBranchId:e.target.value
            },
      })
    }
    const handleChangeInputForOwner =(e)=>{
      setLeadData({
          ...leadData,
          userVO: {
            userId:e.target.value
            },
      })
    }
     const NavigateTOAssignModal = () => {
        toggle();
      };
      // Modal open state
  const [modal1, setModal1] = React.useState(false);
  const [error, setError] = useState(false);
  // Toggle for Modal
  const toggle = () => setModal1(!modal1);
  const char = leadData?.leadNotes.length;
  const submitFormData = (e) => {
    e.preventDefault();
    console.log("LeadData in CustomerDetails:::",leadData)
  
  
    handleNext()
  
 }

 const[familyMemberData,setFamilyMemberData]=useState({
    
  familyMemberCount:"",
  familyMemberName:"",
  familyMemberdob:"",
  familyMembergender:"",
  familyMemberPreviousDisease:""

})

  return (
    <div className="container">
      {/* <pre>{JSON.stringify(familyMemberData)}</pre> */}
       
        {
             leadData.leadTypeVO.leadTypeID === "2" && leadData.typesVO.typeId === "3" ||  leadData.typesVO.typeId === "5"? (
                <Fragment>
                    {
                         leadData.leadTypeVO.leadTypeID === "2" && leadData.typesVO.typeId === "3" ? (
                            <Fragment>
<div className="row">
                      <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end  "
                          >
                            Looking For
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="lookingFor"
                            onChange={handleInputChange}
                            value={leadData.lookingFor}
                          >
                            <option value="">Please Select</option>
                            <option value="Individual">Individual</option>
                            <option value="Family">Family</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                       
                        <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end  "
                          >
                            Payment Mode
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="paymentMode"
                            onChange={handleInputChange}
                            value={leadData.paymentMode}
                          >
                            <option value="">Please Select</option>
                            <option value="Online">Single Premium</option>
                            <option value="Offline">Annual</option>
                            <option value="Offline">Quarterly Annual</option>
                            <option value="Offline">Monthly</option>
                          </select>
                        </div>
                      </div>
                            </Fragment>
                         ):null
                    }
                    {
                     leadData.leadTypeVO.leadTypeID === "2" && leadData.typesVO.typeId === "5" ? (
                        <Fragment>
                              <div className="row mt-4">
                        {/* <p id="heading2">Vehicle Details</p> */}
                        <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end "
                            id="mandatory"
                          >
                            RC Number
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="number"
                            name="rcNumber"
                            className="form-control"
                            placeholder="Enter  RC Number"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleInputChange}
                            value={leadData.rcNumber}
                          />
                        </div>
                      </div>
                        </Fragment>
                     ):null  
                    }
                    
                      <div className="row">
                      <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end"
                            id="mandatory"
                          >
                            Previous Policy
                          </span>
                        </div>
                        <div className="col-md-5 mt-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="previousPolicy"
                              id="inlineRadio24"
                              onChange={handleInputChange}
                              value="YES"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio24"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="previousPolicy"
                              id="inlineRadio23"
                              onChange={handleInputChange}
                              value="NO"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio23"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      {
                        leadData.previousPolicy === "YES" ?<Fragment>
                          <div className="row">
                          <div className="col-md-4 mt-4">
                            <span
                              className="d-flex justify-content-end"
                              id="mandatory"
                            >
                             Policy Cover
                            </span>
                          </div>
                          <div className="col-md-5 mt-3">
                            <input
                              type="number"
                              name="previousPolicyCover"
                              className="form-control"
                              placeholder="Enter Cover Amount"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={leadData.previousPolicyCover}
                              onChange={handleInputChange}
                            />
                          </div>
                          </div>
                          <div className="row">
                          <div className="col-md-4 mt-3">
                            <span
                              className="d-flex justify-content-end"
                              id="mandatory"
                            >
                             Policy Company
                            </span>
                          </div>
                          <div className="col-md-5 mt-2">
                            <input
                              type="text"
                              name="previousPolicyCompany"
                              className="form-control"
                              placeholder="Enter Policy Company Name"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              onChange={handleInputChange}
                              value={leadData.previousPolicyCompany}
                            />
                          </div>
                          </div>
                          <div className="row">
                      <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end"
                            id="mandatory"
                          >
                            NCB
                          </span>
                        </div>
                        <div className="col-md-5 mt-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="NCB"
                              id="inlineRadio25"
                              onChange={handleInputChange}
                              value="YES"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio25"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="NCB"
                              id="inlineRadio26"
                              onChange={handleInputChange}
                              value="NO"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio26"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                        </Fragment>:null
                      }
                      {
                         leadData.leadTypeVO.leadTypeID === "2" && leadData.typesVO.typeId === "3" ? (
                            <Fragment>
                        <div className="row">
                      <div className="col-md-4 mt-4">
                          <span
                            className="d-flex justify-content-end  "
                          >
                            Previous Disease
                          </span>
                        </div>
                        <div className="col-md-5 mt-3">
                        <textarea
                            type="number"
                            rows={3}
                            name="previousDiseaseOfCustomer1"
                            className="form-control"
                            placeholder="Enter Previous Disease"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleInputChange}
                            value={leadData.previousDiseaseOfCustomer1}
                          />
                        </div>
                      </div>
                            </Fragment>
                         ):null

                      }
                      
                </Fragment>
             ):null
        }
        {
             leadData.leadTypeVO.leadTypeID === "2" && leadData.typesVO.typeId === "4"?(
                <Fragment>
                    <div className="row">
                        {/* <p id="heading2">Property Details</p> */}
                        <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end "
                          >
                            Property Age
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="number"
                            name="propertyAge"
                            className="form-control"
                            placeholder="Enter  Property Age"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleInputChange}
                            value={leadData.propertyAge}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end "
                          >
                            Property Cost
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="number"
                            name="propertyCost"
                            className="form-control"
                            placeholder="Enter  Property Cost"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleInputChange}
                            value={leadData.propertyCost}
                          />
                        </div>
                        <div className="col-md-2 mt-2">
                          <span id="propertyCost">As per index-2</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end "
                          >
                            Previous Loan
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="number"
                            name="previousLoanAmount"
                            className="form-control"
                            placeholder="Enter  Previous Loan Amount"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleInputChange}
                            value={leadData.previousLoanAmount}
                          />
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end  "
                          >
                            Add On 
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="addOn"
                            onChange={handleInputChange}
                            value={leadData.addOn}
                          >
                            <option value="">Please Select</option>
                            <option value="Furniture">Furniture</option>
                            <option value="Interior">Interior</option>
                          </select>
                        </div>
                      </div>
                     {
                      leadData.addOn !== ""? <Fragment>
                         <div className="row">
                        <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end "
                          >
                            Add On Estimated Cost 
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="number"
                            name="addOnEstimatedCost"
                            className="form-control"
                            placeholder="Enter Add On Estimated Cost"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleInputChange}
                            value={leadData.addOnEstimatedCost}
                          />
                        </div>
                      </div>
                      <div className="row">
                        {/* <p id="heading2">Nominee Details</p> */}
                        <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end "
                            id="mandatory"
                          >
                            Nominee Name
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="text"
                            name="nomineeName"
                            className="form-control"
                            placeholder="Enter  Nominee Name"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleInputChange}
                            value={leadData.nomineeName}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Nominee DOB
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Enter Email ID"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            name="nomineedob"
                            onChange={handleInputChange}
                            value={leadData.nomineedob}
                          />
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end  "
                          >
                            Nominee Relation
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="nomineeRelation"
                            onChange={handleInputChange}
                            value={leadData.nomineeRelation}
                          >
                            <option value="">Please Select</option>
                            <option value="Online">Father</option>
                            <option value="Offline">Mother</option>
                            <option value="Offline">Spouse</option>
                            <option value="Offline">Brother </option>
                            <option value="Offline">Sister</option>
                            <option value="Offline">Son</option>
                            <option value="Offline">Daughter</option>
                          </select>
                        </div>
                      </div>
                      </Fragment>:null
                     }
                </Fragment>
            ):null
        }
        {
                        leadData.lookingFor === "Family" ? <Fragment>
                          <div className="row">
                          <div className="col-md-4 mt-3">
                          <p id="heading2">Family Details</p>
                          </div>
                          <div className="col-md-7 mt-4">
                          <a aria-hidden="true"className=" button mx-3 addKYC"  style={{cursor:"pointer"}} onClick={NavigateTOAssignModal}>Add More Family Details</a>
                          </div>
                          </div>
                         
                          <div className="row">
                          <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end "
                            id="mandatory"
                          >
                            Family Member
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="number"
                            name="familyMemberCount"
                            className="form-control"
                            placeholder="Enter Family Member"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleInputChange}
                            value={familyMember.familyMemberCount}
                          />
                        </div>
                          </div>
                          <div className="row">
                        
                        <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end "
                            id="mandatory"
                          >
                            Family Member Name
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="text"
                            name="familyMemberName"
                            className="form-control"
                            placeholder="Enter Family Member Name"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleChangeForMember}
                            value={familyMemberData.familyMemberName}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end "
                            id="mandatory"
                          >
                            Family Member DOB
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="date"
                            name="familyMemberdob"
                            className="form-control"
                            placeholder="Enter Family Member Name"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleChangeForMember}
                            value={familyMemberData.familyMemberdob}
                          />
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end"
                            id="mandatory"
                          >
                           Family Member Gender
                          </span>
                        </div>
                        <div className="col-md-5 mt-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="familyMembergender"
                              id="inlineRadio7"
                              onChange={handleChangeForMember}
                              value="Male"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio7"
                            >
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="familyMembergender"
                              id="inlineRadio8"
                              onChange={handleChangeForMember}
                              value="Female"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio8"
                            >
                              Female
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="familyMembergender"
                              id="inlineRadio9"
                              onChange={handleChangeForMember}
                              value="Other"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio9"
                            >
                              Other
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-4 mt-4">
                          <span
                            className="d-flex justify-content-end  "
                          >
                            Previous Disease
                          </span>
                        </div>
                        <div className="col-md-5 mt-3">
                        <textarea
                            type="number"
                            rows={3}
                            name="familyMemberPreviousDisease"
                            className="form-control"
                            placeholder="Enter Previous Disease"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleChangeForMember}
                            value={familyMemberData.familyMemberPreviousDisease}
                          />
                        </div>
                      </div>
                        </Fragment>:null
                       }


<Modal isOpen={modal1}  fade={true}   centered={true}>
                            <ModalHeader className="bg-primary d-flex justify-content-center pb-1 pt-1">
                            <span id="assignowner1">Add Family Details</span>
                            </ModalHeader>
                            <ModalBody>
                            <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12">
                        <div className="row">
                          </div>
                          <div className="row">
                        
                        <div className="col-md-5 mt-3">
                          <span
                            className="d-flex justify-content-end "
                            id="mandatory"
                          >
                            Family Member Name
                          </span>
                        </div>
                        <div className="col-md-7 mt-2">
                          <input
                            type="text"
                            name="familyMemberName"
                            className="form-control"
                            placeholder="Enter Family Member Name"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleChangeForMember}
                            value={familyMemberData.familyMemberName}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-5 mt-3">
                          <span
                            className="d-flex justify-content-end "
                            id="mandatory"
                          >
                            Family Member DOB
                          </span>
                        </div>
                        <div className="col-md-7 mt-2">
                          <input
                            type="date"
                            name="familyMemberdob"
                            className="form-control"
                            placeholder="Enter Family Member Name"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleChangeForMember}
                            value={familyMemberData.familyMemberdob}
                          />
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-5 mt-3">
                          <span
                            className="d-flex justify-content-end"
                            id="mandatory"
                          >
                           Family Member Gender
                          </span>
                        </div>
                        <div className="col-md-7 mt-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="familyMemberGender"
                              id="inlineRadio7"
                              onChange={handleChangeForMember}
                              value="Male"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio7"
                            >
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="familyMemberGender"
                              id="inlineRadio8"
                              onChange={handleInputChange}
                              value="Female"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio8"
                            >
                              Female
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="familyMemberGender"
                              id="inlineRadio9"
                              onChange={handleInputChange}
                              value="Other"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio9"
                            >
                              Other
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-5 mt-4">
                          <span
                            className="d-flex justify-content-end  "
                          >
                            Previous Disease
                          </span>
                        </div>
                        <div className="col-md-7 mt-3">
                           <textarea
                            type="text"
                            name="familyMemberPreviousDisease"
                            className="form-control"
                            placeholder="Enter Previous Disease"
                            aria-label="Username"
                            rows={4}
                            aria-describedby="basic-addon1"
                            onChange={handleChangeForMember}
                            value={familyMemberData.familyMemberPreviousDisease}
                          />
                        </div>
                      </div>
                        </div>
                      </div>
                     
                     
                    </div>
                            </ModalBody>
                            <ModalFooter>
                              <div className="container">
                                <div className="row ">
                                  <div className="col-md-12">
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                                      <Button
                                        color="secondary"
                                        className='previousButton'
                                        onClick={toggle}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        color="primary"
                                        type="submit"
                                        className='nextButton'
                                      >
                                        Add Family Details
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ModalFooter>
                          </Modal>
                       

               
              
                    {
                leadData.leadTypeVO.leadTypeID === "1" ? (
                  <div className="row">
                  {/* <p id="heading2">Bank Details</p> */}
                  <div className="col-md-4 mt-3">
                            <span
                              className="d-flex justify-content-end  "
                            >
                              Bank
                            </span>
                          </div>
                          <div className="col-md-5 mt-2">
                            <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="bankVO.bankId"
                              onChange={handleChangeForBank}
                              value={leadData.bankVO?.bankId ==="" ? leadData.bankVO.bankId = banks.bankId : leadData.bankVO?.bankId}
                            >
                              <option value="">Please Select</option>
                              {banks?.length > 0 ? (
                                <Fragment>
                                  {banks.map((bank, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={bank?.bankId}
                                      >
                                        {bank?.bankName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                            </select>
                          </div>
                          <div className="col-md-4 mt-3">
                            <span
                              className="d-flex justify-content-end  "
                            >
                              Branch
                            </span>
                          </div>
                          <div className="col-md-5 mt-2">
                            <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="bankBranchVO.bankBranchId"
                              onChange={handleChangeForBankBranch}
                              value={leadData.bankBranchVO?.bankBranchId ==="" ? leadData.bankBranchVO.bankBranchId = bankBranch.bankBranchId : leadData.bankBranchId?.bankBranchId}
                            >
                              <option value="">Please Select</option>
                              {bankBranch?.length > 0 ? (
                                <Fragment>
                                  {bankBranch.map((branch, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={branch?.bankBranchId}
                                      >
                                        {branch?.bankBranchName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                            </select>
                          </div>
                  </div>
                ):null
               }
                {/* </Fragment>
            ):null
        } */}
         <div className="row mt-4">
                          <div className="col-md-12 d-flex justify-content-center">
                          <button
                          className="btn btn-secondary  previousButton"
                          onClick={handleBack}
                        >
                          Previous
                        </button>
                        <button onClick={submitFormData}
                        className="btn btn-primary nextButton"
                        >
                          Next
                        </button>
                          </div>
                        </div>
    </div>
  )
}

import React, { Fragment, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import pfslogo from "../../assets/images/pradhanFinservLogo_full_color.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
export default function ResetPassword() {
  const navigate = useNavigate();
const[inputError,setInputError]=useState();
  const navigateEnterCode = () => {
    navigate("/vefifyCode");
  };
const[formError,setFormError]=useState({
  password:'',
  confirmPassword:'',
})
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitted, setSubmitted]=useState(false);
  const[data,setDAta]=useState({
    emailID:"",
    password:'',
  })
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const emailId = JSON.parse(localStorage.getItem('userEmail'));
  data.emailID=emailId;
  data.password=password;
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform password validation logic here
    if (password === confirmPassword) {
      // alert("Password and Confirm Password match!");
      axios.put('/login/resetPassword',data).then((response)=>{
            console.log(response);
            setSubmitted(true);
            localStorage.removeItem("userEmail");
          }).catch((err)=>{
            console.error(err);
          });
    } else {
      alert("Password and Confirm Password do not match!");
    }
  };
  return (
    <Fragment>
       {
       isSubmitted ? navigate('/login') &
       toast.success('Password Reset successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12">
            <div className="row mt-5">
              <div className="col-md-1"></div>
              <div className="col-md-11">
                <img
                  src={pfslogo}
                  alt=""
                  height="73"
                  width="195"
                  className="logo"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <form onSubmit={handleSubmit}>
                  <div className="card resetPasswordcard">
                    <div className="card-header bg-primary">
                      <span className="passforgotcardheader">
                        Reset your password
                      </span>
                    </div>
                    <div className="card-body">
                      <div>
                        <span>Please set a new password for your account.</span>
                      </div>
                      <div className="mt-4">New Password</div>
                      <div className="mt-2">
                        <input
                          className="form-control"
                          required
                          placeholder="Enter Password minimum 8 to maximum 14 characters"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={handlePasswordChange}
                        />
                        <i
                          onClick={toggleShowPassword}
                          className="showHidePassword"
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </i>
                      </div>
                      <div>
                        <span>
                          Use 8 or more characters maximum with a mix of
                          letters, numbers & symbols.
                        </span>
                      </div>
                      <div className="mt-4">Confirm Password</div>
                      <div className="mt-2">
                        <input
                        required
                          className="form-control"
                          placeholder="Confirm New Password"
                          type={showConfirmPassword ? "text" : "password"}
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                        />
                        <i
                          onClick={toggleShowConfirmPassword}
                          className="showHidePassword2"
                        >
                          {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                        </i>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                          <div className="mt-2">
                            <button
                              className="btn btn-primary btn-md"
                              type="submit"
                              id="password-addon"
                            >
                              {" "}
                              Set Password{" "}
                            </button>
                          </div>
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
      </div>
}
    </Fragment>
  );
}

import React,{Fragment} from 'react';
import { Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap" ;
export default function AddBranches() {
  return (
    <Fragment>
        <div className="container">
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-header bg-primary d-flex justify-content-center cardHeader">
                        <span id="assignowner1">Add New Branch</span>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Branch Name</span>
                      </div>
                      <div className="col-md-5 mt-2">
                        <input
                          type="text"
                          name="loanTask"
                          className="form-control sm"
                          placeholder="Enter Branch Name"
                        /> 
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-4 mt-4">
                        <span className="d-flex justify-content-end">City Name</span>
                      </div>
                      <div className="col-md-5 mt-3">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                        >
                          <option selected>Please Select</option>
                          </select>
                          </div>
                          </div>
                        </div>
                            <div className="row mt-3 mb-3">
                        <div className="col-md-12">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  className="cancelButton" onClick={''}>Cancel</Button>
                        <Button color="primary" type="submit" className="submitButton">Add Branch</Button>
                        </div>
                        </div>
                            </div>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
    </Fragment>
  )
}

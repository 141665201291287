import React,{Fragment} from 'react';
import { useState,useEffect } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
const initialValues={
  loanCaseID:'',
  applicantName:'',
  gender:'',
  dob:'',
  type:'',
  employmentType:{
    empTypeID:'',
  },
  residentType:{
    residentTypeID:'',
  },
  religion:{
    religionID:'',
  },
  caste:{
    casteID:'',
  },

  qualification:{
    qualificationID:'',
  },
  qualifyingYear:'',
  maritalStatus:{
    maritalID:'',
  },
  dependents:'',
  noOfChildrens:'',
  spouseName:'',
  spouseDob:'',
  
logNotes:'',
}
export default function AddApplicant() {
  const[isApplicant,setIsApplicant]=useState(false);
  const[isCoApplicant,setISCoApplicant]=useState(false);
  const navigate = useNavigate();
  const [isSubmitted, setSubmitted]=useState(false);
  const year = (new Date()).getFullYear();
  const years = Array.from(new Array(60),( val, index) =>  year-index);
  const {LoanCaseId}=useParams();
      console.log("loanCaseId",LoanCaseId);

      const navigateTOViewLoanCase =()=>{
        navigate(`/viewLoanCase/${LoanCaseId}`);
       }

      const {values,handleBlur,handleChange,handleSubmit,touched,errors}=useFormik({
        initialValues:initialValues,
        onSubmit:(values)=>{
          axios.post('https://pradhanportal.com/loanApplication/customer/addApplicant',values).then((response)=>{
            console.log(response);
            setSubmitted(true);
          }).catch((err)=>{
            console.error(err);
          });
        },
       });
       console.log(values);

       const[residentType,setResidentType]=useState([]);
       useEffect(()=>{
         const d1 =`https://pradhanportal.com/loanApplication/customer/getAllResidentType`;
         axios.get(d1).then((response)=>{
           console.log("response :",response.data)
       
           setResidentType(response.data);
         }).catch((err)=>{
            console.log(err);
         });
       },[]);
     
     
     
       const[empmentType,setempmentType]=useState([]);
       useEffect(()=>{
         const d1 =`https://pradhanportal.com/loanApplication/customer/getAllEmploymentType`;
         axios.get(d1).then((response)=>{
           console.log("response :",response.data)
       
           setempmentType(response.data);
         }).catch((err)=>{
            console.log(err);
         });
       },[]);
     
     
       const[religion,setReligion]=useState([]);
       useEffect(()=>{
         const d1 =`https://pradhanportal.com/loanApplication/customer/getAllReligion`;
         axios.get(d1).then((response)=>{
           console.log("response :",response.data)
       
           setReligion(response.data);
         }).catch((err)=>{
            console.log(err);
         });
       },[]);
     
     
       const[caste,setCaste]=useState([]);
       useEffect(()=>{
         const d1 =`https://pradhanportal.com/loanApplication/customer/getAllCaste`;
         axios.get(d1).then((response)=>{
           console.log("response :",response.data)
       
           setCaste(response.data);
         }).catch((err)=>{
            console.log(err);
         });
       },[]);
     
     
       const[maritalStatus,setMaritalStatus]=useState([]);
       useEffect(()=>{
         const d1 =`https://pradhanportal.com/loanApplication/customer/getAllMaritalStatus`;
         axios.get(d1).then((response)=>{
           console.log("response :",response.data)
       
           setMaritalStatus(response.data);
         }).catch((err)=>{
            console.log(err);
         });
       },[]);

       const[qualification,setQualification]=useState([]);
  useEffect(()=>{
    const d1 =`https://pradhanportal.com/loanApplication/customer/getAllQualification`;
    axios.get(d1).then((response)=>{
      console.log("response :",response.data)
  
      setQualification(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);
  
  values.loanCaseID = LoanCaseId;



  
  return (
    <Fragment>
      {
       isSubmitted ? navigate(`/viewLoanCase/${LoanCaseId}`) &
       toast.success('Applicant Added successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
        <div className="container">
            <div className="row">
            {/* <div className="col-md-1"></div> */}
                <div className="col-md-12">
                   <div className="card">
                   <form  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}>
                    <div className="row">
                  <div className="col-md-9 mt-3">
                  <span className='customerDetails'>Applicant Details</span>
                   <div className="row">
                   <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end" id="mandatory">Type</span>
                        </div>
                        <div className="col-md-6 mt-3">
                        <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="type"
                              id="inlineRadio8"
                              onClick={()=>{setIsApplicant(true);setISCoApplicant(false)}}
                              onChange={handleChange}
                              checked={values.type === "Applicant"}
                              value="Applicant"
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio8"
                            >
                              Applicant
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="type"
                              id="inlineRadio9"
                              onClick={()=>{setIsApplicant(false);setISCoApplicant(true)}}
                              onChange={handleChange}
                              checked={values.type === "Co-Applicant"}
                              value="Co-Applicant"
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio9"
                            >
                              Co-Applicant
                            </label>
                          </div>
                        </div>
                        <div className="co-md-3"></div>
                        {
                          isApplicant &&
                          <Fragment>
                             <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end"id="mandatory">Applicant Name</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="applicantName"
                          onChange={handleChange}
                          value={values.applicantName}
                          onBlur={handleBlur}
                          className="form-control sm"
                          placeholder="FirstName , MiddleName ,LastName"
                        />
                      </div><div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end" id="mandatory">Gender</span>
                        </div>
                        <div className="col-md-6 mt-3">
                        <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio7"
                              onChange={handleChange}
                              checked={values.gender === "Male"}
                              value="Male"
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio7"
                            >
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio8"
                              onChange={handleChange}
                              value="Female"
                              checked={values.gender === "Female"}
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio8"
                            >
                              Female
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio9"
                              onChange={handleChange}
                              checked={values.gender === "Other"}
                              value="Other"
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio9"
                            >
                              Other
                            </label>
                          </div>

                        </div><div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end"id="mandatory">DOB</span>
                      </div>
                      <div  className="col-md-6 mt-2">
                        <input
                          type="date"
                          name="dob"
                          className="form-control sm"
                          placeholder="dd-mm-yy"
                          onChange={handleChange}
                          value={values.dob}
                          onBlur={handleBlur}
                        />
                      </div><div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Employment Type</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='employmentType.empTypeID'
                          onChange={handleChange}
                          value={values.employmentType?.empTypeID}
                          onBlur={handleBlur}
                        >
                          <option selected>Please Select</option>
                          {
                            empmentType?.length > 0 ? <Fragment>
                              {
                                empmentType.map((emp,index)=>{
                                  return(
                                    <option value={emp?.empTypeID}key={index}>{emp.empType}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Resident Type</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name="residentType.residentTypeID"
                          onChange={handleChange}
                          value={values.residentType?.residentTypeID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            residentType?.length > 0 ? <Fragment>
                              {
                                residentType.map((resident,index)=>{
                                  return(
                                    <option value={resident?.residentTypeID}key={index}>{resident.residentType}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Religion</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='religion.religionID'
                          onChange={handleChange}
                          value={values.religion?.religionID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            religion?.length > 0 ? <Fragment>
                              {
                                religion.map((type,index)=>{
                                  return(
                                    <option value={type?.religionID}key={index}>{type.religionName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Caste</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='caste.casteID'
                          onChange={handleChange}
                          value={values.caste?.casteID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            caste?.length > 0 ? <Fragment>
                              {
                                caste.map((type,index)=>{
                                  return(
                                    <option value={type?.casteID}key={index}>{type.casteName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="row">
                    <p className='educationDetails'>Education Details</p>
                    <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Highest Qualification</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='qualification.qualificationID'
                          onChange={handleChange}
                          value={values.qualification?.qualificationID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            qualification?.length > 0 ? <Fragment>
                              {
                                qualification.map((type,index)=>{
                                  return(
                                    <option value={type?.qualificationID}key={index}>{type.qualification}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div><div className='col-md-3'></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Qualifying Year</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='qualifyingYear'
                          onChange={handleChange}
                          value={values.qualifyingYear}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          
     {
       years.map((year, index) => {
         return <option key={`year${index}`} value={year}>{year}</option>
       })
     }
    
                        </select>
                          </div><div className='col-md-3'></div>
                          
                    </div>
                    <div className="row">
                    <p className='familyDetails mt-3'>Family Details</p>
                    <div className="col-md-3 mt-1">
                      <span className="d-flex justify-content-end  ">Marital Status</span>
                          </div>
                          <div className="col-md-6">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='maritalStatus.maritalID'
                          onChange={handleChange}
                          value={values.maritalStatus?.maritalID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            maritalStatus?.length > 0 ? <Fragment>
                              {
                                maritalStatus.map((status,index)=>{
                                  return(
                                    <option value={status?.maritalID}key={index}>{status.maritalStatus}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div><div className='col-md-3'></div>
                          <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">No of Dependents</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="dependents"
                          className="form-control sm"
                          placeholder="Enter No of Dependents"
                          onChange={handleChange}
                          value={values.dependents}
                          onBlur={handleBlur}
                        />
                      </div><div className='col-md-3'></div>
                     {
                      values.maritalStatus?.maritalID=== '1'? <Fragment>
                        <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">No of Children</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="noOfChildrens"
                          className="form-control sm"
                          placeholder="Enter No of Children"
                          onChange={handleChange}
                          value={values.noOfChildrens}
                          onBlur={handleBlur}
                        />
                      </div><div className='col-md-3'></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Spouse Name</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="spouseName"
                          className="form-control sm"
                          placeholder="Enter Spouse Name"
                          onChange={handleChange}
                          value={values.spouseName}
                          onBlur={handleBlur}
                        />
                      </div><div className='col-md-3'></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Spouse DOB</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="date"
                          name="spouseDob"
                          className="form-control sm"
                          placeholder="dd-mm-yy"
                          onChange={handleChange}
                          value={values.spouseDob}
                          onBlur={handleBlur}
                        />
                      </div><div className='col-md-3'></div>
                      </Fragment>:null
                     }
                    </div>
                    <div className="row">
                   <p className='logNotes'>Log Notes</p>
                      <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end">Log Notes</span>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                              <textarea
                              name="logNotes"
                              className="form-control"
                              maxLength="300"
                              rows={3}
                              value={values.logNotes}
                              onChange={handleChange}
                             />
                            </div>
                            {/* <div className=" d-md-flex justify-content-md-end"> Remaining Characters:  {300-char}/{characterLimit} </div> */}
                        </div> <div className='col-md-3'></div>
                   </div>
                  <div className="row mt-3 mb-3">
                      <div className="col-md-10">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                          <button
                          onClick={''}
                            class="btn btn-secondary me-md-2"
                            id="Cancelbtn1"
                            type="button"
                          >
                            Cancel
                          </button>
                          <button class="btn btn-primary" type="submit" id="Cancelbtn2">
                           Add Applicant
                          </button>
                        </div>
                      </div>
                    </div>
                          </Fragment>
                        }
                  
                  {
                          isCoApplicant &&
                          <Fragment>
                            <div className="col-md-3 mt-1">
                        <span className="d-flex justify-content-end"id="mandatory">Applicant Name</span>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="applicantName"
                          onChange={handleChange}
                          value={values.applicantName}
                          onBlur={handleBlur}
                          className="form-control sm"
                          placeholder="FirstName , MiddleName ,LastName"
                        />
                      </div><div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end" id="mandatory">Gender</span>
                        </div>
                        <div className="col-md-6 mt-3">
                        <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio7"
                              onChange={handleChange}
                              checked={values.gender === "Male"}
                              value="Male"
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio7"
                            >
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio8"
                              onChange={handleChange}
                              value="Female"
                              checked={values.gender === "Female"}
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio8"
                            >
                              Female
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio9"
                              onChange={handleChange}
                              checked={values.gender === "Other"}
                              value="Other"
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio9"
                            >
                              Other
                            </label>
                          </div>

                        </div><div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end"id="mandatory">DOB</span>
                      </div>
                      <div  className="col-md-6 mt-2">
                        <input
                          type="date"
                          name="dob"
                          className="form-control sm"
                          placeholder="dd-mm-yy"
                          onChange={handleChange}
                          value={values.dob}
                          onBlur={handleBlur}
                        />
                      </div><div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Employment Type</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='employmentType.empTypeID'
                          onChange={handleChange}
                          value={values.employmentType?.empTypeID}
                          onBlur={handleBlur}
                        >
                          <option selected>Please Select</option>
                          {
                            empmentType?.length > 0 ? <Fragment>
                              {
                                empmentType.map((emp,index)=>{
                                  return(
                                    <option value={emp?.empTypeID}key={index}>{emp.empType}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Resident Type</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name="residentType.residentTypeID"
                          onChange={handleChange}
                          value={values.residentType?.residentTypeID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            residentType?.length > 0 ? <Fragment>
                              {
                                residentType.map((resident,index)=>{
                                  return(
                                    <option value={resident?.residentTypeID}key={index}>{resident.residentType}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Religion</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='religion.religionID'
                          onChange={handleChange}
                          value={values.religion?.religionID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            religion?.length > 0 ? <Fragment>
                              {
                                religion.map((type,index)=>{
                                  return(
                                    <option value={type?.religionID}key={index}>{type.religionName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Caste</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='caste.casteID'
                          onChange={handleChange}
                          value={values.caste?.casteID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            caste?.length > 0 ? <Fragment>
                              {
                                caste.map((type,index)=>{
                                  return(
                                    <option value={type?.casteID}key={index}>{type.casteName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="row">
                    <p className='educationDetails'>Education Details</p>
                    <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Highest Qualification</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='qualification.qualificationID'
                          onChange={handleChange}
                          value={values.qualification?.qualificationID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            qualification?.length > 0 ? <Fragment>
                              {
                                qualification.map((type,index)=>{
                                  return(
                                    <option value={type?.qualificationID}key={index}>{type.qualification}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div><div className='col-md-3'></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Qualifying Year</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='qualifyingYear'
                          onChange={handleChange}
                          value={values.qualifyingYear}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          
     {
       years.map((year, index) => {
         return <option key={`year${index}`} value={year}>{year}</option>
       })
     }
    
                        </select>
                          </div><div className='col-md-3'></div>
                          
                    </div>
                    <div className="row">
                    <p className='familyDetails mt-3'>Family Details</p>
                    <div className="col-md-3 mt-1">
                      <span className="d-flex justify-content-end  ">Marital Status</span>
                          </div>
                          <div className="col-md-6">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='maritalStatus.maritalID'
                          onChange={handleChange}
                          value={values.maritalStatus?.maritalID}
                          onBlur={handleBlur}
                        >
                          <option value="">Please Select</option>
                          {
                            maritalStatus?.length > 0 ? <Fragment>
                              {
                                maritalStatus.map((status,index)=>{
                                  return(
                                    <option value={status?.maritalID}key={index}>{status.maritalStatus}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div><div className='col-md-3'></div>
                          <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">No of Dependents</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="dependents"
                          className="form-control sm"
                          placeholder="Enter No of Dependents"
                          onChange={handleChange}
                          value={values.dependents}
                          onBlur={handleBlur}
                        />
                      </div><div className='col-md-3'></div>
                     {/* {
                      values?.maritalStatus?.maritalID=== '1'? <Fragment> */}
                        <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">No of Children</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="noOfChildrens"
                          className="form-control sm"
                          placeholder="Enter No of Children"
                          onChange={handleChange}
                          value={values.noOfChildrens}
                          onBlur={handleBlur}
                        />
                      </div><div className='col-md-3'></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Spouse Name</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="spouseName"
                          className="form-control sm"
                          placeholder="Enter Spouse Name"
                          onChange={handleChange}
                          value={values.spouseName}
                          onBlur={handleBlur}
                        />
                      </div><div className='col-md-3'></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Spouse DOB</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="date"
                          name="dependents"
                          className="form-control sm"
                          placeholder="dd-mm-yy"
                          onChange={handleChange}
                          value={values.dependents}
                          onBlur={handleBlur}
                        />
                      </div><div className='col-md-3'></div>
                      {/* </Fragment>:null
                     } */}
                    </div>
                    <div className="row">
                   <p className='logNotes'>Log Notes</p>
                      <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end">Log Notes</span>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                              <textarea
                              name="logNotes"
                              className="form-control"
                              maxLength="300"
                              rows={3}
                              value={values.logNotes}
                              onChange={handleChange}
                             />
                            </div>
                            {/* <div className=" d-md-flex justify-content-md-end"> Remaining Characters:  {300-char}/{characterLimit} </div> */}
                        </div> <div className='col-md-3'></div>
                   </div>
                  <div className="row mt-3 mb-3">
                      <div className="col-md-10">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                          <button
                            class="btn btn-secondary me-md-2"
                            className='previousButton'
                            type="button"
                            onClick={navigateTOViewLoanCase}
                          >
                            Cancel
                          </button>
                          <button class="btn btn-primary" type="submit" className='nextButton'>
                           Add Co-Applicant
                          </button>
                        </div>
                      </div>
                    </div>
                          </Fragment>
                        }
                        
                     
                        
                     
                          
                         
                   </div>
                    
                   
                   
                  </div>
                  
                 
                </div>
                    </form>
                   </div>
                </div>
            </div>
        </div>
}
    </Fragment>
  )
}

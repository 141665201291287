import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import Carousel from 'react-grid-carousel'
//redux
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { logInStart } from '../../store/actions';
import axios from 'axios';
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// actions

import logoLight from "../../assets/images/logo-light.png";
import bankimg from "../../assets/images/u18.png";
import userIcon from "../../assets/images/user.png";
import padlock from "../../assets/images/padlock.png";
import slideimg1 from "../../assets/images/imgslide1.jpg";
import slideimg2 from "../../assets/images/imgslide2.jpg";
import slideimg3 from "../../assets/images/imgslide3.jpg";
import pfslogo from "../../assets/images/pradhanFinservLogo_full_color.png";
import "../../assets/scss/pages/login.css"
import withRouter from '../../Components/Common/withRouter';


import { createSelector } from 'reselect';
const Login = () => {
const navigate = useNavigate();


  const [credentials, setCredentials] = useState({ username: "", password: "" });

  const dispatch = useDispatch();

//   const handleChange = (e) =>
//     setCredentials({ ...credentials, [e.target.name]: e.target.value });
console.log("credentials",credentials);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(logInStart(credentials,navigate));
  };
    return (
        <React.Fragment>
            
                <div className="auth-page-content">
                    <div className="container-fluid bg-light">
                        <div className="row">
                           
                            <div className="col-md-6 logincard">
                                <div className="card logincard1">
                            <div className="text-right text-white-100">
                                </div> 
                                <div className='row logomargin'>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-8 mt-5">
                                    <div>
                                        
                                            <img src={pfslogo} alt="" height="73" width="195" className='logo' />
                                        
                                    </div>
                                    <p className="mt-4 loginaccount">Login into your account</p>
                                            <div className="text-center ">
                                        </div>
                                        {/* {errorMsg && errorMsg ? (<Alert color="danger"> {errorMsg} </Alert>) : null} */}
                                        <div className="">
                                            <div className="form">
                                            <Form
                                            onSubmit={handleSubmit}
                                               
                                                action="#">

                                                <div className=" mt-4">
                                              <div className="input-group mb-3 input-group-lg ">
                                              <span className="input-group-text input-group-lg" id="basic-addon1"><img src={userIcon}   width="20" height='15'alt='' /></span>
                                              <input type="text" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"
                                            
                                            name="username"
                                            value={credentials.username}
                                            onChange={(e) =>
                                                setCredentials({ ...credentials, username: e.target.value })
                                              }
                                   required
                                             />
                                            </div>
                                                    {/* {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null} */}
                                                </div>

                                                <div className="mt-4">
                                                    
                                               
                                                {/* <Label className="form-label" htmlFor="password-input">Password</Label> */}
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                     
                                                          <div className="input-group mb-3 input-group-lg">
                                              <span className="input-group-text input-group-lg" id="basic-addon1"><img src={padlock}   width="20" height='15'alt='' /></span>
                                              <input  
                                               name="password"
                                              className="form-control pe-5"
                                              placeholder="Enter Password"
                                  type='password'
                                  value={credentials.password}
                                  onChange={(e) =>
                                    setCredentials({ ...credentials, password: e.target.value })
                                  }
                                   required
                                             />
                                            </div>
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"
                                                        //  onClick={() => setPasswordShow(!passwordShow)}
                                                         > <i className="ri-eye-fill align-middle"></i> </button>
                                                    </div>
                                                    <div className="row mt-4">
                                                    <div className="col-md-4  ">
                                                    <div className=" mr-5">
                                                   
                                                    <Button
                                variant=""
                                type='submit'
                                className="btn btn-primary btn-block"
                                
                              >
                                Sign In
                              </Button>
                              {/* <p className={userValidEroor ? "show lablered":"hidedata1"} style={{textAlign:"center"}}>Invalid User</p> */}
                                                </div>
                                                    </div>
                                                </div>

                                                    <div className="float-start mt-4">
                                                        Forgot password ?
                                                        <Link to="/ForgetPWD" className="passReset text-primary mx-2">Reset</Link>
                                                    </div>
                                                </div>                   
                                               
                                            </Form> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                               </div>
                            </div>
                            
                            <div className="col-md-6">
                               <div className="row">
                                <div className="col-md-12">
                                <div className="card logincard2">
                               <div className="row">
                                    <div className="col-md-12 ">

                                    <Carousel cols={1} rows={1} gap={10} loop autoplay={4000} hideArrow={true} style={{height:"100%"}} >
                                    <Carousel.Item>
                                    <div className="row logincard">
                                    <img width="780" height='400' src={bankimg}  style={{ cursor: "pointer"}} />
                                    </div> 
                                    <div className="row">
                               <div className="card">
                               <div className="col-md-12 mt-4 text-center">
                                    <span className='hdline'>News Headline</span>
                           <p className='mt-4'>
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascet ur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.
                           </p>
                                    </div>
                               </div>
                                </div>  
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <div className="row logincard">
                                    <img width="780" height='400' src={slideimg1}  style={{ cursor: "pointer"}} />
                                    </div> 
                                    <div className="row">
                               <div className="card">
                               <div className="col-md-12 mt-4 text-center">
                                    <span className='hdline'>News Headline</span>
                           <p className='mt-4'>
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascet ur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.
                           </p>
                                    </div>
                               </div>
                                </div>  
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <div className="row">
                                    <img width="780" height='400' src={slideimg2}  style={{ cursor: "pointer"}} />
                                    </div> 
                                    <div className="row">
                               <div className="card">
                               <div className="col-md-12 mt-4 text-center">
                                    <span className='hdline'>News Headline</span>
                           <p className='mt-4'>
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascet ur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.
                           </p>
                                    </div>
                               </div>
                                </div> 
                                    </Carousel.Item>
                                    </Carousel>
                                    
                                    </div>
                                </div>
                               </div>
                                </div>
                               </div>
                              
                           </div>
                        </div>
                    </div>
                        
                </div>
           
        </React.Fragment>
    );
};

export default withRouter(Login);
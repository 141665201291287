import axios from 'axios';
import { all, call, put, takeLatest,takeEvery} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { logInFailure, logInSuccess, registerFailure, registerSuccess } from "./action";
import types from './actionTypes';
import {useNavigate } from 'react-router-dom';
const logIn = async (payload) => {
  
    const data=payload?.credentials;
    const navigate=payload?.navigate;
    console.log("Login data",data)
    console.log("navigate 2 : ",navigate)

  const resp = await axios.post("https://pradhanportal.com/loanApplication/login/userAuthentication",data).then((response)=>{
    console.log(response);
    if(response.code ===200){
       // Assign a value to locastorage
       //sessionStorage.removeItem("authUser");
       localStorage.setItem('user',JSON.stringify(response.data))
       console.log("response.data :",response.data)
       sessionStorage.setItem("authUser",JSON.stringify(response.data));
    // navigate('/dashboard');
    
    navigate('/dashboard');
    }
    else{
      navigate('/login');
        return response;
    }
   
   
  }).catch((err)=>{
    console.error(err);
  });
  
  return resp;
  
};
 
const NavigateToLogin = () =>{
    const navigate = useNavigate();
    navigate("/dashboard");
}
const register = async (username, password) => {
  await axios.post("/login", {
    username,
    password,
  });
};

export function* LogInWithCredentials(data) {
  console.log("payload?.credentials 1 1 : ",data?.payload)
  console.log("payload?.type 1 1 : ",data?.type)
  try {
    const user = yield logIn(data?.payload);
    yield put(logInSuccess(user));
    // navigate('/navigate');
    // yield put(push('/dashboard'));
    // yield call(NavigateToLogin)
  } catch (error) {
    yield put(logInFailure(error));
  }
  }

export function* registerWithCredentials({ payload: { username, password } }) {
  try {
    yield register(username, password);
    yield put(registerSuccess({ username, password }));
  } catch (error) {
    yield put(registerFailure(error));
  }
}

function* logoutUser() {
  try {
    sessionStorage.removeItem("authUser");
    localStorage.clear();
  } catch (error) {
    console.error(error);
  }
}

export function* logInAfterRegister({ payload: { username, password } }) {
  yield LogInWithCredentials({ payload: { username, password } });
}

export function* onLogInStart() {
  yield takeLatest(types.LOG_IN_START, LogInWithCredentials);
}

export function* onRegisterStart() {
  yield takeLatest(types.REGISTER_START, registerWithCredentials);
}

export function* onRegisterSuccess() {
  yield takeLatest(types.REGISTER_SUCCESS, logInAfterRegister);
}

export function* onLogOutSucess() {
  yield takeEvery(types.LOG_OUT, logoutUser);
}

export function* authSagas() {
  yield all([
    call(onLogInStart),
    call(onRegisterStart),
    call(onRegisterSuccess),
    call(onLogOutSucess),
  ]);
}
import React, { useState, useEffect, Fragment } from "react";
import { AutoComplete } from "primereact/autocomplete";
import axios from "axios";
export default function CustomerDetails({
  handleNext,
  addLeadDetails,
  handleBack,
  leadData,
  setLeadData,
}) {
  const [custTypes, setCustTypes] = useState([]);
  useEffect(() => {
    const d4 = `https://pradhanportal.com/loanApplication/master/getAllCustomerType`;
    axios
      .get(d4)
      .then((response) => {
        console.log("response :", response.data);

        setCustTypes(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleInputChange = (e) => {
    setLeadData({
      ...leadData,
      [e.target.name]: e.target.value,
    });
  };

  const [selectedOption, setSelectedOption] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");

  const onSelect = (event, option) => {
    console.log("onselect  option:", option);
    console.log("onselect event:", event.value);
    setSelectedOption(event.value);
    setInputValue(event.value.customerName);
    leadData.customerName = event.value.customerName;
    leadData.customerId = event.value.customerId;
  };

  const onChange = (event, option) => {
    console.log("onChnage :", event.target.value);
    setInputValue(event.target.value);
    setSelectedOption(option); // to remove selected option when user types  something wich doesn't match with any option
    leadData.customerName = event.target.value;
    leadData.customerId = event.value.customerId;
  };

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [filteredCountries, setFilteredCountries] = useState(null);

  const search = (event) => {
    // Timeout to emulate a network connection
    setTimeout(() => {
      let _filteredCountries;

      if (!event.query.trim().length) {
        _filteredCountries = [...countries];
      } else {
        _filteredCountries = countries.filter((country) => {
          return country.customerName
            ?.toLowerCase()
            .startsWith(event.query?.toLowerCase());
        });
      }

      setFilteredCountries(_filteredCountries);
    }, 250);
  };

  useEffect(() => {
    // CountryService.getCountries().then((data) => setCountries(data));
    PopulatUser();
  }, []);

  console.log("filteredCountries", filteredCountries);
  console.log("selectedCountry", selectedCountry);

  const [loanTasks, setloanTasks] = useState();
  const PopulatUser = async () => {
    const result = await axios.get(
      `https://pradhanportal.com/loanApplication/customer/getAllCustomer`
    );
    setCountries(result.data);
    console.log("autofetchuser", result.data);
  };

  const loadLead = async () => {
    const result = await axios.get(
      `https://pradhanportal.com/loanApplication/customer/getAllCustomer`
    );
    //   setData(result.data);
    setloanTasks(result.data);
  };

  const [leadSource, setLeadSurce] = useState([]);
  useEffect(() => {
    const d3 = `https://pradhanportal.com/loanApplication/master/getAllLeadSource`;
    axios
      .get(d3)
      .then((response) => {
        console.log("response :", response.data);

        setLeadSurce(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [residentType, setResidentType] = useState([]);
  useEffect(() => {
    const d1 = `https://pradhanportal.com/loanApplication/master/getAllResidentType`;
    axios
      .get(d1)
      .then((response) => {
        console.log("response :", response.data);

        setResidentType(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChangeForResidentType = (e) => {
    setLeadData({
      ...leadData,
      residentTypeVO: {
        residentTypeID: e.target.value,
      },
    });
  };

  const handleChangeForLeadSource = (e) => {
    setLeadData({
      ...leadData,
      leadSourceVO: {
        leadSourceID: e.target.value,
      },
    });
  };
  const [error, setError] = useState(false);
  const submitFormData = (e) => {
    e.preventDefault();
    console.log("LeadData in CustomerDetails:::", leadData);
    if (
      (leadData.leadTypeVO.leadTypeID === "1" && leadData.customerType == "") ||
      leadData.customerType == null
    ) {
      setError(true);
    } else if (leadData.customerName == "") {
      setError(true);
    } else if (
      leadData.customerType !== "Non-Individual" &&
      leadData.typesVO.typeId !== "5" &&
      leadData.gender == ""
    ) {
      setError(true);
    } else if (
      leadData.customerType === "Individual" &&
      leadData.leadSourceVO.leadSourceID == ""
    ) {
      setError(true);
      // } else if (
      //   leadData.customerType === "Non-Individual" &&
      //   leadData.customerName === ""
      // ) {
      //   setError(true);
    } else if (
      leadData.customerType === "Non-Individual" &&
      leadData.contactPerson === ""
    ) {
      setError(true);
    } else if (
      leadData.customerType === "Non-Individual" &&
      leadData.leadSourceVO.leadSourceID == ""
    ) {
      setError(true);
    //}
    // else if(leadData.gender =="" || leadData.gender == null){
    //   setError(true)
    // }
    // else if (
    //   (leadData.leadTypeVO.leadTypeID === "2" &&
    //     leadData.typesVO.typeId === "3") ||
    //   leadData.typesVO.typeId === "4" ||
    //   (leadData.typesVO.typeId === "8" &&
    //     leadData.leadSourceVO.leadSourceID == "") ||
    //   leadData.leadSourceVO.leadSourceID == null
    // ) {
    //   setError(true);
    } else {
      handleNext();
    }
  };
  return (
    <div className="container">
      {leadData.leadTypeVO.leadTypeID === "1" ? (
        <div className="row">
          <div className="col-md-4 mt-4">
            <span className="d-flex justify-content-end" id="mandatory">
              Customer Type
            </span>
          </div>
          <div className="col-md-7 mt-4">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="customerType"
                id="inlineRadio51"
                checked={leadData.customerType === "Individual" ? true : false}
                onChange={handleInputChange}
                value="Individual"
              />
              <label className="form-check-label" for="inlineRadio51">
                Individual
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="customerType"
                id="inlineRadio52"
                onChange={handleInputChange}
                checked={
                  leadData.customerType === "Non-Individual" ? true : false
                }
                value="Non-Individual"
              />
              <label className="form-check-label" for="inlineRadio52">
                Non-Individual
              </label>
            </div>
            {error &&
            leadData.leadTypeVO.leadTypeID === "1" &&
            leadData.customerType == "" ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Customer Type is required.
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="col-md-4 mt-3">
          <span className="d-flex justify-content-end" id="mandatory">
            Customer Name
          </span>
        </div>
        <div className="col-md-7 mt-2">
          <AutoComplete
            inputStyle={{
              width: "449px",
              height: "35px",

              // background: '#e4f3f7',
              // border: " outset lightgray",
            }}
            panelStyle={{
              background: "#e4f3f7",
              boxShadow: "0 6px 12px rgba(0,0)",
            }}
            field="customerName"
            name="customerName"
            className="text-transform"
            value={leadData.customerName}
            suggestions={filteredCountries}
            completeMethod={search}
            onSelect={onSelect}
            onChange={onChange}
            placeholder="Enter Customer Name"
          />
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-7">
          {error && leadData.customerName == "" ? (
            <span style={{ color: "red", fontSize: "12px" }}>
              Customer Name is required.
            </span>
          ) : (
            ""
          )}{" "}
        </div>
        {leadData.customerType !== "Non-Individual" &&
        leadData.typesVO.typeId !== "5" ? (
          <Fragment>
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end" id="mandatory">
                Gender
              </span>
            </div>
            <div className="col-md-7 mt-3">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="inlineRadio7"
                  value="Male"
                  onChange={handleInputChange}
                  checked={leadData.gender === "Male" ? true : false}
                />
                <label className="form-check-label" for="inlineRadio7">
                  Male
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="inlineRadio8"
                  value="Female"
                  checked={leadData.gender === "Female" ? true : false}
                  onChange={handleInputChange}
                />
                <label className="form-check-label" for="inlineRadio8">
                  Female
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="inlineRadio9"
                  value="Other"
                  checked={leadData.gender === "Other" ? true : false}
                  onChange={handleInputChange}
                />
                <label className="form-check-label" for="inlineRadio9">
                  Other
                </label>
              </div>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-7">
              {error &&
              leadData.customerType !== "Non-Individual" &&
              leadData.typesVO.typeId !== "5" &&
              leadData.gender == "" ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  Gender is required.
                </span>
              ) : (
                ""
              )}
            </div>
          </Fragment>
        ) : null}

        <div className="col-md-4 mt-3">
          <span className="d-flex justify-content-end">Resident Type</span>
        </div>
        <div className="col-md-7 mt-2">
          <select
            style={{ width: "450px" }}
            class="form-select form-select-md"
            aria-label=".form-select-md example"
            name="residentTypeVO.residentTypeID"
            value={leadData.residentTypeVO?.residentTypeID}
            onChange={handleChangeForResidentType}
          >
            <option value="">Please Select</option>
            {residentType?.length > 0 ? (
              <Fragment>
                {residentType.map((resident, index) => {
                  return (
                    <option value={resident?.residentTypeID} key={index}>
                      {resident.residentType}
                    </option>
                  );
                })}
              </Fragment>
            ) : null}
          </select>
        </div>
        <div className="col-md-4 mt-3">
          <span className="d-flex justify-content-end " id="mandatory">
            Lead Source
          </span>
        </div>
        <div className="col-md-7 mt-2">
          <select
            style={{ width: "450px" }}
            class="form-select form-select-md"
            aria-label=".form-select-md example"
            name="leadSourceVO.leadSourceID"
            value={leadData.leadSourceVO?.leadSourceID}
            onChange={handleChangeForLeadSource}
          >
            <option value="">Please Select</option>
            {leadSource?.length > 0 ? (
              <Fragment>
                {leadSource.map((leadsource, index) => {
                  return (
                    <option value={leadsource.leadSourceID} key={index}>
                      {leadsource.leadSource}
                    </option>
                  );
                })}
              </Fragment>
            ) : null}
          </select>
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-7">
          {error && leadData.leadSourceVO.leadSourceID == "" ? (
            <span style={{ color: "red", fontSize: "12px" }}>
              Lead Source is required.
            </span>
          ) : (
            ""
          )}{" "}
        </div>
      </div>

      <div className="row">
        {leadData.customerType === "Non-Individual" ? (
          <Fragment>
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end">Director Name</span>
            </div>
            <div className="col-md-7 mt-2">
              <input
                style={{ width: "450px" }}
                type="text"
                className="form-control sm text-transform"
                placeholder="Enter Director Name"
                name="directorName"
                value={leadData.directorName}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end" id="mandatory">
                Contact Person
              </span>
            </div>
            <div className="col-md-7 mt-2">
              <input
                style={{ width: "450px" }}
                type="text"
                className="form-control sm"
                placeholder="Enter Contact Person Name"
                name="contactPerson"
                value={leadData.contactPerson}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-7">
              {error &&
              leadData.customerType === "Non_Individual" &&
              leadData.contactPerson == "" ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  Contact Person is required.
                </span>
              ) : (
                ""
              )}{" "}
            </div>
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end ">
                Contact Person Relation{" "}
              </span>
            </div>
            <div className="col-md-7 mt-2">
              <select
                style={{ width: "450px" }}
                class="form-select form-select-md"
                aria-label=".form-select-md example"
                name="contactPersonRelation"
                value={leadData.contactPersonRelation}
                onChange={handleInputChange}
              >
                <option value="">Please select</option>
                <option value="Accoutant">Accoutant</option>
                <option value="Manager">Manager</option>
                <option value="Personal Assistant">Personal Assistant</option>
                <option value="Admin">Admin</option>
              </select>
            </div>
          </Fragment>
        ) : null}
      </div>
      <div className="row mt-4">
        <div className="col-md-12 d-flex justify-content-center">
          <button
            className="btn btn-secondary  previousButton"
            onClick={handleBack}
          >
            Previous
          </button>
          <button
            onClick={submitFormData}
            className="btn btn-primary nextButton"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

import React,{Fragment} from 'react';
import Tooltip from "rc-tooltip";
import { Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap"
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import csc from "country-state-city";
import Select from "react-select";
import {  toast } from 'react-toastify';
import { useParams } from "react-router-dom";
export default function EditBankBranch() {
    const [isSubmitted, setSubmitted]=useState(false);
    const { bankBranchId } = useParams();
    const navigate = useNavigate();
    const[branchData,setBranchData]=React.useState({
      bank:{
        bankId:'',
      },
      bankBranchName:'',
      branchEmailID:'',
      bankManagerName:'',
      bankManagerPrimaryNumber:'',
      bankManagerSecondaryNumber:'',
      bankManagerEmailID:'',
      branchCode:'',
      dsaCode:'',
      country:'',
      countryId:'',
      state:'',
      stateId:'',
      cityId:'',
      city:'',
      pinCode:'',
    })
  
    useEffect(() => {
      const d6 = `https://pradhanportal.com/loanApplication/master/getBankBranchByBankBranchId?bankBranchId=${bankBranchId}`;
      axios
        .get(d6)
        .then((response) => {
          console.log("response :", response.data);
          setBranchData(response.data);
          setSelectedVal({"label":response?.data.country,"id":response?.data.countryId})
              setSelectedValstate({"label":response?.data.state,"id":response?.data.stateId})
              setSelectedValcity({"label":response?.data.city,"id":response?.data.cityId})
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);


    const ddlChange =(e)=>{
      setBranchData({...branchData, country:e.name,countryId:e.value});
        setSelectedVal({"label":e.name,"id":e.value})
        
      }
      const ddlChangeState =(e)=>{
        setBranchData({...branchData, state:e.name,stateId:e.value});
          setSelectedValstate({"label":e.name,"id":e.value})
          
        }
        const ddlChangeCity =(e)=>{
          setBranchData({...branchData, city:e.name,cityId:e.value});
            setSelectedValcity({"label":e.name,"id":e.value})
          }


          const [selectedVal, setSelectedVal] = useState({
            label:branchData.country,
            id:branchData.countryId
        });
        const [selectedValstate, setSelectedValstate] = useState({
          label:branchData.state,
          id:branchData.stateId
      });
      const [selectedValcity, setSelectedValcity] = useState({
          label:branchData.city,
          id:branchData.cityId
      });


   const [banks,setBanks] = useState({});
   useEffect(() => {
     const d6 = 'https://pradhanportal.com/loanApplication/master/getAllBank';
     axios
       .get(d6)
       .then((response) => {
         console.log("Bank List response :", response.data);
 
         setBanks(response.data);
       })
       .catch((err) => {
         console.log(err);
       });
   }, []);

   const handleChangeForBank =(e)=>{
    setBranchData({
        ...branchData,
        bank: {
          bankId:e.target.value
          },
    })
  }

  const handleChange=(e)=>{
    setBranchData({
        ...branchData,
       [e.target.name]:e.target.value
    })
   }

  const countries = csc.getAllCountries();
  console.log(csc.getAllCountries());

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country
  }));
  
  const updatedStates = (countryId) => csc.getStatesOfCountry(countryId)
      .map((state) => ({ label: state.name, value: state.id, ...state }));

  const updatedCities = (stateId) =>
    csc
      .getCitiesOfState(stateId)
      .map((city) => ({ label: city.name, value: city.id, ...city }));

    const handleSubmit=(e)=>{
      e.preventDefault();
      axios.put('https://pradhanportal.com/loanApplication/master/editBankBranch',branchData).then((response)=>{
          console.log(response);
          setSubmitted(true);
        }).catch((err)=>{
          console.error(err);
        });
    }
    const navigateToViewBankBranch=()=>{
      navigate(`/viewBankBranch/${bankBranchId}`)
    }

    const authUser = JSON.parse(localStorage.getItem("user"));

    var renderTooltip = <span>Delete Bank Branch</span>;
  
    const [modal1, setModal1] = React.useState(false);
    const toggle1 = () => setModal1(!modal1);
  
    const DeleteLead = () => {
      const dataUrl =`https://pradhanportal.com/loanApplication/master/deleteBankBranch?bankBranchId=${bankBranchId}`;
          axios.post(dataUrl).then((response)=>{
          
            console.log("response :",response.data);
            
            setBranchData(response.data);
          }).catch((err)=>{
             console.log(err);
          });
    navigate(`/bankBranchmgmt`);
    toast.success('Bank Branch Deleted Successfully !', {
      position: toast.POSITION.TOP_RIGHT
  });
  };
  return (
    <Fragment>
      {/* <pre>{JSON.stringify(branchData)}</pre> */}
         {
       isSubmitted ? navigate(`/viewBankBranch/${bankBranchId}`) &
       toast.success('Bank Branch Edited Successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
        <div className="container">
            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                   <form onSubmit={handleSubmit}>
                   <div className="card">
                        <div className="card-header bg-primary d-flex justify-content-center loanCaseCardHeader">
                        <span id="assignowner1">Edit Bank Branch</span>
                        </div>

                        {
            authUser.role?.role === "Local Admin" ? <Fragment>
            <Tooltip  placement="right" overlay={renderTooltip} >
                <h3 id="trashbtn">
                <i class="bi bi-trash  btn-lg" 
                
                onClick={toggle1}
                ></i>
            </h3>
            </Tooltip>
            </Fragment>:null
           }
            <Modal fade={true} isOpen={modal1} toggle={toggle1} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#f7b84b,secondary:#f06548"
            style={{ width: "100px", height: "100px" }}
          ></lord-icon>
          <span className='warningmodal'>Warning !</span>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-4 mb-0">
              Are you sure you want to remove this record ?
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={toggle1}
          >
            Close
          </button>
          <button
            type="button"
            className="btn w-sm btn-danger "
            id="delete-record"
            onClick={()=>{setBranchData(); DeleteLead()}}
          >
            Yes, Delete It!
          </button>
        </div>
      </ModalBody>
    </Modal>
                        <div className="card-body">
                          <div className="row mt-2">
                          <div className="col-md-4 mt-3">
                            <span
                              className="d-flex justify-content-end  "
                              id='mandatory'
                            >
                              Bank
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="bank.bankId"
                              onChange={handleChangeForBank}
                              value={branchData.bank?.bankId}
                            >
                              <option value="">Please Select</option>
                              {banks?.length > 0 ? (
                                <Fragment>
                                  {banks.map((bank, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={bank?.bankId}
                                      >
                                        {bank?.bankName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                            </select>
                          </div>
                            <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end"id='mandatory'>Branch Name</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="bankBranchName"
                          value={branchData.bankBranchName}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Branch Name"
                        /> 
                      </div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Branch Email ID</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="branchEmailID"
                          value={branchData.branchEmailID}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Branch Email ID"
                        /> 
                      </div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Branch Manager Name</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="bankManagerName"
                          value={branchData.bankManagerName}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Branch Manager Name"
                        /> 
                      </div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Branch Manager Primary Number</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="bankManagerPrimaryNumber"
                          value={branchData.bankManagerPrimaryNumber}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Branch Manager Primary Number"
                        /> 
                      </div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Branch Manager Secondary Number</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="bankManagerSecondaryNumber"
                          value={branchData.bankManagerSecondaryNumber}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Branch Manager Secondary Number"
                        /> 
                      </div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Branch Manager Email ID</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="bankManagerEmailID"
                          value={branchData.bankManagerEmailID}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Branch Manager Email ID"
                        /> 
                      </div>
                      <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end  ">Country</span>
            </div>
            <div className="col-md-6 mt-2">
              <Select
                id="country"
                name="country"
                className="countrydrp"
                onChange={ddlChange}
                value={selectedVal}
                options={updatedCountries}
              />
            </div>
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end  ">State</span>
            </div>
            <div className="col-md-6 mt-2">
              <Select
                name="state"
                id="state"
                className="countrydrp"
                value={selectedValstate}
                options={updatedStates(branchData.countryId ? branchData.countryId : null)}
                onChange={ddlChangeState}
              />
            </div>
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end  ">City</span>
            </div>
            <div className="col-md-6 mt-2">
              <Select
                //required
                id="city"
                className="countrydrp"
                name="city"
                options={updatedCities(branchData.stateId ? branchData.stateId : null)}
                value={selectedValcity}
                 onChange={ddlChangeCity}

              />
            </div>
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end ">PIN</span>
            </div>
            <div className="col-md-6 mt-2">
              <input
                type="number"
                name="pinCode"
                className="form-control"
                placeholder="Enter PIN Code"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleChange}
                value={branchData.pinCode}
              />
            </div>
                      <div className="col-md-3 mt-2"></div>
                          </div>
                        </div>
                            <div className="row mt-3 mb-3">
                        <div className="col-md-12">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  className="previousButton" onClick={navigateToViewBankBranch}>Cancel</Button>
                        <Button color="primary" type="submit" className="nextButton">Update</Button>
                        </div>
                        </div>
                        </div>
                    </div>
                   </form>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
}
    </Fragment>
  )
}


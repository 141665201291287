import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import csc from "country-state-city";
import Select from "react-select";
export default function EditPermanentAddress() {
  const [isSubmitted, setSubmitted] = useState(false);
  const { customerId } = useParams();
  const navigate = useNavigate();
  console.log("customerID", customerId);
  const [customerData, setCustomerData] = useState({
    prmntCountry: "",
    prmntCountryId: "",
    prmntCountryCode: "",
    prmntState: "",
    prmntStateId: "",
    prmntCityId: "",
    prmntCity: "",
    pAddressLine1: "",
    pAddressLine2: "",
    pLandmark: "",
    pPIN: "",
  });
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/customer/getCustomerById?customerId=${customerId}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response :", response.data);
        setCustomerData(response.data);
        setSelectedVal({
          label: response?.data.prmntCountry,
          id: response?.data.prmntCountryId,
        });
        setSelectedValstate({
          label: response?.data.prmntState,
          id: response?.data.prmntStateId,
        });
        setSelectedValcity({
          label: response?.data.prmntCity,
          id: response?.data.prmntCityId,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [customerId]);

  const countries = csc.getAllCountries();
  console.log(csc.getAllCountries());

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));

  const updatedStates = (prmntCountryId) =>
    csc
      .getStatesOfCountry(prmntCountryId)
      .map((state) => ({ label: state.name, value: state.id, ...state }));

  const updatedCities = (prmntStateId) =>
    csc
      .getCitiesOfState(prmntStateId)
      .map((city) => ({ label: city.name, value: city.id, ...city }));

  const ddlChange = (e) => {
    setCustomerData({
      ...customerData,
      prmntCountry: e.name,
      prmntCountryId: e.value,
    });
    setSelectedVal({ label: e.name, id: e.value });
  };

  const ddlChangeState = (e) => {
    setCustomerData({
      ...customerData,
      prmntState: e.name,
      prmntStateId: e.value,
    });
    setSelectedValstate({ label: e.name, id: e.value });
  };

  const ddlChangeCity = (e) => {
    setCustomerData({
      ...customerData,
      prmntCity: e.name,
      prmntCityId: e.value,
    });
    setSelectedValcity({ label: e.name, id: e.value });
  };

  const [selectedVal, setSelectedVal] = useState({
    label: customerData.prmntCountry,
    id: customerData.prmntCountryId,
  });
  const [selectedValstate, setSelectedValstate] = useState({
    label: customerData.prmntState,
    id: customerData.prmntStateId,
  });
  const [selectedValcity, setSelectedValcity] = useState({
    label: customerData.prmntCity,
    id: customerData.prmntCityId,
  });

  const handleChange = (e) => {
    setCustomerData({
      ...customerData,
      [e.target.name]: e.target.value,
    });
  };

  const SubmitForm = (e) => {
    e.preventDefault();
    axios
      .put("https://pradhanportal.com/loanApplication/customer/editCustomer", customerData)
      .then((response) => {
        console.log(response);

        setSubmitted(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const [viewCase, SetViewCase] = useState({});
  const LoanCaseId = JSON.parse(localStorage.getItem("LoanCaseId"));
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/master/getLoanCaseById?loanCaseId=${LoanCaseId}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response :", response.data);
        SetViewCase(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [LoanCaseId]);
  const navigateToPrevious = () => {
    navigate(`/viewLoanCase/${LoanCaseId}`);
  };
  return (
    <Fragment>
      {isSubmitted ? (
        navigate(`/viewLoanCase/${LoanCaseId}`) &
        localStorage.removeItem("LoanCaseId") &
        toast.success("Permanent Address Updated successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        })
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <div className="card">
                <form onSubmit={SubmitForm}>
                  <div className="card-header bg-primary d-flex justify-content-Start pb-1 pt-1 loanCaseCardHeader">
                    <span id="assignowner1">
                      {viewCase?.customerName} - {viewCase.id}
                    </span>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end address">
                          Permanent Address
                        </span>
                      </div>
                      <div className="col-md-8 mt-2"></div>
                      <div className="col-md-3 mt-4">
                        <span className="d-flex justify-content-end">
                          Address Line 1
                        </span>
                      </div>
                      <div className="col-md-6 mt-3">
                        <input
                          type="text"
                          name="pAddressLine1"
                          className="form-control sm"
                          placeholder="Enter Address Line 1"
                          value={customerData.pAddressLine1}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">
                          Address Line 2
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="pAddressLine2"
                          className="form-control sm"
                          placeholder="Enter Address Line 2"
                          value={customerData.pAddressLine2}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">
                          Landmark
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="pLandmark"
                          className="form-control sm"
                          placeholder="Enter Landmark"
                          value={customerData.pLandmark}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end  ">
                          Country
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <Select
                          id="prmntCountry"
                          name="prmntCountry"
                          className="countrydrp"
                          onChange={ddlChange}
                          value={selectedVal}
                          options={updatedCountries}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end  ">
                          State
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <Select
                          name="prmntState"
                          id="prmntState"
                          className="countrydrp"
                          value={selectedValstate}
                          options={updatedStates(
                            customerData.prmntCountryId
                              ? customerData.prmntCountryId
                              : null
                          )}
                          onChange={ddlChangeState}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end  ">
                          City
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <Select
                          id="prmntCity"
                          className="countrydrp"
                          name="prmntCity"
                          options={updatedCities(
                            customerData.prmntStateId
                              ? customerData.prmntStateId
                              : null
                          )}
                          value={selectedValcity}
                          onChange={ddlChangeCity}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">PIN</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="number"
                          name="pPIN"
                          className="form-control sm"
                          placeholder="Enter PIN"
                          value={customerData.pPIN}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3"></div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="row">
                      <div className="col-md-3"></div>
                      <div className="col-md-6 my-1">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                          <Button
                            color="secondary"
                            className="previousButton"
                            onClick={navigateToPrevious}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            type="submit"
                            className="nextButton"
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

import React from 'react';
import { Fragment } from 'react';
import { Link } from "react-router-dom";
import loan from "../../../assets/images/Loan Stage.png";
import loanTask from "../../../assets/images/Loan Task.png";
import loanSubTask from "../../../assets/images/Loan Sub Task.png";
import insurance from "../../../assets/images/Insurance stage.png";
import insuranceTask from "../../../assets/images/Insurance task.png";
import template from "../../../assets/images/Task Template.png";
import partner from "../../../assets/images/Partner.png";
import partnership from "../../../assets/images/Channel Partner.png";
import project from "../../../assets/images/project.png";
import bank from "../../../assets/images/Bank (2).png";
import branch from "../../../assets/images/Branch Master.png";
import bankbranch from "../../../assets/images/Bank Branch.png";
import types from "../../../assets/images/Loan Type.png";
import subTypes from "../../../assets/images/Loan sub Type.png";
import docStorage from "../../../assets/images/Document storage.png";
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row, Card, CardHeader, CardBody, Input,} from "reactstrap";
export default function AppSetting() {
  const navigate = useNavigate();
   const navigateToViewCustomer = () => {
    navigate(`/loanStage`);
  };
  const navigateToLoanTask = () => {
    navigate(`/loanTask`);
  };
  const navigateToLoanSubTask = () => {
    navigate(`/loanSubTask`);
  };
  const navigateToInsuranceTask = () => {
    navigate(`/insuranceTask`);
  };
  const navigateToTaskTemplate = () => {
    navigate(`/taskTemplates`);
  };
  const navigateToBranchMaster=()=>{
    navigate('/branchMaster');
  }
  const navigateToDocumentStorage=()=>{
    navigate(`/documentStorage`);
  }
  const navigateToAddPartner=()=>{
    navigate(`/addPartnerManagement`);
  }
  const navigateToAddPartnership=()=>{
    navigate(`/addPartnershipManagement`);
  }
  const navigateToProductMgmt=()=>{
    navigate(`/addProductManagement`);
  }
  const navigateToBankMgmt=()=>{
    navigate(`/bankmgmt`);
  }
  const navigateToBankBranchMgmt=()=>{
    navigate(`/bankBranchmgmt`);
  }
  const navigateToTypeMgmt=()=>{
    navigate(`/typemgmt`);
  }
  const navigateToSubTypeMgmt=()=>{
    navigate(`/subTypesManagement`);
  }
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
          <Card>
          <div className="row m-5">
          <div className="col-md-2">
             <div>
             <img src={loan} width = "40" height = "30" alt='' className='mx-3'  onClick={navigateToViewCustomer} style={{cursor:"pointer"}}/>
             </div>
             <div className='mt-2'>Loan Stage</div>
          </div>
         
          <div className="col-md-2">
          <div>
             <img src={insurance} width = "40" height = "30" alt='' className='mx-4'  onClick={navigateToInsuranceTask} style={{cursor:"pointer"}}/>
             </div>
             <div className='mt-2'>Insurance Stage</div>
          </div>
          <div className="col-md-2">
          <div>
             <img src={loanTask} width = "40" height = "30" alt='' className='mx-5'  onClick={navigateToLoanTask} style={{cursor:"pointer"}}/>
             </div>
             <div className='mt-2'style={{marginLeft:"40px"}}>Loan Task</div>
          </div>
          <div className="col-md-2">
          <div>
             <img src={loanSubTask} width = "40" height = "30" alt='' className='mx-4'  onClick={navigateToLoanSubTask} style={{cursor:"pointer",marginRight:"-0px"}}/>
             </div>
             <div className='mt-2' style={{marginLeft:"20px"}}>Loan Sub Task</div>
          </div>
          <div className="col-md-2">
          <div>
             <img src={insuranceTask} width = "40" height = "30" alt='' className='mx-4'  onClick={navigateToInsuranceTask} style={{cursor:"pointer"}}/>
             </div>
             <div className='mt-2'>Insurance Task</div>
          </div>
          <div className="col-md-2">
          <div>
             <img src={template} width = "40" height = "30" alt='' className='mx-4'  onClick={navigateToTaskTemplate} style={{cursor:"pointer"}}/>
             </div>
             <div className='mt-2'>Task Templates</div>
          </div>
          <div className="col-md-2 mt-5">
          <div>
             <img src={branch} width = "40" height = "30" alt='' className='mx-3'  onClick={navigateToBranchMaster} style={{cursor:"pointer"}}/>
             </div>
             <div className='mt-2'>Branch Master</div>
          </div>
          <div className="col-md-2 mt-5">
          <div>
             <img src={docStorage} width = "40" height = "30" alt='' className='mx-4'  onClick={navigateToDocumentStorage} style={{cursor:"pointer"}}/>
             </div>
             <div className='mt-2'>Document Storage</div>
          </div>
          <div className="col-md-2 mt-5">
          <div>
             <img src={partner} width = "40" height = "30" alt='' className='mx-5'  onClick={navigateToAddPartner} style={{cursor:"pointer"}}/>
             </div>
             <div className='mt-2'style={{marginLeft:"40px"}}>Partner </div>
          </div>
          <div className="col-md-2 mt-5">
          <div>
             <img src={partnership} width = "40" height = "30" alt='' className='mx-4'  onClick={navigateToAddPartnership} style={{cursor:"pointer"}}/>
             </div>
             <div className='mt-2 mx-2'>Channel Partner</div>
          </div>
          {/* <div className="col-md-2 mt-5">
          <div>
             <img src={project} width = "40" height = "30" alt='' className='mx-1'  onClick={navigateToProductMgmt} style={{cursor:"pointer"}}/>
             </div>
             <div className='mt-2'>Project</div>
          </div> */}
          <div className="col-md-2 mt-5">
          <div>
             <img src={bank} width = "40" height = "30" alt='' className='mx-4'  onClick={navigateToBankMgmt} style={{cursor:"pointer"}}/>
             </div>
             <div className='mt-2 mx-4'>Bank</div>
          </div>
          <div className="col-md-2 mt-5">
          <div>
             <img src={bankbranch} width = "40" height = "30" alt='' className='mx-4'  onClick={navigateToBankBranchMgmt} style={{cursor:"pointer"}}/>
             </div>
             <div className='mt-2 mx-2'>Bank Branch</div>
          </div>
          <div className="col-md-2 mt-5">
          <div>
             <img src={types} width = "40" height = "30" alt='' className='mx-3'  onClick={navigateToTypeMgmt} style={{cursor:"pointer"}}/>
             </div>
             <div className='mt-2'>Lead Type</div>
          </div>
          <div className="col-md-2 mt-5">
          <div>
             <img src={subTypes} width = "40" height = "30" alt='' className='mx-4'  onClick={navigateToSubTypeMgmt} style={{cursor:"pointer"}}/>
             </div>
             <div className='mt-2'>Lead Sub Type</div>
          </div>
          </div>
          </Card>
            </div>
         </div>
      </div>
    </Fragment>
  )
}

import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../../assets/images/editIcon.png";
import Tooltip from "rc-tooltip";
export default function ViewSubType() {
  var renderTooltip = <span>Edit Lead Sub Type</span>;
  const { subTypeID } = useParams();
  const [viewCP, setViewCP] = useState([]);
  const navigate = useNavigate();
  const naviagteToChannelPartners = () => {
    navigate("/subTypesManagement");
  };
  const navigateToEditCP = () => {
    navigate(`/editSubType/${subTypeID}`);
  };
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getSubTypeById?subTypeID=${subTypeID}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setViewCP(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Fragment>
      <div className="container-fluid ">
        <div className="card">
          <div className="row mt-5">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="card" id="card1">
                <div className="row">
                  <div className="col-md-9">
                    <div id="heading2" className="">
                      {viewCP.subTypes}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mt-3" style={{ marginRight: "17px" }}>
                      <Tooltip placement="left" overlay={renderTooltip}>
                        <h6 id="viewLeadEditIcon">
                          <img
                            src={editIcon}
                            width="24"
                            height="19"
                            alt=""
                            className=""
                            onClick={navigateToEditCP}
                          />
                        </h6>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <span className="d-flex justify-content-end">
                        Channel Partner ID
                      </span>
                    </div>
                    <div className="col-md-9">
                      <span className=" text-white px-1" style={{backgroundColor:"#FDB714"}}>
                        {viewCP.generatedId}
                      </span>
                    </div>
                    {/* <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                        Channel Partner Name
                      </span>
                    </div>
                    <div className="col-md-9 mt-2">
                      {viewCP.partnershipName}
                    </div> */}
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                       Lead Type
                      </span>
                    </div>
                    <div className="col-md-9 mt-2">{viewCP.leadTypeVO?.leadType}</div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                        Type
                      </span>
                    </div>
                    <div className="col-md-9 mt-2">{viewCP.typeVO?.type}</div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">Sub Type</span>
                    </div>
                    <div className="col-md-9 mt-2">
                      {viewCP.subTypes}
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="row mt-4 mb-4">
              <div className="col-md-12">
                <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                  <button
                    onClick={naviagteToChannelPartners}
                    className="btn btn-secondary previousButton"
                    type="button"
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}


import React,{Fragment,useState,useEffect} from 'react';
import { Button} from "reactstrap" ;
import axios from 'axios';
import { useParams,useNavigate} from 'react-router-dom';
import moment from 'moment';
import {toast} from 'react-toastify';
export default function EditContactDetails() {
  const{LoanCaseId}= useParams();
  const [applicant, setApplicant]=useState({});
  const navigate = useNavigate();
  const [isSubmitted, setSubmitted]=useState(false);
  const year = (new Date()).getFullYear();
  const years = Array.from(new Array(60),( val, index) =>  year-index);
  const[getApplicantId,setApplicantId]=useState({
    type:'',
    applicantId:'',
    applicantName:'',
    primaryNumber:'',
    secondaryNumber:'',
    email:''
  })
  
  const[applicantData,setApplicantData]=useState({
    applicantId:'',
    primaryNumber:'',
    secondaryNumber:'',
    email:''
  })
  
  const [viewCase, SetViewCase]=useState({});
    useEffect (()=>{
    const dataUrl =`https://pradhanportal.com/loanApplication/master/getLoanCaseById?loanCaseId=${LoanCaseId}`;
        axios.get(dataUrl).then((response)=>{
        
          console.log("response :",response.data)
          SetViewCase(response.data);
        }).catch((err)=>{
           console.log(err);
        });
    
  },[LoanCaseId]);
  console.log("ViewLoanCase",viewCase);
  useEffect (()=>{
  const dataUrl =`https://pradhanportal.com/loanApplication/customer/getAllApplicantByLoanCaseID?loanCaseID=${LoanCaseId}`;
      axios.get(dataUrl).then((response)=>{
      
        console.log("response :",response.data)
        setApplicant(response.data);
      }).catch((err)=>{
         console.log(err);
      });
  
},[LoanCaseId]);
console.log("applicant",applicant);

const id =getApplicantId?.applicantId;

useEffect (()=>{
  const dataUrl =`https://pradhanportal.com/loanApplication/customer/getApplicantById?applicantId=${id}`;
      axios.get(dataUrl).then((response)=>{
      
        console.log("response :",response.data)
        setApplicantData(response.data);
      }).catch((err)=>{
         console.log(err);
      });
  
},[id]);
console.log("applicantID",id);
console.log("applicantData",applicantData);

const handleChangeForApplicant = (e) =>{
  setApplicantId({ 
    ...getApplicantId,
  [e.target.name]: e.target.value       
}); 
}

const handleChange = (e) => {  
  setApplicantData({ 
      ...applicantData,
    [e.target.name]: e.target.value       
  }); 
};

applicantData.applicantId =id;

const handleSubmit=(e)=>{
  e.preventDefault();
  axios.put('https://pradhanportal.com/loanApplication/customer/editApplicant',applicantData).then((response)=>{
      console.log(response);
      setSubmitted(true);
    }).catch((err)=>{
      console.error(err);
    });
}; 

const naviagteToViewLoanCase=()=>{
  navigate(`/viewLoanCase/${LoanCaseId}`);
}


  return (
    <Fragment>
       {
       isSubmitted ? navigate(`/viewLoanCase/${LoanCaseId}`) &
       toast.success('Applicant Contact Details Updated successfully!', {
        position: toast.POSITION.TOP_RIGHT
        }) :
        <div className="container">
            <div className="row">
            <div className="col-md-1"></div>
                <div className="col-md-10">
                    <div className="card">
                    <form onSubmit={handleSubmit}>
                        <div className="card-header bg-primary d-flex justify-content-Start loanCaseCardHeader">
                        <span id="assignowner1">{viewCase.customerName} - {viewCase.id}</span>
                        </div>
                        <div className="card-body">
                            <div className="row">
                            <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  "> Applicant Name</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='applicantId'
                          onChange={handleChangeForApplicant}
                          value={getApplicantId.applicantId}
                        >
                          <option value="">Please Select</option>
                          {
                            applicant?.length > 0 ? <Fragment>
                              {
                                applicant.map((apl,index)=>{
                                  return(
                                    <option value={apl?.applicantId}key={index}>{apl.applicantName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="co-md-3"></div>
                          <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Primary Mobile No</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="primaryNumber"
                          className="form-control sm"
                          placeholder="Enter Primary Mobile No"
                          value={applicantData?.primaryNumber}
                          onChange={handleChange}
                        />
                        </div>
                        <div className="co-md-3"></div>
                        <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Secondary  Mobile No</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="secondaryNumber"
                          className="form-control sm"
                          placeholder="Enter Secondary Mobile No"
                          value={applicantData?.secondaryNumber}
                          onChange={handleChange}
                        />
                        </div>
                        <div className="co-md-3"></div>
                        <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Email ID</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="email"
                          className="form-control sm"
                          placeholder="Enter Email ID"
                          value={applicantData?.email}
                          onChange={handleChange}
                        />
                        </div>
                        <div className="co-md-3"></div>
                            </div>
                            {/* {
                              viewCase.partnerVO?.partnerId != 1 ? <Fragment>
                               <div className="row mt-3">
                               <span style={{fontWeight:"600"}}>
                                    channel Partner Contact Details
                                  </span>
                              <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">Channel Partner 1st Mo.No.</span>
                              </div>
                              <div className="col-md-6 mt-2">
                              <input
                          type="text"
                          name="primaryNumber"
                          className="form-control sm"
                          placeholder="Enter Primary Mobile No"
                          value={applicantData?.primaryNumber}
                          onChange={handleChange}
                        />
                              </div>
                              <div className="col-md-3"></div>
                              <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">Channel Partner 2nd Mo.No.</span>
                              </div>
                              <div className="col-md-6 mt-2">
                              <input
                          type="text"
                          name="primaryNumber"
                          className="form-control sm"
                          placeholder="Enter Secondary Mobile No"
                          value={applicantData?.primaryNumber}
                          onChange={handleChange}
                        />
                              </div>
                              <div className="col-md-3"></div>
                              <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">Channel Partner Email ID</span>
                              </div>
                              <div className="col-md-6 mt-2">
                              <input
                          type="text"
                          name="primaryNumber"
                          className="form-control sm"
                          placeholder="Enter Email ID"
                          value={applicantData?.primaryNumber}
                          onChange={handleChange}
                        />
                              </div>
                              <div className="col-md-3"></div>
                            </div>
                            <div className="row mt-3">
                               <span style={{fontWeight:"600"}}>
                                    BM Contact Details
                                  </span>
                              <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">BM 1st Mo.No.</span>
                              </div>
                              <div className="col-md-6 mt-2">
                              <input
                          type="text"
                          name="primaryNumber"
                          className="form-control sm"
                          placeholder="Enter Primary Mobile No"
                          value={applicantData?.primaryNumber}
                          onChange={handleChange}
                        />
                              </div>
                              <div className="col-md-3"></div>
                              
                              <div className="col-md-3 mt-3">
                              <span className="d-flex justify-content-end">BM Email ID</span>
                              </div>
                              <div className="col-md-6 mt-2">
                              <input
                          type="text"
                          name="primaryNumber"
                          className="form-control sm"
                          placeholder="Enter Email ID"
                          value={applicantData?.primaryNumber}
                          onChange={handleChange}
                        />
                              </div>
                              <div className="col-md-3"></div>
                            </div>
                              </Fragment>:null
                            } */}
                        </div>
                        <div className="row mt-1 mb-3">
                            <div className="col-md-3"></div>
                        <div className="col-md-6 my-1">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  className='previousButton' onClick={naviagteToViewLoanCase}>Cancel</Button>
                        <Button color="primary" type="submit" className='nextButton'>Update</Button>
                        </div>
                        </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
}
    </Fragment>
  )
}

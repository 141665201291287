import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumb,BreadcrumbItem} from 'reactstrap';
import ViewLoanTaskDetails from './ViewLoanTaskDetails';


const ViewLoanTask = () => {
document.title="Pradhan Portal";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Row>
                <Col xs={12}>
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">View Loan Task</h4>
                   <Breadcrumb listTag="div"  className="page-title-right ">
                  <BreadcrumbItem
                         href="#"
                         tag="a"
                  >
                   Settings
                </BreadcrumbItem>
                <BreadcrumbItem
                         href="#"
                         tag="a"
                  >
                   Application Settings
                </BreadcrumbItem>
                <BreadcrumbItem
                         href="#"
                         tag="a"
                  >
                   Loan Tasks
                </BreadcrumbItem>
                    <BreadcrumbItem
                         active
                         tag="span"
                    >
                   View Loan Task
                 </BreadcrumbItem>
           </Breadcrumb>
       </div>
                </Col>
               </Row>
                    <Row>
                        <Col lg={12}>
                           <ViewLoanTaskDetails/>
                        </Col>
                    </Row>
                    
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ViewLoanTask;
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Input,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Modal,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import * as moment from "moment";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { isEmpty } from "lodash";

// Import Images
import dummyImg from "../../../assets/images/users/user-dummy-img.jpg";

//Import actions
import {
  getLeads as onGetLeads,
  addNewLead as onAddNewLead,
  updateLead as onUpdateLead,
  deleteLead as onDeleteLead,
} from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
import DeleteModal from "../../../Components/Common/DeleteModal";
import CrmFilter from "./CrmFilter";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createSelector } from "reselect";

const CrmLeads = () => {
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state.Crm;
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (state) => ({
      leads: state.leads,
      isLeadCreated: state.isLeadCreated,
      isLeadsSuccess: state.isLeadsSuccess,
      error: state.error,
    })
  );

  // Inside your component
  const {
    leads,
    isLeadCreated,
    isLeadsSuccess,
    error
  } = useSelector(selectLayoutProperties);

  useEffect(() => {
    if (leads && !leads.length) {
      dispatch(onGetLeads());
    }
  }, [dispatch, leads]);

  useEffect(() => {
    setLead(leads);
  }, [leads]);

  useEffect(() => {
    if (!isEmpty(leads)) {
      setLead(leads);
      setIsEdit(false);
    }
  }, [leads]);

  const [isEdit, setIsEdit] = useState(false);
  const [lead, setLead] = useState([]);

  //delete lead
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal, setModal] = useState(false);

  const [isInfoDetails, setIsInfoDetails] = useState(false);

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  const handlestag = (tags) => {
    setTag(tags);
    const assigned = tags.map((item) => item.value);
    setAssignTag(assigned);
  };

  // const tags = [
  //   { label: "Exiting", value: "Exiting" },
  //   { label: "Lead", value: "Lead" },
  //   { label: "Long-term", value: "Long-term" },
  //   { label: "Partner", value: "Partner" }
  // ];

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setLead(null);
    } else {
      setModal(true);
      setDate(defaultdate());
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  // Delete Data
  const handleDeleteLead = () => {
    if (lead) {
      dispatch(onDeleteLead(lead._id));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (lead) => {
    setLead(lead);
    setDeleteModal(true);
  };

  // Add Data
  const handleLeadClicks = () => {
    setLead("");
    setIsEdit(false);
    toggle();
  };

  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };

  // validation
  // const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
  //   enableReinitialize: true,

  //   initialValues: {
       // img: (lead && lead.img) || '',
  //     name: (lead && lead.name) || '',
  //     company: (lead && lead.company) || '',
  //     score: (lead && lead.score) || '',
  //     phone: (lead && lead.phone) || '',
  //     location: (lead && lead.location) || '',
  //     date: (lead && lead.date) || '',
  //     tags: (lead && lead.tags) || '',
  //   },
  //   validationSchema: Yup.object({
  //     name: Yup.string().required("Please Enter Name"),
  //     company: Yup.string().required("Please Enter Company"),
  //     score: Yup.string().required("Please Enter Score"),
  //     phone: Yup.string().required("Please Enter Phone"),
  //     location: Yup.string().required("Please Enter Location"),
      // date: Yup.string().required("Please Enter Date"),
  //   }),
  //   onSubmit: (values) => {
  //     if (isEdit) {
  //       const updateLead = {
  //         _id: lead ? lead._id : 0,
           // img: values.img,
  //         name: values.name,
  //         company: values.company,
  //         score: values.score,
  //         phone: values.phone,
  //         location: values.location,
  //         date: date,
  //         tags: assignTag,
  //       };
         // update Company
  //       dispatch(onUpdateLead(updateLead));
  //       validation.resetForm();
  //     } else {
  //       const newLead = {
  //         _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
           // img: values["img"],
  //         name: values["name"],
  //         company: values["company"],
  //         score: values["score"],
  //         phone: values["phone"],
  //         location: values["location"],
  //         date: date,
  //         tags: assignTag,
  //       };
         // save new Lead
  //       dispatch(onAddNewLead(newLead));
  //       validation.resetForm();
  //     }
  //     toggle();
  //   },
  // });

  // Update Data
  // const handleLeadClick = useCallback((arg) => {
  //   const lead = arg;

  //   setLead({
  //     _id: lead._id,
  //      img: lead.img,
  //     name: lead.name,
  //     company: lead.company,
  //     score: lead.score,
  //     phone: lead.phone,
  //     location: lead.location,
  //     date: lead.date,
  //     tags: lead.tags,
  //   });

  //   setIsEdit(true);
  //   toggle();
  // }, [toggle]);

  // Node API 
  // useEffect(() => {
  //   if (isLeadCreated) {
  //     setLead(null);
  //     dispatch(onGetLeads());
  //   }
  // }, [
  //   dispatch,
  //   isLeadCreated,
  // ]);

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".leadsCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteLead(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".leadsCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        // Cell: (cellProps) => {
        //   return <input type="checkbox" className="leadsCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
        // },
         id: '#',
      },
      {
        Header: "Customer Name",
        //accessor: "name",
        // filterable: false,
        // Cell: (leads) => (
        //   <>
        //     <div className="d-flex align-items-center">
        //       {/* <div className="flex-shrink-0">
        //         {leads.row.original.image_src ? <img
        //           src={process.env.REACT_APP_API_URL + "/images/users/" + leads.row.original.image_src}
        //           alt=""
        //           className="avatar-xxs rounded-circle"
        //         /> :
        //           <div className="flex-shrink-0 avatar-xs me-2">
        //             <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
        //               {leads.row.original.name.charAt(0)}
        //             </div>
        //           </div>
        //           // <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
        //         }
        //       </div> */}
        //       <div className="flex-grow-1 ms-2 name">
        //         {leads.row.original.name}
        //       </div>
        //     </div>
        //   </>
        // ),
      },
      {
        Header: "Email",
        // accessor: "company",
        // filterable: false,
      },
      {
        Header: "Phone",
        // accessor: "score",
        // filterable: false,
      },
      {
        Header: "Loan Type",
        // accessor: "phone",
        // filterable: false,
      },
      {
        Header: "Loan Amount",
        // accessor: "location",
        // filterable: false,
      },
      {
        Header: "Lead Owner",
        // Cell: (leads) => (
        //   <>
        //     {leads.row.original.tags.map((item, key) => (<span className="badgebg-primary-subtle text-primary me-1" key={key}>{item}</span>))}
        //   </>
        // ),
      },
      {
        Header: "Create Date",
        // accessor: "date",
        // filterable: false,
        // Cell: (cell) => (
        //   <>
        //     {handleValidDate(cell.value)}
        //   </>
        // ),
      },
      {
        Header: "Action",
        // Cell: (cellProps) => {
        //   return (
        //     <ul className="list-inline hstack gap-2 mb-0">
        //       <li className="list-inline-item edit" title="Call">
        //         <Link
        //           to="#"
        //           className="text-muted d-inline-block"
        //         // onClick={toggle}
        //         >
        //           <i className="ri-phone-line fs-16"></i>
        //         </Link>
        //       </li>
        //       <li className="list-inline-item edit" title="Message">
        //         <Link to="#" className="text-muted d-inline-block">
        //           <i className="ri-question-answer-line fs-16"></i>
        //         </Link>
        //       </li>
        //       <li className="list-inline-item" title="View">
        //         <Link to="#">
        //           <i className="ri-eye-fill align-bottom text-muted"></i>
        //         </Link>
        //       </li>
        //       <li className="list-inline-item" title="Edit">
        //         <Link className="edit-item-btn" to="#"
        //           onClick={() => { const LeadData = cellProps.row.original; handleLeadClick(LeadData); }}
        //         >
        //           <i className="ri-pencil-fill align-bottom text-muted"></i>
        //         </Link>
        //       </li>
        //       <li className="list-inline-item" title="Delete">
        //         <Link
        //           className="remove-item-btn"
        //           onClick={() => { const LeadData = cellProps.row.original; onClickDelete(LeadData); }}
        //           to="#"
        //         >
        //           <i className="ri-delete-bin-fill align-bottom text-muted"></i>
        //         </Link>
        //       </li>
        //     </ul>
        //   );
        // },
      },
    ],
    // [handleLeadClick, checkedAll]
  );

  const defaultdate = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear()).toString());
  };

  const [date, setDate] = useState(defaultdate());

  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  document.title = "Leads | Velzon - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteLead}
          onCloseClick={() => setDeleteModal(false)}
        />
        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />

        <Container fluid>
          <BreadCrumb title="Leads" pageTitle="CRM" />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={3}>
                      <div className="search-box">
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder="Search for..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <div className="col-sm-auto ms-auto">
                      <div className="hstack gap-2">
                        {isMultiDeleteButton && <button className="btn btn-soft-danger"
                          onClick={() => setDeleteModalMulti(true)}
                        ><i className="ri-delete-bin-2-line"></i></button>}
                        <button type="button" className="btn btn-info" onClick={toggleInfo}>
                          <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                          Fliters
                        </button>
                        <button
                          type="button"
                          className="btn btn-success add-btn"
                          id="create-btn"
                          onClick={() => { setIsEdit(false); toggle(); }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Leads
                        </button>
                        {/* <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn btn-soft-info btn-icon fs-14"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="ri-settings-4-line"></i>
                          </DropdownToggle>
                          <DropdownMenu
                          >
                            <li>
                              <DropdownItem>
                                Copy
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem>
                                Move to pipline
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem>
                                Add to exceptions
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem>
                                Switch to common form view
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem>
                                Reset form view to default
                              </DropdownItem>
                            </li>
                          </DropdownMenu>
                        </UncontrolledDropdown> */}
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <div>
                    {isLeadsSuccess && leads.length ? (
                      <TableContainer
                        columns={columns}
                        data={(leads || [])}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-0"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        handleLeadClick={handleLeadClicks}
                        isLeadsFilter={false}
                        SearchPlaceholder='Search for'
                      />
                    ) : (<Loader error={error} />)
                    }

                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered width="1000">
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {!!isEdit ? "Edit Lead" : "Add Lead"}
                    </ModalHeader>
                    <Form className="tablelist-form" onSubmit={(e) => {
                      e.preventDefault();
                      
                      return false;
                    }}>
                      <ModalBody >
                        <Input type="hidden" id="id-field" />
                        <div className="row">
                <div className="col-md-12">
                    <div className="card">
                       <div className="row">
                       <div className="col-md-6">
                        <h4 id='subHeading'>Customer Details</h4>
                        <div className="row">
                            <div className="col-md-4">
                                <span id='title'>Title</span>
                            </div>
                            <div className="col-md-8">
                            <select 
                                class="form-select form-select-md" aria-label=".form-select-md example">
                                   <option selected>Please select</option>
                                   <option value="Capt">Capt.</option>
                                   <option value="Dr">Dr.</option>
                                   <option value="M/S">M/S</option>
                                   <option value="Mr.">Mr.</option>
                                   <option value="Mrs.">Mrs.</option>
                                   <option value="Ms.">Ms.</option>
                                   <option value="Prof.">Prof.</option>
                                </select>
                            </div>
                            </div>
                            <div className="row mt-2">
                            <div className="col-md-4">
                                <span id='span'>Customer Name</span>
                            </div>
                            <div className="col-md-8">
                            <input
                                  type="text" className="form-control" 
                                  placeholder="FirstName , LastName" aria-label="Username"
                                   aria-describedby="basic-addon1"/>
                            </div>
                            </div>
                            <div className="row mt-2">
                            <div className="col-md-4">
                                <span id='gender'>Gender</span>
                            </div>
                            <div className="col-md-8">
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                            <label className="form-check-label" for="inlineRadio1">Male</label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                            <label className="form-check-label" for="inlineRadio2">Female</label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3"/>
                            <label className="form-check-label" for="inlineRadio3">Other</label>
                            </div>
                            </div>
                            </div>
                            <div className="row mt-2">
                            <div className="col-md-4">
                                <span id='resident'>Resident Type</span>
                            </div>
                            <div className="col-md-8">
                            <select 
                                class="form-select form-select-md" aria-label=".form-select-md example">
                                   <option selected>Please select</option>
                                </select>
                            </div>
                            </div>
                            <div className="row mt-2">
                            <div className="col-md-4">
                                <span id='leadsource'>Lead Source</span>
                            </div>
                            <div className="col-md-8">
                            <select 
                                class="form-select form-select-md" aria-label=".form-select-md example">
                                   <option selected>Please select</option>
                                </select>
                            </div>
                            </div>
                       </div>
                       <div className="col-md-6">
                       <h4 id='subHeading2'>Customer Contact</h4>
                       <div className="row mt-2">
                            <div className="col-md-5">
                                <span id='span'>Primary mobile number</span>
                            </div>
                            <div className="col-md-7">
                            <input
                                  type="number" className="form-control" 
                                  placeholder="Enter primary mobile number" aria-label="Username"
                                   aria-describedby="basic-addon1"/>
                            </div>
                            </div>
                            <div className="row mt-2">
                            <div className="col-md-5">
                                <span id='secnum'>Secondary mobile number</span>
                            </div>
                            <div className="col-md-7">
                            <input
                                  type="number" className="form-control" 
                                  placeholder="Enter secondary mobile number" aria-label="Username"
                                   aria-describedby="basic-addon1"/>
                                 
                            </div>
                            </div>
                            <div className="row mt-2">
                            <div className="col-md-5">
                                <span id='email'>Email ID</span>
                            </div>
                            <div className="col-md-7">
                            <input
                                  type="email" className="form-control" 
                                  placeholder="Enter email ID" aria-label="Username"
                                   aria-describedby="basic-addon1"/>
                            </div>
                            </div>
                       </div>
                       <div className="row mt-4">
                        <div className="col-md-6">
                            <h4 id='subHeading'>Lead Details</h4>
                            <div className="row mt-2">
                            <div className="col-md-4">
                                <span id='leadsource'>Lead Initiator</span>
                            </div>
                            <div className="col-md-8">
                            <select 
                                class="form-select form-select-md" aria-label=".form-select-md example">
                                   <option selected>Please select</option>
                                </select>
                            </div>
                            </div>
                            <div className="row mt-2">
                            <div className="col-md-4">
                                <span id='leadsource'>Lead Owner</span>
                            </div>
                            <div className="col-md-8">
                            <select 
                                class="form-select form-select-md" aria-label=".form-select-md example">
                                   <option selected>Please select</option>
                                </select>
                            </div>
                            </div>
                            <div className="row mt-2">
                            <div className="col-md-4">
                                <span id='leadsource'>Lead Type</span>
                            </div>
                            <div className="col-md-8">
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptionss" id="inlineRadio4" value="option4"/>
                            <label className="form-check-label" for="inlineRadio4">Loan</label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptionss" id="inlineRadio5" value="option5"/>
                            <label className="form-check-label" for="inlineRadio5">Insurance</label>
                            </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-2">
                            <h4 id='subHeading3'>Loan Details</h4>
                            <div className="row mt-2">
                            <div className="col-md-5">
                                <span id='loanType'>Loan Type</span>
                            </div>
                            <div className="col-md-7">
                            <select 
                                class="form-select form-select-md" aria-label=".form-select-md example">
                                   <option selected>Please select</option>
                                </select>
                            </div>
                            </div>
                            <div className="row mt-2">
                            <div className="col-md-5">
                                <span id='loanAmount'>Loan Amount</span>
                            </div>
                            <div className="col-md-7">
                            <input
                                  type="number" className="form-control" 
                                  placeholder="Enter loan amount" aria-label="Username"
                                   aria-describedby="basic-addon1"/>
                            </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-4">
                                <span id='gender'>Notes</span>
                                </div>
                                <div className="col-md-8">
                                <div className="form-group">
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" maxLength={300}></textarea>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-12">
                            <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                               <button class="btn btn-secondary me-md-2" type="button">Cancel</button>
                               <button class="btn btn-primary" type="button">Create Lead</button>
                            </div>
                            </div>
                        </div>
                       </div>
                       
                       </div>

                    </div>
                </div>
            </div>
                      </ModalBody>
                    
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <CrmFilter
        show={isInfoDetails}
        onCloseClick={() => setIsInfoDetails(false)}
      />
    </React.Fragment >
  );
};

export default CrmLeads;
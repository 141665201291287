import React, { useEffect, useState,Fragment } from 'react'
import axios from 'axios';
import TableRows from './TableRows';
import { isEmpty } from 'validator';
import { Button } from 'reactstrap';
export default function TaskRow( {updateParentState,index}) {

const[rowData,setRowsData]=useState(
    {
        key:index,
        loanStage:{
           loanStageID:'',
         },
         loanTaskVO:{
           loanTaskId:'',
        },
        subTaskVO:{
           subTaskId:'',
         },
         duration:'' 
       }
)
 


console.log("Task rows RowData==",rowData);
const[stage,setStage]=useState([]);
  useEffect(() => {
    loadStage();
    }, []);
  const loadStage = async () => {
      const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllLoanStage`);
      setStage(result.data);
 
    };
const stageId=rowData?.loanStage?.loanStageID;
console.log("stageID==",stageId);
const [loanTasks,setloanTasks]=useState([]);
useEffect(()=>{
    const d1 =`https://pradhanportal.com/loanApplication/master/getAllLoanTaskByStageId?loanStageID=${stageId}`;
    if(stageId)
        axios.get(d1).then((response)=>{
          console.log("response :",response.data)
      
          setloanTasks(response.data);
        }).catch((err)=>{
           console.log(err);
        });
},[stageId])

const loanTaskId =rowData.loanTaskVO?.loanTaskId;
const[subTask,setSubTask]=useState();
useEffect(()=>{
    const d1 =`https://pradhanportal.com/loanApplication/master/getAllSubTaskByTaskId?loanTaskId=${loanTaskId}`;
    if(loanTaskId)
    axios.get(d1).then((response)=>{
      console.log("response :",response.data)
  
      setSubTask(response.data);
    }).catch((err)=>{
       console.log(err);
    });
},[loanTaskId])

const handleChange =(e)=>{
   setRowsData({
    ...rowData,
    [e.target.name]: [e.target.value]
   })
   updateParentState(rowData)

}
const handleChangeForStage =(e)=>{
    setRowsData({
        ...rowData,
        loanStage:{
            loanStageID:e.target.value
        }
    })
    updateParentState(rowData)

}
const handleChangeForTask =(e)=>{
    setRowsData({
        ...rowData,
        loanTaskVO:{
          loanTaskId:e.target.value
        }
    })
    updateParentState(rowData)
}
const handleChangeForSubtask =(e)=>{
    
    setRowsData({
        ...rowData,
        subTaskVO:{
            subTaskId:e.target.value
        }
    })
    updateParentState(rowData)
}
const checkTableData=()=>{
console.log("Check me row data :",rowData);
}


  return (
    
    <div>   
         <tr  key={index}>
         <td style={{width:"80px"}}>
                                              <input
                                                type="text"
                                                value={index + 101}
                                                name="Id"
                                                className="form-control"
                                                disabled="true"
                                              />
                                            </td>
                <td style={{width:"500px"}}>

               <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='loanStage.loanStageID'
                        //   onChange={(evnt)=>{(handleChange(index, evnt))}}
                        onChange={handleChangeForStage}
                          value={rowData.loanStage?.loanStageID}
                        >
                          <option selected>Please Select</option>
                          {
                            stage?.length > 0 ? <Fragment>
                              {
                                stage.map((Stage,index)=>{
                                  return(
                                    <option value={Stage?.loanStageID}key={index}>{Stage.loanStage}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
            </td>

            <td style={{width:"500px"}}>
               <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='loanTaskVO.loanTaskId'
                        //   onChange={(evnt)=>{(handleChange(index, evnt))}}
                        onChange={handleChangeForTask}
                          value={rowData.loanTaskVO?.loanTaskId}
                        >
                          <option selected>Please Select</option>
                          {
                            loanTasks?.length > 0 ? <Fragment>
                              {
                                loanTasks.map((task,index)=>{
                                  return(
                                    <option value={task?.loanTaskId}key={index}>{task.loanTask}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
            </td>
            <td style={{width:"500px"}}>
               <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='subTaskVO.subTaskId'
                        //   onChange={(evnt)=>(handleChange(index, evnt))}
                        onChange={handleChangeForSubtask}
                          value={rowData.subTaskVO?.subTaskId}
                        >
                          <option selected>Please Select</option>
                          {
                            subTask?.length > 0 ? <Fragment>
                              {
                                subTask.map((subtask,index)=>{
                                  return(
                                    <option value={subtask?.subTaskId}key={index}>{subtask.subTaskName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
            </td>
                <td style={{width:"80px"}}><input type="number" value={rowData.duration}  onChange={handleChange} name="duration" className="form-control" /> </td>
{/*                
                <Button
                                color="secondary"
                                id="btn-cancel"
                                onClick={checkTableData}
                              >
                                ck
                              </Button> */}
            </tr>
           
    </div>
  )
}

import React, { Fragment, useMemo, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Tooltip from "rc-tooltip";
import { useState, useEffect } from "react";
import TableContainer from "../../../Components/Common/TableContainer";
import {
  Col,
  Container,
  Card,
  row,
  CardHeader,
  CardBody,
  Input,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import leftChevron from "../../../assets/images/profileimg.png";
import pdfimg from "../../../assets/images/pdfimg.svg";
import editicon from "../../../assets/images/editIcon.png";
import setting from "../../../assets/images/setting.png";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
} from "reactstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import PrismCode from "../../../Components/Common/Prism";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import ViewLoanCase from ".";
import PfsLCases from "./PFSLoanCase";
import ApnaRupeeLoanCases from "./ApnaRupeeLoanCase";
import ApnaRupee from "./ApnaRupeeLoanCase/ApnaRupee";
import PFSLoanCase from "./PFSLoanCase/PFSLoanCase";
const initialValues = {
  taskStatus: {
    taskStatusId: "",
  },
};

export default function ViewCase(props) {
 console.log("Received state from parent: :",props.ownerList);
  const { LoanCaseId } = useParams();
  const [owner, setOwner] = useState([]);
  const[showOwner,setShowOWner]=useState([]);
  const [startDates,SetStartdates]=useState({
    startDate:'',
    id:''
  })
  const [plannedEndDates,SetPlannedEndDates]=useState({
    planedEndDate:'',
    id:''
  })
  const [endDates,SetEnddates]=useState({
    endDate:'',
    id:''
  })
  const [templateTaskData , setTemplateTaskData]=useState({
    userVO:{
      userId:''
    },
    id:'',
  });
  useEffect( () => {
    getAllOwnerList()
    //setShowOWner(props.ownerList);
  },[]);
  useEffect(() => {
    console.log("Owner list 2 :",owner);
    console.log("Owner list 02 :",showOwner);
  },[])
  console.log("Owner showOwner 05 :",showOwner);
  const [isSubmitted, setSubmitted] = useState(false);
  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues: initialValues,
      onSubmit: (values) => {
        setSubmitted(true);
        toast.success("Lead created successfully !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
    });

  var renderTooltip = <span>Add More Applicants</span>;
  const [visible, setVisible] = useState(false);
  const navigateToCreateLead = () => {
    navigate("/AddLead");
  };

  const navigateToInsuranceTask = () => {
    navigate(`/insuranceTask`);
  };

  const [products, setProducts] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);

  // useEffect(() => {
  //     ProductService.getProductsWithOrdersSmall().then((data) => setProducts(data));
  // }, []);

  //const { LoanCaseId } = useParams();
  //console.log("loanCaseId", LoanCaseId);

  const [task, settask] = useState([]);
  
 
  const getAllOwnerList= ()=>{
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllUsers`;
      axios
      .get(d6)
      .then((response) => {
        console.log("Owner list 0 :", response.data);
        setOwner(response.data);
        setShowOWner(response.data);
        console.log("Owner list 1 :",owner)
        console.log("Owner list 01 :",showOwner)
      })
      .catch((err) => {
        console.log(err);
      });
      console.log("Owner list 03 :",showOwner);
  }
 
  const [getCustomers, setGetCustomers] = useState([]);
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllLoanCaseTaskByLoanCaseId?loanCaseID=${LoanCaseId}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setProducts(response.data);
        setGetCustomers(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
 // console.log("task", products);
  const expandAll = () => {
    let _expandedRows = {};

   // products.forEach((p) => (_expandedRows[`${p.taskId}`] = true));

    setExpandedRows(_expandedRows);
  };

  const onRowExpand = (event) => {
    toast.current.show({
      severity: "info",
      summary: "Product Expanded",
      detail: event.data.name,
      life: 3000,
    });
  };

  const onRowCollapse = (event) => {
    toast.current.show({
      severity: "success",
      summary: "Product Collapsed",
      detail: event.data.name,
      life: 3000,
    });
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  const allowExpansion = (rowData) => {
    return rowData.subTask.length > 0;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <DataTable value={data.subTask} className="child">
          <Column field="subTaskId"></Column>
          <Column field="subTaskId"></Column>
          <Column field="" style={{ width: "9rem", resize: "none" }}></Column>
          <Column
            field="subTaskName"
            style={{ width: "14rem", resize: "none" }}
          ></Column>
          <Column
            field="caseOwner.ownerName"
            style={{ width: "6rem", resize: "none" }}
          ></Column>
          <Column
            field="taskStatus.taskStatus"
            style={{ width: "6rem", resize: "none" }}
          ></Column>
          <Column
            field="startDate"
            style={{ width: "11rem", resize: "none" }}
          ></Column>
          <Column
            field="plannedEndDate"
            style={{ width: "11rem", resize: "none" }}
          ></Column>

          <Column field="" style={{ width: "6rem", resize: "none" }}></Column>
          <Column field="" style={{ width: "6rem", resize: "none" }}></Column>
          <Column field="" style={{ width: "6rem", resize: "none" }}></Column>
        </DataTable>
      </div>
    );
  };

  const header = (
    <div className="flex flex-wrap justify-content-center gap-2">
      <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} text />
      <Button
        icon="pi pi-minus"
        label="Collapse All"
        onClick={collapseAll}
        text
      />
    </div>
  );

  //console.log("getCustomers=======", getCustomers);
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/customer/getAllTaskStatus`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        settask(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const navigate = useNavigate();
  const navigateToCustomermanagement = () => {
    navigate("/customers");
  };
  const navigateToAddApplicant = () => {
    navigate(`/addApplicantDetails/${LoanCaseId}`);
  };
  const navigateToEditApplicant = () => {
    navigate(`/EditApplicantDetails/${LoanCaseId}`);
  };

  const navigateToAddContact = () => {
    navigate(`/addContactDetails/${LoanCaseId}`);
  };
  const navigateToAddKYC = () => {
    navigate(`/addKycDetail/${LoanCaseId}`);
  };
  const navigateToAddAddress = () => {
    navigate(`/addAddressDetail/${LoanCaseId}`);
  };

  const navigateToAddDocuments = () => {
    navigate(`/addDocuments/${LoanCaseId}`);
  };
  const navigateToAddTask = () => {
    navigate(`/addTask/${LoanCaseId}`);
  };

  const navigateToEditCaseDetails = () => {
    navigate(`/EditCaseDetails/${LoanCaseId}`);
  };

  const navigateToEditContact = () => {
    navigate(`/EditContactDetails/${LoanCaseId}`);
  };

  const [isSetting, setIsSetting] = useState(false);
  const[templateTaskStatus,setTemplateTaskTStatus]=useState({
    taskStatus:{
      taskStatusId:''
    },
    id:'',
  });
  const [viewCase, SetViewCase] = useState({});

  
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/master/getLoanCaseById?loanCaseId=${LoanCaseId}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response :", response.data);
        SetViewCase(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [LoanCaseId]);
  console.log("ViewLoanCase", viewCase);
  const templateId = viewCase.template?.templateId;
  //console.log("templateId", templateId);
  const [template, setTemplate] = useState({});
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/master/getAllTaskByTemplateId?templateId=${templateId}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response template=============== :", response.data);
        setTemplate(response.data);
        // localStorage.setItem('caseTempateTask',JSON.stringify(response.data));
        // assignLoanCAseId();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [templateId]);
  // const storeLocalStorage =()=>{
    
    //assignLoanCAseId();
  //}
 
// const assignLoanCAseId =()=>{
//   const data = window.localStorage.getItem('caseTempateTask')
//       const newData = JSON.parse(data)
//       console.log("newData",newData);
  
//       for (var i = 0; i <= newData.length+1; i++) {
//         if(newData[i]?.loanCaseId != null || newData[i]?.loanCaseId != undefined ||newData[i]?.loanCaseId != 0){  
//           newData[i].loanCaseId = LoanCaseId;  
//           console.log("djnsjbnis======" ,newData[i].loanCaseId);
//         }
//         }
//       localStorage.setItem('caseTempateTask',JSON.stringify(newData));
  
// }
//console.log("TemplateData in for loop 2 ===", template);

  const [tempData, setTempData] = useState({});
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/master/getAllTemplateTaskByLoanCaseId?loancaseId=${LoanCaseId}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response :", response.data);
        setTempData(response.data);
        if(response.code===400){
          addCaseTemplateTaskData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },[templateTaskData,templateTaskStatus,startDates,endDates,plannedEndDates]);
 const addCaseTemplateTaskData=()=>{
  console.log("add CaseTemplateTaskData",template)
  
    template?.map((item) => {
      if (item?.loanCaseId === 0 || item?.loanCaseId === null || item?.loanCaseId === undefined) {
        return (  item.loanCaseId = LoanCaseId );
      } else {
        return item;
      }
    })
  
  axios.post('https://pradhanportal.com/loanApplication/master/addTaskForTemplate',template).then((response)=>{
    console.log(response);
  }).catch((err)=>{
    console.error(err);
  });
  // for(let i=0;i<=template.length;i++){
  //   if(template[i]?.loanCaseId === null || template[i]?.loanCaseId === 0 || template[i]?.loanCaseId === undefined ||template[i]?.loanCaseId === ""){
  //     template[i].loanCaseId=LoanCaseId; 
  //     console.log("template============++++++++++++", template[i].loanCaseId);
  //   }
  // }
  console.log("CaseTemplateTaskData After",template)
 
 }

  
   
  

  const [applicant, setApplicant] = useState({});
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/customer/getAllApplicantByLoanCaseID?loanCaseID=${LoanCaseId}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response :", response.data);
        setApplicant(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [LoanCaseId]);
  //console.log("applicant", applicant);

  var renderTooltip1 = <span>Add Contact Details</span>;
  var renderTooltip2 = <span>Add KYC Details</span>;
  var renderTooltip3 = <span>Add Address Details</span>;
  var renderTooltip4 = <span>Add More Documents</span>;
  var renderTooltip5 = <span>Edit Case Details</span>;
  var renderTooltip6 = <span>Edit Applicant Details</span>;
  var renderTooltip7 = <span>Edit Conatct Details</span>;
  var renderTooltip8 = <span>Edit KYC Details</span>;
  var renderTooltip9 = <span>Edit Permanent Address</span>;
  var renderTooltip10 = <span>Edit Site Address</span>;
  var renderTooltip20 = <span>Edit Current Address</span>;
  var renderTooltip11 = <span>Edit Office Address</span>;
  var renderTooltip12 = <span>Delete Document</span>;
  var renderTooltip21 = <span>Add New Task</span>;
  const [status, setStatus] = useState([]);
  const [updatedStatus, setUpdatedStatus] = useState({
    id:'',
    templateId: "",
    loanCaseId: LoanCaseId,
    templateTaskId: "",
    loanStage: {
      loanStageID: "",
    },
    loanTaskVO: {
      loanTaskId: "",
    },
    subTaskVO: {
      subTaskId: "",
    },
    ownerVO: {
      ownerID: '',
    },
    taskStatus: {
      taskStatusId: "",
    },
    startDate: "",
    plannedEndDate: "",
    duration: "",
    createdBy: "",
    actualEndDate: "",
    taskId: "",
  });


  // const loadOwner =  () => {
  //   const result =  axios.get(`https://pradhanportal.com/loanApplication/master/getAllOwner`);
  //   setOwner(result.data);
  // };
 // console.log("owner", owner);
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/customer/getAllTaskStatus`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setStatus(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChangeFortaskstatus = (e ,id) => {
   // e.preventDefault();
   // console.log("e.target.value :",e?.target?.value);
    // setTemplateTaskTStatus({
    //  // ...templateTaskStatus,
    //   taskStatus: {
    //     taskStatusId: e.target.value,
    //   },
    //   id:id,
    // });
    templateTaskStatus.taskStatus.taskStatusId=e?.target?.value;
    templateTaskStatus.id=id;
     if(templateTaskStatus?.taskStatus.taskStatusId !=null || templateTaskStatus?.taskStatus.taskStatusId != undefined || templateTaskStatus?.taskStatus.taskStatusId !="" || templateTaskStatus?.taskStatus.taskStatusId !=0){
       updateTaskStatus();
     }
  };
  const setTaskIdForStatusTemp = (e, templateTaskId) => {
    setUpdatedStatus({
      ...updatedStatus,
      taskStatus: {
        taskStatusId: e.target?.value,
      },
    });
    updatedStatus.templateTaskId = templateTaskId;
    if (updatedStatus.templateTaskId != null) {
      updateTaskStatusTemp();
    }
  };
  const setTaskIdForStatus = (e, taskId) => {
    setUpdatedStatus({
      ...updatedStatus,
      taskStatus: {
        taskStatusId: e.target?.value,
      },
    });
    updatedStatus.taskId = taskId;
    if (updatedStatus.taskId != null) {
      updateTaskStatus();
    }
  };

  const setTaskIdForCaseOwner = (e, id) => {
   // e.preventDefault();
    console.log("e.target.value :",e.target.value);
      templateTaskData.userVO.userId=e.target.value;

      templateTaskData.id=id;
      //})
      console.log("Update Template Task Owner updatedOwner 2:",templateTaskData)
      if (templateTaskData.id != null || templateTaskData.id != "" || templateTaskData.id != undefined) {
      updateTaskOwner();
    }
  };
  const updateTaskOwner =()=>{
   // alert(JSON.stringify(updatedStatus));
    //alert("updated")
    console.log("Update Status Data updatedStatus in updateTaskOwner:",templateTaskData)
    axios.put('https://pradhanportal.com/loanApplication/master/editTemplateTaskForCase',templateTaskData).then((response)=>{
            console.log(response);
          }).catch((err)=>{
            console.error(err);
          });
  }
  console.log("Status", updatedStatus.taskStatus?.taskStatusId);
  const updateTaskStatusTemp =()=>{
    //alert(JSON.stringify(updatedStatus));
    //alert("updated")
  }
  const updateTaskStatus = () => {
    console.log("Update Status Data updatedStatus in updateTaskOwner:",templateTaskStatus)
    axios.put('https://pradhanportal.com/loanApplication/master/editTemplateTaskForTaskStatus',templateTaskStatus).then((response)=>{
      console.log(response);
    }).catch((err)=>{
      console.error(err);
    });
  };
 const handleChangeForStartDates =(e,id)=>{
   console.log("e.target.value :",e.target.value);
  startDates.startDate=e.target.value;
  startDates.id=id;
   if (startDates.id != null || startDates.id != "" || startDates.id != undefined) {
    updateStartDate();
  }
 }

 const updateStartDate =()=>{
  console.log("Update Start Date :",startDates)
  axios.put('https://pradhanportal.com/loanApplication/master/editTemplateTaskForDates',startDates).then((response)=>{
    console.log(response);
  }).catch((err)=>{
    console.error(err);
  });
}
 const handleChangeForplannedEndDate =(e,id)=>{
  console.log("e.target.value :",e.target.value);
 plannedEndDates.planedEndDate=e.target.value;
 plannedEndDates.id=id;
  if (plannedEndDates.id != null || plannedEndDates.id != "" || plannedEndDates.id != undefined) {
    updatePlannedEndDate();
 }
}
const updatePlannedEndDate =()=>{
  console.log("Update Start Date :",plannedEndDates)
  axios.put('https://pradhanportal.com/loanApplication/master/editTemplateTaskForPlannedDates',plannedEndDates).then((response)=>{
    console.log(response);
  }).catch((err)=>{
    console.error(err);
  });
}
const handleChangeForEndDate =(e,id)=>{
  console.log("e.target.value :",e.target.value);
  endDates.endDate=e.target.value;
  endDates.id=id;
  if (endDates.id != null || endDates.id != "" || endDates.id != undefined) {
    updateEndtDate();
 }
}
const updateEndtDate =()=>{
  console.log("Update Start Date :",endDates)
  axios.put('https://pradhanportal.com/loanApplication/master/editTemplateTaskForEndDates',endDates).then((response)=>{
    console.log(response);
  }).catch((err)=>{
    console.error(err);
  });
}
  const columns = useMemo(
    () => [
      {
        Header: "Sr No",
        Cell: (data) => {
          return (
              (data.row.index + 1)
              
          );
        },
      },

      {
        Header: "Task ID",
        accessor: "id",
        // Cell: (data) => {
        //   return (
        //     <Link
        //       id="leadid1"
        //       to={`/viewTaskDetails/${data?.row.original.taskId}`}
        //     >
        //       {data?.row.original.generatedId}
        //     </Link>
        //   );
        // },
      },

      {
        Header: "Stage",
        accessor: "loanStage.loanStage",
      },
      {
        Header: "Task",
        accessor: "loanTaskVO.loanTask",
      },
      {
        Header: "subTask",
        accessor: "subTaskVO.subTaskName",
      },
      {
        Header: "Task Owner",
       // accessor: "caseOwner.ownerName",
        Cell: (data) => (
          <>

           {JSON.stringify(showOwner)}
             <select
        //  data?.row.original.taskStatus?.taskStatusId != null ? data?.row.original.taskStatus?.taskStatus : 1
        class="form-select form-select-sm"
        style={{width:"150px"}}
        aria-label=".form-select-sm example"
        //name="ownerVO.ownerID"
        onChange={(e) => {
          
          setTaskIdForCaseOwner(e,data?.row.original.id)}}
        value={data.row.original.ownerVO?.ownerID}
      >
        <option value="">Please Select</option>
        {showOwner?.length > 0 ? (
          <Fragment>
            {showOwner.map((taskStatus, index) => {
              return (
                <option value={taskStatus.ownerID} key={index}>
                  {taskStatus.ownerName}
                </option>
              );
            })}
          </Fragment>
        ) : null}
      </select>
          </>
        ),
      },
      {
        Header: "Task Status",
       // accessor: "taskStatus.taskStatus",
        Cell: (data) => (
          <>
              <select
            //  data?.row.original.taskStatus?.taskStatusId != null ? data?.row.original.taskStatus?.taskStatus : 1
                      class="form-select form-select-sm"
                      style={{width:"150px"}}
                      aria-label=".form-select-sm example"
                    //  name='taskStatus.taskStatusId'
                      onChange={(e) => {
                        // setTemplateTaskTStatus({
                        //   ...templateTaskStatus,
                        //   taskStatus:{
                        //     taskStatusId:e.target.value,
                        //   }
                        // });
                        handleChangeFortaskstatus(e,data?.row.original.id)}}
                      value={data?.row.original.taskStatus?.taskStatusId}
                    >
                      <option value="">Please Select</option>
                      {
                        status?.length > 0 ? <Fragment>
                          {
                            status?.map((taskStatus,index)=>{
                              return(
                                <option value={taskStatus.taskStatusId}key={index}>{taskStatus.taskStatus}</option>

                              );
                            })
                          }
                        </Fragment>:null
                      }
                    </select>
          </>
        ),
      },
      {
        Header: "Start Date",
        // accessor: (d) => {
        //   return moment(d.startDate).format("DD-MMM-YYYY");
        // },
        Cell: (data) => (
          <>
            <input
              type="Date"
              name="startDate"
              className="form-control form-control-sm"
              placeholder="Enter Task Name"
            />
          </>
        ),
        filterable: false,
      },
      {
        Header: "Planned End Date",
        // accessor: (d) => {
        //   return moment(d.plannedEndDate).format("DD-MMM-YYYY");
        // },
        Cell: (data) => (
          <>
            <input
              type="Date"
              name="startDate"
              className="form-control form-control-sm"
              placeholder="Enter Task Name"
            />
          </>
        ),
        filterable: false,
      },
      {
        Header: "Duration",
        // accessor:"duration",
        Cell: (data) => {
          return (
            // ({data?.row.original.duration}-Days)
            <Fragment>{data?.row.original?.duration} Day</Fragment>
          );
        },
      },
      {
        Header: "Created By",
        accessor: "createdBy",
      },
      {
        Header: "Actual End Date",
        Cell: (data) => (
          <>
            <input
              type="Date"
              name="startDate"
              className="form-control form-control-sm"
              placeholder="Enter Task Name"
            />
          </>
        ),
        filterable: false,
      },
    ],
    []
  );

  const createtaskSubtask = (rowData) => {
    <UncontrolledDropdown>
      {rowData + 1}
      <DropdownToggle
        href="#"
        className="btn btn-soft-light btn-sm"
        tag="button"
      >
        <img src={setting} width="15" height="15" alt="" className="mx-4" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem
          href="#"
          // onClick={() => {
          //   const productData = cellProps.row.original;
          //   onClickDelete(productData);
          // }}
        >
          <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
          Delete Task
        </DropdownItem>
        <DropdownItem href="/addTask">
          <i className="ri-eye-fill align-bottom me-2 text-muted"></i> Create
          Task Above
        </DropdownItem>
        <DropdownItem href="/addTask">
          <i className="ri-eye-fill align-bottom me-2 text-muted"></i> Create
          Task Below
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>;
  };

  const startDateTemplate = (rowData) => {
    return moment(rowData.startDate).format("DD-MMM-YYYY");
  };

  const plannedDateTemplate = (rowData) => {
    return moment(rowData.startDate).format("DD-MMM-YYYY");
  };

  const durationTemplate = (rowData) => {
    return (rowData.duration),"1 Day";
  };

  const endDateTemplate = (rowData) => {
    return moment(rowData.startDate).format("DD-MMM-YYYY");
  };
  const CaseOwner = (rowData) => {
    return (
      <select
        //  data?.row.original.taskStatus?.taskStatusId != null ? data?.row.original.taskStatus?.taskStatus : 1
        class="form-select form-select-sm"
        aria-label=".form-select-sm example"
        name="userVO.userId"
         onChange={(e) => { setTaskIdForCaseOwner(e,rowData.id,rowData)}}
        value={rowData.userVO?.userId}
      >
        <option value="">Please Select</option>
        {owner?.length > 0 ? (
          <Fragment>
            {owner.map((taskStatus, index) => {
              return (
                <option value={taskStatus?.userId} key={index}>
                  {taskStatus.userName}
                </option>
              );
            })}
          </Fragment>
        ) : null}
      </select>
    );
  };
  const startDateTemp = (rowData) => {
    return (
      <input
        type="Date"
        name="startDate"
        className="form-control form-select-sm"
        placeholder="Enter Task Name"
       value={rowData?.startDate !=="" ? moment(rowData?.startDate).format('YYYY-MM-DD') : ""}
       onChange={(e) => { handleChangeForStartDates(e,rowData.id,rowData);}}
      />
    );
  };
  const plannedDateTemp = (rowData) => {
    return (
      <input
        type="Date"
        name="planedEndDate"
        className="form-control form-select-sm"
        placeholder="Enter Task Name"
        value={rowData?.planedEndDate !==null ? moment(rowData?.planedEndDate).format('YYYY-MM-DD') : ""}
        onChange={(e) => { handleChangeForplannedEndDate(e,rowData.id,rowData);}}
      />
    );
  };
  const endDateTemp = (rowData) => {
    return (
      <input
        type="Date"
        name="endDate"
        className="form-control form-select-sm"
        placeholder="Enter Task Name"
        value={rowData?.endDate !==null ? moment(rowData?.endDate).format('YYYY-MM-DD') : ""}
        onChange={(e) => { handleChangeForEndDate(e,rowData.id,rowData);}}
      />
    );
  };

  const getColor = (taskStatusId) => {
    switch (taskStatusId) {
        case 1:
            return 'open';
        case 2:
            return 'Inprogress';
        case 3:
            return 'hold';
            case 4:
            return 'close';
            case 5:
            return 'cancel';
        default:
            return 'black'; // default color
    }
};
  const statusTemp = (rowData) => {
    return (
      <select
        //  data?.row.original.taskStatus?.taskStatusId != null ? data?.row.original.taskStatus?.taskStatus : 1
        class="form-select form-select-sm"
        aria-label=".form-select-sm example"
        name="taskStatus.taskStatusId"
        onChange={(e) => {
        setTemplateTaskTStatus({
            ...templateTaskStatus,
            taskStatus:{
            taskStatusId:e.target.value,
            },
            id:rowData.id,
        })
          handleChangeFortaskstatus(e, rowData.id);
        }}
        value={rowData.taskStatus?.taskStatusId}
        className={getColor(rowData?.taskStatus?.taskStatusId)}
      >
        <option value=""className="bg-light text-dark">Please Select</option>
        {status?.length > 0 ? (
          <Fragment>
            {status?.map((taskStatus, index) => {
              return (
                <option value={taskStatus?.taskStatusId} key={index} className="bg-light text-dark">
                  {taskStatus?.taskStatus}
                </option>
              );
            })}
          </Fragment>
        ) : null}
      </select>
    );
  };
  const statusTemplate = (rowData) => {
    return (
      <select
        //  data?.row.original.taskStatus?.taskStatusId != null ? data?.row.original.taskStatus?.taskStatus : 1
        class="form-select form-select-sm"
        aria-label=".form-select-sm example"
        name="taskStatus.taskStatus"
        onChange={(e) => {
          setUpdatedStatus({
            ...updatedStatus,
            taskStatus: {
              taskStatusId: e.target.value,
            },
          });
          setTaskIdForStatus(e, rowData.taskId);
        }}
        value={rowData.taskStatus?.taskStatusId}
        className={getColor(rowData?.taskStatus?.taskStatusId)}
      >
        <option value=""  className="bg-light text-dark">Please Select</option>
        {status?.length > 0 ? (
          <Fragment>
            {status?.map((taskStatus, index) => {
              return (
                <option value={taskStatus?.taskStatusId} key={index}  className="bg-light text-dark">
                  {taskStatus?.taskStatus}
                </option>
              );
            })}
          </Fragment>
        ) : null}
      </select>
    );
  };
  const navigateToCreateAboveTask = (sequenceNo, loanCaseID) => {
    navigate(`/addTaskAbove/${sequenceNo},${loanCaseID}`);
  };

  const navigateToCreateBelowTask = (sequenceNo, loanCaseID) => {
    navigate(`/addTaskBelow/${sequenceNo},${loanCaseID}`);
  };
  const navigateToViewTask = (rowData) => {
    return (
      <Link id="leadid1" to={`/viewTaskDetails/${rowData?.taskId}`}>
        {rowData?.generatedId}
      </Link>
    );
  };

  const customerID = viewCase.customerId;
  console.log("customerID", customerID);
  const [customer, setCustomer] = useState({});
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/customer/getCustomerById?customerId=${customerID}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response :", response.data);
        setCustomer(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [customerID]);
  console.log("customer", customer);

  const navigateToEditPermanentAddress = () => {
    localStorage.setItem("LoanCaseId", LoanCaseId);
    navigate(`/EditPermanentAddress/${customerID}`);
  };

  const navigateToEditCurrentAddress = () => {
    localStorage.setItem("LoanCaseId", LoanCaseId);
    navigate(`/EditCurrentAddress/${customerID}`);
  };

  const navigateToEditOfficeAddress = () => {
    localStorage.setItem("LoanCaseId", LoanCaseId);
    navigate(`/EditOfficeAddress/${customerID}`);
  };
  const navigateToEditSiteAddress = () => {
    navigate(`/EditSiteAddress/${LoanCaseId}`);
  };
  const expandedTemplate = (data) => {
    return (
      <div>
        {data.subTask.map((item, index) => {
          return (
            <div className="row" key={index}>
              <div className="col-md-12">
                <div className="table-responsive">
                  <Table className="caption-top table-nowrap mb-0 table-hover">
                    <tbody>
                      <tr>
                        <td className="col-md-1">
                          {/* <span className="subTaskOwner">
                            {item.caseOwner.ownerName}
                          </span> */}
                        </td>
                        <td className="col-md-1">
                          <Link
                            id="leadid1"
                            to={`/viewTaskDetails/${item?.subTaskId}`}
                          >
                            <span className="text-start">
                              1000{item?.subTaskId}
                            </span>
                          </Link>
                          {/* 1000{item.subTaskId} */}
                        </td>
                        <td className="col-md-2 text-center">
                          <span className="subTaskCreatedBy">
                            {item.subTaskName}
                          </span>
                        </td>
                        <td className="col-md-1">
                          <span className="subTaskOwner">
                            {item.caseOwner.ownerName}
                          </span>
                        </td>
                        <td className="col-md-1 text-center">
                          <select
                            className="form-select form-select-sm subTaskStatus"
                            aria-label=".form-select-sm example"
                            name="taskStatus.taskStatus"
                            onChange={handleChangeFortaskstatus(
                              item?.subTaskId
                            )}
                            value={item.taskStatus?.taskStatusId}
                          >
                            <option value="">Please Select</option>
                            {status?.length > 0 ? (
                              <Fragment>
                                {status.map((taskStatus, index) => {
                                  return (
                                    <option
                                      value={taskStatus?.taskStatusId}
                                      key={index}
                                    >
                                      {taskStatus.taskStatus}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </td>
                        <td className="col-md-1 text-center">
                          {" "}
                          <span className="subTaskStartDate">20-Jan-2024</span>
                        </td>
                        <td className="col-md-1 text-center">
                          <span className="subTaskEndDate">21-Jan-2024</span>
                        </td>
                        <td className="col-md-1 text-center">
                          <span className="subTaskDuration">2 Days</span>
                        </td>
                        <td className="col-md-1">
                          <span className="subTaskCreatedBy">Vinay Varma</span>
                        </td>
                        <td className="col-md-1">
                          <span className="subTaskActualEndDate">
                            22-Jan-2024
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Fragment>
      {
        viewCase.partnerVO?.partnerId === 2 ? 
<ApnaRupee/>:null
      }
      {
        viewCase.partnerVO?.partnerName === "PFS" ? 
<PFSLoanCase/>:null
      }
    </Fragment>
  );
}


import React, { useState } from "react";
import TaskRow from "./TaskRows";
function TableRows({rowsData,addTableRows, deleteTableRows, handleChange,props}) {
 const [taskData,setTaskData]=useState([]);


    return(
        
        rowsData?.map((data, index)=>{
          
         
            return(
              
                <tr key={index}>
                    <td><input type="text" value={index+1001}  name="Id" className="form-control" disabled= "true"/></td>
              <TaskRow   />
              <td>
                <i class="bi bi-trash  btn-lg mt-2" id="trashbtn"
                onClick={()=>(deleteTableRows(index))}
                ></i>
                </td>
            </tr>
            )
        })
   
    )
    
}
export default TableRows;
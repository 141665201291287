import React from 'react';
import { Fragment,useMemo } from 'react';
import { useRef } from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useState,useEffect } from 'react';
import TableContainer from '../../../Components/Common/TableContainer';
import { Col, Container, Row, Card, CardHeader, CardBody, Input,} from "reactstrap";
import googleMapIcon from "../../../assets/images/placeholder.png";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap" 
export default function ViewCust() {
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const fileref =useRef(null);
  const toggle = () => setModal(!modal);
  const [modal, setModal] = React.useState(false); 
  const navigate = useNavigate();
  const navigateToCustomermanagement = () => {
    navigate('/customers');
  };

  const navigateToUpdateCustomer=()=>{
    navigate(`/EditCustomer/${customerId}`);
  }

  const onChangePicture = event=>{
    if (event.target.files[0]) {
      console.log("picture: ", event.target.files);
      setPicture(event.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);}
    }
       console.log(imgData);
      const initialValues={
        file:null
      }

      const {customerId}=useParams();
   console.log("customerId",customerId);
   const[loanCasesCount,setLoanCaseCount]=useState();
  const[loanCases,setLoanCases]=useState([]);
  useEffect(()=>{
    const d6 =`https://pradhanportal.com/loanApplication/master/getLoanCasesByCustomerId?customerId=${customerId}`;
    axios.get(d6).then((response)=>{
      console.log("response :",response.data)
      setLoanCases(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);


  useEffect(()=>{
    const d6 =`https://pradhanportal.com/loanApplication/master/getLoanCasesCountByCustomerId?customerId=${customerId}`;
    axios.get(d6).then((response)=>{
      console.log("response :",response.data)
      setLoanCaseCount(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);

  console.log("loanCases======",loanCasesCount?.count);
 
// 
//   if(loanCases?.length > 0){
//     setLoanCaseCount(loanCases?.length);
//   }
 
  


      const[viewCustomer,setviewCustomer]=useState({});
  useEffect(()=>{
    const d6 =`https://pradhanportal.com/loanApplication/customer/getCustomerById?customerId=${customerId}`;
    axios.get(d6).then((response)=>{
      console.log("response :",response.data)
  
      setviewCustomer(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[customerId]);
  console.log(viewCustomer);
 const [abc,setAbc]=useState();
 const  getAge = (dateString)=>{
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}
console.log('age: ' + getAge(viewCustomer.dob));
const dateBirth=viewCustomer.dob;
const dsa=moment(dateBirth).format("DD-MMM-YYYY");
console.log("dateBirth",dsa);

const spousedob=viewCustomer.spouseDOB;
const spouseDOB=moment(spousedob).format("DD-MMM-YYYY");
console.log("spouseDOB",spouseDOB);
const columns = useMemo(
  () => [
    {
      Header: "Case ID",
      Cell: (data) => {
        return (
          <Link id='leadid1' to={`/ViewCustomer/${data?.row.original.loanCaseId}`} >{data?.row.original.id}</Link>
        )}
    },
   
    
    {
      Header: "Type",
      accessor:"leadTypeVO.leadType"
    },
    {
      Header: "Sub Type",
      accessor:"typesVO.type"
    },
    {
      Header: "Start Date",
      accessor:"createdDate"
    },
    {
      Header: "End Date",
    },
    {
      Header: "Product",
    },
    {
      Header: "Case Status",
      accessor:"caseStatus"
    },
   
  ],
  []
);
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card card0">
            <h5 className='mx-2 my-2'>{viewCustomer.customerName} - <span>  <a href="#react" aria-hidden="true"className=" button mx-3 addKYC">Add New Case</a>
</span></h5>
            <div className="row">
              <div className="col-sm-2">
                <div className="card bg-primary card2">
                  <div className='mt-3 text-light text-bold mx-4'>
                    {viewCustomer.customerName}
                  </div>
                <div className="previewProfilePic" >
                
                {
                  imgData !=null ?(
                    <img className="playerProfilePic_home_tile image"
                    src={imgData} 
               
                />
                  ):(
                    <img  className="playerProfilePic_home_tile image"
                    src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                />

                  )
                }
              </div>
              <div className="register_profile_image">
                <input id="profilePic1" ref={fileref} type="file" hidden onChange={onChangePicture} />       
              </div> 
              <div className='mt-4 mx-4'>
                <span className='text-white text-bold'>
                  Customer ID {viewCustomer.generatedID}
                </span>
                </div>  
                <div className='mt-4  mx-4'>
                  <span ><img src={googleMapIcon} alt='' width = "24" height = "30"/></span>
                <span className='text-white text-bold mx-1'>{viewCustomer.prmntState}, </span>
                <span className='text-white text-bold'> {viewCustomer.prmntCountry}</span>
                  </div>  

                  <div className='mt-4  mx-4'>
                  <span className='text-white text-bold mx-2'>Services Availed</span>
                  </div> 
                  <div className='mt-4  mx-4'>
                <span className='text-white text-bold  viewCustomerCaseCount'>{loanCasesCount?.count}</span>
                  </div> 
                </div>
                
              </div>
              <div className="col-sm-3">
              <div className="card card1" >
                    <span id='heading2'>Customer Details </span>
                  <div className="card-body">
                    <div className="row">
                <div className="col-md-6 mt-2">
                   <span className="d-flex justify-content-start">Gender</span>
                </div>
                <div className="col-md-6 mt-2">
                 {viewCustomer.gender}
                </div>
                <div className="col-md-6 mt-2">
                   <span className="d-flex justify-content-start">DOB</span>
                </div>
                <div className="col-md-6 mt-2">
                  {dsa}
                </div>
                <div className="col-md-6 mt-2">
                   <span className="d-flex justify-content-start">Age</span>
                </div>
                <div className="col-md-6 mt-2">
                 {getAge(viewCustomer.dob)}
                </div>
                <div className="col-md-6 mt-2">
                   <span className="d-flex justify-content-start">Customer Type</span>
                </div>
                <div className="col-md-6 mt-2">
                 {viewCustomer.customerType}
                </div>
                <div className="col-md-6 mt-2">
                   <span className="d-flex justify-content-start">Empl Type</span>
                </div>
                <div className="col-md-6 mt-2">
                {viewCustomer.employmentType?.empType}
                </div>
                <div className="col-md-6 mt-2">
                   <span className="d-flex justify-content-start">Resident Type</span>
                </div>
                <div className="col-md-6 mt-2">
               {viewCustomer.residentType?.residentType}

                </div>
                <div className="col-md-6 mt-2">
                   <span className="d-flex justify-content-start">Religion</span>
                </div>
                <div className="col-md-6 mt-2">
                  {viewCustomer.religion?.religionName}
                </div>
                <div className="col-sm-6 mt-2">
                   <span className="d-flex justify-content-start">Caste</span>
                </div>
                <div className="col-sm-6 mt-2">
                {viewCustomer.caste?.casteName}
                </div>
                
                  </div>
                  </div>
                </div>
                <div className="card card6" >
                  {/* <div className="card-header"> */}
                    <span id='heading2'>KYC Details</span>
                  {/* </div> */}
                  <div className="card-body">
                      <div className="row">
                        <div className="col-sm-6 mt-2">
                        <span className="d-flex justify-content-start">PAN</span>
                        </div>
                        <div className="col-sm-6 mt-2">
                          {viewCustomer.pan}
                        </div>
                        <div className="col-sm-6 mt-2">
                        <span className="d-flex justify-content-start">AADHAR</span>
                        </div>
                        <div className="col-sm-6 mt-2">
                          {viewCustomer.aadhar}
                        </div>
                      </div>
                  </div>
                  </div>
              </div>
              <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-12">
                    <div className="card card5">
                {/* <div className="card-header"> */}
                  <span id='heading2'>Contact Details</span>
                {/* </div> */}
                <div className="card-body">
                <div className="row">
                <div className="col-sm-6 mt-2">
                   <span className="d-flex justify-content-start">Primary Mobile No</span>
                </div>
                <div className="col-sm-6 mt-1">
                  {viewCustomer.primaryNumber}
                <span className='mx-2' id='whatsapp'>
                          <i class="bi bi-whatsapp"></i>
                        </span>
                </div>
                <div className="col-sm-6 mt-2">
                   <span className="d-flex justify-content-start">Secondary Mo No</span>
                </div>
                <div className="col-sm-6 mt-2 d-flex justify-content-start">
                {viewCustomer.secondaryNumber}
                </div>
                <div className="col-sm-5 mt-2">
                   <span className="d-flex justify-content-start">Email ID</span>
                </div>
                <div className="col-sm-7 mt-2">
                  {viewCustomer.emailID}
                </div>
                </div>
                </div>
               </div>
              
                   
                    <div className="card card4">
                   
                {/* <div className="card-header"> */}
                 <span id='heading2'> Education Details </span>
                {/* </div> */}
                <div className="card-body">
                     <div className="row">
                        <div className="col-sm-6 mt-2">
                        <span className="d-flex justify-content-start">Highest Qualification</span>
                        </div>
                        <div className="col-sm-6 mt-2">
                          {viewCustomer.qualification?.qualification}
                        </div>
                        <div className="col-sm-6 mt-2">
                        <span className="d-flex justify-content-start">Qualifying Year</span>
                        </div>
                        <div className="col-sm-6 mt-2">
                          {viewCustomer.qualifyingYear}
                        </div>
                     </div>
                </div>
               
             
               </div>
               <div className="card card7">
                  <span id='heading2'>Address Details </span>
                <span className='mt-1 mx-3'  >Permanent Address</span>
                <div className="card-body">
                    {viewCustomer.pAddressLine1}<br/>
                     {viewCustomer.permanentAddressLine2}<br/>
                    {viewCustomer.pLandmark}<br/> 
                     {viewCustomer.prmntCity} - 
                    {viewCustomer.pPIN}<br/> 
                    {viewCustomer.prmntState}<br/> 
                     {viewCustomer.prmntCountry}
                </div>
               </div>
              
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                <div className="card card1">
                  <span id='heading2'>Family Details </span>
                <div className="card-body">
                     <div className="row">
                        <div className="col-sm-6 mt-2">
                        <span className="d-flex justify-content-start">Marital Status</span>
                        </div>
                        <div className="col-sm-6 mt-2">
                          {viewCustomer.maritalStatus?.maritalStatus}
                        </div>
                        {viewCustomer.maritalStatus?.maritalStatus==="Single"?<Fragment>
                          <div className="col-md-6 mt-2">
                        <span className="d-flex justify-content-start">No.of Dependent</span>
                        </div>
                        <div className="col-sm-6 mt-2">
                          {viewCustomer.noOfDependents}
                        </div>
                        </Fragment>:<Fragment>
                        <div className="col-md-6 mt-2">
                        <span className="d-flex justify-content-start">No.of Dependent</span>
                        </div>
                        <div className="col-sm-6 mt-2">
                          {viewCustomer.noOfDependents}
                        </div>
                        <div className="col-sm-6 mt-2">
                        <span className="d-flex justify-content-start">No of Children</span>
                        </div>
                        <div className="col-sm-6 mt-2">
                          {viewCustomer.noOfChildrens}
                        </div>
                        <div className="col-sm-6 mt-2">
                        <span className="d-flex justify-content-start">Spouse Name</span>
                        </div>
                        <div className="col-sm-6 mt-2">
                          {viewCustomer.spouseName}
                        </div>
                        <div className="col-sm-6 mt-2">
                        <span className="d-flex justify-content-start">Spouse DOB</span>
                        </div>
                        <div className="col-sm-6 mt-2">
                        {spouseDOB}
                        </div>
                          </Fragment>}
                       
                     </div>
                </div>
               </div>
               <div className="card card7">
                  <span id='heading12'>Address Details </span>
                <span className='mt-2 mx-3' >Correspondence Address</span>
                <div className="card-body">
                {viewCustomer.cAddressLine1} <br/> 
                {viewCustomer.cAddressLine2}<br/>
                    {viewCustomer.cLandmark} <br/>
                   {viewCustomer.correspondCity} - {viewCustomer.cPIN}<br/> 
                   {viewCustomer.correspondState} <br/>{viewCustomer.correspondsCountry}
                </div>
               </div>
              </div>
              
            </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid ">
      <div className="row service mt-5">
          <div className="col-md-12">
              <span className='p-1'>Service Details</span>
          </div>
        </div>
      </div>
      
      <Container fluid>
            <Row>
                <Col lg={12}>
                <Card >
                <CardBody className="pt-0">
                {
                  loanCases?.length > 0 ? <Fragment>
                    <div className="main">
                    <TableContainer
                    columns={columns}
                    data={loanCases}
                    isGlobalFilter={false}
                    isAddUserList={false}
                    customPageSize={3}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-0"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-secondary"
                    isLeadsFilter={false}
                    SearchPlaceholder='Search for'
                    highlightOnHover
                  />
                  </div>
                  </Fragment>:null
                }
                  </CardBody>
                  <div className="row mt-3 mb-2">
                      <div className="col-md-12">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                          <button
                          onClick={navigateToCustomermanagement}
                            class="btn btn-secondary"
                            id="editbtn"
                            type="button"
                          >
                            Back
                          </button>
                          <button
                          onClick={navigateToUpdateCustomer}
                          class="btn btn-primary" type="button" id='editbtn'>
                           Edit Customer
                          </button>
                        </div>
                      </div>
                    </div>
                   
                </Card>
                </Col>
            </Row>
        </Container>
    </Fragment>
  )
}

import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../../assets/images/editIcon.png";
import Tooltip from "rc-tooltip";

export default function ViewBankBranch() {
    var renderTooltip = <span>Edit Bank Branch</span>;
    const { bankBranchId } = useParams();
    const [bankBranch, setBankBranch] = useState([]);
    const navigate = useNavigate();
    const naviagteToChannelPartners = () => {
      navigate("/bankBranchmgmt");
    };
    const navigateToEditCP = () => {
      navigate(`/editBankBranch/${bankBranchId}`);
    };
    useEffect(() => {
      const d6 = `https://pradhanportal.com/loanApplication/master/getBankBranchByBankBranchId?bankBranchId=${bankBranchId}`;
      axios
        .get(d6)
        .then((response) => {
          console.log("response :", response.data);
          setBankBranch(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);
  return (
    <Fragment>
        <div className="container-fluid ">
        <div className="card">
          <div className="row mt-5">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="card" id="card1">
                <div className="row">
                  <div className="col-md-9">
                    <div id="heading2" className="">
                      {bankBranch.bankBranchName}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mt-3" style={{ marginRight: "17px" }}>
                      <Tooltip placement="left" overlay={renderTooltip}>
                        <h6 id="viewLeadEditIcon">
                          <img
                            src={editIcon}
                            width="24"
                            height="19"
                            alt=""
                            className=""
                            onClick={navigateToEditCP}
                          />
                        </h6>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <span className="d-flex justify-content-end">
                        Branch ID
                      </span>
                    </div>
                    <div className="col-md-8">
                      <span className=" text-white px-1" id="leadidbg">
                        {bankBranch.generatedId}
                      </span>
                    </div>
                    <div className="col-md-4 mt-2">
                      <span className="d-flex justify-content-end">Bank Name</span>
                    </div>
                    <div className="col-md-8 mt-2">
                      {bankBranch.bank?.bankName}
                    </div>
                    <div className="col-md-4 mt-2">
                      <span className="d-flex justify-content-end">Branch Name</span>
                    </div>
                    <div className="col-md-8 mt-2">
                      {bankBranch.bankBranchName}
                    </div>
                    <div className="col-md-4 mt-2">
                      <span className="d-flex justify-content-end">Branch Email ID</span>
                    </div>
                    <div className="col-md-8 mt-2">
                      {bankBranch.branchEmailID}
                    </div>
                    <div className="col-md-4 mt-2">
                      <span className="d-flex justify-content-end">Branch Code</span>
                    </div>
                    <div className="col-md-8 mt-2">
                      {bankBranch.branchCode}
                    </div>
                    <div className="col-md-4 mt-2">
                      <span className="d-flex justify-content-end">BM Name</span>
                    </div>
                    <div className="col-md-8 mt-2">
                      {bankBranch.bankManagerName}
                    </div>
                    <div className="col-md-4 mt-2">
                      <span className="d-flex justify-content-end">BM Primary Mo.No.</span>
                    </div>
                    <div className="col-md-8 mt-2">
                      {bankBranch.bankManagerPrimaryNumber}
                    </div>
                    <div className="col-md-4 mt-2">
                      <span className="d-flex justify-content-end">BM Secondary Mo.No.</span>
                    </div>
                    <div className="col-md-8 mt-2">
                      {bankBranch.bankManagerSecondaryNumber}
                    </div>
                    <div className="col-md-4 mt-2">
                      <span className="d-flex justify-content-end">BM Email ID</span>
                    </div>
                    <div className="col-md-8 mt-2">
                      {bankBranch.bankManagerEmailID}
                    </div>
                    <div className="col-md-4 mt-2">
                      <span className="d-flex justify-content-end">DSA Code</span>
                    </div>
                    <div className="col-md-8 mt-2">
                      {bankBranch.dsaCode}
                    </div>
                    <div className="col-md-4 mt-2">
                      <span className="d-flex justify-content-end">Country</span>
                    </div>
                    <div className="col-md-8 mt-2">
                      {bankBranch.country}
                    </div>
                    <div className="col-md-4 mt-2">
                      <span className="d-flex justify-content-end">State</span>
                    </div>
                    <div className="col-md-8 mt-2">
                      {bankBranch.state}
                    </div>
                    <div className="col-md-4 mt-2">
                      <span className="d-flex justify-content-end">City</span>
                    </div>
                    <div className="col-md-8 mt-2">
                      {bankBranch.city}
                    </div>
                    <div className="col-md-4 mt-2">
                      <span className="d-flex justify-content-end">Pin Code</span>
                    </div>
                    <div className="col-md-8 mt-2">
                      {bankBranch.pinCode}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="row mt-4 mb-4">
              <div className="col-md-12">
                <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                  <button
                    onClick={naviagteToChannelPartners}
                    className="btn btn-secondary previousButton"
                    type="button"
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

import React,{Fragment} from 'react';
import { Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap" ;
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import {  toast } from 'react-toastify';

export default function AddSubType() {
    const [isSubmitted, setSubmitted]=useState(false);
    const navigate = useNavigate();
  const[loanTypeData,setLoanTypeData]=useState({
    leadTypeVO:{
      leadTypeID:"",
    },    
    typeVO:{
      typeId:''
    },
    subTypes:'',
  })


   const [leadType,setLeadType] = useState({});
   useEffect(() => {
     const d6 = 'https://pradhanportal.com/loanApplication/master/getAllLeadType';
     axios
       .get(d6)
       .then((response) => {
         console.log("Bank List response :", response.data);
 
         setLeadType(response.data);
       })
       .catch((err) => {
         console.log(err);
       });
   }, []);

const typeId=loanTypeData.leadTypeVO?.leadTypeID;
   const [type,setType] = useState({});
   useEffect(() => {
     const d6 = `https://pradhanportal.com/loanApplication/master/getTypesByLeadID?leadTypeID=${typeId}`;
     axios
       .get(d6)
       .then((response) => {
         setType(response.data);
       })
       .catch((err) => {
         console.log(err);
       });
   }, [typeId]);

   const handleChangeForLeadType =(e)=>{
    setLoanTypeData({
        ...loanTypeData,
        leadTypeVO: {
            leadTypeID:e.target.value
          },
    })
  }

  const handleChangeForType =(e)=>{
    setLoanTypeData({
        ...loanTypeData,
        typeVO: {
            typeId:e.target.value
          },
    })
  }

const handleChange=(e)=>{
  setLoanTypeData({
    ...loanTypeData,
    [e.target.name]:e.target.value,
  })
}
  
  const handleSubmit=(e)=>{
    e.preventDefault();
    axios.post('https://pradhanportal.com/loanApplication/master/addSubType',loanTypeData).then((response)=>{
      console.log(response);
      setSubmitted(true);
    }).catch((err)=>{
      console.error(err);
    });
   }

   const navigateToSubTypeManagement=()=>{
    navigate('/subTypesManagement');
   }
  return (
    <Fragment>
         {
       isSubmitted ? navigate(`/subTypesManagement`) &
       toast.success('Lead Sub Type Added successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
        <div className="container">
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                   <form onSubmit={handleSubmit}>
                   <div className="card">
                        <div className="card-header bg-primary d-flex justify-content-center cardHeader">
                        <span id="assignowner1">Add New Lead Sub Type</span>
                        </div>
                        <div className="card-body">
                          <div className="row mt-2">
                          <div className="col-md-4 mt-3">
                            <span
                              className="d-flex justify-content-end  "
                            >
                              Lead Type
                            </span>
                          </div>
                          <div className="col-md-5 mt-2">
                            <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="leadTypeVO.leadTypeID"
                              onChange={handleChangeForLeadType}
                              value={loanTypeData.leadTypeVO?.leadTypeID}
                            >
                              <option value="">Please Select</option>
                              {leadType?.length > 0 ? (
                                <Fragment>
                                  {leadType.map((type, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={type?.leadTypeID}
                                      >
                                        {type?.leadType}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                            </select>
                          </div>
                          <div className="col-md-4 mt-3">
                            <span
                              className="d-flex justify-content-end  "
                            >
                            Type
                            </span>
                          </div>
                          <div className="col-md-5 mt-2">
                            <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="typeVO.typeId"  
                              onChange={handleChangeForType}
                              value={loanTypeData.typeVO?.typeId}
                            >
                              <option value="">Please Select</option>
                              {type?.length > 0 ? (
                                <Fragment>
                                  {type.map((type, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={type?.typeId}
                                      >
                                        {type?.type}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                            </select>
                          </div>
                            <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Sub Type Name</span>
                      </div>
                      <div className="col-md-5 mt-2">
                        <input
                         type="text"
                         name="subTypes"
                          value={loanTypeData.subTypes}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Sub Type Name"
                        /> 
                      </div>
                      <div className="col-md-3 mt-2"></div>
                          </div>
                        </div>
                            <div className="row mt-3 mb-3">
                        <div className="col-md-12">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  className="previousButton" onClick={navigateToSubTypeManagement}>Cancel</Button>
                        <Button color="primary" type="submit" className="nextButton">Add Sub Type</Button>
                        </div>
                        </div>
                        </div>
                    </div>
                   </form>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
}
    </Fragment>
  )
}


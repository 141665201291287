import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {  toast } from 'react-toastify';
import Tooltip from "rc-tooltip";
import { Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap"
export default function EditSubTask() {
  const { subTaskId } = useParams();
  const [isSubmitted, setSubmitted]=useState(false);
  const [loanTaskData, setLoanSTaskData] = useState({
    loanStageVO:{
        loanStageID:''
    },
    loanTaskVO:{
        loanTaskId:''
    },
    subTaskName:'',
  });
  const navigate = useNavigate();
  const naviagteToViewLoanTask = () => {
    navigate(`/viewLoanSubTask/${subTaskId}`);
  };
 

  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getSubTaskBySubTaskId?subTaskId=${subTaskId}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setLoanSTaskData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const[loanStage,setLoanStage]=useState([]);
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllLoanStage`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setLoanStage(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
const stageId=loanTaskData.loanStageVO?.loanStageID
  const[loantask,setLoanTask]=useState([]);
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllLoanTaskByStageId?loanStageID=${stageId}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setLoanTask(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [stageId]);


  const handleChange=(e)=>{
    setLoanSTaskData({
        ...loanTaskData,
        [e.target.name]:e.target.value
    })
  }
  const handleChangeForStage=(e)=>{
    setLoanSTaskData({
        ...loanTaskData,
        loanStageVO:{
            loanStageID:e.target.value
            }
            })
  }

  const handleChangeForTask=(e)=>{
    setLoanSTaskData({
        ...loanTaskData,
        loanTaskVO:{
            loanTaskId:e.target.value
            }
            })
  }
  const handleSubmit=(e)=>{
    e.preventDefault();
    axios.put('https://pradhanportal.com/loanApplication/master/editLoanSubTask',loanTaskData).then((response)=>{
        console.log(response);
        setSubmitted(true);
      }).catch((err)=>{
        console.error(err);
      });
  }

  const authUser = JSON.parse(localStorage.getItem("user"));

  var renderTooltip = <span>Delete Sub Task</span>;

  const [modal1, setModal1] = React.useState(false);
  const toggle1 = () => setModal1(!modal1);

  const DeleteLead = () => {
    const dataUrl =`https://pradhanportal.com/loanApplication/master/deleteLoanSubTask?subTaskId=${subTaskId}`;
        axios.post(dataUrl).then((response)=>{
        
          console.log("response :",response.data);
          
          setLoanSTaskData(response.data);
        }).catch((err)=>{
           console.log(err);
        });
  navigate(`/loanSubTask`);
  toast.success('Loan Sub Task Deleted Successfully !', {
    position: toast.POSITION.TOP_RIGHT
});
};


  return (
   <Fragment>
    {/* <pre>{JSON.stringify(loanTaskData)}</pre> */}
     {
       isSubmitted ? navigate(`/viewLoanSubTask/${subTaskId}`) &
       toast.success('Loan Sub Task Edited Successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
     <div className="container">
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                   <form onSubmit={handleSubmit}>
                   <div className="card">
                        <div className="card-header bg-primary d-flex justify-content-center  cardHeader">
                        <span id="assignowner1">Edit Loan Sub Task</span>
                        </div>
                        {
            authUser.role?.role === "Local Admin" ? <Fragment>
            <Tooltip  placement="right" overlay={renderTooltip} >
                <h3 id="trashbtn">
                <i class="bi bi-trash  btn-lg" 
                
                onClick={toggle1}
                ></i>
            </h3>
            </Tooltip>
            </Fragment>:null
           }
            <Modal fade={true} isOpen={modal1} toggle={toggle1} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#f7b84b,secondary:#f06548"
            style={{ width: "100px", height: "100px" }}
          ></lord-icon>
          <span className='warningmodal'>Warning !</span>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-4 mb-0">
              Are you sure you want to remove this record ?
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={toggle1}
          >
            Close
          </button>
          <button
            type="button"
            className="btn w-sm btn-danger "
            id="delete-record"
            onClick={()=>{setLoanSTaskData(); DeleteLead()}}
          >
            Yes, Delete It!
          </button>
        </div>
      </ModalBody>
    </Modal>
                        <div className="card-body">
                          <div className="row mt-2">
                          <div className="col-md-4 mt-3">
                                <span
                                  className="d-flex justify-content-end  "
                                >
                                  Loan Stage
                                </span>
                              </div>
                              <div className="col-md-5 mt-2">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-md example"
                                  name="loanStageVO.loanStageID"
                                  onChange={handleChangeForStage}
                                  value={loanTaskData.loanStageVO?.loanStageID ==="" ? loanTaskData.loanStageVO.loanStageID = loanStage.loanStageID : loanTaskData.loanStageVO?.loanStageID}
                                >
                                  <option value="">Please Select</option>
                                  {loanStage?.length > 0 ? (
                                    <Fragment>
                                      {loanStage.map((loanStage, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={loanStage?.loanStageID}
                                          >
                                            {loanStage?.loanStage}
                                          </option>
                                        );
                                      })}
                                    </Fragment>
                                  ) : null}
                                </select>
                              </div>
                              <div className="col-md-3 mt-2"></div>
                              <div className="col-md-4 mt-3">
                                <span
                                  className="d-flex justify-content-end  "
                                >
                                  Loan Task
                                </span>
                              </div>
                              <div className="col-md-5 mt-2">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-md example"
                                  name="loanTaskVO.loanTaskId"
                                  onChange={handleChangeForTask}
                                  value={loanTaskData.loanTaskVO?.loanTaskId ==="" ? loanTaskData.loanTaskVO.loanTaskId = loantask.loanTaskId : loanTaskData.loanTaskVO?.loanTaskId}
                                >
                                  <option value="">Please Select</option>
                                  {loantask?.length > 0 ? (
                                    <Fragment>
                                      {loantask.map((loanTask, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={loanTask?.loanTaskId}
                                          >
                                            {loanTask?.loanTask}
                                          </option>
                                        );
                                      })}
                                    </Fragment>
                                  ) : null}
                                </select>
                              </div>
                              <div className="col-md-3 mt-2"></div>
                            <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Loan Sub Task</span>
                      </div>
                      <div className="col-md-5 mt-2">
                        <input
                         type="text"
                         name="subTaskName"
                          value={loanTaskData.subTaskName}
                         onChange={handleChange}
                          className="form-control sm"
                        /> 
                      </div>
                      <div className="col-md-3 mt-2"></div>
                          </div>
                        </div>
                            <div className="row mt-3 mb-3">
                        <div className="col-md-12">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  className="previousButton" onClick={naviagteToViewLoanTask}>Cancel</Button>
                        <Button color="primary" type="submit" className="nextButton">Update</Button>
                        </div>
                        </div>
                            </div>
                    </div>
                   </form>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
}
   </Fragment>
  )
}


import React,{Fragment,useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
export default function AddSubTask() {
  const{taskId}= useParams();
  const [isSubmitted, setSubmitted]=useState(false);
  const[subTask,setSubTask]=useState({
    taskId:'',
    subTaskName:'',
    description:'',
    startDate:'',
    plannedEndDate:'',
    caseOwner:{
      ownerID:'',
    }
  })

subTask.taskId=taskId;
console.log("taskID=====",subTask.taskId);

  const[task,settask]=useState([]);
  useEffect(()=>{
    const d6 =`https://pradhanportal.com/loanApplication/master/getLoanCaseTaskById?taskId=${taskId}`;
    axios.get(d6).then((response)=>{
      console.log("response :",response.data)
      settask(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);

  const[leadOwners,setLeadOwners]=useState([]);
  useEffect(()=>{
    const d1 =`https://pradhanportal.com/loanApplication/master/getAllOwner`;
    axios.get(d1).then((response)=>{
      console.log("response :",response.data)
  
      setLeadOwners(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);
console.log("task========",task); 


const handleChange = (e) => {  
  setSubTask({ 
      ...subTask,
    [e.target.name]: e.target.value       
  }); 
};

const handleChangeForCaseOwner=(e)=>{
  setSubTask({ 
    ...subTask,
    caseOwner:{
      ownerID: e.target.value } 
}); 
}

const handleSubmit=(e)=>{
  // e.priventDefault();
  e.preventDefault();
  axios.post('https://pradhanportal.com/loanApplication/master/addloanCaseSubTask',subTask).then((response)=>{
            console.log(response);
            setSubmitted(true);
          }).catch((err)=>{
            console.error(err);
          });
  }

  return (
    <Fragment>
      <pre>{JSON.stringify(subTask)}</pre>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                <form onSubmit={handleSubmit}>
                    <div className="card">
                        <div className="row">
                            <div className="col-md-12">
                            <span id="taskheading3"className='mx-3 my-3'>Task - <span className=' text-white px-1'id='leadidbg'> {task?.taskName} </span>
                           </span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-1"></div>
                            {/* <div className="col-md-2">
                                 <span>Task Name</span>
                            </div>
                            <div className="col-md-7">
                                
                            <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='employmentType.empTypeID'
                        >
                          <option selected>Please Select</option>
                        </select>
                            </div> */}
                        </div>
                        <div className="row mt-1">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 <span>Sub Task Name</span>
                            </div>
                            
                        </div>
                        <div className="row mt-1">
                        <div className="col-md-1"></div>
                        <div className="col-md-9">
                            <input
                          type="text"
                          name="subTaskName"
                          onChange={handleChange}
                          value={subTask?.subTaskName}
                          className="form-control sm"
                          placeholder="Enter Task Name"
                        />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 <span>Add Description (Optional)</span>
                            </div>
                           
                        </div>
                        <div className="row">
                        <div className="col-md-1"></div>
                            <div className="col-md-9">
                             <div className="form-group">
                              <textarea
                              name="description"
                              onChange={handleChange}
                              value={subTask?.description}
                              className="form-control"
                              maxLength="300"
                              rows={5}
                             />
                            </div>
                            <div className=" d-md-flex justify-content-md-end"> Remaining Characters: 300 /300 </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 {/* <span>Add Description</span> */}
                            </div>
                            
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 <span>Schedule</span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-1"></div>
                            <div className="col-md-1 mt-2">
                                 <span className='mt-2'>Start Date</span>
                            </div>
                            <div className="col-md-3">
                            <input
                          type="Date"
                          name="startDate"
                          onChange={handleChange}
                          value={subTask?.startDate}
                          className="form-control sm"
                          placeholder="Enter Task Name"
                        />
                            </div>
                            <div className="col-md-1 mt-2">
                                 <span className='mt-2'>End Date</span>
                            </div>
                            <div className="col-md-3">
                            <input
                          type="Date"
                          name="plannedEndDate"
                          onChange={handleChange}
                          value={subTask?.plannedEndDate}
                          className="form-control sm"
                          placeholder="Enter Task Name"
                        />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 <span>Owner</span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                            <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='caseOwner.ownerID'
                          onChange={handleChangeForCaseOwner}
                          value={subTask?.caseOwner?.ownerID}
                        >
                          <option selected>Please Select</option>
                          {leadOwners?.length > 0 ? (
                                    <Fragment>
                                      {leadOwners.map((role, index) => {
                                        return (
                                          <option
                                            value={role.ownerID}
                                            key={index}
                                          >
                                            {role.ownerName}
                                          </option>
                                        );
                                      })}
                                    </Fragment>
                                  ) : null}
                        </select>
                            </div>
                        </div>
                        <div className="row mt-3 mb-3">
                      <div className="col-md-9 mt-2">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                          <button
                          onClick={''}
                            class="btn btn-secondary me-md-2"
                            id=""
                            type="button"
                          >
                            Cancel
                          </button>
                          <button class="btn btn-primary" type="submit" id="">
                           Add Sub Task
                          </button>
                          <button class="btn btn-primary" type="submit" id="">
                           Add More Sub Task
                          </button>
                        </div>
                      </div>
                    </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

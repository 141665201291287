import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import csc from "country-state-city";
import Select from "react-select";
export default function EditSiteAddress() {
  const [isSubmitted, setSubmitted] = useState(false);
  const { LoanCaseId } = useParams();
  const navigate = useNavigate();
  console.log("loanCaseId", LoanCaseId);

  const [loanCase, SetLoanCase] = useState({
    LoanCaseId: "",
    siteAddressLine1: "",
    siteAddressLine2: "",
    siteLandmark: "",
    sitePin: "",
    siteCountryId: "",
    siteCountry: "",
    siteStateId: "",
    siteState: "",
    siteCityId: "",
    siteCity: "",
  });
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/master/getLoanCaseById?loanCaseId=${LoanCaseId}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response :", response.data);
        SetLoanCase(response.data);
        setSelectedsiteVal({
          label: response?.data.siteCountry,
          id: response?.data.siteCountryId,
        });
        setSelectedValsitestate({
          label: response?.data.siteState,
          id: response?.data.siteStateId,
        });
        setSelectedValsitecity({
          label: response?.data.siteCity,
          id: response?.data.siteCityId,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [LoanCaseId]);
  console.log("loanCase", loanCase);

  loanCase.LoanCaseId = LoanCaseId;


  const countries = csc.getAllCountries();
  console.log(csc.getAllCountries());

  const updatedSiteCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));

  const updatedSiteStates = (siteCountryId) =>
    csc
      .getStatesOfCountry(siteCountryId)
      .map((state) => ({ label: state.name, value: state.id, ...state }));

  const updatedSiteCities = (siteStateId) =>
    csc
      .getCitiesOfState(siteStateId)
      .map((city) => ({ label: city.name, value: city.id, ...city }));

  const [selectedsiteVal, setSelectedsiteVal] = useState({
    label: loanCase.siteCountry,
    id: loanCase.siteCountryId,
  });
  const [selectedValsitestate, setSelectedValsitestate] = useState({
    label: loanCase.siteState,
    id: loanCase.siteStateId,
  });
  const [selectedValsitecity, setSelectedValsitecity] = useState({
    label: loanCase.siteCity,
    id: loanCase.siteCityId,
  });

  const ddlChangeSiteCountry = (e) => {
    SetLoanCase({
      ...loanCase,
      siteCountry: e.name,
      siteCountryId: e.value,
    });
    setSelectedsiteVal({ label: e.name, id: e.value });
  };

  const ddlChangeSiteState = (e) => {
    SetLoanCase({
      ...loanCase,
      siteState: e.name,
      siteStateId: e.value,
    });
    setSelectedValsitestate({ label: e.name, id: e.value });
  };

  const ddlChangesiteCity = (e) => {
    SetLoanCase({
      ...loanCase,
      siteCity: e.name,
      siteCityId: e.value,
    });
    setSelectedValsitecity({ label: e.name, id: e.value });
  };
  const handleChange = (e) => {
    SetLoanCase({
      ...loanCase,
      [e.target.name]: e.target.value,
    });
  };

  const SubmitForm = (e) => {
    e.preventDefault();
    axios
      .put("https://pradhanportal.com/loanApplication/master/editLoanCaseSiteAddress", loanCase)
      .then((response) => {
        console.log(response);

        setSubmitted(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const navigateToPrevious = () => {
    navigate(`/viewLoanCase/${LoanCaseId}`);
  };
  return (
    <Fragment>
      {isSubmitted ? (
        navigate(`/viewLoanCase/${LoanCaseId}`) &
        toast.success("Site Address Updated successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        })
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <div className="card">
                <form onSubmit={SubmitForm}>
                  <div className="card-header bg-primary d-flex justify-content-Start pb-1 pt-1 loanCaseCardHeader">
                    <span id="assignowner1">
                      {loanCase.customerName} - {loanCase.id}
                    </span>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end address">
                          Site Address
                        </span>
                      </div>
                      <div className="col-md-8 mt-2"></div>
                      <div className="col-md-3 mt-4">
                        <span className="d-flex justify-content-end">
                          Address Line 1
                        </span>
                      </div>
                      <div className="col-md-6 mt-3">
                        <input
                          type="text"
                          name="siteAddressLine1"
                          className="form-control sm"
                          placeholder="Enter Address Line 1"
                          value={loanCase.siteAddressLine1}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">
                          Address Line 2
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="siteAddressLine2"
                          className="form-control sm"
                          placeholder="Enter Address Line 2"
                          value={loanCase.siteAddressLine2}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">
                          Landmark
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="siteLandmark"
                          className="form-control sm"
                          placeholder="Enter Landmark"
                          value={loanCase.siteLandmark}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end  ">
                          Country
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <Select
                          id="siteCountry"
                          name="siteCountry"
                          className="countrydrp"
                          onChange={ddlChangeSiteCountry}
                          value={selectedsiteVal}
                          options={updatedSiteCountries}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end  ">
                          State
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <Select
                          name="siteState"
                          id="siteState"
                          className="countrydrp"
                          value={selectedValsitestate}
                          options={updatedSiteStates(
                            loanCase.siteCountryId
                              ? loanCase.siteCountryId
                              : null
                          )}
                          onChange={ddlChangeSiteState}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end  ">
                          City
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <Select
                          id="siteCity"
                          className="countrydrp"
                          name="siteCity"
                          options={updatedSiteCities(
                            loanCase.siteStateId ? loanCase.siteStateId : null
                          )}
                          value={selectedValsitecity}
                          onChange={ddlChangesiteCity}
                        />
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">PIN</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="number"
                          name="sitePin"
                          className="form-control sm"
                          placeholder="Enter PIN"
                          value={loanCase.sitePin}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3"></div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="row">
                      <div className="col-md-3"></div>
                      <div className="col-md-6 my-1">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                          <Button
                            color="secondary"
                           className="previousButton"
                            onClick={navigateToPrevious}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            type="submit"
                            className="nextButton"
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

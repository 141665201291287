
import React,{Fragment} from 'react';
import { useEffect,useState } from 'react';
import axios from 'axios';
import { useFormik } from "formik";
import { useNavigate, useParams,useLocation } from 'react-router-dom';
import AddTaskTemplate from '../addTemplates.js';
const initialValues = {
  templateId:'',
  loanStage:{
    loanStageID:'',
  },
  loanTask:'',
  description:'',
  startDate:'',
  endDate:'',
  ownerVO: {
    ownerID: '',
},
subTaskVO:{
  taskId:'',
  subTaskName:'',
  description:'',
  startDate:'',
  endDate:'',
  ownerVO: {
    ownerID: '',
},
}
}
export default function AddLoanTask() {
const navigate = useNavigate();

  const{templateId} = useParams();
 // console.log("tempId",templateId)
  const[taskData,setTaskData]= useState({
    id:'',
    templateId:'',
  loanStage:{
    loanStageID:'',
    loanStage:'',
  },
  loanTask:'',
  description:'',
  startDate:'',
  endDate:'',
  ownerVO: {
    ownerID: '',
},

  });
  const handleChangeForStage=(e)=>{
    setTaskData({ 
      ...taskData,
      loanStage:{
        loanStageID: e.target.value } 
  }); 
  }

    const handleChange = (e) => {  
      setTaskData({ 
          ...taskData,
        [e.target.name]: e.target.value       
      }); 
    };
    const handleChangeForOwner=(e)=>{
      setTaskData({ 
        ...taskData,
        ownerVO:{
          ownerID: e.target.value } 
    }); 
    }
    
    
  const tempTask = JSON.parse(localStorage.getItem('tempTask') || '[]');
  tempTask.push(taskData);


   console.log("taskData",taskData);
   const navigateToTemplate =()=>{
    navigate('/addTaskTemplates')
   }
  const[stage,setStage]=useState([]);
  useEffect(() => {
      loadLead();
      //  setTaskData([...taskData, state?.values]);
    }, []);
  const loadLead = async () => {
      const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllLoanStage`);
      setStage(result.data);
 
    };
    //console.log("stage",stage);

    const[owner,setOwner]=useState([]);
  useEffect(() => {
    loadOwner();
    }, []);
  const loadOwner = async () => {
      const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllOwner`);
      setOwner(result.data);
 
    };
    const addId=()=>{
      const data = window.localStorage.getItem('tempTask')
      const newData = JSON.parse(data)
      console.log("newData",newData);
  
      for (var i = 0; i <= newData.length+1; i++) {
        if(newData[i]?.id != null || newData[i]?.id != undefined){  
          newData[i].id = i+1;  
          console.log("djnsjbnis======" ,newData[i].id);
        }
        }
      localStorage.setItem('tempTask',JSON.stringify(newData));
      
    }
    const handleSubmit=(e)=>{
      
      e.preventDefault();
     
      localStorage.setItem("tempTask",JSON.stringify(tempTask));
      addId();
      
      console.log("taskData=====",taskData);
    };
  return (
    <Fragment>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                    <form onSubmit={handleSubmit} >
                        <div className="row">
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 <span>Stage</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                            <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='loanStage.loanStageID'
                          onChange={handleChangeForStage}
                          value={taskData.loanStage?.loanStageID}
                        >
                          <option selected>Please Select</option>
                          {
                            stage?.length > 0 ? <Fragment>
                              {
                                stage.map((loanStage,index)=>{
                                  return(
                                    <option value={loanStage?.loanStageID}key={index}>{loanStage.loanStage}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 <span>Task Name</span>
                            </div>
                            
                        </div>
                        <div className="row ">
                        <div className="col-md-1"></div>
                        <div className="col-md-9">
                            <input
                          type="text"
                          name="loanTask"
                          className="form-control sm"
                          placeholder="Enter Task Name"
                          onChange={handleChange}
                          value={taskData.loanTask}
                        />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 <span>Add Description (Optional)</span>
                            </div>
                           
                        </div>
                        <div className="row">
                        <div className="col-md-1"></div>
                            <div className="col-md-9">
                             <div className="form-group">
                              <textarea
                              name="description"
                              className="form-control"
                              maxLength="300"
                              rows={5}
                              onChange={handleChange}
                              value={taskData.description}
                             />
                            </div>
                            <div className=" d-md-flex justify-content-md-end"> Remaining Characters: 300 /300 </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 {/* <span>Add Description</span> */}
                            </div>
                            
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 <span>Schedule</span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-1"></div>
                            <div className="col-md-1 mt-2">
                                 <span className='mt-2'>Start Date</span>
                            </div>
                            <div className="col-md-3">
                            <input
                          type="Date"
                          name="startDate"
                          className="form-control sm"
                          placeholder="Enter Task Name"
                          onChange={handleChange}
                          value={taskData.startDate}
                        />
                            </div>
                            <div className="col-md-1 mt-2">
                                 <span className='mt-2'>End Date</span>
                            </div>
                            <div className="col-md-3">
                            <input
                          type="Date"
                          name="endDate"
                          className="form-control sm"
                          placeholder="Enter Task Name"
                          onChange={handleChange}
                          value={taskData.endDate}
                        />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                                 <span>Owner</span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-1"></div>
                            <div className="col-md-9">
                            <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='ownerVO.ownerID'
                          onChange={handleChangeForOwner}
                          value={taskData.ownerVO.ownerID}
                        >
                          <option selected>Please Select</option>
                          {
                            owner?.length > 0 ? <Fragment>
                              {
                                owner.map((ownerList,index)=>{
                                  return(
                                    <option value={ownerList?.ownerID}key={index}>{ownerList.ownerName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                            </div>
                        </div>
                        <div className="row mt-3 mb-3">
                      <div className="col-md-9 mt-2">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                          <button
                          onClick={''}
                            class="btn btn-secondary me-md-2"
                            id="Cancelbtn1"
                            type="button"
                          >
                            Cancel
                          </button>
                          <button class="btn btn-primary" type="submit" id="Cancelbtn2">
                           Add Task
                          </button>
                          <button class="btn btn-primary" type="" id="Cancelbtn2">
                           Add More Task
                          </button>
                        </div>
                      </div>
                    </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

import React,{Fragment, useMemo}from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import "rc-tooltip/assets/bootstrap.css";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap" ;

export default function AddLoanTasks() {
  const [isSubmitted, setSubmitted]=useState(false);
  const navigate = useNavigate();
const[loanTaskData,setLoanTaskData]=useState({
  loanStage:{
    loanStageID:''
  },
  loanTask:"",
})
    const[stages,setStages]=useState([]);
  useEffect(()=>{
    const d1 =`https://pradhanportal.com/loanApplication/master/getAllLoanStage`;
    axios.get(d1).then((response)=>{
      console.log("response :",response.data)
  
      setStages(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[])
const handleChangeForStage=(e)=>{
setLoanTaskData({
  ...loanTaskData,
  loanStage: {
    loanStageID:e.target.value
  }
})
}
const handleChange=(e)=>{
  setLoanTaskData({
    ...loanTaskData,
    [e.target.name]: e.target.value,
  })
 }

 const handleSubmit=(e)=>{
  e.preventDefault();
  axios.post('https://pradhanportal.com/loanApplication/master/addLoanTask',loanTaskData).then((response)=>{
    console.log(response);
    setSubmitted(true);
  }).catch((err)=>{
    console.error(err);
  });
 }
 const navigateToPrevious=()=>{
  navigate('/loanTask');
 }
  return (
    <Fragment>
      {
       isSubmitted ? navigate(`/loanTask`) &
       toast.success('Loan Task Added successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
        <div className="container">
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                        <div className="card-header bg-primary d-flex justify-content-center pb-1 pt-1 cardHeader">
                        <span id="assignowner1">Add New Loan Task</span>
                        </div>
                        <div className="card-body">
                          <div className="row">
                          <div className="col-md-3 mt-4">
                      <span className="d-flex justify-content-end  ">Loan Stage</span>
                          </div>
                          <div className="col-md-8 mt-3">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='loanStage.loanStageID'
                          onChange={handleChangeForStage}
                          value={loanTaskData.loanStage?.loanStageID}
                        >
                          <option selected>Please Select</option>
                          {
                            stages?.length > 0 ? <Fragment>
                              {
                                stages.map((Stage,index)=>{
                                  return(
                                    <option value={Stage?.loanStageID}key={index}>{Stage.loanStage}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                          </select>
                          </div>
                          <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Task Name</span>
                      </div>
                      <div className="col-md-8 mt-2">
                        <input
                          type="text"
                          name="loanTask"
                          value={loanTaskData.loanTask}
                          onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter New Task Name"
                        />
                      </div>
                      {/* <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">User Role</span>
                          </div>
                          <div className="col-md-8 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='employmentType.empTypeID'
                          // onChange={handleChange}
                          // value={values.employmentType?.empTypeID}
                          // onBlur={handleBlur}
                        >
                          <option selected>Please Select</option>
                          {
                            roles?.length > 0 ? <Fragment>
                              {
                                roles.map((role,index)=>{
                                  return(
                                    <option value={role?.roleId}key={index}>{role.role}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                          </select>
                          </div> */}
                     
                          </div>
                        </div>
                            <div className="row mt-3 mb-3">
                        <div className="col-md-12">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  className="cancelButton"  onClick={navigateToPrevious}>Cancel</Button>
                        <Button color="primary" type="submit" className="submitButton">Add Task</Button>
                        </div>
                        </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
}
    </Fragment>
  )
}



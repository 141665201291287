import React,{Fragment, useMemo}from 'react'
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Input,} from "reactstrap";
import "rc-tooltip/assets/bootstrap.css";
import axios from "axios";
import moment from 'moment';
import { Pagination } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Cell } from 'gridjs';
export default function ArchivedLeads() {


    var renderTooltip = <span>Create Lead</span>;

    const navigate = useNavigate();
  const navigateToCreateLead = () => {
    // 👇️ navigate to /CreateLead
    navigate('/AddLead');
  };
  
  
    const[filterLeads,setFilterLeads]=useState([]);
    const [search,setSearch]=useState("");
    const [data, setData] = useState([]);
    const[dataAll,setDataAll]=useState([]);
     
  
    const loadLead = async (value) => {
      if (value != null) {
        const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllArchivedLeads?pageNumber=`+page+`&pageSize=`+value)
        setDataAll(result.data);
        console.log("getListOfAllCases",result.data)
      } else {
        const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllArchivedLeads?pageNumber=`+page+`&pageSize=`+showRecord)
        setDataAll(result.data);
        console.log("getListOfAllCases",result.data)
      }
    };
   
    useEffect(() => {
      loadLead();
    }, []);

    const [showRecord, setshowRecord] = useState(10);

    const  handleChanegeShowRec = (e) => {
      const { name, value, type, checked } = e.currentTarget;
   
      setshowRecord(e.currentTarget.value);
  
      loadLead(value);
     }
    //for table records per page
    const [recordCount, setrecordCount] = useState(10);
   
   
    //for pagination
   
    const [pageData, setPageData] = useState([]);
   const [page, setPage] = useState(1);
   const [pageCount, setPageCount] = useState(10);
   // handle next
   const handleNext = () => {
      if (page === pageCount) return page;
      setPage(page + 1)
   }
   
   // handle previous
   const handlePrevios = () => {
      if (page === 1) return page;
      setPage(page - 1)
   }
   
   useEffect(() => {
    loadLead()
   }, [page])
   
   useEffect(() => {
      const pagedatacount = Math.ceil(dataAll?.length / showRecord);
      setPageCount(pagedatacount);
   
      if (page) {
          const LIMIT = showRecord;
          const skip = LIMIT * page // 5 *2 = 10
           const dataskip = dataAll?.slice(page === 1 ? 0 : skip - LIMIT, skip);
          setPageData(dataskip)
      }
   }, [search,dataAll])
    useEffect(() => {
      const result=data.filter(leads=>{
        return leads.customerName.toLowerCase().match(search.toLowerCase())
      });
      setFilterLeads(result);
    }, [search]);

    const gdStatus=(dataAll)=>{
      return(
       dataAll.gdStatusVO?.gdStatus !== "Pending"  && dataAll.gdStatusVO?.gdStatus !== "In Progress" ? <Fragment>
       <div className="completeStatus px-1">
        {dataAll.gdStatusVO?.gdStatus}
       </div>
   </Fragment>:dataAll.gdStatusVO?.gdStatus !== "Pending" && dataAll.gdStatusVO?.gdStatus !== "Completed" ?
   <Fragment>
      <div className="inprogressStatus px-1">
        {dataAll.gdStatusVO?.gdStatus}
       </div>
   </Fragment>:<Fragment>
      <div className="pendingStatus px-1">
        {dataAll.gdStatusVO?.gdStatus}
       </div>
   </Fragment>
      )
     }
     const leadAgeing=(dataAll)=>{
   return(
     dataAll.days < 1 && dataAll.hours < 1 ? (
       <Fragment>
         <span className="px-1" id="leadAge1">
           {" "}
           {dataAll.minutes} Minutes ago
         </span>
       </Fragment>
     ) : dataAll.days < 1 &&
     dataAll.hours < 4 &&
     dataAll.minutes > 60 ? (
       <Fragment>
         <span className="px-1" id="leadAge1">
           {" "}
           {dataAll.hours} Hours ago
         </span>
       </Fragment>
     ) : dataAll.days < 1 &&
     dataAll.hours >= 4 ? (
       <Fragment>
         <span className=" px-1" id="leadAge2">
           {" "}
           {dataAll.hours} Hours ago
         </span>
       </Fragment>
     ) : (
       <Fragment>
         <span className=" px-1" id="leadAge3">
           {" "}
           {dataAll.days} Days ago
         </span>
       </Fragment>
     )
   )
     }
   const leadOwner=(dataAll)=>{
     return(
       dataAll.userVO?.userName === null ||
         (dataAll.userVO?.userName === undefined &&
           dataAll.gdStatusVO?.gdStatus !== "Pending") ? (
           <Fragment>
             <Link
              //  onClick={() =>
              //    NavigateTOAssignModal(dataAll.leadID)
              //  }
               className="disabledCursor"
               id="leadassignbtn"
             >
               Assign
             </Link>
           </Fragment>
         ) : dataAll.gdStatusVO?.gdStatus !== "Pending" &&
         dataAll.userVO?.userName !== null ? (
           <Fragment>
             <Link
               alt=""
               id="leadassignbtn"
               className="notDisabled"
              //  onClick={() =>
              //    NavigateTOAssignModal(dataAll.leadID)
              //  }
             >
               {dataAll.userVO?.userName}
             </Link>
           </Fragment>
         ) : (
           <Fragment>
             <Link
              //  onClick={completeJD}
               className="disabledCursor"
               id="disabledCursor"
               
             >
               Assign
             </Link>
           </Fragment>
     ))
   }

   const createdDate=(dataAll)=>{
    return moment(dataAll.createdDate)
    .format("DD-MMM-YYYY")
  }
    const columns = useMemo(
      () => [
        {
            Header: "Sr No",
            Cell: (data) => {
                return ( 
                    <>{data.row.index + 1}</>
                )},
                filterable: false,
        },
        {
          Header: "Lead ID",
          Cell: (data) => {
            return (
              <Link id='leadid1' to={`/ViewArchivedLead/${data?.row.original.leadID}`} >{data?.row.original.id}</Link>
            )}
          
        },
        {
          Header: "Date",
          accessor: "createdDate",
          filterable: false,
          
        },
        
        {
          Header: "Customer Name",
          accessor: "customerName",
          filterable: false,
          
        },
        {
          Header: "Mobile Number",
          accessor: "primaryNumber",
          filterable: false,
        },
        {
          Header: "Lead Type",
          accessor: "typesVO.leadTypeVO.leadType",
          filterable: false,
          
        },
        {
          Header: "Sub Type",
          accessor: "typesVO.type",
          filterable: false,
        },
        {
          Header: "GD Status",
          accessor: "gdStatusVO.gdStatus",
          filterable: false,
        },
       
        //   Header: "Lead Ageing",
        //   Cell: (data) => (
        //       <>
        //                  {
        //                    data?.row.original.days <  1 && data?.row.original.hours < 4 ? <Fragment><span className='px-1'id='leadAge1'>  {data.row.original.hours} Hours ago</span>
        //                   </Fragment>: data?.row.original.days > 0 && data?.row.original.hours > 24 ?<Fragment>
        //                   <span className=' px-1'id='leadAge3'> {data.row.original.days} Days ago</span>
        //                   </Fragment>:<Fragment>
        //                   <span className=' px-1'id='leadAge2'> {data.row.original.hours} Hours ago</span>
        //                   </Fragment>
        //                  }
                         
        //       </>
        //     ),
        // },
        {
          Header: "Lead Owner",
          accessor: "ownerVO.ownerName",
        //   Cell: (data) => (
        //     <>
        //         {
        //         data?.row.original.status !=="Pending" ? (
                 
        //          <> <Link to="" alt="" id='assignbtn'  className="notDisabled">   Assign </Link></>
        //      ):(
        //       <><Link to="" onClick={completeJD} className='disabledCursor'id='disabledCursor'>Assignssss</Link> </>
        //       )
        //       }
        //     </>
        //   ),
        },
        {
            Header: "Case ID",
            accessor: "id",
            filterable: false,
          },
        // {
          
      ],
      []
    );

  return (
    <Fragment>
        <Container fluid>
            <Row>
                <Col lg={12}>
                <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={3}>
                    <div className='d-flex align-items-center '>
              <div className='d-inline-block'>
                <h3> Archived Leads</h3>
              </div>
              <div className='d-inline-block mx-1'>
              </div>
            </div>
              </Col>
              <Col sm={5}></Col>
                  <Col sm={8} style={{marginTop:"0px", paddingLeft:"30px"}}>
                  <div className='' style={{float:'left'}}>
           <select  id="inputState" name='showrecc' onChange={handleChanegeShowRec}
           value={showRecord}
                     className="selectdrpdn form-control" style={{height:"32px",marginTop:"4px"}}>
                     <option  value="10">Show 10</option>
                     <option value="25">Show 25</option>
                     <option value="50">Show 50</option>
                     <option value="100">Show 100</option>
   
                     </select>
                     </div>
                  </Col>
                  {/* <Col sm={1}></Col> */}
                  <Col sm={1} style={{marginTop:"0px"}}>
                  <div className="d-inline-block">
                      </div>
                  </Col>
                  <Col sm={3} style={{marginTop:"0px",paddingRight:"30px"}}>
                
                    <div className="search-box">
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder="Search for..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                <div className="main">
                {
                 dataAll?.length > 0 && dataAll?.deleteFlag!==null?(
                    <>
                    <div className="container-fluid">
                 <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive" style={{overflowX:"auto",height:"600px"}}
                    id="tableData">
                      <table className="table align-items-center table-flush table-hover" id="dataTableHover">
                        <thead className="tableheader">
                        <tr>    
          <th scope="col" className="tableroww">Sr.No.</th>
       <th scope="col" className="tableroww">Lead ID</th>
         <th scope="col" className="tableroww">Date</th>
         <th scope="col" className="tableroww">Customer Name</th>
         <th scope="col" className="tableroww">Mobile Number</th>
         <th scope="col" className="tableroww">Lead Type</th>
         <th scope="col" className="tableroww">Sub Type</th>
         <th scope="col" className="tableroww">GD Status</th>
         <th scope="col" className="tableroww">Lead Ageing</th>
         <th scope="col" className="tableroww">Lead Owner</th>
       </tr>
     </thead>
     <tbody>
      
       {
           pageData?.map((dataAll,index)=>( 
               <tr key={index}>
               <th scope='row' className="tableroww1">{index + 1}</th>
               <td className="tableroww1 capitalizetxxxt">
               <Link id="leadid1" to={`/ViewArchivedLead/${dataAll.leadID}`} >
         {dataAll.id}
            </Link>
               </td>
               <td className="tableroww1 capitalizetxxxt">{createdDate(dataAll)}</td>
                <td className="tableroww1 capitalizetxxxt">{dataAll.customerName}</td>
               <td className="tableroww1 capitalizetxxxt">{dataAll.primaryNumber}</td>
               <td className="tableroww1 capitalizetxxxt">{dataAll.typesVO.leadTypeVO.leadType}</td>
               <td className="tableroww1 capitalizetxxxt">{dataAll.typesVO.type}</td>
               <td className="tableroww1 capitalizetxxxt">{gdStatus(dataAll)}</td>
               <td className="tableroww1 capitalizetxxxt">{leadAgeing(dataAll)}</td>
               <td className="tableroww1 capitalizetxxxt">{leadOwner(dataAll)}</td>
               </tr>
   
   
            ))
       } 
     </tbody>
       </table>
       {dataAll?.length > 0 && (
                      <div className="" style={{ float: "right" }}>
                        <Pagination>
                          <Pagination.Prev
                            onClick={() => handlePrevios()}
                            disabled={page === 1}
                          />
                          {Array(pageCount)
                            .fill(null)
                            .map((ele, index) => {
                              return (
                                <>
                                  <Pagination.Item
                                    active={page === index + 1 ? true : false}
                                    onClick={() => setPage(index + 1)}
                                  >
                                    {index + 1}
                                  </Pagination.Item>
                                </>
                              );
                            })}
                          <Pagination.Next
                            onClick={() => handleNext()}
                            disabled={page === pageCount}
                          />
                        </Pagination>
                      </div>
                    )}
                            </div>
                  </div>
                 </div>
                 </div>
                     </>
                  ):<>
                  <div>No Records Available</div></>
                }
                  </div>
                  </CardBody>
                </Card>
                </Col>
            </Row>
        </Container>
    </Fragment>
  )
}



import React,{Fragment, useMemo}from 'react'
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Input,} from "reactstrap";
import "rc-tooltip/assets/bootstrap.css";
// import "../../assets/scss/pages/lead.css";
import "../../../assets/scss/pages/lead.css";
import axios from "axios";
import Tooltip from "rc-tooltip";
import TableContainer from '../../../Components/Common/TableContainer';
import { time } from 'echarts';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Cell } from 'gridjs';
import moment from 'moment';
export default function InsuranceTasks() {
  var renderTooltip = <span>Add New Task</span>;

    const navigate = useNavigate();
  const navigateToCreateLead = () => {
    // 👇️ navigate to /CreateLead
    navigate('/AddNewCustomer');
  };
  
  const completeJD = () => {
    toast.error('Error! Complete the General Discussion First', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toast-message'
    });
};
    const[filterLeads,setFilterLeads]=useState([]);
    const [search,setSearch]=useState("");
    const [data, setData] = useState([]);
   
     
    const loadLead = async () => {
      const result = await axios.get(`https://pradhanportal.com/loanApplication/customer/getAllCustomer`);
      setData(result.data);
      
      setFilterLeads(result.data);
    };

   
    useEffect(() => {
      loadLead();
    }, []);
    useEffect(() => {
      const result=data.filter(leads=>{
        return leads.customerName.toLowerCase().match(search.toLowerCase())
      });
      setFilterLeads(result);
    }, [search]);
  const columns = useMemo(
    () => [
      {
        Header: "Sr No",
        // accessor: "customerName",
        // filterable: false
      },
      {
        Header: "Task ID",
        // Cell: (data) => {
        //   return (
        //     <Link id='leadid1' to={`/ViewCustomer/${data?.row.original.customerId}`} >{data?.row.original.generatedID}</Link>
        //   )}
      },
      {
        Header: "Task Name",
        // Cell: (data) => {
        //   return (
        //     <Link id='leadid1' to={`/ViewCustomer/${data?.row.original.customerId}`} >{data?.row.original.generatedID}</Link>
        //   )}
      },
      {
        Header: "Stage",
        // accessor: (d) => {
        //   return moment(d.dob)
        //     .format("DD-MMM-YYYY")
        // },
        // filterable: false
      },
      {
        Header: "Role",
        // accessor: "primaryNumber",
        // filterable: false
      },
      {
        Header: "Created Date",
        // accessor: "customerName",
        // filterable: false
      }
      
    ],
    []
  );
  return (
    <fragment>
      <Container fluid>
            <Row>
                <Col lg={12}>
                <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={3}>
                    <div className='d-flex align-items-center '>
              <div className='d-inline-block'>
                <h3> Loan Task</h3>
              </div>
              <div className='d-inline-block mx-1'>
              <Tooltip 
                   placement="right"
                   overlay={renderTooltip}
              >
              <h2><i onClick={navigateToCreateLead} className="bi bi-plus-square-fill btn btn-lg"></i> </h2>  
            </Tooltip>
              </div>
            </div>
              </Col><Col sm={6}></Col>
              <Col sm={3}>
                      <div className="search-box">
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder="Search for..."
                          value={search}
                          onChange={(e)=>setSearch(e.target.value)}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                <div className="main">
                {
                  // data?.deleteFlag!==null?(
                    <TableContainer
                    columns={columns}
                    data={filterLeads}
                    isGlobalFilter={false}
                    isAddUserList={false}
                    customPageSize={5}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-0"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-secondary"
                    isLeadsFilter={false}
                    SearchPlaceholder='Search for'
                    highlightOnHover
                   
                  />
                  // ):null
                }
                  </div>
                  </CardBody>
                </Card>
                </Col>
            </Row>
        </Container>
    </fragment>
  )
}

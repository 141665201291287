import React from "react";
import { Fragment } from "react";
import "../../assets/scss/pages/lead.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
// import "../../store/lead/leadAction";
import { createLead } from "../../store/actions";
import {leadForm} from "../../schema";
import { useFormik } from "formik";

const initialValues={
    title: "",
    customerName: "",
    gender: "",
    residentType: "",
    leadSource: "",
    primaryNumber: "",
    secondaryNumber: "",
    email: "",
    leadInitiator: "",
    leadOwner: "",
    leadType: "",
    
    loanType: "",
    loanAmount: "",
    notes: "",
};

export default function Lead() {
//   const dispatch = useDispatch();
//   const [customer, setCustomer] = useState({
//     title: "",
//     customer_name: "",
//     gender: "",
//     resident_type: "",
//     lead_source: "",
//     primary_number: "",
//     secondary_number: "",
//     email: "",
//     lead_initiator: "",
//     lead_owner: "",
//     lead_type: "",
//     loan_type: "",
//     loan_amount: "",
//     notes: "",
    
//   });
//   const [errors,setErrors]=useState({})
  const {values,errors,handleBlur,touched,handleChange,handleSubmit} =useFormik({
    initialValues:initialValues,
    validationSchema:leadForm,
    
    onSubmit:(values)=>{
      values.preventDefault();
        console.log(values);
    }
    
    
  });
 
  console.log(errors);
  
//   const SubmitAddCustomer = (e) => {
    
//     console.log(customer);
//     e.preventDefault();
//     const validationErrors={}
//     if(!customer.customer_name.trim()){
//         validationErrors.customer_name = "Please enter your name"
//     }
//     setErrors(validationErrors)

//     if(Object.keys(validationErrors).length===0){
//         alert("Form submitted successfully");
//     }
//     dispatch(createLead(customer))
//   };


  return (
    <Fragment>
      {/* <pre>{JSON.stringify(customer)}</pre> */}
      <div className="container-fluid leads"> 
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <form  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}>
                <div className="row">
                  <div className="col-md-6">
                    <h4 id="subHeading">Customer Details</h4>
                    <div className="row">
                      <div className="col-md-3">
                        <span id="title">Title</span>
                      </div>
                      <div className="col-md-8">
                        <select
                        //   value={customer.title}
                        //   onChange={(e) =>
                        //     setCustomer({
                        //       ...customer,
                        //       title: e.target.value,
                        //     })
                         // }
                         value={values.title}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         name="title"
                                autoComplete="off"
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                        >
                          <option selected>Please select</option>
                          <option value="Capt">Capt.</option>
                          <option value="Dr">Dr.</option>
                          <option value="M/S">M/S</option>
                          <option value="Mr.">Mr.</option>
                          <option value="Mrs.">Mrs.</option>
                          <option value="Ms.">Ms.</option>
                          <option value="Prof.">Prof.</option>
                        </select>
                        {errors.title && touched.title ?( <p className="form-error">{errors.title}</p>): null}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <span id="span">Customer Name</span>
                      </div>
                      <div className="col-md-8">
                        <input
                        //   required
                        // name={values.customer_name}
                         // value={customer.customer_name}
                        //   onChange={(e) =>
                        //     setCustomer({
                        //       ...customer,
                        //       customer_name: e.target.value,
                        //     })
                        //   }
                        value={values.customerName}
                         onChange={handleChange}
                         onBlur={handleBlur}
                        name="customerName"
                                autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="FirstName , LastName"
                          
                          aria-describedby="basic-addon1"
                        />
                        {/* {errors.customer_name && <span id="errors">{errors.customer_name}</span>} */}
                        {errors.customerName && touched.customerName ?( <p className="form-error">{errors.customerName}</p>): null}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <span id="gender">Gender</span>
                      </div>
                      <div className="col-md-8">
                        <div className="form-check form-check-inline">
                          <input
                            // value={customer.gender}
                            // onChange={(e) =>
                            //   setCustomer({
                            //     ...customer,
                            //     gender: e.target.value,
                            //   })
                            // }
                            value={values.gender}
                         onChange={handleChange}
                         onBlur={handleBlur}
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            autoComplete="off"
                            id="inlineRadio1"
                            value="Male"
                          />
                          <label
                            className="form-check-label"
                            for="inlineRadio1"
                          >
                            Male
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            // value={customer.gender}
                            // onChange={(e) =>
                            //   setCustomer({
                            //     ...customer,
                            //     gender: e.target.value,
                            //   })
                            // }
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="Female"
                          />
                          <label
                            className="form-check-label"
                            for="inlineRadio2"
                          >
                            Female
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            // value={customer.gender}
                            // onChange={(e) =>
                            //   setCustomer({
                            //     ...customer,
                            //     gender: e.target.value,
                            //   })
                            // }
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio3"
                             value="Other"
                          />
                          <label
                            className="form-check-label"
                            for="inlineRadio3"
                          >
                            Other
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <span id="resident">Resident Type</span>
                      </div>
                      <div className="col-md-8">
                        <select
                        //   value={customer.resident_type}
                        //   onChange={(e) =>
                        //     setCustomer({
                        //       ...customer,
                        //       resident_type: e.target.value,
                        //     })
                        //   }
                        value={values.residentType}
                         onChange={handleChange}
                         onBlur={handleBlur}
                        name="residentType"
                                autoComplete="off"
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                        >
                          <option selected>Please select</option>
                          <option value="Residental">Residental</option>
                          <option value="Non-Residental">Non-Residental</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <span id="leadsource">Lead Source</span>
                      </div>
                      <div className="col-md-8">
                        <select
                        //   value={customer.lead_source}
                        //   onChange={(e) =>
                        //     setCustomer({
                        //       ...customer,
                        //       lead_source: e.target.value,
                        //     })
                        //   }
                        value={values.leadSource}
                         onChange={handleChange}
                         onBlur={handleBlur}
                        name="leadSource"
                                autoComplete="off"
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                        >
                          <option selected>Please select</option>
                          <option value="Anuj Jain">Anuj Jain</option>
                          <option value="Anupam">Anupam</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h4 id="subHeading2">Customer Contact</h4>
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <span id="pmn">Primary mobile number</span>
                      </div>
                      <div className="col-md-7">
                        <input
                        //   value={customer.primary_number}
                        //   onChange={(e) =>
                        //     setCustomer({
                        //       ...customer,
                        //       primary_number: e.target.value,
                        //     })
                        //   }
                        
                        value={values.primaryNumber}
                         onChange={handleChange}
                         onBlur={handleBlur}
                          type="number"
                          name="primaryNumber"
                                autoComplete="off"
                          className="form-control"
                          placeholder="Enter primary mobile number"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                        {errors.primaryNumber && touched.primaryNumber ?( <p className="form-error">{errors.primaryNumber}</p>): null}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <span id="secnum">Secondary mobile number</span>
                      </div>
                      <div className="col-md-7">
                        <input
                        //   value={customer.secondary_number}
                        //   onChange={(e) =>
                        //     setCustomer({
                        //       ...customer,
                        //       secondary_number: e.target.value,
                        //     })
                        //   }
                        value={values.secondaryNumber}
                         onChange={handleChange}
                         onBlur={handleBlur}
                        name="secondaryNumber"
                                autoComplete="off"
                          type="number"
                          className="form-control"
                          placeholder="Enter secondary mobile number"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <div className="col-md-1" id="whatsapp">
                        <h3>
                          <i class="bi bi-whatsapp"></i>
                        </h3>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <span id="email">Email ID</span>
                      </div>
                      <div className="col-md-7">
                        <input
                        //   value={customer.email}
                        //   onChange={(e) =>
                        //     setCustomer({
                        //       ...customer,
                        //       email: e.target.value,
                        //     })
                        //   }
                        value={values.email}
                         onChange={handleChange}
                         onBlur={handleBlur}
                        name="email"
                                autoComplete="off"
                          type="email"
                          className="form-control"
                          placeholder="Enter email ID"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <h4 id="subHeading">Lead Details</h4>
                      <div className="row mt-2">
                        <div className="col-md-3">
                          <span id="leadini">Lead Initiator</span>
                        </div>
                        <div className="col-md-8">
                          <select
                            // value={customer.lead_initiator}
                            // onChange={(e) =>
                            //   setCustomer({
                            //     ...customer,
                            //     lead_initiator: e.target.value,
                            //   })
                            // }\
                            value={values.leadInitiator}
                         onChange={handleChange}
                         onBlur={handleBlur}
                            name="leadInitiator"
                                autoComplete="off"
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                          >
                            <option selected>Please select</option>
                            <option value="Anuj Jain">Anuj Jain</option>
                            <option value="Anupam">Anupam</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-3">
                          <span id="leadsource">Lead Owner</span>
                        </div>
                        <div className="col-md-8">
                          <select
                            // value={customer.lead_owner}
                            // onChange={(e) =>
                            //   setCustomer({
                            //     ...customer,
                            //     lead_owner: e.target.value,
                            //   })
                            // }
                            value={values.leadOwner}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="leadOwner"
                                autoComplete="off"
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                          >
                            <option selected>Please select</option>
                            <option value="Anuj Jain">Anuj Jain</option>
                            <option value="Anupam">Anupam</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-3">
                          <span id="leadtype">Lead Type</span>
                        </div>
                        <div className="col-md-8">
                          <div className="form-check form-check-inline">
                            <input
                             // value={customer.lead_type}
                            //   onChange={(e) =>
                            //     setCustomer({
                            //       ...customer,
                            //       lead_type: e.target.value,
                            //     })
                            //   }
                            value={values.leadType}
                         onChange={handleChange}
                         onBlur={handleBlur}
                              className="form-check-input"
                              type="radio"
                              name="loan"
                              
                                autoComplete="off"
                              id="inlineRadio4"
                              value="Loan"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio4"
                            >
                              Loan
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                            //   value={customer.lead_type}
                            //   onChange={(e) =>
                            //     setCustomer({
                            //       ...customer,
                            //       lead_type: e.target.value,
                            //     })
                            //   }
                            
                                autoComplete="off"
                              className="form-check-input"
                              type="radio"
                              name="insurance"
                              id="inlineRadio5"
                              value="Insurance"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio5"
                            >
                              Insurance
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mt-2">
                      <h4 id="subHeading3">Loan Details</h4>
                      <div className="row mt-2">
                        <div className="col-md-4">
                          <span id="loanType">Loan Type</span>
                        </div>
                        <div className="col-md-7">
                          <select
                            // value={customer.loan_type}
                            // onChange={(e) =>
                            //   setCustomer({
                            //     ...customer,
                            //     loan_type: e.target.value,
                            //   })
                            // }
                            value={values.loanType}
                         onChange={handleChange}
                         onBlur={handleBlur}
                            name="loanType"
                                autoComplete="off"
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                          >
                            <option selected>Please select</option>
                            <option value="Home Loan">Home Loan</option>
                            <option value="Car Loan">Car Loan</option>
                            <option value="SME Loan">SME Loan</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-4">
                          <span id="loanAmount">Loan Amount</span>
                        </div>
                        <div className="col-md-7">
                          <input
                            // value={customer.loan_amount}
                            // onChange={(e) =>
                            //   setCustomer({
                            //     ...customer,
                            //     loan_amount: e.target.value,
                            //   })
                            // }
                            value={values.loanAmount}
                         onChange={handleChange}
                         onBlur={handleBlur}
                            type="number"
                            name="loanAmount"
                                autoComplete="off"
                            className="form-control"
                            placeholder="Enter loan amount"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-3">
                            <span id="gender">Notes</span>
                          </div>
                          <div className="col-md-8">
                            <div className="form-group">
                              <textarea
                                // value={customer.notes}
                                // onChange={(e) =>
                                //   setCustomer({
                                //     ...customer,
                                //     notes: e.target.value,
                                //   })
                                // }
                                value={values.notes}
                         onChange={handleChange}
                         onBlur={handleBlur}
                                name="notes"
                                autoComplete="off"
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                maxLength={300}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 mb-3">
                      <div className="col-md-12">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                          <button
                            class="btn btn-secondary me-md-2"
                            id="btn-cancel"
                            type="button"
                          >
                            Cancel
                          </button>
                          <button class="btn btn-primary" type="submit">
                            Create Lead
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

import React,{useState,useEffect,Fragment} from 'react';

export default function ContactDetails({handleNext,addLeadDetails,handleBack,leadData,setLeadData}) {
    const handleInputChange =(e)=>{
        setLeadData({
         ...leadData,
         [e.target.name]:e.target.value
        });
     };
     const [error, setError] = useState(false);
     const [contactValidEroor ,setContactValidEroor] = useState(false);
    const submitFormData = (e) => {
        e.preventDefault();
        console.log("LeadData in CustomerDetails:::",leadData)
        if (leadData.leadTypeVO.leadTypeID === "1"  &&  leadData.customerType === "Individual" && leadData.primaryNumber == "" ) {
          setError(true);
        }
        // else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(leadData.primaryNumber)){
        //   setContactValidEroor(true);
          
        // }
        else if ((  leadData.customerType !=="Non-Individual" && leadData.primaryNumber != null && leadData.primaryNumber != "")
      && (leadData.primaryNumber?.length != 10) ){
        setError(true);
      }
      else if (( leadData.customerType !=="Non-Individual" && leadData.secondaryNumber != null && leadData.secondaryNumber != "")
      && (leadData.secondaryNumber?.length != 10) ){
        setError(true);
      }
      else if ((leadData.directorPrimaryNumber != null && leadData.directorPrimaryNumber != "")
      && (leadData.directorPrimaryNumber?.length != 10) ){
        setError(true);
      }
      else if ((leadData.directorSecondaryNumber != null && leadData.directorSecondaryNumber != "")
      && (leadData.directorSecondaryNumber?.length != 10) ){
        setError(true);
      }
      else if ((leadData.leadTypeVO.leadTypeID === "1"  &&  leadData.customerType === "Non-Individual" && leadData.contactPerson1stNumber != null && leadData.contactPerson1stNumber != "")
      && (leadData.contactPerson1stNumber?.length != 10) ){
        setError(true);
      }
        else if (leadData.leadTypeVO.leadTypeID === "1"  &&  leadData.customerType === "Non-Individual" && leadData.contactPerson1stNumber == "" || leadData.contactPerson1stNumber == null) {
          setError(true);
        } 
        else if (leadData.customerType !== "Non-Individual" && leadData.emailID === "" || leadData.customerType !== "Non-Individual" && leadData.emailID === null) {
          setError(true);
        } 
        else if (leadData.leadTypeVO.leadTypeID === "1"  &&  leadData.customerType === "Non-Individual" && leadData.contactPersonEmail == "" || leadData.contactPersonEmail == null) {
          setError(true);
        }
       
        else {
          handleNext()
        }
     }
  return (
    <Fragment>
        <div className="container">
        {
           leadData.customerType !=="Non-Individual"? (
               <Fragment>
                <div className="row mt-4">
                        <div className="col-md-4 mt-2">
                          <span
                            className="d-flex justify-content-end "
                            id="mandatory"
                          >
                            Primary Mobile Number
                          </span>
                        </div>
                        <div className="col-md-5">
                          <input
                            type="number"
                            name="primaryNumber"
                            className="form-control"
                            placeholder="Enter  Mobile Number"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleInputChange}
                            value={leadData.primaryNumber}
                          />
                        </div>
                        <div className="col-md-1" id="whatsapp">
                          <span className="mx-1" id="whatsapp">
                            <i class="bi bi-whatsapp"></i>
                          </span>
                        </div>
                        <div className="col-md-4"></div>
                            <div className="col-md-7">
                              
                                {/* <span className={contactValidEroor?'':"hide"} style={{color:"red",fontSize:"12px" }}>Please Enter Valid Mobile No</span> */}
                            {error &&  leadData.customerType !=="Non-Individual" &&  leadData.primaryNumber == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Number is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                     {error &&  leadData.primaryNumber.length !=10 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Mobile No is 10 digits only.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                      </div>
                        
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Secondary Mobile Number
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter  Mobile Number"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            name="secondaryNumber"
                            onChange={handleInputChange}
                            value={leadData.secondaryNumber}
                          />
                        </div>
                        <div className="col-md-4"></div>
                            <div className="col-md-7">
                                     {error &&  leadData.secondaryNumber.length !=10 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Mobile No is 10 digits only.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                      </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <span className="d-flex justify-content-end"  id="mandatory">
                            Email ID
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Email ID"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            name="emailID"
                            onChange={handleInputChange}
                            value={leadData.emailID}
                          />
                        </div>
                        <div className="col-md-4"></div>
                            <div className="col-md-7">
                                     {error &&  leadData.customerType !== "Non-Individual" && leadData.emailID === "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Email ID is required
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                      </div>
                      </div>
               </Fragment>
            ):null
        }
        {
           leadData.leadTypeVO.leadTypeID === "1"  &&  leadData.customerType ==="Non-Individual" ?(
                <Fragment>
                    <div className="row mt-4">
                        <div className="col-md-4 mt-2">
                          <span
                            className="d-flex justify-content-end "
                          >
                            Director 1st Mo.No.
                          </span>
                        </div>
                        <div className="col-md-5">
                          <input
                            type="number"
                            name="directorPrimaryNumber"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleInputChange}
                            value={leadData.directorPrimaryNumber}
                          />
                        </div>
                        <div className="col-md-1" id="whatsapp">
                        </div>
                        <div className="col-md-4"></div>
                            <div className="col-md-7">
                                     {error &&  leadData.directorPrimaryNumber.length !=10 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Mobile No is 10 digits only.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                      </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <span className="d-flex justify-content-end  ">
                          Director 2nd Mo.No.
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter  Mobile Number"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            name="directorSecondaryNumber"
                            onChange={handleInputChange}
                            value={leadData.directorSecondaryNumber}
                          />
                        </div>
                        <div className="col-md-4"></div>
                            <div className="col-md-7">
                                     {error &&  leadData.directorSecondaryNumber.length !=10 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Mobile No is 10 digits only.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                      </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <span className="d-flex justify-content-end  ">
                           Director Email ID
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Email ID"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            name="directorEmailID"
                            onChange={handleInputChange}
                            value={leadData.directorEmailID}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end "
                            id="mandatory"
                          >
                           Contact Person 1st Mo.No.
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="number"
                            name="contactPerson1stNumber"
                            className="form-control"
                            placeholder="Enter  Mobile Number"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleInputChange}
                            value={leadData.contactPerson1stNumber}
                          />
                        </div>
                        <div className="col-md-1" id="whatsapp">
                          <span className="mx-1" id="whatsapp">
                            <i class="bi bi-whatsapp"></i>
                          </span>
                        </div>
                        <div className="col-md-4"></div>
                            <div className="col-md-7">
                            {error && leadData.leadTypeVO.leadTypeID === "1"  &&  leadData.customerType === "Non-Individual" &&   leadData.contactPerson1stNumber == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Number is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                     {error &&  leadData.contactPerson1stNumber.length !=10 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Mobile No is 10 digits only.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                      </div>
                       
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <span className="d-flex justify-content-end  ">
                          Contact Person 2nd Mo.No.
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter  Mobile Number"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            name="contactPerson2ndNumber"
                            onChange={handleInputChange}
                            value={leadData.contactPerson2ndNumber}
                          />
                        </div>
                        <div className="col-md-4"></div>
                            <div className="col-md-7">
                                     {error &&  leadData.contactPerson2ndNumber.length !=10 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Mobile No is 10 digits only.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                      </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <span className="d-flex justify-content-end  ">
                          Contact Person Email ID
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <input
                            type="emailID"
                            className="form-control"
                            placeholder="Enter Email ID"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            name="contactPersonEmail"
                            onChange={handleInputChange}
                            value={leadData.contactPersonEmail}
                          />
                        </div>
                        <div className="col-md-4"></div>
                            <div className="col-md-7">
                                     {error &&  leadData.customerType === "Non-Individual" && leadData.contactPersonEmail === "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Email ID is required
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                      </div>
                      </div>
                </Fragment>
            ):null
        }
          <div className="row mt-4">
                          <div className="col-md-12 d-flex justify-content-center">
                          <button
                          className="btn btn-secondary  previousButton"
                          onClick={handleBack}
                        >
                          Previous
                        </button>
                        <button onClick={submitFormData}
                        className="btn btn-primary nextButton"
                        >
                          Next
                        </button>
                          </div>
                        </div>
        </div>
    </Fragment>
  )
}

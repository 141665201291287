import React,{Fragment} from 'react';
import { useState,useEffect } from 'react'
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import {Button} from 'reactstrap';
export default function AddDocuments() {


  const[data,setData]=useState({
    type:'',
    applicantId:'',
    documentType:'',
    documentName:'',
    FileList:{
      name:'',
      type:''
    },
    email:''
  })

  const {LoanCaseId}=useParams();
  const navigate = useNavigate();
  console.log("loanCaseId",LoanCaseId);

      const [applicant, setApplicant]=useState({});
      useEffect (()=>{
      const dataUrl =`https://pradhanportal.com/loanApplication/customer/getAllApplicantByLoanCaseID?loanCaseID=${LoanCaseId}`;
          axios.get(dataUrl).then((response)=>{
          
            console.log("response :",response.data)
            setApplicant(response.data);
          }).catch((err)=>{
             console.log(err);
          });
      
    },[LoanCaseId]);
    console.log("applicant",applicant);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const handleFileChange = (e) => {
     console.log(e.target.files);
       setSelectedFiles([...e.target.files]);
    };

    const handleUpload = async () => {
      if (selectedFiles.length === 0) {
         alert('Please select files first');
         return;
      }
      const formData = new FormData();
      selectedFiles.forEach((file) => {
         formData.append('files', file);
      });
      try {
         // Replace this URL with your server-side endpoint for handling file uploads
         const response = await fetch('https://your-upload-endpoint.com/upload', {
            method: 'POST',
            body: formData,
         });
         if (response.ok) {
            alert('Files uploaded successfully');
         } else {
            alert('Failed to upload the files');
         }
      } catch (error) {
         console.error('Error while uploading the files:', error);
         alert('Error occurred while uploading the files');
      }
   };
  return (
    <Fragment>
   <div className="container">
            <div className="row">
                <div className="col-md-12">
                   <div className="card">
                    <form action="">
                    <div className="row">
                  <div className="col-md-9 mt-3">
                  <span className='customerDetails'>Add Documents</span>
                   <div className="row">
                             <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  "> Applicant Name</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='maritalStatus.maritalID'
                        >
                          <option value="">Please Select</option>
                          {
                            applicant?.length > 0 ? <Fragment>
                              {
                                applicant.map((apl,index)=>{
                                  return(
                                    <option value={apl?.applicantId}key={index}>{apl.applicantName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="co-md-3"></div>
                          
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Document Type</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='maritalStatus.maritalID'
                        >
                          <option value="">Please Select</option>
                          <option value="KYC">KYC</option>
                          <option value="Financial">Financial</option>
                          <option value="Banking">Banking</option>
                          <option value="Property">Property</option>
                        </select>
                          </div>
                          <div className="co-md-3"></div>

                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Document Name</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='maritalStatus.maritalID'
                        >
                          <option value="">Please Select</option>
                          <option value="AADHAR">AADHAR</option>
                          <option value="PAN">PAN</option>
                          <option value="Company Id">Company Id</option>
                          <option value="Salary Slip">Salary Slip</option>
                        </select>
                          </div>
                          <div className="co-md-3"></div>
                          <div className="col-md-3 mt-3">
                           <span className="d-flex justify-content-end  ">Upload Document</span>
                          </div>
                          <div className="col-md-6 mt-3">
                          <input type="file" multiple onChange={handleFileChange} />
                          </div>
                          <div className="co-md-3"></div>
                          <div className="col-md-3 mt-3">
                          </div>
                          <div className="col-md-6 mt-4">
                          <div className="d-grid gap-2 d-md-flex justify-content-md-center p-3">
                          <Button
                                  color="secondary"
                                  id="btn-cancel"
                                  onClick={""}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  type="submit"
                                  id="btn-cancel"
                                >
                                  Add Document
                                </Button></div>
                          </div>
                   </div>
                   </div>
                   </div>
                   </form>
                   </div>
                   </div>
                   </div>
                   </div>

    </Fragment>
  )
}

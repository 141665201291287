import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumb,BreadcrumbItem} from 'reactstrap';
import CreateCustomer from './CreateCustomer';
import "../../../assets/scss/pages/customer.css";




const AddNewCustomer = () => {
document.title="Pradhan Portal";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid >
                <Row>
                <Col xs={12}>
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4>Customer Management</h4>
                   <Breadcrumb listTag="div"  className="page-title-right"> 
                  <BreadcrumbItem
                  className='.breadcrumb-item'
                         href="/customers"
                         tag="a"
                  >
                Customer Management
                </BreadcrumbItem>
                 <BreadcrumbItem
                 className='.breadcrumb-item'
                         href="/customers"
                         tag="a"
                 >
                   Customer Master
                 </BreadcrumbItem>
                    <BreadcrumbItem
                    className='.breadcrumb-item'
                         active
                         tag="span"
                    >
                      Add New Customer
                 </BreadcrumbItem>
           </Breadcrumb>
       </div>
                </Col>
               </Row>
                    <Row>
                        <Col lg={12}>
                          <CreateCustomer/>
                            {/* <UpgradeAccountNotise />
                            <Widget /> */}
                        </Col>
                        {/* <LiveUsers /> */}
                    </Row>
                    
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddNewCustomer;